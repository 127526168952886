import { FilterAlt } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  DialogContent,
  FormControlLabel,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CustomLoader from "app/components/common/CustomLoader";
import CommonFilterDialog from "app/components/common/Dialogs/CommonFilterDialog";
import StudentCard from "app/components/common/Dialogs/ledger/books/components/StudentCard";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { getStudentLogic } from "app/services/academics";
import { getClassDropdownLogic } from "app/services/reception";
import { MenuProps } from "app/utils/helper";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const Table1Filter = ({ loading, mutate }) => {
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const [state, setState] = useState({
    dialog: false,
    from: new Date(),
    to: new Date(),
    className: "",
    classDocId: "",
    section: "",
    studentDocId: "",
    sectionList: [],
    selectedStudent: "",
  });
  const [checkboxes, setCheckboxes] = useState({
    createdOn: true,
    class_and_studetName: false,
  });
  const handleSaveAndView = () => {
    mutate({
      status: "ACTIVE",
      ...(checkboxes.createdOn && {
        feeReportFrom: state.from,
        feeReportTo: state.to,
      }),
      ...(checkboxes.class_and_studetName &&
        state.className && { className: state.className }),
      ...(checkboxes.class_and_studetName &&
        state.section && { section: state.section }),
      ...(checkboxes.class_and_studetName &&
        state.studentDocId && { studentDocId: state.studentDocId }),
    });
    setState((prev) => ({ ...prev, dialog: false }));
  };
  //fetch class data
  const { data: getClassDropdownLogicData } = useQuery({
    queryKey: ["getClassDropdownLogic", selectedSession, selectedMedium],
    queryFn: () =>
      getClassDropdownLogic({ schoolDocId, selectedSession, selectedMedium }),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    data: studentData,
    isLoading: studentLoading,
    isFetching: studentFetching,
  } = useQuery({
    queryKey: ["getStudentInfo", state.section],
    queryFn: () =>
      getStudentLogic({
        streamValue: state.classDocId,
        section: state.section,
        session: selectedSession,
      }),
    enabled: state.classDocId !== "" && state.section !== "",
  });
  useEffect(() => {
    handleSaveAndView();
  }, []);

  return (
    <>
      <CustomLoader show={studentLoading || studentFetching} />
      {/* filter dialog */}
      <CommonFilterDialog
        open={state.dialog}
        onClose={() => setState((prev) => ({ ...prev, dialog: false }))}
        title="Filter"
        minWidth="550px"
        handleSaveAndView={handleSaveAndView}
        loading={loading}
      >
        <DialogContent>
          <Box>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.createdOn}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  createdOn: !prev.createdOn,
                }))
              }
              label="Created On"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}
            />
            {checkboxes.createdOn && (
              <Stack direction={"row"} gap={2}>
                <Box>
                  <TextFieldLabel title="From" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.from}
                    onChange={(e) => setState((prev) => ({ ...prev, from: e }))}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Box>
                <Box>
                  <TextFieldLabel title="To" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.to}
                    onChange={(e) => setState((prev) => ({ ...prev, to: e }))}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Box>
              </Stack>
            )}
          </Box>
          <Box>
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={checkboxes.class_and_studetName}
              onChange={() =>
                setCheckboxes((prev) => ({
                  ...prev,
                  class_and_studetName: !prev.class_and_studetName,
                }))
              }
              label="Class - Student Name"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 500,
                },
              }}
            />
            {checkboxes.class_and_studetName && (
              <Grid container spacing={2}>
                <Grid item xs={12} md={4.5}>
                  <TextFieldLabel title="Class" />
                  <CustomSelectBox
                    fullWidth
                    displayEmpty
                    value={state.classDocId}
                    size="small"
                    MenuProps={MenuProps}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {getClassDropdownLogicData?.data?.list.map((item, idx) => (
                      <MenuItem
                        key={idx}
                        value={item.classDocId}
                        onClick={() =>
                          setState((prev) => ({
                            ...prev,
                            classDocId: item.classDocId,
                            sectionList: item.sections,
                            section: "",
                            className: item?.className,
                          }))
                        }
                      >
                        {item?.className_stream}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Grid>
                <Grid item xs={12} md={3}>
                  <TextFieldLabel title="Section" />
                  <CustomSelectBox
                    fullWidth
                    displayEmpty
                    size="small"
                    value={state.section}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        section: e.target.value,
                        studentDocId: "",
                      }))
                    }
                    MenuProps={MenuProps}
                    disabled={state.classDocId ? false : true}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {state.sectionList?.map((item, idx) => (
                      <MenuItem key={idx} value={item}>
                        {item}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Grid>
                <Grid item xs={12} md={4.5}>
                  <TextFieldLabel title="Student" />
                  <CustomSelectBox
                    displayEmpty
                    size="small"
                    fullWidth
                    value={state.studentDocId}
                    MenuProps={MenuProps}
                    renderValue={
                      state.studentDocId === ""
                        ? () => "Select"
                        : () => state.selectedStudent?.firstName
                    }
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        studentDocId: e.target.value?._id,
                        selectedStudent: e.target.value,
                      }))
                    }
                  >
                    <MenuItem value="">Select</MenuItem>
                    {studentData &&
                      studentData?.data?.list?.map((student) => (
                        <MenuItem key={student._id} value={student}>
                          <StudentCard item={student} />
                        </MenuItem>
                      ))}
                  </CustomSelectBox>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
      </CommonFilterDialog>
      <Box>
        <Button
          variant="contained"
          onClick={() => setState((prev) => ({ ...prev, dialog: true }))}
          color="secondary"
          sx={{
            width: "20rem",
            height: "4rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-around",
            borderRadius: "10px",
            background: "#0099ff",
            fontSize: "1.2rem",
          }}
        >
          Filter
          <FilterAlt />
          <Typography
            sx={{
              padding: "0 5px",
              background: "#fff",
              color: "black",
              borderRadius: "50%",
            }}
          >
            {Object.values(checkboxes).filter((item) => item === true).length}
          </Typography>
        </Button>
      </Box>
    </>
  );
};

export default Table1Filter;
