import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
} from "@mui/material";
import * as XLSX from "xlsx";
import DialogHeader from "../DialogHeader";
const ExportDialog2 = ({
  open = false,
  handleClose = () => {},
  header = [],
  fileName = "Report",
  worksheetData,
  dataPoints,
  selectedOptions,
  setSelectedOptions,
}) => {
  const handleCloseDialog = () => {
    handleClose();
  };
  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };
  const handleExport = () => {
    const filteredWorksheetData = worksheetData.map((row) =>
      selectedOptions.map((option) => row[header.indexOf(option)])
    );

    let worksheet = XLSX.utils.aoa_to_sheet(filteredWorksheetData);
    const addDataToSheet = (worksheet, data, origin) => {
      XLSX.utils.sheet_add_aoa(worksheet, [[data]], { origin });
    };
    dataPoints.forEach(({ data, origin }) =>
      addDataToSheet(worksheet, data, origin)
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, fileName);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
    handleCloseDialog();
  };

  useEffect(() => {
    if (!open) {
      setSelectedOptions(header);
    }
  }, [open]);

  return (
    <Dialog
      open={open}
      onClose={handleCloseDialog}
      PaperProps={{
        sx: { minWidth: "400px" },
      }}
    >
      <DialogHeader
        title="Select Information to show in the excel file"
        handleClose={handleCloseDialog}
      />
      <DialogContent>
        <Box>
          {header.map((option) => (
            <Stack direction="row">
              <FormControlLabel
                key={option}
                control={
                  <Checkbox
                    checked={selectedOptions.includes(option)}
                    onChange={(e) =>
                      handleCheckboxChange(option, e.target.checked)
                    }
                  />
                }
                label={option}
              />
            </Stack>
          ))}
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          variant="contained"
          color="primary"
          size="large"
          sx={{ mb: 1 }}
          onClick={handleExport}
        >
          DownLoad
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportDialog2;
