import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let arrear_url = `${SCHOOL_BASE}/arrears`;

export const getArrearSummary = (params) => {
  return schoolTokenAxios.get(`${arrear_url}/getArrearSummary`, {
    params: params,
  });
};
export const getArrearInvoice = (params) => {
  return schoolTokenAxios.get(`${arrear_url}/getArrearInvoice`, {
    params: params,
  });
};

export const getArrears = (params) => {
  return schoolTokenAxios.get(`${arrear_url}/getArrears`, {
    params: params,
  });
};

export const addArrear = (payload) => {
  return schoolTokenAxios.post(`${arrear_url}/addArrear`, payload);
};
export const collectArrears = (payload) => {
  return schoolTokenAxios.put(`${arrear_url}/collectArrears`, payload);
};
export const updateArrear = (payload) => {
  return schoolTokenAxios.put(`${arrear_url}/updateArrear`, payload);
};
export const deleteArrear = (payload) => {
  return schoolTokenAxios.delete(`${arrear_url}/deleteArrear/${payload}`);
};
export const deleteArrearInvoice = (payload) => {
  return schoolTokenAxios.delete(
    `${arrear_url}/deleteArrearInvoice/${payload}`
  );
};
