import { Button, Modal, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import styled from "styled-components";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

export default function MigrateModal({ open, setOpen }) {
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "50%",
    height: "60%",
    borderRadius: "20px",
    border: "1px solid #000",
    padding: "1.5rem",
    backgroundColor: "white",
    color: "#0C2F49",
    overflow: "scroll",
  };

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box>
          <NotificationContainer />
          <div style={style}>
            <ModalHeader>
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "14px",
                }}
              >
                Migrate Student
              </Typography>
              <button
                type="button"
                style={{
                  width: "25px",
                  height: "25px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  padding: "0",
                  margin: "0",
                  borderRadius: "4px",
                  background: "transparent",
                }}
              >
                <X onClick={() => handleClose()} />
              </button>
            </ModalHeader>
            <hr style={{ width: "100%" }} />

            <ModalBody
              style={{
                marginTop: "1rem",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <FullWidthDiv>
                <Typography>Fee Dues</Typography>
                <TextField disabled />
              </FullWidthDiv>

              <FullWidthDiv>
                <Typography>Library Dues</Typography>
                <TextField disabled />
              </FullWidthDiv>
              <FullWidthDiv>
                <Typography>Total Dues</Typography>
                <TextField disabled />
              </FullWidthDiv>
            </ModalBody>
            <ModalFooter
              style={{
                gap: "1rem",
              }}
            >
              <Button
                onClick={() => {
                  // formik.resetForm();
                }}
                variant="outlined"
              >
                Cancel
              </Button>
            </ModalFooter>
          </div>
        </Box>
      </Modal>
    </>
  );
}

const HalfWidthDiv = styled.div`
  width: 48%;
`;

const FullWidthDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;
