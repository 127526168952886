import { Clear, HighlightOff } from "@mui/icons-material";
import { Chip, Stack } from "@mui/material";

export default function ChipComponent({ values, setValues, name }) {
  const handleRemove = (val) => {
    const newClasses = values.filter((item) => item !== val);
    setValues(name, newClasses);
  };
  return (
    <Stack direction="row" spacing={1} marginTop={1}>
      {values?.map((value, index) => {
        return (
          <Chip
            key={index}
            label={value}
            variant="outlined"
            onDelete={() => handleRemove(value)}
            deleteIcon={<Clear style={{ color: "red", fontSize: "12px" }} />}
            style={{
              background: "rgba(43, 81, 177, 0.05)",
              fontSize: "12px",
            }}
          />
        );
      })}
    </Stack>
  );
}
