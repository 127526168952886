import React, { useState } from "react";
import {
  Box,
  Typography,
  useMediaQuery,
  Container,
  Stack,
  Button,
} from "@mui/material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import CustomLoader from "app/components/common/CustomLoader";
import ChangeStatusDialog from "./ChangeStatusDialog";

import TableActionButtons from "app/components/common/TableActionButtons";
import AdmissionEnquiryFilterDialog from "app/components/common/Dialogs/AdmissionEnquiryFilterDialog";
import AddEnquiryForm from "./AddEnquiryForm";
import ExportDialog from "app/components/common/exportDialog";
import {
  getAdmissionEnquiry,
  deleteAdmissionEnquiry,
  changeStatusOfAdmissionEnquiry,
} from "app/services/schoolService/admissionEnquiry.service";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { toast } from "react-toastify";
import { FormatFullName } from "app/utils/helper";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import { useMutation } from "react-query";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import dayjs from "dayjs";

const AdmissionEnquiry = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [openCheckBox, setOpenCheckBox] = useState(false);

  const [state, setState] = useState({
    filterData: false,
    addDialog: false,
    editDialog: false,
    deleteDialog: false,
    changeStatusDialog: false,
    deleteId: "",
    editData: {},
    changeStatusData: {},
  });
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const {
    data: getAdmissionEnquiryData,
    isLoading: getAdmissionEnquiryLoading,
    refetch: getAdmissionEnquiryRefetch,
    isRefetching,
  } = useQuery({
    queryKey: [
      "getAdmissionEnquiry",
      state.fetch,
      selectedSession,
      selectedMedium,
    ],
    queryFn: () => {
      if (state.filterData) {
        return getAdmissionEnquiry({
          session: selectedSession,
          ...state.filterData,
        });
      } else {
        return getAdmissionEnquiry({
          session: selectedSession,
        });
      }
    },
    onSuccess: (data) => {},
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });

  const { mutate: deleteMutate, isLoading: deleteLoading } = useMutation(
    deleteAdmissionEnquiry,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        setState((prev) => ({
          ...prev,
          deleteId: "",
          deleteDialog: false,
        }));
        getAdmissionEnquiryRefetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );

  let header = [
    "Form Number",
    "ClassName",
    "Student Name",
    "Mobile Number",
    "Form Amount",
    "Payment Mode",
    "Enquiry Date",
    "Enquiry Status",
    "Payment Status",
    "Category",
    "Community",
    "Address",
    "Gender",
    "Father Name",
    "Father Annual Income",
    "Father Mobile Number",
    "Father Occupation",
    "Mother Name",
    "Mother Annual Income",
    "Mother Mobile Number",
    "Mother Occupation",
    "Guardian Name",
    "Guardian Annual Income",
    "Guardian Mobile Number",
    "Guardian Occupation",
    "Guardian Relation",
  ];
  let exportArray = getAdmissionEnquiryData?.data?.list?.map((item) => {
    return {
      "Form Number": item?.formNumber ?? "--",
      ClassName: item?.className ?? "--",
      "Student Name": FormatFullName(
        item?.firstName,
        item?.middleName,
        item?.lastName
      ),
      "Mobile Number": item?.mobileNumber ?? "--",
      "Form Amount": item?.formAmount ?? "--",
      "Payment Mode": item?.paymentMode ?? "--",
      "Enquiry Status": item?.enquiryStatus ?? "--",
      "Payment Status": item?.paymentStatus ?? "--",
      "Enquiry Date": dayjs(item?.enquiryDate).format("DD MMM,YYYY") ?? "--",
      Category: item?.category ?? "--",
      Community: item?.community ?? "--",
      Address:
        `${item?.currentAddress?.address ?? ""} ${
          item?.currentAddress?.dist ?? ""
        } ${item?.currentAddress?.state ?? ""} ${
          item?.currentAddress?.pinCode ?? ""
        }` ?? "--",

      Gender: item?.gender,
      "Father Name": FormatFullName(
        item?.fatherInfo?.firstName,
        item?.fatherInfo?.middleName,
        item?.fatherInfo?.lastName
      ),
      "Father Annual Income": item?.fatherInfo?.annualIncome ?? "--",
      "Father Mobile Number": item?.fatherInfo?.mobileNumber ?? "--",
      "Father Occupation": item?.fatherInfo?.occupation ?? "--",
      "Mother Name": FormatFullName(
        item?.motherInfo?.firstName,
        item?.motherInfo?.middleName,
        item?.motherInfo?.lastName
      ),
      "Mother Annual Income": item?.motherInfo?.annualIncome ?? "--",
      "Mother Mobile Number": item?.motherInfo?.mobileNumber ?? "--",
      "Mother Occupation": item?.motherInfo?.occupation ?? "--",
      "Guardian Name": FormatFullName(
        item?.guardianInfo?.firstName,
        item?.guardianInfo?.middleName,
        item?.guardianInfo?.lastName
      ),
      "Guardian Annual Income": item?.guardianInfo?.annualIncome ?? "--",
      "Guardian Mobile Number": item?.guardianInfo?.mobileNumber ?? "--",
      "Guardian Occupation": item?.guardianInfo?.occupation ?? "--",
      "Guardian Relation": item?.guardianInfo?.relation ?? "--",
    };
  });

  const columns = [
    {
      header: "Sr.No",
      Cell: ({ cell }) => (
        <Typography>
          {parseInt(cell.row.id) < 9
            ? `0${parseInt(cell.row.id) + 1}`
            : parseInt(cell.row.id) + 1}
        </Typography>
      ),
      size: 30,
    },
    {
      accessorKey: "formNumber",
      header: "Form Number",
      size: 50,
    },
    {
      accessorKey: "firstName",
      header: "Student Name",
      Cell: ({ cell }) => (
        <Typography>
          {FormatFullName(
            cell?.row?.original?.firstName,
            cell?.row?.original?.middleName,
            cell?.row?.original?.lastName
          )}
        </Typography>
      ),
      size: 70,
    },
    {
      accessorKey: "fatherInfo.firstName",
      header: "Father Name",
      Cell: ({ cell }) => (
        <Typography>
          {FormatFullName(
            cell?.row?.original?.fatherInfo?.firstName,
            cell?.row?.original?.fatherInfo?.middleName,
            cell?.row?.original?.fatherInfo?.lastName
          )}
        </Typography>
      ),
      size: 70,
    },
    {
      accessorKey: "className",
      header: "Class-Stream",
      Cell: ({ cell }) => (
        <Typography>
          {cell?.row?.original?.className} - {cell?.row?.original?.stream}
        </Typography>
      ),
      size: 50,
    },
    {
      accessorKey: "mobileNumber",
      header: "Mobile Number",
      size: 50,
    },

    {
      accessorKey: "formAmount",
      header: "Form Amount",
      size: 50,
    },
    {
      accessorKey: "enquiryStatus",
      header: "Enquiry Status",
      Cell: ({ cell }) => {
        return (
          <Typography
            sx={{
              color:
                cell?.row?.original?.enquiryStatus === "Active"
                  ? "#FBB122"
                  : cell?.row?.original?.enquiryStatus === "Won"
                  ? "green"
                  : cell?.row?.original?.enquiryStatus === "Lost"
                  ? "red"
                  : "black",
              textDecoration: "underline",
              fontStyle: "italic",
              cursor: "pointer",
            }}
            onClick={() => {
              setState((prev) => ({
                ...prev,
                changeStatusDialog: true,
                changeStatusData: cell?.row?.original,
              }));
            }}
          >
            {cell?.row?.original?.enquiryStatus}
          </Typography>
        );
      },
      size: 50,
    },
    {
      accessorKey: "paymentStatus",
      header: "Payment Status",
      Cell: ({ cell }) => {
        return (
          <>
            <Typography
              sx={{
                color:
                  cell?.row?.original?.paymentStatus === "Paid"
                    ? "green"
                    : "red",
                textDecoration: "underline",
                fontStyle: "italic",
                cursor: "pointer",
              }}
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  changeStatusDialog: true,
                  changeStatusData: cell?.row?.original,
                }));
              }}
            >
              {cell?.row?.original?.paymentStatus}
            </Typography>
          </>
        );
      },
      size: 50,
    },
    {
      header: "Action",
      maxSize: 50,
      Cell: ({ cell }) => {
        const { original } = cell.row;
        return (
          <TableActionButtons
            showEdit
            // showView
            showDelete
            handleEdit={() => handleEdit(cell?.row?.original)}
            handleDelete={() => handleDelete(cell?.row?.original)}
            // handleDelete={() => handleViewDeleteDialog(cell?.row?.original)}
            // handleView={() => handleViewDialog(cell)}
          />
        );
      },
    },
  ];
  const handleMutate = (payload) => {
    setState((prev) => ({
      ...prev,
      filterData: payload,
      fetch: !prev.fetch,
    }));
  };
  const handleEdit = (data) => {
    setState((prev) => ({
      ...prev,
      editDialog: true,
      editData: data,
    }));
  };

  const handleCloseDeleteDialog = () => {
    setState((prev) => ({
      ...prev,
      deleteDialog: false,
      deleteId: "",
    }));
  };
  const handleCloseChangeStatusDialog = () => {
    setState((prev) => ({
      ...prev,
      changeStatusDialog: false,
    }));
  };

  const handleDelete = (data) => {
    setState((prev) => ({
      ...prev,
      deleteDialog: true,
      deleteId: data?._id,
    }));
  };
  const handledeleteForm = () => {
    deleteMutate(state?.deleteId);
  };

  return (
    <>
      {/* add form */}
      <AddEnquiryForm
        add={state.addDialog}
        edit={state.editDialog}
        editData={state.editData}
        refetch={() => getAdmissionEnquiryRefetch()}
        handleClose={() =>
          setState((prev) => ({
            ...prev,
            editData: {},
            editDialog: false,
            addDialog: false,
          }))
        }
      />

      <ChangeStatusDialog
        open={state.changeStatusDialog}
        handleClose={handleCloseChangeStatusDialog}
        data={state.changeStatusData}
        refetch={() => getAdmissionEnquiryRefetch()}
      />

      {/* Export  */}
      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Admission Enquiry"
      />

      {/* Delete Dialog Confirmation */}
      <DeleteDialog
        open={state.deleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleDelete={handledeleteForm}
        loading={deleteLoading}
      />
      <CustomLoader
        show={getAdmissionEnquiryLoading || isRefetching || deleteLoading}
      />

      <Container maxWidth="xl">
        <Stack
          direction={"row"}
          justifyContent={"space-between"}
          sx={{
            mb: 2,
          }}
        >
          <AdmissionEnquiryFilterDialog
            mutate={handleMutate}
            loading={() => isRefetching()}
          />
          <Stack
            direction="row"
            spacing={2}
            sx={{ justifyContent: "center", alignItems: "center" }}
          >
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: 600,
                // mb: 1.5,
              }}
            >
              Total Student :{" "}
              <span style={{ fontSize: "18px" }}>
                {getAdmissionEnquiryData?.data?.list?.length || 0}
              </span>
            </Typography>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  addDialog: true,
                }));
              }}
            >
              + ADD NEW
            </Button>
            <ExportButtonGroup
              showPdf={false}
              handleClickExcel={() => setOpenCheckBox(true)}
            />
          </Stack>
        </Stack>

        <Box>
          <CommonTable1
            columns={columns}
            data={getAdmissionEnquiryData?.data?.list || []}
            maxHeight={isMobile ? "45vh" : "63vh"}
          />
        </Box>
      </Container>
    </>
  );
};

export default AdmissionEnquiry;
