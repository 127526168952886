import { Close } from "@mui/icons-material";
import {
  Box,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Stack,
  Typography,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import StaffSearchInput from "app/components/common/StaffSearchInput";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { selectActiveSession } from "app/modules/schools/selectors";
import { addDebtTransaction } from "app/services/schoolService/hr.service";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { useEffect } from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import CommonDialog from "../../CommonDialog";
import { LoadingButton } from "@mui/lab";
const LabelText = ({ label = "", value = "" }) => {
  return (
    <Stack direction={"row"} gap={1}>
      <Typography
        sx={{
          color: "primary.main",
          fontWeight: 600,
          opacity: 0.7,
          fontSize: "12px",
        }}
      >
        {label}{" "}
      </Typography>
      <Typography
        sx={{ color: "primary.main", fontWeight: 600, fontSize: "13px" }}
      >
        {value}{" "}
      </Typography>
    </Stack>
  );
};
export const StaffCard = ({ item }) => {
  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems={"center"} px={2}>
        {/* student profile pic */}
        <div>
          <img
            src={
              item?.profilePic ? item?.profilePic : "/image-placeholder.jpeg"
            }
            alt="student"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
        {/* student details */}
        <Stack>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "15px",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {item?.name}
          </Typography>

          <LabelText
            label="Department:- "
            value={item?.departmentName ?? "--"}
          />
          <LabelText label="Designation:-" value={item?.designation ?? "--"} />
        </Stack>
      </Stack>
    </Box>
  );
};
const validationSchema = yup.object({
  staffDocId: yup.string().required("Staff Name is required"),
  widthdrawAmount: yup.number().required("Transaction amount is required"),
  transactionDate: yup.date().required("Transaction date is required"),
});
const dt = new Date();
const AddNewDebtToStaffFreshDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  staffDetail = {},
}) => {
  const selectedSession = useSelector(selectActiveSession);

  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: {
      staffData: {},
      staffDocId: "",
      transactionType: "givingToStaff",
      widthdrawAmount: "",
      transactionDate: dt,
      refrenceNumber: "",
      remarks: "",
    },
    validationSchema,
    onSubmit: (formValues) => {
      const body = {
        session: selectedSession,
        staffDocId: formValues.staffDocId,
        adjustmentType: "Salary",
        transactionAmount:
          formValues.transactionType === "givingToStaff"
            ? -Number(formValues.widthdrawAmount)
            : Number(formValues.widthdrawAmount),

        transactionDate: formValues.transactionDate,

        referenceNumber: formValues.refrenceNumber,
        remarks: formValues.remarks,
      };
      addDebtTransactionMutate(body);
    },
  });
  const handleClose = () => {
    onClose();
    resetForm();
  };
  const {
    mutate: addDebtTransactionMutate,
    isLoading: addDebtTransactionLoading,
  } = useMutation({
    mutationKey: [""],
    mutationFn: addDebtTransaction,
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      handleClose();
      refetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  useEffect(() => {
    if (staffDetail?._id?.staffDocId) {
      setValues((prev) => ({
        ...prev,
        staffDocId: staffDetail?._id?.staffDocId,
        staffData: staffDetail,
      }));
    }
  }, [open]);

  return (
    <>
      <CommonDialog
        title="Adust Salary /  Payroll ? "
        minWidth="500px"
        onClose={handleClose}
        open={open}
      >
        <DialogContent>
          <form onSubmit={handleSubmit} id="add-new-debt-form">
            <Grid container spacing={1}>
              {!staffDetail?._id?.staffDocId && (
                <>
                  <Grid item xs={4}>
                    <TextFieldLabel title={"Staff Name"} required />
                    <StaffSearchInput
                      returnResponse={(item) =>
                        setValues((prev) => ({
                          ...prev,
                          staffData: item,
                          staffDocId: item?.staffDocId,
                        }))
                      }
                    />
                    {errors?.staffDocId && (
                      <Typography color={"error"}>
                        {errors.staffDocId}
                      </Typography>
                    )}
                  </Grid>
                  <Grid item xs={8}>
                    <Stack direction={"row"} alignItems={"center"} gap={1}>
                      {Object.keys(values.staffData).length > 0 && (
                        <>
                          <StaffCard item={values.staffData} />
                          <Box>
                            <IconButton
                              sx={{ background: "#f6f6f6" }}
                              onClick={() =>
                                setValues((prev) => ({
                                  ...prev,
                                  staffData: {},
                                  staffDocId: "",
                                }))
                              }
                            >
                              <Close sx={{ color: "red" }} />
                            </IconButton>
                          </Box>
                        </>
                      )}
                    </Stack>
                  </Grid>
                </>
              )}
            </Grid>

            <Stack direction={"row"} gap={1} alignItems={"center"}>
              <Typography>Transaction Type</Typography>
              <FormControlLabel
                control={<Radio color="secondary" />}
                value={values.transactionType}
                checked={values.transactionType === "givingToStaff"}
                onChange={(e) =>
                  setValues((prev) => ({
                    ...prev,
                    transactionType: "givingToStaff",
                  }))
                }
                label="Giving To Staff"
                componentsProps={{
                  typography: { fontSize: "14px" },
                }}
              />
              <FormControlLabel
                checked={values.transactionType === "takingFromStaff"}
                control={<Radio color="secondary" />}
                value={values.transactionType}
                onChange={(e) =>
                  setValues((prev) => ({
                    ...prev,
                    transactionType: "takingFromStaff",
                  }))
                }
                label="Taking From Staff"
                componentsProps={{
                  typography: { fontSize: "14px" },
                }}
              />
            </Stack>
            <Grid container spacing={1}>
              <Grid item xs={6}>
                <TextFieldLabel
                  title={
                    values.transactionType === "takingFromStaff"
                      ? "Credit Amount"
                      : "WithDrawl Amount"
                  }
                />
                <CustomTextField
                  sx={{
                    "& .MuiFormHelperText-root": {
                      fontSize: "12px", // Change the font size here
                    },
                  }}
                  type="number"
                  size="small"
                  fullWidth
                  name="widthdrawAmount"
                  value={values.widthdrawAmount}
                  onChange={handleChange}
                  placeholder="type withdrawnumber"
                  error={
                    Boolean(errors.widthdrawAmount) && touched.widthdrawAmount
                  }
                  helperText={touched.widthdrawAmount && errors.widthdrawAmount}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Transaction Date" />
                <DesktopDatePicker
                  inputFormat="DD-MM-YYYY"
                  onChange={(e) =>
                    setValues((prev) => ({ ...prev, transactionDate: e }))
                  }
                  value={values.transactionDate}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      sx={{
                        "& .MuiFormHelperText-root": {
                          fontSize: "12px", // Change the font size here
                        },
                      }}
                      size="small"
                      fullWidth
                      error={
                        Boolean(errors.transactionDate) &&
                        touched.transactionDate
                      }
                      helperText={
                        touched.transactionDate && errors.transactionDate
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldLabel title="Cheq./Ref.No." />
                <CustomTextField
                  size="small"
                  fullWidth
                  name="refrenceNumber"
                  value={values.refrenceNumber}
                  onChange={handleChange}
                  placeholder="type refrence Number"
                  error={
                    Boolean(errors.refrenceNumber) && touched.refrenceNumber
                  }
                  helperText={touched.refrenceNumber && errors.refrenceNumber}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldLabel title="Remarks" />
                <CustomTextField
                  size="small"
                  fullWidth
                  name="remarks"
                  value={values.remarks}
                  onChange={handleChange}
                  placeholder="type remarks"
                  error={Boolean(errors.remarks) && touched.remarks}
                  helperText={touched.remarks && errors.remarks}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={addDebtTransactionLoading}
            size="large"
            type="submit"
            form="add-new-debt-form"
            variant="contained"
            color="secondary"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddNewDebtToStaffFreshDialog;
