import { ArrowForwardIos } from "@mui/icons-material";
import {
  Divider,
  Collapse,
  Stack,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

const ExpenseHeadDropdown = ({ item, formikState, index, formikSetState }) => {
  const [expand, setExpand] = useState(false);
  const [data, setData] = useState(item);
  const isMounted = useRef(false);

  console.log(formikState, "formikState");

  const handleCheckHead = () => {
    const updatedData = { ...data };
    if (updatedData.checked) {
      updatedData.checked = false;
      updatedData.subHeadList.forEach((head) => {
        head.checked = false;
      });
    } else {
      updatedData.subHeadList.forEach((head) => {
        head.checked = true;
      });
      updatedData.checked = true;
    }
    setData(updatedData);
  };

  const handleCheckSubHead = (subHeadId) => {
    const updatedData = { ...data };
    let allHeadCheck = true;
    updatedData.subHeadList = updatedData.subHeadList.map((subHead) => {
      if (subHead.expenseSubHeadName === subHeadId) {
        subHead.checked = !subHead.checked;
      }
      if (!subHead.checked) {
        allHeadCheck = false;
      }
      return subHead;
    });
    updatedData.checked = allHeadCheck;
    setData(updatedData);
  };

  useEffect(() => {
    if (isMounted.current) {
      const filteredByHeadId = formikState?.postHeadList?.filter(
        (res) => res?.expenseHeadDocId !== data?.expenseHeadDocId
      );
      const newArray = [];
      data?.subHeadList?.forEach((res) => {
        if (res?.checked) {
          newArray.push({
            expenseHeadDocId: data?.expenseHeadDocId,
            expenseHeadName: data?.expenseHeadName,
            expenseSubHeadName: res?.expenseSubHeadName,
            expenseSubHeadDocId: res?.expenseSubHeadDocId,
            checked: true,
            display: `${data?.expenseHeadName}(${res?.expenseSubHeadName})`,
          });
        }
      });

      formikSetState((prev) => ({
        ...prev,
        postHeadList: [...filteredByHeadId, ...newArray],
      }));
    } else {
      isMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <>
      <Box key={data?.expenseHeadDocId}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ px: 1, py: 0.5, width: "100%" }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Checkbox
              color="secondary"
              checked={data?.checked}
              onChange={handleCheckHead}
            />
            <Typography>{data?.expenseHeadName}</Typography>
            <Typography
              sx={{
                color: "blue",
                fontWeight: 600,
                fontSize: "14px",
                mt: "-1px",
              }}
            >
              {data?.subHeadList
                ?.filter((item) => item.checked)
                .map((item) => item?.expenseSubHeadName)
                .join(", ")}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            onClick={() => setExpand(!expand)}
            sx={{ cursor: "pointer", flexGrow: 1 }}
          >
            <ArrowForwardIos
              sx={{
                fontSize: "14px",
                transform: expand
                  ? "rotate(270deg) !important"
                  : "rotate(0deg) !important",
                cursor: "pointer",
              }}
            />
          </Stack>
        </Stack>
        <Divider sx={{ background: "#B6C8D6" }} />
        <Collapse in={expand}>
          <Stack direction="row" gap={1} sx={{ pl: 5 }} flexWrap={"wrap"}>
            {data?.subHeadList?.map((subHead) => (
              <Stack
                direction="row"
                alignItems="center"
                key={subHead?.expenseSubHeadName}
              >
                <Checkbox
                  color="secondary"
                  checked={subHead?.checked}
                  onChange={() =>
                    handleCheckSubHead(subHead.expenseSubHeadName)
                  }
                />
                <Typography>{subHead?.expenseSubHeadName}</Typography>
              </Stack>
            ))}
          </Stack>
          <Divider sx={{ background: "#B6C8D6" }} />
        </Collapse>
      </Box>
    </>
  );
};

export default ExpenseHeadDropdown;
