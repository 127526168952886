import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { screen } from "app/utils/mixins";
import { useSelector, useDispatch } from "react-redux";
import NotificationsIcon from "@mui/icons-material/Notifications";

import {
  selectSession,
  userDetailSelector,
  selectSelectedSchool,
} from "app/modules/schools/selectors";

import { userDocIdSelector } from "app/modules/auth/selectors";
import { getLoggedInUserDetail } from "app/modules/schools/thunk";
import { generateNameInitial } from "app/utils/helper";
import {
  Badge,
  Box,
  Container,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { userPrimaryRoleSelector } from "app/modules/schools/selectors";

import UserProfileDrawer from "./components/UserProfileDrawer";
const UserHeader = ({ handleOnClickHamburgeMenu }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const dispatch = useDispatch();
  const primaryRole = useSelector(userPrimaryRoleSelector);
  const userDocId = useSelector(userDocIdSelector);
  const selectedSchool = useSelector(selectSelectedSchool);
  const userDetail = useSelector(userDetailSelector);
  const sessionlist = useSelector(selectSession);
  const sessions = [];
  const [state, setState] = useState({
    showSidebar: false,
  });

  const createSessionList = () => {
    sessionlist.forEach((session) => {
      sessions.push(session.sessionName);
    });
  };
  createSessionList();

  // Fetch user Details
  useEffect(() => {
    dispatch(getLoggedInUserDetail({ staffDocId: userDocId }));
  }, [userDocId]);

  return (
    <>
      <UserProfileDrawer state={state} setState={setState} />
      <StyledContainer>
        <div className="inner-nav">
          <Container maxWidth="xl">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              {selectedSchool?.instituteType ? (
                <Link
                  to="/schools"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  <Stack
                    direction="row"
                    gap={1}
                    alignItems="center"
                    sx={{ cursor: "pointer" }}
                  >
                    <img
                      src={
                        selectedSchool?.schoolLogo ?? "/image-placeholder.jpeg"
                      }
                      alt="ekalsutra-school-logo"
                      style={{
                        height: "60px",
                        width: "60px",
                        objectFit: "cover",
                        borderRadius: "50%",
                        border: "1px solid rgba(0,0,0,0.5)",
                      }}
                    />
                    {!isMobile && (
                      <Box>
                        <Typography className="header_text">
                          {selectedSchool?.schoolName}
                        </Typography>
                        <Typography sx={{ fontSize: "14px" }}>
                          {`${selectedSchool?.schoolAddress.dist}, ${selectedSchool?.schoolAddress?.state} ${selectedSchool?.schoolAddress?.pinCode}`}
                        </Typography>
                      </Box>
                    )}
                  </Stack>
                </Link>
              ) : (
                <Typography className="header_text">
                  {isMobile
                    ? "Ekalsutra"
                    : "Welcome to Ekalsutra : India’s Leading School Management ERP"}
                </Typography>
              )}
              <Stack direction="row" alignItems="center" gap={1}>
                <Badge
                  badgeContent={2}
                  variant={"standard"}
                  color="error"
                  overlap="circular"
                >
                  <NotificationsIcon
                    sx={{ fontSize: "25px", color: "#3C6EEF" }}
                  />
                </Badge>
                <ProfileNameWrapper>
                  <p className="profile-name">
                    {userDetail?.firstName} {userDetail?.lastName}
                  </p>
                  <p className="role" style={{ textTransform: "capitalize" }}>
                    {/* {String(primaryRole).split("_")[1]?.toLowerCase()} */}
                    {userDetail?.employmentInfo?.designation ?? ""}
                  </p>
                </ProfileNameWrapper>
                <Box
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      showSidebar: !prev.showSidebar,
                    }))
                  }
                  sx={{ cursor: "pointer" }}
                >
                  {userDetail?.profilePic ? (
                    <img
                      src={userDetail?.profilePic}
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                        objectFit: "cover",
                      }}
                    />
                  ) : (
                    <span className="avatar avatar-24 bg-gray rounded-circle text-color p-4 fw-bold">
                      {generateNameInitial(
                        userDetail?.firstName,
                        userDetail?.lastName
                      )}
                    </span>
                  )}
                </Box>
              </Stack>
            </Stack>
          </Container>
        </div>
      </StyledContainer>
    </>
  );
};

export default UserHeader;

const ProfileNameWrapper = styled.div`
  .profile-name {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 10rem;
    line-height: 126.19%;
    text-align: center;
    color: #0c2f49;
    width: 10rem;
    /* line-height: 126.19%; */
    color: #0c2f49;
    font-weight: 600;
    /* margin:0 10px; */
    margin: 0 !important;
  }
  .role {
    text-overflow: ellipsis;
    width: 10rem;
    white-space: nowrap;
    overflow: hidden;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 156.19%;
    color: #0c2f49;
  }
`;

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 20;
  .MuiBadge-badge {
    height: 14px !important;
    width: 14px !important;
    border-radius: 7px !important;
  }
  .navbar-brand {
    svg {
      width: 200px;
    }
  }
  .navbar-bg {
    background: #084c84;
    padding-top: 0px;
    margin-left: 0;
    transition: 0.4s;
    padding-bottom: 0px;
    padding-left: 200px;
  }
  .navbar-bg-NX {
    background: #084c84;
    padding-top: 0px;
    margin-left: 0;
    padding-bottom: 0px;
    transition: 0.4s;
  }
  .inner-nav {
    background: white;
    width: 100vw;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-bottom: 2px solid rgba(0, 0, 0, 0.5);
    height: 70px;
    display: flex;
    align-items: center;
  }
  .text-color {
    color: #084c84;
  }
  .header_text {
    font-weight: 500;
    font-size: 20px;
    color: #0c2f49;
  }
  ${screen.medium.standard`
      width: 100px
    `};
`;
