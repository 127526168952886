import {
  CreditCard,
  CreditScore,
  CurrencyRupee,
  KeyboardArrowRight,
  Receipt,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  Modal,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { selectActiveSession } from "app/modules/schools/selectors";
import { Form, Formik } from "formik";
import { useState } from "react";
import { ModalBody, ModalFooter, ModalHeader } from "react-bootstrap";
import { X } from "react-bootstrap-icons";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import * as Yup from "yup";
import DatePickerComponent from "../old-component/components/FormUi/DatePickerComponent";
import TextFieldComponent from "../old-component/components/FormUi/TextfieldComponent";
import { paymentsListSelector } from "./selectors";
import "react-notifications/lib/notifications.css";
import { payFee } from "app/services/management";
import { useMutation } from "react-query";
import { resetFeeCollectionModalState } from "./operations";
import { LoadingButton } from "@mui/lab";
import { toast } from "react-toastify";
import {
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
const dt = new Date();
const initialValues = {
  igst: 0,
  sgst: 0,
  cgst: 0,
  discountAmount: 0,
  payingNow: 0,
  balanceAmount: 0,
  totalAmount: 0,
  remarkDiscount: "",
  balanceRemark: "",
  cashAmount: 0,
  referenceNumber: "",
  transactionNumber: "",
  NEFTAmount: 0,
  cardNumber: "",
  approvalCode: "",
  cardHolderName: "",
  cardAmount: 0,
  chequeNumber: "",
  depositDate: dt,
  checkAmount: 0,
  depositorName: "",
  payerGSTNumber: "",
  hsncode: "",
};

const VALIDATION_SCHEMA = Yup.object().shape({
  igst: Yup.number().typeError("Enter Numbers only"),
  sgst: Yup.number().typeError("Enter Numbers only"),
  cgst: Yup.number().typeError("Enter Numbers only"),
  discountAmount: Yup.number("Enter Numbers only"),
  payingNow: Yup.number("Enter Numbers only").required(
    "This field is required"
  ),
  remarkDiscount: Yup.string(),
  balanceRemark: Yup.string(),
  cardNumber: Yup.string().min(4, "Enter 4 Digits"),
});

export default function PaymentModal({
  open,
  setOpen,
  totalSum,
  studentDocId,
  fetchFeeCollectionByRegNo,
}) {
  const list = useSelector(paymentsListSelector);
  const session = useSelector(selectActiveSession);
  const [igstAmount, setIgstAmount] = useState(0);
  const [sgstAmount, setSgstAmount] = useState(0);
  const [cgstAmount, setCgstAmount] = useState(0);
  const [paymentMode, setPaymentMode] = useState("CashMode");
  const [state, setState] = useState({
    gstCollapse: false,
  });
  const dispatch = useDispatch();

  const { mutate, isLoading: payingFees } = useMutation(payFee, {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      setOpen(false);
      dispatch(resetFeeCollectionModalState());
      fetchFeeCollectionByRegNo();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleClose = () => {
    setOpen(false);
  };
  const calculateGST = (amount, percent, setValue) => {
    const calculatedAmount = Math.round((percent / 100) * amount);
    setValue(calculatedAmount);
  };

  const addFeeCollectionId = (list) => {
    let feeIds = [];
    list?.map((item) => {
      item.paymentList?.map((ele) => feeIds.push(ele));
    });
    return feeIds;
  };
  const addAdvancedFeeIds = (list) => {
    let advanceFeeList = [];
    list?.map((item) => {
      let monthYear = item.monthName.split("-");

      item.advancedPaymentList?.map((element) => {
        let advanceFeeObject = {
          month: monthYear[0],
          year: monthYear[1],
          feeType: element.feeType,
          feeCode: element.feeCode,
          remark: "",
        };
        advanceFeeList.push(advanceFeeObject);
      });
    });
    return advanceFeeList;
  };

  const handlePayment = (payload) => {
    mutate(payload);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    height: "100%",
    borderRadius: "20px",
    border: "1px solid #000",
    padding: "1.5rem",
    backgroundColor: "white",
    color: "#0C2F49",
    overflow: "scroll",
  };
  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box>
          <div style={style}>
            <Formik
              initialValues={{
                ...initialValues,
              }}
              validationSchema={VALIDATION_SCHEMA}
              onSubmit={(values, { resetForm }) => {
                const taxableAmount = parseInt(
                  igstAmount +
                    sgstAmount +
                    cgstAmount +
                    parseInt(values?.payingNow)
                );

                const balanceAmount = parseInt(
                  parseInt(values?.payingNow) +
                    parseInt(values?.discountAmount) -
                    totalSum
                );

                const CashModeObj = {
                  amount: taxableAmount,
                };
                const ChequeModeObj = {
                  chequeNumber: values.chequeNumber,
                  depositionDate: values.depositDate,
                  amount: taxableAmount,
                };
                const NEFTModeObj = {
                  referenceNumber: values.referenceNumber,
                  transactionNumber: values.transactionNumber,
                  amount: taxableAmount,
                };
                const CardModeObj = {
                  cardNumber: values.cardNumber,
                  approvalCode: values.approvalCode,
                  cardHolderName: values.cardHolderName,
                  amount: taxableAmount,
                };

                const mainObj = {
                  paymentDetails: {
                    [paymentMode]:
                      paymentMode === "CashMode"
                        ? CashModeObj
                        : paymentMode === "ChequeMode"
                        ? ChequeModeObj
                        : paymentMode === "NEFTMode"
                        ? NEFTModeObj
                        : CardModeObj,
                  },
                  advancePaymentDetails: addAdvancedFeeIds(list),
                  feeCollectionIds: addFeeCollectionId(list),
                  depositorName: values.depositorName,
                  discountAmount: parseInt(values.discountAmount),
                  discountRemark: values.remarkDiscount,
                  taxableAmount: taxableAmount,
                  hsn: {
                    hsncode: values.hsncode,
                    sgst: parseInt(values.sgst),
                    cgst: parseInt(values.cgst),
                    igst: parseInt(values.igst),
                  },
                  payerGSTNumber: values.payerGSTNumber,
                  session: session,
                  studentDocId: studentDocId,
                  balanceAmount: balanceAmount,
                  balanceRemark: values.balanceRemark,
                };

                handlePayment(mainObj);
              }}
            >
              {(formik) => {
                return (
                  <Form>
                    <ModalHeader>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          fontSize: "14px",
                        }}
                      >
                        Payment Information
                      </Typography>
                      <button
                        type="button"
                        style={{
                          width: "25px",
                          height: "25px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          padding: "0",
                          margin: "0",
                          borderRadius: "4px",
                          background: "transparent",
                        }}
                      >
                        <X onClick={() => handleClose()} />
                      </button>
                    </ModalHeader>
                    <hr style={{ width: "100%" }} />
                    <ModalBody
                      style={{
                        marginTop: "1rem",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "1rem",
                      }}
                    >
                      <FullWidthDiv>
                        <HalfWidthDiv>
                          <Box
                            sx={{
                              backgroundColor: "rgba(43, 81, 177, 0.05)",
                              border: "1px solid rgba(27, 50, 60, 0.05)",
                              borderRadius: "10px",
                              p: 1,
                              mb: "-10px",
                            }}
                          >
                            <Typography sx={{ fontWeight: 600 }}>
                              GRAND TOTAL: {totalSum}
                            </Typography>
                          </Box>
                        </HalfWidthDiv>
                        <HalfWidthDiv>
                          <Typography>
                            Paying Now <span style={{ color: "red" }}>*</span>:
                          </Typography>
                          <TextFieldComponent name="payingNow" fullWidth />
                        </HalfWidthDiv>
                      </FullWidthDiv>

                      <FullWidthDiv>
                        <HalfWidthDiv>
                          <Typography>Discount Amount:</Typography>
                          <TextFieldComponent
                            name="discountAmount"
                            placeholder="type discount"
                            fullWidth
                            style={{
                              input: {
                                "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
                                  {
                                    display: "none",
                                  },
                                "& input[type=number]": {
                                  MozAppearance: "textfield",
                                },
                              },
                            }}
                          />
                        </HalfWidthDiv>
                        <HalfWidthDiv>
                          <Typography>Remarks for discount amount:</Typography>
                          <TextFieldComponent
                            name="remarkDiscount"
                            fullWidth
                            placeholder="type remark"
                          />
                        </HalfWidthDiv>
                      </FullWidthDiv>
                      <FullWidthDiv>
                        <HalfWidthDiv>
                          <Typography>Balance Amount:</Typography>
                          <CustomTextField
                            size="small"
                            fullWidth
                            value={
                              parseInt(formik?.values?.payingNow) +
                              parseInt(formik?.values?.discountAmount) -
                              parseInt(totalSum)
                                ? parseInt(formik?.values?.payingNow) +
                                  parseInt(formik?.values?.discountAmount) -
                                  parseInt(totalSum)
                                : 0
                            }
                          />
                        </HalfWidthDiv>
                        <HalfWidthDiv>
                          <span
                            style={{
                              width: "100%",
                            }}
                          >
                            <Typography>Remarks for Balance amount:</Typography>
                            <TextFieldComponent
                              name="balanceRemark"
                              fullWidth
                              placeholder="type remark"
                            />
                          </span>
                        </HalfWidthDiv>
                      </FullWidthDiv>
                      {/* Gst stuff */}
                      <CollapseWrapper>
                        <Stack
                          direction="row"
                          justifyContent="space-between"
                          alignItems="center"
                          sx={{
                            px: 2,
                            py: 1,
                            backgroundColor:
                              state.gstCollapse && "rgba(22, 75, 146, 0.05)",
                          }}
                        >
                          <Typography
                            color="primary"
                            fontWeight={500}
                            fontSize={14}
                          >
                            GST Information
                          </Typography>

                          <KeyboardArrowRight
                            color="primary"
                            onClick={() =>
                              setState((prev) => ({
                                ...prev,
                                gstCollapse: !state.gstCollapse,
                              }))
                            }
                            sx={{
                              fontSize: "25px",
                              transition: "all 0.3s",
                              cursor: "pointer",
                              transform: state.gstCollapse
                                ? "rotate(90deg)"
                                : "rotate(0deg)",
                            }}
                          />
                        </Stack>
                        <Collapse in={state.gstCollapse}>
                          <Box>
                            <Box className="collapse_inner__box">
                              <FullWidthDiv>
                                <HalfWidthDiv>
                                  <Typography>Payer’s GST Number</Typography>
                                  <TextFieldComponent
                                    name="payerGSTNumber"
                                    fullWidth
                                    placeholder="type gst number"
                                  />
                                </HalfWidthDiv>
                                <HalfWidthDiv>
                                  <Typography>HSN Code</Typography>
                                  <TextFieldComponent
                                    name="hsncode"
                                    label="HSN Code"
                                    fullWidth
                                  />
                                </HalfWidthDiv>
                              </FullWidthDiv>

                              <FullWidthDiv>
                                <HalfWidthDiv>
                                  <Typography>Add IGST%:</Typography>
                                  <TextFieldComponent
                                    name="igst"
                                    fullWidth
                                    onKeyUp={() => {
                                      calculateGST(
                                        totalSum,
                                        parseInt(formik.values.igst),
                                        setIgstAmount
                                      );
                                    }}
                                  />
                                </HalfWidthDiv>
                                <HalfWidthDiv>
                                  <Typography>IGST Value:</Typography>
                                  <CustomTextField
                                    size="small"
                                    value={igstAmount}
                                    fullWidth
                                    disabled
                                  />
                                </HalfWidthDiv>
                              </FullWidthDiv>

                              <FullWidthDiv>
                                <HalfWidthDiv>
                                  <Typography>Add SGST%:</Typography>
                                  <TextFieldComponent
                                    // value={200}
                                    name="sgst"
                                    fullWidth
                                    onKeyUp={() => {
                                      calculateGST(
                                        totalSum,
                                        parseInt(formik?.values?.sgst),
                                        setSgstAmount
                                      );
                                    }}
                                  />
                                </HalfWidthDiv>
                                <HalfWidthDiv>
                                  <Typography>SGST Value:</Typography>
                                  <CustomTextField
                                    size="small"
                                    value={sgstAmount}
                                    fullWidth
                                    disabled
                                  />
                                </HalfWidthDiv>
                              </FullWidthDiv>

                              <FullWidthDiv>
                                <HalfWidthDiv>
                                  <Typography>Add CGST%:</Typography>
                                  <TextFieldComponent
                                    onKeyUp={() => {
                                      calculateGST(
                                        totalSum,
                                        parseInt(formik?.values?.cgst),
                                        setCgstAmount
                                      );
                                    }}
                                    name="cgst"
                                    fullWidth
                                  />
                                </HalfWidthDiv>
                                <HalfWidthDiv>
                                  <Typography>CGST Value:</Typography>
                                  <CustomTextField
                                    size="small"
                                    value={cgstAmount}
                                    fullWidth
                                    disabled
                                  />
                                </HalfWidthDiv>
                              </FullWidthDiv>
                            </Box>
                          </Box>
                        </Collapse>
                      </CollapseWrapper>

                      <FullWidthDiv>
                        <HalfWidthDiv>
                          <Typography>
                            Depositor’s Name{" "}
                            <span style={{ color: "red" }}>*</span>{" "}
                          </Typography>
                          <TextFieldComponent
                            name="depositorName"
                            fullWidth
                            placeholder="type depositor name"
                            required
                          />
                        </HalfWidthDiv>
                        <HalfWidthDiv>
                          <Typography>Total Amount:</Typography>
                          <CustomTextFieldDisabled
                            size="small"
                            value={
                              parseInt(igstAmount) +
                              parseInt(sgstAmount) +
                              parseInt(cgstAmount) +
                              parseInt(formik?.values?.payingNow)
                                ? parseInt(igstAmount) +
                                  parseInt(sgstAmount) +
                                  parseInt(cgstAmount) +
                                  parseInt(formik?.values?.payingNow)
                                : 0
                            }
                            fullWidth
                          />
                        </HalfWidthDiv>
                      </FullWidthDiv>
                    </ModalBody>

                    <hr style={{ width: "100%" }} />
                    <ModalFooter
                      style={{
                        gap: "1rem",
                      }}
                    >
                      <FooterDiv1>
                        <PaymentDiv
                          style={
                            paymentMode === "ChequeMode"
                              ? {
                                  border: "2px solid lightblue",
                                }
                              : null
                          }
                          onClick={(e) => setPaymentMode("ChequeMode")}
                        >
                          <CreditScore
                            sx={{
                              backgroundColor: "#FEBC1233",
                              height: "20px",
                              width: "25px",
                              borderRadius: "5px",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "9px",
                            }}
                            fullWidth
                          >
                            Cheque Mode
                          </Typography>
                        </PaymentDiv>
                        <PaymentDiv
                          onClick={(e) => setPaymentMode("NEFTMode")}
                          style={
                            paymentMode === "NEFTMode"
                              ? {
                                  border: "2px solid lightblue",
                                }
                              : null
                          }
                        >
                          <Receipt
                            sx={{
                              backgroundColor: "#FEBC1233",
                              height: "20px",
                              width: "25px",
                              borderRadius: "5px",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "9px",
                            }}
                            fullWidth
                          >
                            NEFT Mode
                          </Typography>
                        </PaymentDiv>
                        <PaymentDiv
                          onClick={(e) => setPaymentMode("CardMode")}
                          style={
                            paymentMode === "CardMode"
                              ? {
                                  border: "2px solid lightblue",
                                }
                              : null
                          }
                        >
                          <CreditCard
                            sx={{
                              backgroundColor: "#FEBC1233",
                              height: "20px",
                              width: "25px",
                              borderRadius: "5px",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "9px",
                            }}
                            fullWidth
                          >
                            Credit Card
                          </Typography>
                        </PaymentDiv>
                        <PaymentDiv
                          onClick={(e) => setPaymentMode("CashMode")}
                          style={
                            paymentMode === "CashMode"
                              ? {
                                  border: "2px solid lightblue",
                                }
                              : null
                          }
                        >
                          <CurrencyRupee
                            sx={{
                              backgroundColor: "#FEBC1233",
                              height: "20px",
                              width: "25px",
                              borderRadius: "5px",
                            }}
                          />
                          <Typography
                            sx={{
                              fontSize: "9px",
                            }}
                            fullWidth
                          >
                            Cash Mode
                          </Typography>
                        </PaymentDiv>
                      </FooterDiv1>

                      <div>
                        {paymentMode === "CashMode" && (
                          <PaymentValueDiv>
                            <Typography sx={{ mr: 1 }}>Cash Amount</Typography>
                            <CustomTextFieldDisabled
                              size="small"
                              value={
                                parseInt(igstAmount) +
                                parseInt(sgstAmount) +
                                parseInt(cgstAmount) +
                                parseInt(formik?.values?.payingNow)
                                  ? parseInt(igstAmount) +
                                    parseInt(sgstAmount) +
                                    parseInt(cgstAmount) +
                                    parseInt(formik?.values?.payingNow)
                                  : 0
                              }
                              name="cashAmount"
                            />
                          </PaymentValueDiv>
                        )}
                        {paymentMode === "NEFTMode" && (
                          <PaymentValueDiv>
                            <HalfWidthDiv>
                              <Typography>Reference Number</Typography>
                              <TextFieldComponent
                                name="referenceNumber"
                                placeholder="type reference Number"
                              />
                            </HalfWidthDiv>
                            <HalfWidthDiv>
                              <Typography>Transaction Number</Typography>
                              <TextFieldComponent
                                name="transactionNumber"
                                placeholder="type transaction number"
                              />
                            </HalfWidthDiv>
                            <HalfWidthDiv>
                              <Typography>NEFT Amount</Typography>
                              <CustomTextField
                                size="small"
                                value={
                                  parseInt(igstAmount) +
                                  parseInt(sgstAmount) +
                                  parseInt(cgstAmount) +
                                  parseInt(formik?.values?.payingNow)
                                    ? parseInt(igstAmount) +
                                      parseInt(sgstAmount) +
                                      parseInt(cgstAmount) +
                                      parseInt(formik?.values?.payingNow)
                                    : 0
                                }
                                name="NEFTAmount"
                              />
                            </HalfWidthDiv>
                          </PaymentValueDiv>
                        )}
                        {paymentMode === "CardMode" && (
                          <PaymentValueDiv>
                            <HalfWidthDiv>
                              <Typography>
                                Card Number(Last four digits)
                              </Typography>

                              <CustomTextField
                                name="cardNumber"
                                type="number"
                                placeholder="type last four digit"
                                size="small"
                                fullWidth
                                value={formik?.values?.cardNumber}
                                onChange={(e) => {
                                  if (e.target.value?.length < 5) {
                                    formik?.setValues((prev) => ({
                                      ...prev,
                                      cardNumber: e.target.value,
                                    }));
                                  }
                                }}
                              />
                            </HalfWidthDiv>
                            <HalfWidthDiv>
                              <Typography>Approval Code</Typography>
                              <TextFieldComponent
                                name="approvalCode"
                                placeholder="type approval code"
                              />
                            </HalfWidthDiv>
                            <HalfWidthDiv>
                              <Typography>Card Holder Name</Typography>
                              <TextFieldComponent
                                name="cardHolderName"
                                placeholder="type cardholder name"
                              />
                            </HalfWidthDiv>
                            <HalfWidthDiv>
                              <Typography>Card Amount</Typography>
                              <CustomTextField
                                size="small"
                                value={
                                  parseInt(igstAmount) +
                                  parseInt(sgstAmount) +
                                  parseInt(cgstAmount) +
                                  parseInt(formik?.values?.payingNow)
                                    ? parseInt(igstAmount) +
                                      parseInt(sgstAmount) +
                                      parseInt(cgstAmount) +
                                      parseInt(formik?.values?.payingNow)
                                    : 0
                                }
                                name="cardAmount"
                              />
                            </HalfWidthDiv>
                          </PaymentValueDiv>
                        )}
                        {paymentMode === "ChequeMode" && (
                          <PaymentValueDiv>
                            <HalfWidthDiv>
                              <Typography>Cheque Number</Typography>
                              <TextFieldComponent
                                name="chequeNumber"
                                placeholder="type checque Number"
                              />
                            </HalfWidthDiv>
                            <HalfWidthDiv>
                              <Typography>Deposit Date</Typography>
                              <LocalizationProvider dateAdapter={AdapterMoment}>
                                <DesktopDatePicker
                                  inputFormat="DD MMM, YYYY"
                                  value={formik?.values?.depositDate}
                                  onChange={(e) =>
                                    formik?.setValues((prev) => ({
                                      ...prev,
                                      depositDate: e,
                                    }))
                                  }
                                  renderInput={(params) => (
                                    <CustomTextField
                                      {...params}
                                      size="small"
                                      fullWidth
                                    />
                                  )}
                                />
                              </LocalizationProvider>
                              {/* <DatePickerComponent
                                name="depositDate"
                                size="small"
                              /> */}
                            </HalfWidthDiv>
                            <HalfWidthDiv>
                              <Typography>Cheque Amount</Typography>
                              <CustomTextField
                                size="small"
                                value={
                                  parseInt(igstAmount) +
                                  parseInt(sgstAmount) +
                                  parseInt(cgstAmount) +
                                  parseInt(formik?.values?.payingNow)
                                    ? parseInt(igstAmount) +
                                      parseInt(sgstAmount) +
                                      parseInt(cgstAmount) +
                                      parseInt(formik?.values?.payingNow)
                                    : 0
                                }
                                name="chequeAmount"
                              />
                            </HalfWidthDiv>
                          </PaymentValueDiv>
                        )}
                      </div>

                      <Stack
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        gap={1.5}
                      >
                        <Button
                          onClick={() => {
                            formik.resetForm();
                          }}
                          variant="outlined"
                        >
                          Cancel
                        </Button>
                        {/* <Button variant="outlined" disabled>
                          Send For Approval
                        </Button> */}
                        <LoadingButton
                          type="submit"
                          loading={payingFees}
                          variant="contained"
                          color="primary"
                        >
                          Submit
                        </LoadingButton>
                      </Stack>
                    </ModalFooter>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </Box>
      </Modal>
    </>
  );
}

const HalfWidthDiv = styled.div`
  width: 48%;
`;
const CollapseWrapper = styled.div`
  width: 100%;
  background: #ffffff;
  border: 1px solid rgba(22, 75, 146, 0.15);
  box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
  border-radius: 10px;
  .collapse_inner__box {
    padding: 16px 8px;
    border-top: 1px solid rgba(22, 75, 146, 0.15);
  }
`;
const FullWidthDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FooterDiv1 = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

const PaymentDiv = styled.div`
  width: 6rem;
  height: 6rem;
  border: 1px solid rgba(21, 62, 111, 0.12);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
`;

const PaymentValueDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`;
