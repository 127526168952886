import {
  Checkbox,
  FormControl,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import { Field, useField, useFormikContext } from "formik";
import { useState } from "react";
import ChipComponent from "./ChipComponent";

export default function MultipleSelectComponent({
  name,
  classOptions,
  ...otherProps
}) {
  const [classes, setClasses] = useState([]);
  const { setFieldValue, values } = useFormikContext();
  const [field, metaData] = useField(name);
  const handleChange = (e) => {
    const {
      target: { value },
    } = e;

    setClasses(typeof value === "string" ? value.split(",") : value);
    let tempArray = [...field.value, value];

    setFieldValue(name, value);
  };

  const multiSelectConfig = {
    ...field,
    ...otherProps,
    select: "true",
    variant: "outlined",
    fullWidth: true,
    onChange: handleChange,
  };

  return (
    <>
      <CustomSelectBox
        {...multiSelectConfig}
        multiple
        displayEmpty
        input={<OutlinedInput label="Name" />}
        value={field.value}
        onChange={(e) => handleChange(e)}
        renderValue={(selected) => selected.join(", ")}
        style={{
          overflow: "hidden",
        }}
      >
        <MenuItem value="" disabled>
          Select
        </MenuItem>
        {classOptions?.map((name, index) => (
          <MenuItem key={index} value={name}>
            <Checkbox checked={field.value.indexOf(name) > -1} />
            <ListItemText primary={name} />
          </MenuItem>
        ))}
      </CustomSelectBox>
      <div>
        <ChipComponent
          values={field.value}
          setValues={setFieldValue}
          name={name}
        />
      </div>
    </>
  );
}
