import Fallback from "app/components/Fallback";
import React from "react";
import RouteDetail from "./RouteDetail";

const RouteTransport = React.lazy(() =>
  import("app/modules/transport/pages/FeeStructure/routeTransport")
);
const TransportDetail = React.lazy(() =>
  import("app/modules/transport/pages/TransportDetail")
);
export const FeeStructurePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <RouteTransport {...props} />
  </React.Suspense>
);

export const TransportDetailPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <TransportDetail {...props} />
  </React.Suspense>
);

export const RouteDetailPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <RouteDetail {...props} />
  </React.Suspense>
);
