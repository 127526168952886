import React, { useEffect, useState } from "react";
import { useSidebarMenuItems } from "../hooks";
import { ClickAwayListener, Typography, styled } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { Search } from "@mui/icons-material";

const SearcMenuInput = ({ setShowMobile = () => {} }) => {
  const menuItems = useSidebarMenuItems();
  const location = useLocation();
  const pathname = location.pathname;
  const [state, setState] = useState({
    searchMenuItems: [],
    searchText: "",
    searchMenuList: [],
  });
  const handleChangeText = (text) => {
    if (text) {
      const filteredData = state.searchMenuList.filter(
        (res) =>
          String(res.name).toLowerCase().includes(String(text).toLowerCase()) ||
          String(res.link).toLowerCase().includes(String(text).toLowerCase())
      );
      setState((prev) => ({
        ...prev,
        searchText: text,
        searchMenuItems: filteredData,
      }));
    } else {
      setState((prev) => ({
        ...prev,
        searchText: text,
        searchMenuItems: [],
      }));
    }
  };
  useEffect(() => {
    const newArray = [];
    menuItems.forEach((item) => {
      if (item.link) {
        newArray.push({ link: item.link, name: item.name });
      } else {
        item?.subMenu?.forEach((res) => {
          newArray.push({ link: res.link, name: res.name });
        });
      }
    });
    setState((prev) => ({ ...prev, searchMenuList: newArray }));
  }, []);
  useEffect(() => {
    setState((prev) => ({ ...prev, searchText: "", searchMenuItems: [] }));
  }, [pathname]);
  return (
    <StyledWrapper>
      <div className="menu_search_box">
        <div className="search_input__container">
          <div className="search_icon">
            <Search sx={{ color: "#fff", fontSize: 20 }} />
          </div>
          <input
            className="search_input"
            placeholder="search menu"
            value={state.searchText}
            onChange={(e) => handleChangeText(e.target.value)}
          />
        </div>
        {state.searchMenuItems?.length > 0 && (
          <ClickAwayListener
            onClickAway={() =>
              setState((prev) => ({
                ...prev,
                searchText: "",
                searchMenuItems: [],
              }))
            }
          >
            <div className="menu_search__absolute">
              <div className="menu_search_items">
                {state.searchMenuItems?.map((item, i) => (
                  <div key={i} style={{ marginBottom: "6px" }}>
                    <Typography
                      title={item?.link}
                      className="menu_search_itemTitle"
                      component={Link}
                      to={`/${item?.link}`}
                      onClick={() => setShowMobile(false)}
                    >
                      {item?.name}
                    </Typography>
                  </div>
                ))}
              </div>
            </div>
          </ClickAwayListener>
        )}
      </div>
    </StyledWrapper>
  );
};

export default SearcMenuInput;
const StyledWrapper = styled("div")`
  .menu_search_box {
    position: relative;
    padding-bottom: 8px;
  }
  .menu_search__absolute {
    position: absolute;
    top: 12;
    z-index: 20;
    left: 0;
    right: 0;
  }

  .menu_search_items {
    margin-left: 5px;
    margin-right: 12px;
    margin-top: 2px;
    padding: 10px;
    background-color: #fff;
    border-radius: 10px;
    border: 1px solid rgba(0, 0, 0, 0.07);
    max-height: 250px;
    overflow-y: auto;
  }

  .menu_search_itemTitle {
    color: #000;
    font-size: 14px;
    text-decoration: none;
    overflow-wrap: anywhere;

    :hover {
      color: blue;
      text-decoration: underline;
    }
  }
  .search_icon {
    padding: 0 4px;
    color: #fff;
  }
  .search_input__container {
    background-color: #ffffff1d;
    margin-left: 5px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    padding: 4px 5px;
  }
  .search_input {
    background: transparent;
    outline: none;
    border: none;
    font-size: 14px;
    color: #fff;
    ::placeholder {
      color: #ffffff96;
    }
  }
  .search_input:focus {
    .search_input__container {
      border: 1px solid red;
    }
  }
`;
