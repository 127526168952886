import React, { useState } from "react";
import CommonFilterDialog from "../CommonFilterDialog";
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import FilterButton from "../../FilterButton";

const BarChartFilter = ({ mutate = () => {} }) => {
  const dt = new Date();
  const [state, setState] = useState({
    dialog: false,
  });
  const [checkboxes, setCheckboxes] = useState({
    one: false,
    two: false,
    three: false,
    four: false,
    dialog: false,
  });
  const [filtered, setFiltered] = useState({
    collectionFrom: dt,
    collectionTo: dt,
    collectionCreatedFrom: dt,
    collectionCreatedTo: dt,
    expenseFrom: dt,
    expenseTo: dt,
    expenseCreatedFrom: dt,
    expenseCreatedTo: dt,
  });
  const handleClose = () => {
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const handleView = () => {
    const params = {
      ...(checkboxes.two && {
        collectionFrom: filtered.collectionFrom,
        collectionTo: filtered.collectionTo,
      }),
      ...(checkboxes.one && {
        collectionCreatedFrom: filtered.collectionCreatedFrom,
        collectionCreatedTo: filtered.collectionCreatedTo,
      }),
      ...(checkboxes.four && {
        expenseFrom: filtered.expenseFrom,
        expenseTo: filtered.expenseTo,
      }),
      ...(checkboxes.three && {
        expenseCreatedFrom: filtered.expenseCreatedFrom,
        expenseCreatedTo: filtered.expenseCreatedTo,
      }),
    };
    mutate(params);
    handleClose();
  };
  return (
    <>
      <FilterButton
        count={Object.values(checkboxes).filter((item) => item === true).length}
        hanldeClick={() => setState((prev) => ({ ...prev, dialog: true }))}
      />
      <CommonFilterDialog
        handleSaveAndView={handleView}
        open={state.dialog}
        onClose={handleClose}
        minWidth="400px"
      >
        <DialogContent>
          <Stack padding="10px">
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.one}
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      one: !prev.one,
                    }));
                    checkboxes.one === true
                      ? setFiltered((prev) => ({
                          ...prev,
                          collectionCreatedFrom: dt,
                          collectionCreatedTo: dt,
                        }))
                      : setFiltered((prev) => ({ ...prev }));
                  }}
                />
              }
              label="Collection Created On"
            />
            {checkboxes.one && (
              <Stack direction="row" spacing={2}>
                <Stack>
                  <Typography>From</Typography>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={filtered.collectionCreatedFrom}
                    onChange={(e) => {
                      setFiltered((prev) => ({
                        ...prev,
                        collectionCreatedFrom: e,
                      }));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <Typography>To</Typography>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={filtered.collectionCreatedTo}
                    onChange={(e) =>
                      setFiltered((prev) => ({
                        ...prev,
                        collectionCreatedTo: e,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
              </Stack>
            )}
            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.two}
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      two: !prev.two,
                    }));
                    checkboxes.two === true
                      ? setFiltered((prev) => ({
                          ...prev,
                          collectionFrom: dt,
                          collectionTo: dt,
                        }))
                      : setFiltered((prev) => ({ ...prev }));
                  }}
                />
              }
              label="Collection Date"
            />
            {checkboxes.two && (
              <Stack direction="row" spacing={2}>
                <Stack>
                  <Typography>From</Typography>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={filtered.collectionFrom}
                    onChange={(e) =>
                      setFiltered((prev) => ({
                        ...prev,
                        collectionFrom: e,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <Typography>To</Typography>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={filtered.collectionTo}
                    onChange={(e) =>
                      setFiltered((prev) => ({
                        ...prev,
                        collectionTo: e,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
              </Stack>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.three}
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      three: !prev.three,
                    }));
                    checkboxes.three === true
                      ? setFiltered((prev) => ({
                          ...prev,
                          expenseCreatedFrom: dt,
                          expenseCreatedTo: dt,
                        }))
                      : setFiltered((prev) => ({ ...prev }));
                  }}
                />
              }
              label="Expense Created On"
            />
            {checkboxes.three && (
              <Stack direction="row" spacing={2}>
                <Stack>
                  <Typography>From</Typography>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={filtered.expenseCreatedFrom}
                    onChange={(e) =>
                      setFiltered((prev) => ({
                        ...prev,
                        expenseCreatedFrom: e,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <Typography>To</Typography>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={filtered.expenseCreatedTo}
                    onChange={(e) =>
                      setFiltered((prev) => ({
                        ...prev,
                        expenseCreatedTo: e,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
              </Stack>
            )}

            <FormControlLabel
              control={
                <Checkbox
                  checked={checkboxes.four}
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      four: !prev.four,
                    }));
                    checkboxes.four === true
                      ? setFiltered((prev) => ({
                          ...prev,
                          expenseFrom: dt,
                          expenseTo: dt,
                        }))
                      : setFiltered((prev) => ({ ...prev }));
                  }}
                />
              }
              label="Expense Date"
            />

            {checkboxes.four && (
              <Stack direction="row" spacing={2}>
                <Stack>
                  <Typography>From</Typography>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={filtered.expenseFrom}
                    onChange={(e) =>
                      setFiltered((prev) => ({
                        ...prev,
                        expenseFrom: e,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <Typography>To</Typography>
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={filtered.expenseTo}
                    onChange={(e) =>
                      setFiltered((prev) => ({
                        ...prev,
                        expenseTo: e,
                      }))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
              </Stack>
            )}
          </Stack>
        </DialogContent>
      </CommonFilterDialog>
    </>
  );
};

export default BarChartFilter;
