import { createSlice } from "@reduxjs/toolkit";
import {
  getVisitorInfoLogic,
  getVisitorInfoByRangeLogic,
  addVisitorLogic,
  exitVisitorLogic
} from "./thunk";

const initialState = {
  isLoading: false,
  count: 0,
  list: [],
  successMessage: "",
  error: "",
  addedSuccessfully:false,
};

export const receptionSlice = createSlice({
  name: "reception",
  initialState,
  reducers: {},
  extraReducers: {
    [getVisitorInfoLogic.pending](state) {
      state.isLoading = true;
    },
    [getVisitorInfoLogic.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.successMessage = payload.message;
      state.list = payload.list;
    },
    [getVisitorInfoLogic.rejected](state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
    [getVisitorInfoByRangeLogic.pending](state) {
      state.isLoading = true;
    },
    [getVisitorInfoByRangeLogic.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.successMessage = payload.message;
      state.list = payload.list;
    },
    [getVisitorInfoByRangeLogic.rejected](state, { payload }) {
      state.isLoading = false;
      state.error = payload;
    },
    [addVisitorLogic.pending](state) {
      state.isLoading = true;
    },
    [addVisitorLogic.fulfilled](state, { payload }) {
      state.isLoading = false;
      state.successMessage = payload;
      state.addedSuccessfully = true;
    },
    [addVisitorLogic.rejected](state, { payload }) {
      state.isLoading = false;
      state.addedSuccessfully = false;
      state.error = payload;
    },
    [exitVisitorLogic.pending](state){
        state.isLoading=true;
    },
    [exitVisitorLogic.fulfilled](state,{payload}){
        state.isLoading=false;
        state.successMessage = payload;
    },
    [exitVisitorLogic.rejected](state,{payload}){
        state.isLoading=false;
        state.error = payload;
    }
  },
});

export default receptionSlice.reducer;
