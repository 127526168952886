import { createSlice } from "@reduxjs/toolkit";

export const commonSlice = createSlice({
  name: "commonSlice",
  initialState: {
    policyExpireTime: "",
  },
  reducers: {
    setPolicyTimeout: (state, { payload }) => {
      state.policyExpireTime = payload;
    },
  },
});

export default commonSlice.reducer;
export const { setPolicyTimeout } = commonSlice.actions;
