import React, { useState } from "react";
import CommonDialog from "../../CommonDialog";
import { DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { IndianCurrency } from "app/utils/helper";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { LoadingButton } from "@mui/lab";
import { useMutation } from "react-query";
import { addPayrollSecurity } from "app/services/schoolService/hr.service";
import { toast } from "react-toastify";

const SecurityMoneyDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  data = {},
}) => {
  const [state, setState] = useState({
    amount: "",
    remark: "",
  });
  const handleClose = () => {
    setState((prev) => ({ ...prev, amount: "", remark: "" }));
    onClose();
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    addPayrollSecurityMutate({
      staffDocId: data?.staffDocId,
      securityMoney: Number(state.amount),
      remarks: state.remark,
    });
  };
  const {
    isLoading: addPayrollSecurityLoading,
    mutate: addPayrollSecurityMutate,
  } = useMutation({
    mutationFn: addPayrollSecurity,
    mutationKey: ["addPayrollSecurity"],
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      handleClose();
      refetch();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  return (
    <CommonDialog
      open={open}
      onClose={handleClose}
      title="Update Payment Detail"
      minWidth="500px"
    >
      <DialogContent>
        <form onSubmit={handleSubmit} id="security-form">
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Existing Security Money
              </Typography>
              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                {IndianCurrency(data?.securityMoney)}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Existing Security Remarks
              </Typography>

              <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                {data?.remarks}
              </Typography>
            </Grid>

            <Grid item xs={4}>
              <TextFieldLabel title={"New Security Money"} required />
              <CustomTextField
                size="small"
                fullWidth
                required
                placeholder="Type amount"
                type="number"
                value={state.amount}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, amount: e.target.value }))
                }
              />
            </Grid>
            <Grid item xs={8}>
              <TextFieldLabel title={"New Remarks"} required />
              <CustomTextField
                type="text"
                size="small"
                fullWidth
                placeholder="Type remark"
                value={state.remark}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, remark: e.target.value }))
                }
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          variant="contained"
          form="security-form"
          color="secondary"
          type="submit"
          loading={addPayrollSecurityLoading}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
};

export default SecurityMoneyDialog;
