import { ArrowForwardIos } from "@mui/icons-material";
import {
  Divider,
  Collapse,
  Stack,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";

const ClassDropDown = ({ item, formikState, index, formikSetState }) => {
  const [expand, setExpand] = useState(false);
  const [data, setData] = useState(item);
  const [update, setUpdate] = useState(false);
  const isMounted = useRef(false);
  const handleCheckClass = () => {
    if (data?.checked) {
      data["checked"] = false;
      data?.sections?.forEach((sec) => {
        sec["checked"] = false;
      });
      setUpdate(!update);
    } else {
      data?.sections?.forEach((sec) => {
        sec["checked"] = true;
      });
      data["checked"] = true;
    }
    setUpdate(!update);
  };
  const handleCheckSection = () => {
    let allSectionChecked = true;
    data?.sections?.forEach((sec) => {
      if (!sec.checked) {
        allSectionChecked = false;
      }
      data["checked"] = allSectionChecked;
      setUpdate(!update);
    });
  };

  useEffect(() => {
    if (isMounted.current) {
      const filteredByClassId = formikState?.postClassList?.filter(
        (res) => res?.classDocId !== data?.classDocId
      );
      const newArray = [];
      data?.sections?.forEach((res) => {
        if (res?.checked) {
          newArray.push({
            classDocId: data?.classDocId,
            section: res?.section,
            display: `${data?.className}-${res?.section}`,
            queryClass: data?.queryClassName,
          });
        }
      });

      formikSetState((prev) => ({
        ...prev,
        postClassList: [...filteredByClassId, ...newArray],
      }));
    } else {
      isMounted.current = true;
    }
  }, [update]);

  return (
    <Box key={data?.classDocId}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ px: 1, py: 0.5, width: "100%" }}
      >
        <Stack direction="row" alignItems="center" gap={1}>
          <Checkbox
            color="secondary"
            checked={data?.checked}
            onChange={handleCheckClass}
          />
          <Typography>{data?.className}</Typography>
          <Typography
            sx={{
              color: "blue",
              fontWeight: 600,
              fontSize: "14px",
              mt: "-1px",
            }}
          >
            {data?.sections
              ?.filter((item) => item.checked)
              .map((item) => item?.section)
              .join(", ")}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          justifyContent="flex-end"
          onClick={() => setExpand(!expand)}
          sx={{ cursor: "pointer", flexGrow: 1 }}
        >
          <ArrowForwardIos
            sx={{
              fontSize: "14px",
              transform: expand
                ? "rotate(270deg) !important"
                : "rotate(0deg) !important",
              cursor: "pointer",
            }}
          />
        </Stack>
      </Stack>
      <Divider sx={{ background: "#B6C8D6" }} />
      <Collapse in={expand}>
        <Stack direction="row" gap={1} sx={{ pl: 5 }} flexWrap={"wrap"}>
          {data?.sections?.map((sec) => (
            <Stack direction="row" alignItems="center" key={sec?.section}>
              <Checkbox
                color="secondary"
                checked={sec?.checked}
                onChange={(e) => {
                  sec["checked"] = !sec?.checked;
                  handleCheckSection();
                }}
              />
              <Typography>{sec?.section}</Typography>
            </Stack>
          ))}
        </Stack>
        <Divider sx={{ background: "#B6C8D6" }} />
      </Collapse>
    </Box>
  );
};
export default ClassDropDown;
