import Fallback from "app/components/Fallback";
import React from "react";
const PunchLog = React.lazy(() =>
  import("app/modules/bio-metric/pages/punch-log/index")
);
const UserDetail = React.lazy(() =>
  import("app/modules/bio-metric/pages/user-details/UserDetails")
);
const DeviceDetail = React.lazy(() =>
  import("app/modules/bio-metric/pages/device-detail/DeviceDetail")
);

export const UserDetailPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <UserDetail {...props} />
  </React.Suspense>
);
export const PunchLogPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <PunchLog {...props} />
  </React.Suspense>
);
export const DeviceDetailPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <DeviceDetail {...props} />
  </React.Suspense>
);
