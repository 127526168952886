import { TextField, MenuItem } from "@mui/material";
import { useField, useFormikContext } from "formik";

export default function SectionSelectComponent({ name, options, setSection, ...otherProps }) {
    const { setFieldValue } = useFormikContext();
    const [field, metaData] = useField(name);

    const handleChange = (e) => {
        setFieldValue(name, e.target.value);
    };
    const selectConfig = {
        ...field,
        ...otherProps,
        select: true,
        variant: "outlined",
        fullWidth: false,
        onChange: handleChange,
    };

    if (metaData && metaData.touched && metaData.error) {
        selectConfig.error = true;
        selectConfig.helperText = metaData.error;
    }
    return (
        <TextField {...selectConfig}>
            {options &&
                options.map((option, index) => {
                    return (
                        <MenuItem value={option} key={index} onClick={() => setSection(option)}>
                            {option}
                        </MenuItem>
                    );
                })}
        </TextField>
    );
}
