import { Edit } from "@mui/icons-material";
import { Box, Button, Stack, styled, Typography } from "@mui/material";
import CustomLoader from "app/components/common/CustomLoader";
import AddSecurityMoneyDialog from "app/components/common/Dialogs/hr/payroll/AddSecurityMoneyDialog";
import SecurityMoneyDialog from "app/components/common/Dialogs/hr/payroll/SecurityMoneyDialog";
import SecurityTransactionDetail from "app/components/common/Dialogs/hr/payroll/SecurityTransactionDetail";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import CommonTable1 from "app/components/Tables/CommonTable1";
import appRoutes from "app/config/routes";
import { getPayrollSecurity } from "app/services/schoolService/hr.service";
import { IndianCurrency } from "app/utils/helper";
import React, { useState } from "react";

import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const SecurityMoney = () => {
  const [state, setState] = useState({
    transactionDetailDialog: false,
    staffDocId: "",
    addNewDialog: false,
    editSecurityMoneyDialog: false,
    editSecurityMoneyData: false,
  });
  const {
    isLoading: getPayrollSecurityLoading,
    isFetching: getPayrollSecurityFetching,
    refetch: getPayrollSecurityRefetch,
    data: getPayrollSecurityData,
  } = useQuery({
    queryKey: [
      "getPayrollSecurity",
      state.data?.employmentInfo?.staffDetail?._id,
    ],
    queryFn: getPayrollSecurity,
    onSuccess: (success) => {},
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const Columns = [
    {
      header: "Sr.No",
      accessorKey: "srNo",
      Cell: ({ cell }) => (
        <Typography>
          {parseInt(cell?.row?.id) < 9
            ? `0${parseInt(cell?.row?.id) + 1}`
            : parseInt(cell?.row?.id) + 1}
        </Typography>
      ),
      maxSize: 30,
    },
    {
      header: "Employee ID",
      accessorKey: "employeeId",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <>
            <Typography>{original?.employeeId}</Typography>
          </>
        );
      },
      size: 40,
    },
    {
      header: "Employee name",
      accessorKey: "staffDetail.firstName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <LinkText
            to={`/${appRoutes.staffProfile}/${original?.staffDetail?._id}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {original?.staffDetail?.firstName}{" "}
            {original?.staffDetail?.middleName}{" "}
            {original?.staffDetail?.lastName}
          </LinkText>
        );
      },
      size: 70,
    },

    {
      header: "Department",
      accessorKey: "departmentName",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.departmentName}</Typography>;
      },
      size: 40,
    },
    {
      header: "Designation",
      accessorKey: "designation",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.designation}</Typography>;
      },
      size: 40,
    },
    {
      header: "Security Amount",
      accessorKey: "securityMoneyObject.securityMoney",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Typography
              sx={{
                color: "blue",
                textDecoration: "undeline",
                cursor: "pointer",
              }}
              onClick={() =>
                setState((prev) => ({
                  ...prev,
                  transactionDetailDialog: true,
                  staffDocId: original?.staffDetail?._id,
                }))
              }
            >
              {IndianCurrency(original?.securityMoneyObject?.securityMoney)}
            </Typography>
            <Box>
              <img
                src="/edit-icon.png"
                alt="edit"
                style={{
                  height: "20px",
                  width: "20px",
                  objectFit: "contain",
                  cursor: "pointer",
                }}
                onClick={() =>
                  setState((prev) => ({
                    ...prev,
                    editSecurityMoneyDialog: true,
                    editSecurityMoneyData: {
                      ...original?.securityMoneyObject,
                      staffDocId: original?.staffDetail?._id,
                    },
                  }))
                }
              />
            </Box>
          </Stack>
        );
      },
      size: 30,
    },
    {
      header: "Security Remarks",
      accessorKey: "securityMoneyObject.remarks",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Typography>{original?.securityMoneyObject?.remarks}</Typography>
        );
      },
      size: 40,
    },
  ];
  return (
    <>
      <CustomLoader
        show={getPayrollSecurityLoading || getPayrollSecurityFetching}
      />
      <SecurityMoneyDialog
        open={state.editSecurityMoneyDialog}
        onClose={() =>
          setState((prev) => ({ ...prev, editSecurityMoneyDialog: false }))
        }
        refetch={getPayrollSecurityRefetch}
        data={state.editSecurityMoneyData}
      />
      <AddSecurityMoneyDialog
        open={state.addNewDialog}
        onClose={() => setState((prev) => ({ ...prev, addNewDialog: false }))}
        refetch={getPayrollSecurityRefetch}
      />
      <SecurityTransactionDetail
        open={state.transactionDetailDialog}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            transactionDetailDialog: false,
            staffDocId: "",
          }))
        }
        list={[]}
        staffDocId={state.staffDocId}
      />
      <Stack
        direction={"row"}
        justifyContent={"flex-end"}
        gap={2}
        alignItems={"flex-end"}
      >
        <Button
          variant="contained"
          color="secondary"
          onClick={() => setState((prev) => ({ ...prev, addNewDialog: true }))}
        >
          +Add New
        </Button>
        <ExportButtonGroup />
      </Stack>
      <Stack py={1}>
        <CommonTable1
          columns={Columns}
          data={getPayrollSecurityData?.data?.list || []}
          maxHeight="65vh"
        />
      </Stack>
    </>
  );
};

export default SecurityMoney;
const LinkText = styled(Link)`
  font-size: 14px;
  font-weight: 600;
  color: blue;
`;
