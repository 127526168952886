import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
// import { Container } from "react-bootstrap";
import { Container } from "@mui/material";

import { Helmet } from "react-helmet";

import AdmissionRequest from "../../components/AdmissionApproval/AdmissionRequest";
import ProfileUpdateRequest from "../../components/AdmissionApproval/ProfileUpdateRequest";
import { TabsWrapper } from "assets/styles/globalStyledComponent";

const AdmissionApproval = () => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Admission Approval</title>
      </Helmet>
      <Container maxWidth="xl">
        <Box sx={{ width: "100%" }}>
          <TabContext value={value}>
            <TabsWrapper>
              <TabList onChange={handleChange}>
                <Tab label="Admission Request" value="1" />
                <Tab label="Profile Update Request" value="2" />
              </TabList>
            </TabsWrapper>
            <TabPanel value="1" sx={{ p: "0 !important" }}>
              <AdmissionRequest />
            </TabPanel>
            <TabPanel value="2" sx={{ p: "0 !important" }}>
              <ProfileUpdateRequest />
            </TabPanel>
          </TabContext>
        </Box>
      </Container>
    </>
  );
};

export default AdmissionApproval;
