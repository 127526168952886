import React from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import styled from "styled-components";
import { Plus } from "react-bootstrap-icons";
import { TextField } from "@mui/material";
import { emailRegex } from "app/utils/helper";

const validationSchema = yup.object({
  email: yup
    .string("Enter your email")
    .matches(emailRegex, "Enter a valid email")
    .required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

export const PrevSchoolInfoComponent = () => {
  const formik = useFormik({
    initialValues: {
      schoolName: "",
      class: "",
      stream: "",
      board: "",
      regNo: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });
  return (
    <Wrapper>
      <form onSubmit={formik.handleSubmit}>
        <TextField
          label="School Name"
          id="schoolName"
          value={formik.values.schoolName}
          name="schoolName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={formik.handleChange}
        />
        <TextField
          label="Class"
          id="class"
          value={formik.values.class}
          name="class"
          sx={{ width: "200px", margin: "10px" }}
          onChange={formik.handleChange}
        />
        <TextField
          label="Stream"
          id="stream"
          value={formik.values.stream}
          name="stream"
          sx={{ width: "200px", margin: "10px" }}
          onChange={formik.handleChange}
        />
        <TextField
          label="Board"
          id="board"
          value={formik.values.board}
          name="board"
          sx={{ width: "200px", margin: "10px" }}
          onChange={formik.handleChange}
        />
        <TextField
          label="Registration Number"
          id="regNo"
          value={formik.values.regNo}
          name="regNo"
          sx={{ width: "200px", margin: "10px" }}
          onChange={formik.handleChange}
        />
        <button>
          <Plus size={40} />
        </button>
      </form>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: relative;
  border-radius: round;
  overflow: hidden !important;
  margin: 2px;
  flex-wrap: wrap;
  .separate {
    margin: 20px 0 10px 0;
  }

  button {
    padding: 4px;
    margin-top: 10px;
    margin-left: 5px;
    background: rgba(39, 137, 253, 0.08);
    border: 1px solid rgba(39, 137, 253, 0.2);
    border-radius: 10px;
  }
`;
