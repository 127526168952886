import React from "react";
import { Box, Stack, Typography } from "@mui/material";
import imagePlaceHolder from "assets/media/images/idCard-Images/userImage.png";
import { FormatFullName } from "app/utils/helper";

export const FirmCardDropDown = ({ item, mn = true, description = false }) => {
  return (
    <>
      <Box
        sx={{
          "&:hover": {
            backgroundColor: "#fff",
          },
        }}
      >
        <Stack direction="row" spacing={1} alignItems={"center"}>
          {/* staff profile pic */}
          <Stack direction="column">
            <img
              src={item?.profilePic ?? imagePlaceHolder}
              alt="Staff"
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </Stack>
          {/* Staff details */}
          <Stack direction="column">
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "10px",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                width: "100%",
              }}
            >
              {item?.firmName}
              {/* {FormatFullName(
                item?.firstName,
                item?.middleName,
                item?.lastName
              ) ?? item?.name} */}
            </Typography>
            {mn && item?.mobileNumber && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "8px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  width: "100%",
                }}
              >
                Mob.:- {item?.mobileNumber}
              </Typography>
            )}
            {description && item?.description && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "8px",
                  whiteSpace: "normal",
                  // overflowWrap: "break-word",
                  width: "100%",
                  overflowWrap: "anywhere",
                }}
              >
                {item?.description}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};
