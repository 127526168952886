import { Box, Typography, useMediaQuery } from "@mui/material";
import React from "react";

const LabelTextComponent = ({ label, text, flex = 1 }) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <Box sx={{ flex }}>
      <Typography fontSize={isMobile ? 12 : 14} color="primary" sx={{ opacity: 0.75 }}>
        {label ?? "Label"}
      </Typography>
      <Typography
        fontSize={isMobile ? 14 : 16}
        fontWeight={600}
        color="primary"
        sx={{ overflowWrap: "anywhere" }}
      >
        {text && text !== "undefined" && text !== undefined
          ? String(text).replace(/undefined/g, "")
          : "--"}
      </Typography>
    </Box>
  );
};

export default LabelTextComponent;
