import { DeleteOutline, ExpandMore } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Typography,
  styled,
  Stack,
  InputAdornment,
  useMediaQuery,
} from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { queryClient } from "App";
import CustomLoader from "app/components/common/CustomLoader";
import AddCollectionSubHead from "app/components/common/Dialogs/AddCollectionSubHead";
import PaymentModeComponent from "app/components/common/PaymentModeComponent";
import {
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import {
  addCollectionDetail,
  getAllCollectionHead,
  getAllCollectionSubHead,
} from "app/services/ledgerAccount";
import {
  getStaffByDepartmentDocId,
  getStudents1,
} from "app/services/management";
import { getAllDepartmentLogic } from "app/services/reception";
import {
  getAllBusinessCategory,
  getAllFirm,
} from "app/services/schoolService/firm.service";
import { getClassDropdown } from "app/services/student-management";
import { FormatFullName, IndianCurrency, MenuProps } from "app/utils/helper";
import {
  CustomSelectBox,
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { useFormik } from "formik";
import React, { useState } from "react";

import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import * as yup from "yup";
import CommonDialog from "../../CommonDialog";
import StudentCard from "../books/components/StudentCard";
import { StaffCardDropDown } from "app/components/common/StaffCardDropDown";
const AddNewCollectionDialog = ({
  dialogs,
  setDialogs,
  refetch = () => {},
}) => {
  console.log(dialogs, "in dialogs");
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [state, setState] = useState({});
  const [gst, setGst] = useState({
    gstNumber: "",
    igst: "",
    cgst: "",
    hsn: "",
    amountCgst: "",
    amountIgst: "",
    amountSgst: "",
  });
  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  const selectedMedium = useSelector(selectedMediumSelector);
  const selectedSession = useSelector(selectActiveSession);
  const [openCollectionSubHeadDialog, setOpenCollectionSubHeadDialog] =
    useState(false);
  const collectionFormik = useFormik({
    initialValues: {
      cHead: "",
      subHead: "",
      dept: "",
      role: "",
      class: "",
      section: "",
      selectedCat: "",
      name: "",
      date: new Date(),
      list: [
        {
          description: "",
          UOM: "",
          quantity: 0,
          rate: 0,
          discountAmount: 0,
          discountrupees: 0,
          itemAmount: 0,
        },
      ],
    },
    validationSchema: yup.object({
      cHead: yup.mixed().required("Head selection is required"),
      subHead: yup.mixed().required("Sub Head selection is required"),
      role: yup.mixed().required("Role selection is required"),
      name: yup.string().required("Name is required"),
      class: yup.mixed().when("role", {
        is: (val) => val === "Student",
        then: yup.mixed().required("class is required"),
        otherwise: yup.mixed(),
      }),
      section: yup.string().when("role", {
        is: (val) => val === "Student",
        then: yup.string().required("section is required"),
        otherwise: yup.string(),
      }),
      dept: yup.string().when("role", {
        is: (val) => val === "Staff",
        then: yup.string().required("department is required"),
        otherwise: yup.string(),
      }),
      list: yup.array().of(
        yup.object({
          description: yup.string().required("required"),
          UOM: yup.string().required("required"),
          quantity: yup.mixed().required(" required"),
          rate: yup.mixed().required("required"),
          // discountAmount: yup.mixed().required("required"),
        })
      ),
    }),
    onSubmit: (values) => {
      values.list?.length > 0
        ? setDialogs((prev) => ({
            ...prev,
            collection: false,
            payment: true,
          }))
        : toast.error("Please Add Atleast One Product Detail");
    },
  });
  const payDetailFormik = useFormik({
    initialValues: {
      edRemarks: "",
      taxableAmount: "",
      bRemarks: "",
      paymentDetails: {},
    },

    onSubmit: (values) => {
      const newInvoiceItem = collectionFormik.values.list?.map((item) => ({
        UOM: item?.UOM,
        description: item.description,
        discountAmount: item.discountrupees,
        itemAmount: item.itemAmount,
        quantity: item.quantity,
        rate: item.rate,
      }));
      const obj = {
        session: selectedSession,
        collectionSubHeadDocId: collectionFormik.values.subHead,
        payingPersonRole: collectionFormik.values.role,
        payingPersonDocId: collectionFormik.values.name,
        collectionDate: collectionFormik.values.date,
        invoiceItems: newInvoiceItem,
        totalAmount: collectionFormik.values.totalAmount,
        taxableAmount: values.taxableAmount,
        paymentDetails: values.paymentDetails,
      };
      const newObj =
        gst.igst || gst.cgst
          ? {
              ...obj,
              payerGSTNumber: gst.gstNumber,
              hsn: {
                hsncode: gst.hsn,
                igst: gst.igst || "0",
                cgst: gst.cgst || "0",
                sgst: gst.cgst || "0",
              },
            }
          : obj;
      addCollectionDetailMutate(newObj);
    },
  });
  const {
    mutate: addCollectionDetailMutate,
    isLoading: addCollectionDetailLoading,
  } = useMutation(addCollectionDetail, {
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      refetch();
      queryClient.invalidateQueries({ queryKey: "getAllCollections" });
      setDialogs((prev) => ({ ...prev, payment: false }));
      handleResetForm();
      payDetailFormik.resetForm();
      setGst({
        gstNumber: "",
        igst: "",
        cgst: "",
        hsn: "",
        amountCgst: "",
        amountIgst: "",
      });
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  const {
    isLoading: getAllCollectionLoading,
    isFetching: getAllCollectionFetching,
  } = useQuery({
    queryKey: ["getAllCollectionHead"],
    queryFn: getAllCollectionHead,
    onSuccess: (res) => {
      collectionFormik.setValues((prev) => ({
        ...prev,
        ...(dialogs?.headName && { cHead: dialogs?.headName }),
        ...(dialogs?.subHead && { subHead: dialogs?.subHead }),
        heads: res?.data?.list?.map((item) => ({
          id: item._id,
          head: item.collectionHeadName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: dialogs?.collection ? true : false,
  });

  const {
    isLoading: collectionSubHeadLoading,
    isFetching: collectionSubHeadFetching,
    refetch: getAllCollectionSubHeadRefetch,
  } = useQuery({
    queryKey: ["getAllCollectionSubHeads", collectionFormik.values.cHead],
    queryFn: () =>
      getAllCollectionSubHead({
        collectionHeadDocId: collectionFormik.values.cHead,
      }),

    onSuccess: (res) => {
      collectionFormik.setValues((prev) => ({
        ...prev,

        subHeads: res?.data?.list?.map((item) => ({
          id: item._id,
          subHeads: item.collectionSubHeadName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: collectionFormik.values.cHead ? true : false,
  });
  const {
    refetch: getClassesQ,
    isLoading: getClassesQLoading,
    isFetching: getClassesQFetching,
  } = useQuery({
    queryKey: "getClassess",
    queryFn: () => getClassDropdown(selectedSchoolId, selectedMedium),
    onSuccess: ({ data }) => {
      setState((prev) => ({ ...prev, classData: data?.list }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: false,
  });

  const {
    refetch: getAllBusinessCatQ,
    isLoading: getAllBusinessCatQLoading,
    isFetching: getAllBusinessCatQFetching,
  } = useQuery({
    queryKey: "getAllBusinessCat",
    queryFn: () => getAllBusinessCategory(),
    enabled: false,
    onSuccess: ({ data }) => {
      collectionFormik.setValues((prev) => ({ ...prev, cat: data?.list }));
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });

  const {
    refetch: getDeptsQ,
    isLoading: getDeptsQLoading,
    isFetching: getDeptsQFetching,
  } = useQuery({
    queryKey: "getAllDepartments",
    queryFn: () => getAllDepartmentLogic({ selectedSession, selectedMedium }),
    onSuccess: ({ data }) => {
      setState((prev) => ({ ...prev, departments: data?.list }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: false,
  });

  const { isLoading: getStaffNamesLoading, isFetching: getStaffNamesFetching } =
    useQuery({
      queryKey: ["getAllStaffNames", collectionFormik.values.dept],
      queryFn: () => getStaffByDepartmentDocId(collectionFormik.values.dept),
      onSuccess: ({ data }) => {
        collectionFormik.setValues((prev) => ({ ...prev, staff: data?.list }));
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
      enabled: collectionFormik.values.dept ? true : false,
    });
  const { isLoading: getAllFirmLoading, isFetching: getAllFirmFetching } =
    useQuery({
      queryKey: ["getAllFirms", collectionFormik.values.selectedCat],
      queryFn: () =>
        getAllFirm({ businessCategory: collectionFormik.values.selectedCat }),
      onSuccess: (res) => {
        setState((prev) => ({ ...prev, firms: res?.data?.list }));
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
      enabled: collectionFormik.values.selectedCat ? true : false,
    });

  const { isLoading: getStudentsLoading, isFetching: getStudentFetching } =
    useQuery({
      queryKey: ["getAllStudents", collectionFormik.values.section],
      queryFn: () =>
        getStudents1({
          classDocId: collectionFormik.values.class,
          section: collectionFormik.values.section,
          session: selectedSession,
        }),
      onSuccess: (res) => {
        collectionFormik.setValues((prev) => ({
          ...prev,
          students: res?.data?.list?.map((item) => ({
            id: item._id,
            firstName: item.firstName,
            middleName: item.middleName,
            lastName: item.lastName,
            profilePic: item.profilePic,
            fatherInfo: item.fatherInfo,
            registrationNumber: item.registrationNumber,
            admissionNumber: item.admissionNumber,
          })),
        }));
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
      enabled: collectionFormik.values.section ? true : false,
    });
  const totalAmount = collectionFormik.values.list?.reduce(
    (sum, value) => sum + value.itemAmount,
    0
  );
  const getError = (name, index) => {
    try {
      if (
        collectionFormik.touched?.list[index][name] &&
        Boolean(collectionFormik.errors?.list[index][name])
      ) {
        return true;
      }
    } catch (error) {
      return false;
    }
  };

  const getTouched = (name, index) => {
    try {
      if (collectionFormik.touched?.list[index][name]) {
        return collectionFormik.errors?.list[index][name];
      }
    } catch (error) {
      return "";
    }
  };
  const classes = state?.classData?.map((item) => ({
    name: item?.className_stream,
    classDocId: item?.classDocId,
  }));

  const sections = (data) => {
    return state?.classData?.filter((item) => item.classDocId === data)[0]
      ?.sections;
  };

  const depts = state?.departments?.map((item) => ({
    id: item._id,
    name: item.departmentName,
  }));
  const handleResetForm = () => {
    collectionFormik.setValues((prev) => ({
      cHead: "",
      subHead: "",
      dept: "",
      role: "",
      class: "",
      section: "",
      name: "",
      date: new Date(),
      list: [
        {
          description: "",
          UOM: "",
          quantity: 0,
          rate: 0,
          discountAmount: 0,
          itemAmount: 0,
        },
      ],
    }));
    collectionFormik.setTouched({});
  };
  return (
    <>
      {/* add Collection Sub Head */}
      <AddCollectionSubHead
        open={openCollectionSubHeadDialog}
        handleClose={() => setOpenCollectionSubHeadDialog(false)}
        headId={dialogs.headName}
        refetch={() =>
          getAllCollectionSubHeadRefetch({
            collectionHeadDocId: collectionFormik.values.cHead,
          })
        }
      />
      <CustomLoader
        show={
          collectionSubHeadLoading ||
          collectionSubHeadFetching ||
          getStudentsLoading ||
          getStudentFetching ||
          getAllFirmLoading ||
          getAllFirmFetching ||
          getStaffNamesLoading ||
          getStaffNamesFetching ||
          getAllCollectionLoading ||
          getAllCollectionFetching ||
          getClassesQFetching ||
          getClassesQLoading ||
          getAllBusinessCatQLoading ||
          getAllBusinessCatQFetching ||
          getDeptsQLoading ||
          getDeptsQFetching
        }
      />
      <CommonDialog
        open={dialogs?.collection}
        onClose={() => {
          setDialogs((prev) => ({ ...prev, collection: false }));
          handleResetForm();
        }}
        title="Other Collection Detail"
        minWidth="800px"
      >
        <DialogContent>
          <form id="collectionForm" onSubmit={collectionFormik.handleSubmit}>
            <Stack direction="column" gap={2}>
              <GrayBox>
                <Stack direction={isMobile ? "column" : "row"} gap={2}>
                  <Stack flex={1}>
                    <Label>Collection Head</Label>
                    <CustomSelectBox
                      displayEmpty
                      MenuProps={MenuProps}
                      onBlur={collectionFormik.handleBlur}
                      name="cHead"
                      value={collectionFormik.values.cHead}
                      onChange={(e) =>
                        collectionFormik.setValues((prev) => ({
                          ...prev,
                          cHead: e.target.value,
                          subHead: "",
                        }))
                      }
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {collectionFormik.values.heads?.map((head) => (
                        <MenuItem value={head.id}>{head.head}</MenuItem>
                      ))}
                    </CustomSelectBox>
                    {collectionFormik.touched.cHead &&
                      collectionFormik.errors.cHead && (
                        <Typography sx={{ color: "red" }}>
                          {collectionFormik.errors.cHead}
                        </Typography>
                      )}
                  </Stack>
                  <Stack flex={1}>
                    <Label>Sub Head Name</Label>
                    <CustomSelectBox
                      displayEmpty
                      onBlur={collectionFormik.handleBlur}
                      MenuProps={MenuProps}
                      name="subHead"
                      value={collectionFormik.values.subHead}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {collectionFormik.values.subHeads?.map((item) => (
                        <MenuItem
                          value={item.id}
                          onClick={() =>
                            collectionFormik.setValues((prev) => ({
                              ...prev,
                              subHead: item.id,
                            }))
                          }
                        >
                          {item.subHeads}
                        </MenuItem>
                      ))}
                      <MenuItem
                        sx={{
                          color: "blue",
                          textDecoration: "underline",
                          fontStyle: "italic",
                        }}
                        value="Add New"
                        onClick={() => {
                          setOpenCollectionSubHeadDialog(true);
                          collectionFormik.setValues((prev) => ({
                            ...prev,
                            subHead: "",
                          }));
                        }}
                      >
                        Add New
                      </MenuItem>
                    </CustomSelectBox>
                    {collectionFormik.touched.subHead &&
                      collectionFormik.errors.subHead && (
                        <Typography sx={{ color: "red" }}>
                          {collectionFormik.errors.subHead}
                        </Typography>
                      )}
                  </Stack>
                  <Stack flex={1}>
                    <Label>Paying Person Role</Label>
                    <CustomSelectBox
                      displayEmpty
                      name="role"
                      value={collectionFormik.values.role}
                      onBlur={collectionFormik.handleBlur}
                      onChange={(e) => {
                        collectionFormik.handleChange(e);
                        e.target.value === "Student"
                          ? getClassesQ()
                          : e.target.value === "Staff"
                          ? getDeptsQ()
                          : getAllBusinessCatQ();
                        // getAllFirmQ()
                        // getClassesQ()
                        // getDeptsQ()
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="Student">Student</MenuItem>
                      <MenuItem value="Staff">Staff</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </CustomSelectBox>
                    {collectionFormik.touched.role &&
                      collectionFormik.errors.role && (
                        <Typography sx={{ color: "red" }}>
                          {collectionFormik.errors.role}
                        </Typography>
                      )}
                  </Stack>
                </Stack>
                {collectionFormik.values.role === "Student" && (
                  <Stack direction={isMobile ? "column" : "row"} gap={2} mt={2}>
                    <Stack flex={1}>
                      <Label>Class-Stream</Label>
                      <CustomSelectBox
                        onBlur={collectionFormik.handleBlur}
                        MenuProps={MenuProps}
                        name="class"
                        displayEmpty
                        value={collectionFormik.values.class}
                        onChange={(e) => {
                          // collectionFormik.handleChange(e)
                          collectionFormik.setValues((prev) => ({
                            ...prev,
                            class: e.target.value,
                            sections: sections(e.target.value),
                            section: "",
                          }));
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {classes?.map((item) => (
                          <MenuItem value={item.classDocId}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </CustomSelectBox>
                      {collectionFormik.touched.class &&
                        collectionFormik.errors.class && (
                          <Typography sx={{ color: "red" }}>
                            {collectionFormik.errors.class}
                          </Typography>
                        )}
                    </Stack>
                    <Stack flex={1}>
                      <Label>Section</Label>
                      <CustomSelectBox
                        onBlur={collectionFormik.handleBlur}
                        MenuProps={MenuProps}
                        name="section"
                        displayEmpty
                        value={collectionFormik.values.section}
                        disabled={!collectionFormik.values.class}
                        onChange={(e) => {
                          collectionFormik.handleChange(e);
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {collectionFormik.values.sections?.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                      </CustomSelectBox>
                      {collectionFormik.touched.section &&
                        collectionFormik.errors.section && (
                          <Typography sx={{ color: "red" }}>
                            {collectionFormik.errors.section}
                          </Typography>
                        )}
                    </Stack>
                    <Stack flex={1}>
                      <Label>Paying Person Name</Label>
                      <CustomSelectBox
                        onBlur={collectionFormik.handleBlur}
                        MenuProps={MenuProps}
                        name="name"
                        displayEmpty
                        value={collectionFormik.values.name}
                        renderValue={() =>
                          collectionFormik?.values?.students
                            ? FormatFullName(
                                collectionFormik.values.students?.filter(
                                  (item) =>
                                    item.id === collectionFormik.values.name
                                )[0]?.firstName,
                                collectionFormik.values.students?.filter(
                                  (item) =>
                                    item.id === collectionFormik.values.name
                                )[0]?.middleName,
                                collectionFormik.values.students?.filter(
                                  (item) =>
                                    item.id === collectionFormik.values.name
                                )[0]?.lastName
                              )
                            : "Select Student"
                        }
                        onChange={(e) => {
                          collectionFormik.handleChange(e);
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {collectionFormik.values.students?.map((item) => (
                          <MenuItem value={item.id}>
                            <StudentCard item={item} />
                          </MenuItem>
                        ))}
                      </CustomSelectBox>
                      {collectionFormik.touched.name &&
                        collectionFormik.errors.name && (
                          <Typography sx={{ color: "red" }}>
                            {collectionFormik.errors.name}
                          </Typography>
                        )}
                    </Stack>
                  </Stack>
                )}
                {collectionFormik.values.role === "Staff" && (
                  <Stack direction={isMobile ? "column" : "row"} gap={2} mt={2}>
                    <Stack>
                      <Label>Department Name</Label>

                      <CustomSelectBox
                        onBlur={collectionFormik.handleBlur}
                        sx={{ width: isMobile ? "auto" : "17rem" }}
                        MenuProps={MenuProps}
                        name="dept"
                        displayEmpty
                        value={collectionFormik.values.dept}
                        onChange={(e) => {
                          collectionFormik.handleChange(e);
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {depts?.map((item) => (
                          <MenuItem value={item.id}>{item.name}</MenuItem>
                        ))}
                      </CustomSelectBox>
                      {collectionFormik.touched.dept &&
                        collectionFormik.errors.dept && (
                          <Typography sx={{ color: "red" }}>
                            {collectionFormik.errors.dept}
                          </Typography>
                        )}
                    </Stack>
                    <Stack>
                      <Label>Paying Person Name</Label>
                      <CustomSelectBox
                        onBlur={collectionFormik.handleBlur}
                        sx={{ width: isMobile ? "auto" : "17rem" }}
                        MenuProps={MenuProps}
                        name="name"
                        displayEmpty
                        value={collectionFormik.values.name}
                        renderValue={() =>
                          collectionFormik?.values?.staff
                            ? FormatFullName(
                                collectionFormik.values.staff?.filter(
                                  (item) =>
                                    item.staffDocId ===
                                    collectionFormik.values.name
                                )[0]?.firstName,
                                collectionFormik.values.staff?.filter(
                                  (item) =>
                                    item.staffDocId ===
                                    collectionFormik.values.name
                                )[0]?.middleName,
                                collectionFormik.values.staff?.filter(
                                  (item) =>
                                    item.staffDocId ===
                                    collectionFormik.values.name
                                )[0]?.lastName
                              )
                            : "Select Staff"
                        }
                        onChange={(e) => {
                          collectionFormik.handleChange(e);
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {collectionFormik.values.staff?.map((item) => (
                          <MenuItem value={item.staffDocId}>
                            <StaffCardDropDown item={item} />
                          </MenuItem>
                        ))}
                      </CustomSelectBox>
                      {collectionFormik.touched.name &&
                        collectionFormik.errors.name && (
                          <Typography sx={{ color: "red" }}>
                            {collectionFormik.errors.name}
                          </Typography>
                        )}
                    </Stack>
                  </Stack>
                )}
                {collectionFormik.values.role === "Other" && (
                  <Stack direction={isMobile ? "column" : "row"} gap={2} mt={2}>
                    <Stack>
                      <Label>Business Category</Label>
                      <CustomSelectBox
                        onBlur={collectionFormik.handleBlur}
                        sx={{ width: isMobile ? "auto" : "17rem" }}
                        MenuProps={MenuProps}
                        name="selectedCat"
                        displayEmpty
                        value={collectionFormik.values.selectedCat || ""}
                        onChange={(e) => {
                          collectionFormik.handleChange(e);
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {collectionFormik.values.cat &&
                          collectionFormik.values.cat?.map((item) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                      </CustomSelectBox>
                    </Stack>
                    <Stack>
                      <Label>Paying Person Name</Label>
                      <CustomSelectBox
                        onBlur={collectionFormik.handleBlur}
                        sx={{ width: isMobile ? "auto" : "17rem" }}
                        MenuProps={MenuProps}
                        disabled={!collectionFormik.values.selectedCat}
                        name="name"
                        displayEmpty
                        value={collectionFormik.values.name}
                        onChange={(e) => {
                          collectionFormik.handleChange(e);
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {state?.firms?.map((item) => (
                          <MenuItem value={item._id}>{item.firmName}</MenuItem>
                        ))}
                      </CustomSelectBox>
                      {collectionFormik.touched.name &&
                        collectionFormik.errors.name && (
                          <Typography sx={{ color: "red" }}>
                            {collectionFormik.errors.name}
                          </Typography>
                        )}
                    </Stack>
                  </Stack>
                )}
                <Stack mt={2}>
                  <Label>Collection Date</Label>
                  <DesktopDatePicker
                    inputFormat="DD-MM-YYYY"
                    onBlur={collectionFormik.handleBlur}
                    name="collectionDate"
                    value={collectionFormik.values.date}
                    onChange={(e) => {
                      collectionFormik.setValues((prev) => ({
                        ...prev,
                        date: e,
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        sx={{ width: isMobile ? "auto" : "17rem" }}
                        size="small"
                      />
                    )}
                  />
                  {collectionFormik.touched.date &&
                    collectionFormik.errors.date && (
                      <Typography sx={{ color: "red" }}>
                        {collectionFormik.errors.date}
                      </Typography>
                    )}
                </Stack>
              </GrayBox>
              <GrayBox sx={{ width: "100%", overflowX: "auto" }}>
                <Box sx={{ width: { xs: "700px", md: "100%" } }}>
                  <Grid container columns={17} spacing={1}>
                    <Grid item xs={1.3}>
                      <Typography>Sr. No.</Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography>Description</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>UOM</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>Qty</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>Rate</Typography>
                    </Grid>
                    {/* <Grid item xs={2}>
                      <Typography>% Dis.</Typography>
                    </Grid> */}
                    <Grid item xs={2}>
                      <Typography>&#8377; Dis.</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography>Amount</Typography>
                    </Grid>
                    <Grid item xs={0.7} />
                  </Grid>

                  {collectionFormik.values.list?.map((item, index) => (
                    <Grid container columns={17} spacing={1} sx={{ pt: 0.5 }}>
                      {/* sr number  */}
                      <Grid item xs={1.3}>
                        <Typography sx={{ mt: 1.6, ml: 2 }}>
                          {index + 1}
                        </Typography>
                      </Grid>
                      {/* description  */}
                      <Grid item xs={3}>
                        <CustomTextField
                          onBlur={collectionFormik.handleBlur}
                          value={item.description}
                          placeholder="Enter Description"
                          onChange={(e) => {
                            item["description"] = e.target.value;
                            collectionFormik.setValues((prev) => ({
                              ...prev,
                            }));
                          }}
                        />
                        {getError("description", index) &&
                          getTouched("description", index) && (
                            <Typography sx={{ color: "red" }}>
                              {getTouched("description", index)}
                            </Typography>
                          )}
                      </Grid>
                      {/* UOM   */}
                      <Grid item xs={2}>
                        <CustomTextField
                          onBlur={collectionFormik.handleBlur}
                          value={item.UOM}
                          placeholder="Enter UOM"
                          onChange={(e) => {
                            item["UOM"] = e.target.value;
                            collectionFormik.setValues((prev) => ({
                              ...prev,
                            }));
                          }}
                        />
                        {getError("UOM", index) && getTouched("UOM", index) && (
                          <Typography sx={{ color: "red" }}>
                            {getTouched("UOM", index)}
                          </Typography>
                        )}
                      </Grid>
                      {/* Qty  */}
                      <Grid item xs={2}>
                        <CustomTextField
                          fullWidth
                          onBlur={collectionFormik.handleBlur}
                          type="number"
                          value={item.quantity}
                          onChange={(e) => {
                            const temp =
                              Number(e.target.value || 0) *
                              Number(item.rate || 0);
                            const amt =
                              temp -
                              temp * (Number(item?.discountPercent || 0) / 100);
                            item["quantity"] = e.target.value;
                            item["discountrupees"] = Number(
                              e.target.value *
                                item?.rate *
                                (Number(item?.discountPercent || 0) / 100)
                            ).toFixed(2);
                            item["itemAmount"] = amt;
                            collectionFormik.setValues((prev) => ({
                              ...prev,
                            }));
                          }}
                        />
                        {getError("quantity", index) &&
                          getTouched("quantity", index) && (
                            <Typography sx={{ color: "red" }}>
                              {getTouched("quantity", index)}
                            </Typography>
                          )}
                      </Grid>

                      {/* Rate */}
                      <Grid item xs={2}>
                        <CustomTextField
                          fullWidth
                          onBlur={collectionFormik.handleBlur}
                          type="number"
                          value={item.rate}
                          onChange={(e) => {
                            item["rate"] = e.target.value;
                            const temp =
                              Number(item.quantity || 0) *
                              Number(e.target.value || 0);
                            item["itemAmount"] = temp;
                            item["discountrupees"] = Number(
                              item?.quantity *
                                e.target.value *
                                (Number(item?.discountPercent || 0) / 100)
                            ).toFixed(2);
                            collectionFormik.setValues((prev) => ({
                              ...prev,
                            }));
                          }}
                        />
                        {getError("rate", index) &&
                          getTouched("rate", index) && (
                            <Typography sx={{ color: "red" }}>
                              {getTouched("rate", index)}
                            </Typography>
                          )}
                      </Grid>

                      {/* % Dis. */}
                      {/* <Grid item xs={2}>
                        <CustomTextField
                          fullWidth
                          onBlur={collectionFormik.handleBlur}
                          type="number"
                          value={item.discountPercent}
                          onChange={(e) => {
                            item["discountPercent"] = e.target.value;

                            const temp =
                              Number(item.quantity) * Number(item.rate);
                            const amt =
                              temp - temp * (Number(e.target.value) / 100);
                            item["discountrupees"] = Number(
                              item?.quantity *
                                item?.rate *
                                (Number(e.target.value) / 100)
                            ).toFixed(2);
                            item["itemAmount"] = amt;

                            collectionFormik.setValues((prev) => ({
                              ...prev,
                            }));
                          }}
                        />
                        {getError("discountPercent", index) &&
                          getTouched("discountPercent", index) && (
                            <Typography sx={{ color: "red" }}>
                              {getTouched("discountPercent", index)}
                            </Typography>
                          )}
                      </Grid> */}

                      {/* ₹ Dis. */}
                      <Grid item xs={2}>
                        <CustomTextField
                          fullWidth
                          onBlur={collectionFormik.handleBlur}
                          type="number"
                          value={item.discountrupees || 0}
                          onChange={(e) => {
                            item["discountrupees"] = e.target.value;
                            let dis =
                              Number(100 * e.target.value) /
                              Number(item?.quantity * item?.rate);
                            item["discountPercent"] = Number(dis).toFixed(2);
                            item["itemAmount"] =
                              item?.quantity * item?.rate - e.target.value;
                            collectionFormik.setValues((prev) => ({
                              ...prev,
                            }));
                          }}
                        />
                        {getError("discountPercent", index) &&
                          getTouched("discountPercent", index) && (
                            <Typography sx={{ color: "red" }}>
                              {getTouched("discountPercent", index)}
                            </Typography>
                          )}
                      </Grid>

                      {/* Amount */}
                      <Grid item xs={2}>
                        <Typography sx={{ mt: 1.6 }}>
                          {IndianCurrency(item.itemAmount)}
                        </Typography>
                      </Grid>
                      <Grid item xs={0.7}>
                        {collectionFormik.values.list?.length > 1 && (
                          <DeleteOutline
                            onClick={() => {
                              collectionFormik.setValues((prev) => ({
                                ...prev,
                                list: prev.list.filter(
                                  (item, i) => index !== i
                                ),
                              }));
                            }}
                            sx={{ cursor: "pointer", color: "red", mt: 1.6 }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  ))}
                  <Typography
                    style={{
                      color: " #2789FD",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                    onClick={() =>
                      collectionFormik.setValues((prev) => ({
                        ...prev,
                        list: prev.list.concat({
                          description: "",
                          UOM: "",
                          quantity: 0,
                          rate: 0,
                          discountrupees: 0,
                          itemAmount: 0,
                        }),
                      }))
                    }
                  >
                    + Add New
                  </Typography>
                </Box>
              </GrayBox>
              <Stack alignSelf="flex-end">
                <Typography>
                  Total Amount: <span>{IndianCurrency(totalAmount)}</span>
                </Typography>
              </Stack>
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            form="collectionForm"
            type="submit"
            size="large"
            variant="contained"
            color="secondary"
            onClick={() => {
              collectionFormik.setValues((prev) => ({
                ...prev,
                totalAmount,
              }));
              payDetailFormik.setValues((prev) => ({
                ...prev,
                taxableAmount: totalAmount,
              }));
            }}
          >
            Next
          </Button>
        </DialogActions>
      </CommonDialog>
      <CommonDialog
        open={dialogs?.payment}
        onClose={() => {
          setDialogs((prev) => ({ ...prev, payment: false }));
          // newBookSellFormik.resetForm()
          payDetailFormik.resetForm();
        }}
        title="Payment Detail"
        minWidth="700px"
      >
        <DialogContent>
          <form onSubmit={payDetailFormik.handleSubmit} id="payForm">
            <Stack direction="row" alignItems="center" gap={2}>
              <Stack flex={3}>
                <Accordion className="customaccordian_style">
                  <AccordionSummary expandIcon={<ExpandMore />}>
                    <Title>GST Information</Title>
                  </AccordionSummary>
                  <AccordionDetails className="accordiantDetail_border">
                    <Grid container rowSpacing={1} columnSpacing={2}>
                      <Grid item>
                        <Label>Payer's GST Number</Label>
                        <CustomTextField
                          placeholder="Type gst number"
                          onChange={(e) =>
                            setGst((prev) => ({
                              ...prev,
                              gstNumber: e.target.value,
                            }))
                          }
                          value={gst.gstNumber}
                        />
                      </Grid>
                      <Grid item>
                        <Label>HSN code</Label>
                        <CustomTextField
                          fullWidth
                          size="small"
                          value={gst.hsn}
                          onChange={(e) =>
                            setGst((prev) => ({ ...prev, hsn: e.target.value }))
                          }
                          placeholder="Type gst number"
                        />
                      </Grid>
                      <Grid item>
                        <Label>Add IGST %</Label>
                        <CustomTextField
                          placeholder="Type IGST"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography>%</Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={gst.igst}
                          onChange={(e) =>
                            setGst((prev) => ({
                              ...prev,
                              igst: e.target.value,
                              sgst: "",
                              cgst: "",
                              amountCgst: "",
                              amountSgst: "",
                              amountIgst:
                                Number(payDetailFormik.values.taxableAmount) *
                                (Number(e.target.value) / 100),
                            }))
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Amount IGST</Label>
                        <CustomTextFieldDisabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography>&#8377;</Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={
                            Number(payDetailFormik.values.taxableAmount) *
                            (Number(gst.igst) / 100)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Add SGST %</Label>
                        <CustomTextField
                          placeholder="Type SGST"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography>%</Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={gst.sgst}
                          onChange={(e) =>
                            setGst((prev) => ({
                              ...prev,
                              sgst: e.target.value,
                              igst: "",
                              amountIgst: "",
                              amountSgst:
                                Number(payDetailFormik.values.taxableAmount) *
                                (Number(e.target.value) / 100),
                            }))
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Amount SGST</Label>
                        <CustomTextFieldDisabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography>&#8377;</Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={
                            Number(payDetailFormik.values.taxableAmount) *
                            (Number(gst.sgst) / 100)
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Add CGST %</Label>
                        <CustomTextField
                          placeholder="Type CGST"
                          type="number"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Typography>%</Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={gst.cgst}
                          onChange={(e) =>
                            setGst((prev) => ({
                              ...prev,
                              cgst: e.target.value,
                              igst: "",
                              amountIgst: "",
                              amountCgst:
                                Number(payDetailFormik.values.taxableAmount) *
                                (Number(e.target.value) / 100),
                            }))
                          }
                        />
                      </Grid>
                      <Grid item>
                        <Label>Amount CGST</Label>
                        <CustomTextFieldDisabled
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <Typography>&#8377;</Typography>
                              </InputAdornment>
                            ),
                          }}
                          value={
                            Number(payDetailFormik.values.taxableAmount) *
                            (Number(gst.cgst) / 100)
                          }
                        />
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </Stack>
              <Stack flex={1} direction="column" gap={1} mt={isMobile && 2}>
                {/* <Stack>
                  <Label>Taxable Amount</Label>
                  <Typography>
                    {IndianCurrency(
                      Number(payDetailFormik.values.taxableAmount)
                    )}
                  </Typography>
                </Stack> */}
                <Stack>
                  <Label>Tax Amount</Label>
                  <Typography>
                    {IndianCurrency(
                      Number(gst.amountCgst) +
                        Number(gst.amountIgst) +
                        Number(gst.amountSgst)
                    )}
                  </Typography>
                </Stack>
                <Stack>
                  <Label>Paid Amount</Label>
                  <Typography>
                    {IndianCurrency(
                      Number(payDetailFormik.values.taxableAmount) +
                        Number(gst.amountCgst) +
                        Number(gst.amountIgst) +
                        Number(gst.amountSgst)
                    )}
                  </Typography>
                </Stack>
              </Stack>
            </Stack>
          </form>

          <Stack mt={2}>
            <PaymentModeComponent
              amount={
                Number(payDetailFormik.values.taxableAmount ?? 0) +
                Number(gst.amountCgst ?? 0) +
                Number(gst.amountIgst ?? 0) +
                Number(gst.amountSgst ?? 0)
              }
              formId="pay-form-pay"
              onClickSubmit={(data) => {
                payDetailFormik.setValues((prev) => ({
                  ...prev,
                  paymentDetails: data,
                }));
                payDetailFormik.handleSubmit();
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            onClick={() => {
              setDialogs((prev) => ({
                ...prev,
                collection: true,
                payment: false,
              }));
            }}
          >
            Previous
          </Button>
          <LoadingButton
            form="pay-form-pay"
            variant="contained"
            color="secondary"
            size="large"
            type="submit"
            loading={addCollectionDetailLoading}
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddNewCollectionDialog;
const Title = styled(Typography)(({ theme }) => ({
  fontSize: "1.2rem",
  lineHeight: "1.4rem",
  fontWeight: 600,
  color: "#0C2F49",
}));

const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 500,
  color: "#0C2F49",
}));

const GrayBox = styled(Box)(({ theme }) => ({
  padding: "10px",
  backgroundColor: "#f8fbff",
  border: "1px solid rgba(61, 57, 137, 0.1)",
  boxShadow: "0px 4px 10px rgba(61, 57, 137, 0.05)",
  borderRadius: "20px",
}));
