import { LoadingButton } from "@mui/lab";
import { DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import React from "react";

const StudentInfoChangeConfimationDialog = ({
  open,
  handleClose = () => {},
  text,
  loading,
  handleSubmit,
  infoId,
}) => {
  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleClose}
        title={`Confirmation Dialog`}
        minWidth="25%"
      >
        <DialogContent>
          <Stack
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "500",
                color: "text.primary",
              }}
            >
              {text}
            </Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Stack direction={"row"} justifyContent={"center"} gap={2}>
            <LoadingButton
              color="secondary"
              variant="outlined"
              size="medium"
              type="submit"
              onClick={() => handleClose()}
            >
              CANCEL
            </LoadingButton>
            <LoadingButton
              color="error"
              loading={loading}
              variant="contained"
              size="medium"
              type="submit"
              onClick={() => handleSubmit()}
            >
              CONFIRM
            </LoadingButton>
          </Stack>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default StudentInfoChangeConfimationDialog;
