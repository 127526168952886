import React from "react";
import { BackgroundBox } from "../style";
import FormSectionTitle from "./FormSectionTitle";
import ShowFeeStructure from "./ShowFeeStructure";

const FeeStructureInfo = ({
  feeData,
  showTransportFee,
  transportData,
  transportFeeData,
}) => {
  return (
    <BackgroundBox>
      <FormSectionTitle title={"Applied Academic Fee Structure"} />
      <ShowFeeStructure
        feeData={feeData}
        showTransportFee={showTransportFee}
        transportData={transportData}
        transportFeeData={transportFeeData}
      />
    </BackgroundBox>
  );
};

export default FeeStructureInfo;
