import React, { useState } from "react";
import FilterButton from "../../FilterButton";
import CommonFilterDialog from "../CommonFilterDialog";
import {
  Checkbox,
  FormControlLabel,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from "@mui/material";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { DatePicker } from "@mui/x-date-pickers";

const TransferReportFilter = ({ mutate = () => {} }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [filterStatus, setFilterStatus] = useState("");
  const [checkboxes, setCheckboxes] = useState({
    created: false,
    transfer: false,
    status: false,
  });
  const dt = new Date();
  const [date, setDate] = useState({
    createdFrom: dt,
    createdTo: dt,
    transferDateFrom: dt,
    transferDateTo: dt,
  });
  const handleSave = () => {
    mutate({
      ...(checkboxes.created && {
        createdFrom: date.createdFrom,
        createdTo: date.createdTo,
      }),
      ...(checkboxes.transfer && {
        transferDateFrom: date.transferDateFrom,
        transferDateTo: date.transferDateTo,
      }),
      ...(filterStatus && {
        status: String(filterStatus).toUpperCase(),
      }),
    });
    setModalOpen(false);
  };
  return (
    <>
      <FilterButton
        count={Object.values(checkboxes).filter((item) => item === true).length}
        hanldeClick={() => setModalOpen(true)}
      />
      <CommonFilterDialog
        minWidth="400px"
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        handleSaveAndView={handleSave}
      >
        <Stack padding="10px">
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxes.created}
                onClick={() => {
                  setCheckboxes((prev) => ({
                    ...prev,
                    created: !prev.created,
                  }));
                  checkboxes.created === true
                    ? setDate((prev) => ({
                        ...prev,
                        createdFrom: dt,
                        createdTo: dt,
                      }))
                    : setDate((prev) => ({ ...prev }));
                }}
              />
            }
            label="Created On"
          />
          {checkboxes.created && (
            <Stack direction="row" spacing={2}>
              <Stack>
                <Typography>From</Typography>
                <DatePicker
                  inputFormat="DD-MM-YYYY"
                  value={date.createdFrom}
                  onChange={(e) => {
                    setDate((prev) => ({
                      ...prev,
                      createdFrom: e,
                    }));
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="Select"
                      size="small"
                    />
                  )}
                />
              </Stack>
              <Stack>
                <Typography>To</Typography>
                <DatePicker
                  inputFormat="DD-MM-YYYY"
                  value={date.createdTo}
                  onChange={(e) => {
                    setDate((prev) => ({
                      ...prev,
                      createdTo: e,
                    }));
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="Select"
                      size="small"
                    />
                  )}
                />
              </Stack>
            </Stack>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxes.transfer}
                onClick={() => {
                  setCheckboxes((prev) => ({
                    ...prev,
                    transfer: !prev.transfer,
                  }));
                  checkboxes.created === true
                    ? setDate((prev) => ({
                        ...prev,
                        transferDateFrom: dt,
                        transferDateTo: dt,
                      }))
                    : setDate((prev) => ({ ...prev }));
                }}
              />
            }
            label="Transferred On"
          />
          {checkboxes.transfer && (
            <Stack direction="row" spacing={2}>
              <Stack>
                <Typography>From</Typography>
                <DatePicker
                  inputFormat="DD-MM-YYYY"
                  value={date.transferDateFrom}
                  onChange={(e) => {
                    setDate((prev) => ({
                      ...prev,
                      transferDateFrom: e,
                    }));
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="Select"
                      size="small"
                    />
                  )}
                />
              </Stack>
              <Stack>
                <Typography>To</Typography>
                <DatePicker
                  inputFormat="DD-MM-YYYY"
                  value={date.transferDateTo}
                  onChange={(e) => {
                    setDate((prev) => ({
                      ...prev,
                      transferDateTo: e,
                    }));
                  }}
                  renderInput={(params) => (
                    <CustomTextField
                      {...params}
                      placeholder="Select"
                      size="small"
                    />
                  )}
                />
              </Stack>
            </Stack>
          )}
          <FormControlLabel
            control={
              <Checkbox
                checked={checkboxes.status}
                onClick={() =>
                  setCheckboxes((prev) => ({
                    ...prev,
                    status: !prev.status,
                  }))
                }
              />
            }
            label="Status"
          />
          {checkboxes.status && (
            <Stack direction="row" spacing={2}>
              <Stack>
                <RadioGroup
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                  value={filterStatus}
                  onChange={(e) => {
                    setFilterStatus(e.target.value);
                  }}
                >
                  <FormControlLabel
                    value="Requested"
                    control={<Radio />}
                    label="Requested"
                  />
                  <FormControlLabel
                    value="Rejected"
                    control={<Radio />}
                    label="Rejected"
                  />
                  <FormControlLabel
                    value="Approved"
                    control={<Radio />}
                    label="Approved"
                  />
                </RadioGroup>
              </Stack>
            </Stack>
          )}
        </Stack>
      </CommonFilterDialog>
    </>
  );
};

export default TransferReportFilter;
