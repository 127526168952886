import React from "react";
import CommonDialog from "../../CommonDialog";
import {
  Box,
  Divider,
  Grid,
  LinearProgress,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { IndianCurrency } from "app/utils/helper";
import dayjs from "dayjs";
import { useQuery } from "react-query";
import { getPayrollSecurity } from "app/services/schoolService/hr.service";
import { toast } from "react-toastify";

const SecurityTransactionDetail = ({
  open = false,
  onClose = () => {},
  list = [],
  staffDocId = "",
}) => {
  const {
    isLoading: getPayrollSecurityLoading,
    isFetching: getPayrollSecurityFetching,
    data: getPayrollSecurityData,
  } = useQuery({
    queryKey: ["getPayrollSecurity", open],
    queryFn: () => getPayrollSecurity({ staffDocId }),
    onSuccess: (success) => {},
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: staffDocId ? true : false,
  });
  return (
    <CommonDialog
      open={open}
      onClose={onClose}
      title="Security Money Payment Detail"
      minWidth="700px"
    >
      {getPayrollSecurityLoading && getPayrollSecurityFetching && (
        <LinearProgress />
      )}
      <InvoiceHeading>
        <Grid container spacing={1} p={1}>
          <Grid item xs={3}>
            <Typography className="invoice_head__text">Amount</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className="invoice_head__text">Remark</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className="invoice_head__text">Created On</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography className="invoice_head__text">Created By</Typography>
          </Grid>
        </Grid>
        <Divider sx={{ background: "#000" }} />

        {!staffDocId &&
          list?.map((item, i) => (
            <Box key={i}>
              <Grid container spacing={1} p={1}>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {IndianCurrency(item?.amount)}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {item?.remarks}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                  >
                    {dayjs(item?.createdOn).format("DD MMM, YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography
                    sx={{
                      fontSize: "13px",
                      fontWeight: 500,
                    }}
                  >
                    {item?.createdBy?.personName}
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                sx={{ background: (theme) => theme.palette.primary.main }}
              />
            </Box>
          ))}
        {staffDocId &&
          getPayrollSecurityData?.data?.obj?.transactionHistory?.map(
            (item, i) => (
              <Box key={i}>
                <Grid container spacing={1} p={1}>
                  <Grid item xs={3}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      {IndianCurrency(item?.amount)}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      {item?.remarks}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      {dayjs(item?.createdOn).format("DD MMM, YYYY")}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <Typography
                      sx={{
                        fontSize: "13px",
                        fontWeight: 500,
                      }}
                    >
                      {item?.createdBy?.personName}
                    </Typography>
                  </Grid>
                </Grid>
                <Divider
                  sx={{ background: (theme) => theme.palette.primary.main }}
                />
              </Box>
            )
          )}
      </InvoiceHeading>
    </CommonDialog>
  );
};

export default SecurityTransactionDetail;
const InvoiceHeading = styled(Box)`
  background-color: rgba(246, 248, 252, 1);
  .invoice_head__text {
    color: #0c2f49;
    font-size: 14px;
    font-weight: 500;
  }
`;
