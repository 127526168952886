import React, { useState } from "react";
import {
  getSchoolFirm,
  upsertSchoolFirmProfile,
} from "app/services/management";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { Helmet } from "react-helmet";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Input,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import DialogHeader from "app/components/common/DialogHeader";
import { useFormik } from "formik";
import * as yup from "yup";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import uploadFile from "app/utils/uploadFile";

import { MenuProps } from "app/utils/helper";
import { States, Distrct } from "app/config/administrativeConstants";
import { LoadingButton } from "@mui/lab";
import { ButtonWrapper, UploadBtn } from "app/modules/student/components";

const validationSchema = yup.object({
  firmName: yup.string().required("Firm Name Required"),
  firstName: yup.string().required("First name Required"),
  address: yup.string().required("address Required"),
  dist: yup.string().required("district Required"),
  pinCode: yup.string().required("pinCode Required"),
  state: yup.string().required("state Required"),
  description: yup.string().required("description Required"),
  phoneNumber: yup.string().required("Phone no. Required"),
  mobileNumber: yup.string().required("Mobile No. Required"),
  email: yup.string().required("Email Required"),
  website: yup.string().required("Website Required"),
  // gstNumber: yup.string().required("gstNumber Required"),
});

const FirmSchoolProfile = () => {
  const [open, setOpen] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [uploadTarget, setUploadTarget] = useState();
  const [stateIndex, setStateIndex] = useState(0);
  const [attachmentFile, setAttachmentFile] = useState(null);

  const formik = useFormik({
    initialValues: {
      profilePic: "",
      coverPhoto: "",
      firmName: "",
      firstName: "",
      middleName: "",
      lastName: "",
      phoneNumber: "",
      mobileNumber: "",
      email: "",
      website: "",
      address: "",
      dist: "",
      pinCode: "",
      state: "",
      gstNumber: "",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      const updateFirmProfile = {
        profilePic: values.profilePic,
        coverPhoto: values.coverPhoto,
        firmName: values.firmName,
        firstName: values.firstName,
        middleName: values.middleName,
        lastName: values.lastName,
        phoneNumber: String(values.phoneNumber),
        mobileNumber: String(values.mobileNumber),
        website: values?.website,
        email: values.email,
        schoolFirmAddress: {
          address: values.address,
          dist: values.dist,
          pinCode: values.pinCode,
          state: values.state,
        },
        gstNumber: values.gstNumber,
        description: values.description,
      };
      mutate(updateFirmProfile);
    },
    enableReinitialize: true,
  });
  const { mutate, isLoading: updatingSchoolProfile } = useMutation(
    upsertSchoolFirmProfile,

    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        setOpen(false);
        getSchoolFirmrefetch();
      },
      onError: (error) => {
        toast.error(error.response.data?.message);
      },
    }
  );

  const handleUploadFile = async () => {
    if (attachmentFile) {
      let s3FilePath = `student/${schoolProfile?._id}/${attachmentFile?.name}`;
      let attachmentUrl = await uploadFile(
        attachmentFile,
        s3FilePath,
        attachmentFile.type
      );
      if (uploadTarget === "cover") {
        formik.setValues((prev) => ({
          ...prev,
          coverPhoto: attachmentUrl.fileURL,
        }));
      }
      if (uploadTarget === "profile") {
        formik.setValues((prev) => ({
          ...prev,
          profilePic: attachmentUrl.fileURL,
        }));
      }
      setOpenUploadDialog(false);
      setAttachmentFile(null);
    }
  };
  const {
    data: getSchoolFirmData,
    isLoading: loadingSchoolProfile,
    refetch: getSchoolFirmrefetch,
    isFetching,
  } = useQuery({
    queryKey: ["getSchoolFirm"],
    queryFn: () => getSchoolFirm(),
    onSuccess: ({ data }) => {
      formik.setValues((prev) => ({
        ...prev,
        profilePic: data?.obj?.profilePic,
        coverPhoto: data?.obj?.coverPhoto,
        firmName: data?.obj?.firmName,
        description: data?.obj?.description,
        firstName: data?.obj?.firstName,
        middleName: data?.obj?.middleName,
        lastName: data?.obj?.lastName,
        address: data?.obj?.schoolFirmAddress?.address,
        dist: data?.obj?.schoolFirmAddress?.dist,
        pinCode: data?.obj?.schoolFirmAddress?.pinCode,
        state: data?.obj?.schoolFirmAddress?.state,
        affiliatedTo: data?.obj?.affiliatedTo,
        affiliationNumber: data?.obj?.affiliationNumber,
        educationType: data?.obj?.educationType,
        gstNumber: data?.obj?.gstNumber,
        schoolRegisterDate: data?.obj?.schoolRegisterDate,
        email: data?.obj?.email,
        website: data?.obj?.website,
        phoneNumber: data?.obj?.phoneNumber,
        mobileNumber: data?.obj?.mobileNumber,
        schoolGstNumber: data?.obj?.gstNumber,
        licenseNumber: data?.obj?.ekalRefNumber,
        licenseDate: data?.obj?.verifiedOn,
        medium: data?.obj?.medium,
        verifiedOn: data?.obj?.verifiedOn,
        affiliationUpTo: data?.obj?.affiliationUpTo,
        signatureForIdCard: data?.obj?.signatureForIdCard,
      }));
    },
    onError: (data) => {
      toast.error(data?.response?.data?.message);
    },
  });
  let schoolProfile = getSchoolFirmData?.data?.obj;
  return (
    <>
      {/* upload photo */}
      <Dialog
        sx={{ height: "auto" }}
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
      >
        <DialogTitle>
          <h3>Upload {uploadTarget} photo</h3>
        </DialogTitle>
        <DialogContent>
          <Input
            className="m-2"
            type="file"
            onChange={(e) => setAttachmentFile(e.target.files[0])}
          ></Input>
          <ButtonWrapper>
            <UploadBtn
              className="mt-5 "
              onClick={() => {
                handleUploadFile();
              }}
            >
              Upload
            </UploadBtn>
          </ButtonWrapper>
        </DialogContent>
      </Dialog>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogHeader title="Edit Profile" handleClose={() => setOpen(false)} />
        <form onSubmit={formik.handleSubmit}>
          <DialogContent>
            <UploadedCoverPhoto
              src={
                formik.values.coverPhoto
                  ? formik.values.coverPhoto
                  : "/banner-placeholder.png"
              }
            />
            <UploadCoverPicBtn>
              <p
                onClick={() => {
                  setOpenUploadDialog(true);
                  setUploadTarget("cover");
                }}
              >
                Upload Cover Photo
              </p>
            </UploadCoverPicBtn>
            <div className="d-flex flex-row justify-content-start align-items-center">
              <ProfilePic
                src={
                  formik.values.profilePic
                    ? formik.values.profilePic
                    : "/image-placeholder.jpeg"
                }
              />
              <UploadProfilePicBtn>
                <p
                  onClick={() => {
                    setOpenUploadDialog(true);
                    setUploadTarget("profile");
                  }}
                >
                  Upload Logo
                </p>
              </UploadProfilePicBtn>
            </div>
            <Grid container spacing={2}>
              {/* 1st */}
              <Grid item xs={12}>
                <TextFieldLabel title="Firm Name" required />
                <CustomTextField
                  size="small"
                  fullWidth
                  id="firmName"
                  name="firmName"
                  value={formik.values.firmName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firmName && Boolean(formik.errors.firmName)
                  }
                  helperText={formik.touched.firmName && formik.errors.firmName}
                />
              </Grid>
              {/* 2nd */}
              <Grid item xs={4}>
                <TextFieldLabel title="Owner’s First Name" required />

                <CustomTextField
                  size="small"
                  fullWidth
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.firstName && Boolean(formik.errors.firstName)
                  }
                  helperText={
                    formik.touched.firstName && formik.errors.firstName
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldLabel title="Owner’s Middle Name" />
                <CustomTextField
                  size="small"
                  fullWidth
                  id="middleName"
                  name="middleName"
                  value={formik.values.middleName}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={4}>
                <TextFieldLabel title="Owner’s Last Name" />
                <CustomTextField
                  size="small"
                  fullWidth
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                />
              </Grid>

              {/* 3rd */}
              <Grid item xs={6}>
                <TextFieldLabel title="Firm Phone Number :" required />
                <CustomTextField
                  size="small"
                  fullWidth
                  id="phoneNumber"
                  name="phoneNumber"
                  // type="number"
                  value={formik.values.phoneNumber}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Mobile Number :" required />
                <CustomTextField
                  size="small"
                  fullWidth
                  id="mobileNumber"
                  name="mobileNumber"
                  type="number"
                  value={formik.values.mobileNumber}
                  onChange={(e) => {
                    if (String(e.target.value).length < 11) {
                      formik.handleChange(e);
                    }
                  }}
                />
              </Grid>

              {/* 4th */}
              <Grid item xs={6}>
                <TextFieldLabel title="Firm Email Address :" required />
                <CustomTextField
                  size="small"
                  fullWidth
                  placeholder="type  email"
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                />
              </Grid>
              <Grid item xs={6}>
                <TextFieldLabel title="Firm Website :" required />
                <CustomTextField
                  placeholder="type  website"
                  size="small"
                  fullWidth
                  id="website"
                  name="website"
                  value={formik.values.website}
                  onChange={formik.handleChange}
                />
              </Grid>

              {/* 5th */}
              <Grid item xs={12}>
                <TextFieldLabel title="Firm’s Address :" required />
                <CustomTextField
                  size="small"
                  fullWidth
                  id="address"
                  name="address"
                  placeholder="type school address"
                  value={formik.values.address}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.address && Boolean(formik.errors.address)
                  }
                  helperText={formik.touched.address && formik.errors.address}
                />
              </Grid>

              {/* 6th */}

              <Grid item xs={4}>
                <TextFieldLabel title="State" required />
                <CustomSelectBox
                  displayEmpty
                  fullWidth
                  size="small"
                  id="state"
                  required
                  value={formik.values.state}
                  name="state"
                  onChange={formik.handleChange}
                  MenuProps={MenuProps}
                  error={formik.touched.state && Boolean(formik.errors.state)}
                  helperText={formik.touched.state && formik.errors.state}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {States.map((state, index) => (
                    <MenuItem
                      key={state}
                      value={state}
                      onClick={() => {
                        setStateIndex(index);
                      }}
                    >
                      {state}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
              </Grid>
              <Grid item xs={4}>
                <TextFieldLabel title="District" required />
                <CustomSelectBox
                  size="small"
                  fullWidth
                  displayEmpty
                  id="dist"
                  required
                  value={formik.values.dist}
                  name="dist"
                  onChange={formik.handleChange}
                  MenuProps={MenuProps}
                  error={formik.touched.dist && Boolean(formik.errors.dist)}
                  helperText={formik.touched.dist && formik.errors.dist}
                >
                  <MenuItem disabled value="">
                    Select
                  </MenuItem>
                  {Distrct[States.indexOf(String(formik.values.state)) + 1].map(
                    (district) => (
                      <MenuItem key={district} value={district}>
                        {district}
                      </MenuItem>
                    )
                  )}
                </CustomSelectBox>
              </Grid>
              <Grid item xs={4}>
                <TextFieldLabel title="Pincode" required />
                <CustomTextField
                  fullWidth
                  placeholder="type pin code"
                  size="small"
                  id="pinCode"
                  name="pinCode"
                  value={formik.values.pinCode}
                  onChange={formik.handleChange}
                  inputProps={{
                    maxLength: 6,
                  }}
                  error={
                    formik.touched.pinCode && Boolean(formik.errors.pinCode)
                  }
                  helperText={formik.touched.pinCode && formik.errors.pinCode}
                />
              </Grid>

              {/* 7th */}

              <Grid item xs={6}>
                <TextFieldLabel title="Firm GST Number :" />
                <CustomTextField
                  size="small"
                  fullWidth
                  placeholder="type school registration number"
                  id="gstNumber"
                  name="gstNumber"
                  value={formik.values.gstNumber}
                  onChange={formik.handleChange}
                />
              </Grid>

              {/* 8th */}
              <Grid item xs={12}>
                <TextFieldLabel title="Description :" required />
                <CustomTextField
                  size="small"
                  placeholder="description"
                  fullWidth
                  id="description"
                  name="description"
                  // type="number"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </Grid>

              <Grid item xs={12}>
                <TextFieldLabel title="Note :" />
                <Typography sx={{ fontSize: "15px" }}>
                  After updating All data will be updated in existing Invoices
                  Also.
                </Typography>
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={updatingSchoolProfile}
              color="secondary"
              variant="contained"
              type="submit"
              size="large"
              sx={{ fontSize: "capitalize" }}
            >
              Save
            </LoadingButton>
          </DialogActions>
        </form>
      </Dialog>
      <Helmet>
        <title>School Firm Profile</title>
      </Helmet>
      <Wrapper>
        {(loadingSchoolProfile || isFetching) && (
          <Stack direction="row" justifyContent="center" sx={{ py: 2 }}>
            <CircularProgress />
          </Stack>
        )}

        <Card sx={{ width: { md: "80%" }, m: "auto" }}>
          <CoverPhoto
            src={
              schoolProfile?.coverPhoto
                ? schoolProfile?.coverPhoto
                : "/banner-placeholder.png"
            }
          />
          <Stack
            direction="row"
            justifyContent="center"
            sx={{ mt: "-40px", position: "relative" }}
          >
            <LogoContainer>
              <LogoContainerImg
                src={
                  schoolProfile?.profilePic
                    ? schoolProfile?.profilePic
                    : "/image-placeholder.jpeg"
                }
              />
            </LogoContainer>
            <EditButtonWrapper onClick={() => setOpen(true)}>
              <Edit sx={{ color: "#fff" }} />
            </EditButtonWrapper>
          </Stack>

          <Typography variant="h4" textAlign={"center"} my={2}>
            {schoolProfile?.firmName}
          </Typography>
          <Grid container spacing={1} px={1}>
            <Grid item xs={12} md={4}>
              <Typography className="header">Owner Name</Typography>
              <Typography className="content">
                {schoolProfile?.firstName} {schoolProfile?.middleName}{" "}
                {schoolProfile?.lastName}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">Mobile Number</Typography>
              <Typography className="content">
                {schoolProfile?.mobileNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">Firm Phone Number :</Typography>
              <Typography className="content">
                {schoolProfile?.phoneNumber}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">Email Address :</Typography>
              <Typography className="content">
                {schoolProfile?.email}
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <Typography className="header">Website</Typography>
              <Typography className="content">
                {schoolProfile?.website}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="header">Firm’s Address :</Typography>
              <Typography className="content">
                {schoolProfile?.schoolFirmAddress?.address},{" "}
                {schoolProfile?.schoolFirmAddress?.dist},{" "}
                {schoolProfile?.schoolFirmAddress?.state} (
                {schoolProfile?.schoolFirmAddress?.pinCode})
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="header">GST Number :</Typography>
              <Typography className="content">
                {schoolProfile?.gstNumber}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="header">Descriptions</Typography>
              <Typography className="content">
                {schoolProfile?.description}
              </Typography>
            </Grid>
          </Grid>
        </Card>
      </Wrapper>
    </>
  );
};

export default FirmSchoolProfile;
const Wrapper = styled.div`
  .header {
    font-weight: 400;
    color: #0c2f49;
    font-size: 14px;
  }

  .content {
    font-weight: 600;
    color: #0c2f49;
    font-size: 18px;
    display: flex;
    flex-wrap: wrap;
  }
`;
const EditButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 28.09px;
  height: 28.09px;
  background: #2789fd;
  border: 2px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  bottom: 0;
  margin-bottom: -10px;
  margin-left: 50px;
  cursor: pointer;
`;
const Card = styled(Box)`
  border-radius: 10px;
  background-color: white;
  padding-bottom: 10px;
  display: flex;
  flex-direction: column;
  border: 1px solid #a7a7a7;
`;

const CoverPhoto = styled.img`
  height: 25rem;
  border-radius: 10px 10px 0px 0px;
`;
const LogoContainer = styled.div`
  width: 82.17px;
  height: 82.17px;
  background: #f6f8fc;
  border: 1px solid rgba(182, 200, 214, 0.2);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const LogoContainerImg = styled.img`
  width: 60px;
  height: 60px;
  object-fit: contain;
  border-radius: 50%;
`;
const UploadedCoverPhoto = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 142px;
  border-radius: 20px;
  margin-bottom: 10px;
`;
const ProfilePic = styled.img`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 98px;
  border-radius: 20px;
  margin: 10px;
`;
const UploadCoverPicBtn = styled.div`
  background: #ffffff;
  border: 1px solid #3d3efd;
  border-radius: 20px;
  width: 13rem;
  display: flex;
  height: 3rem;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  p {
    padding-top: 12px;
    font-weight: 500;
    font-size: 11px;
    color: #3d3efd;
  }
`;

const UploadProfilePicBtn = styled.div`
  background: #ffffff;
  border: 1px solid #3d3efd;
  border-radius: 20px;
  width: 10rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  p {
    padding-top: 12px;
    font-weight: 500;
    font-size: 11px;
    color: #3d3efd;
  }
`;
