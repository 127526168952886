import {
  Box,
  Button,
  CircularProgress,
  DialogContent,
  Grid,
  IconButton,
  MenuItem,
  Stack,
  Typography,
  colors,
  styled,
} from "@mui/material";

import LabelTextComponent from "app/components/common/LabelTextComponent";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { primaryRoles } from "app/config/administrativeConstants";
import {
  getPayrollHeads,
  getStaffDetailByStaffDocId,
  updateEmployeeInfo,
} from "app/services/hr";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import {
  CustomSelectBox,
  CustomSelectBoxDisabled,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import dayjs from "dayjs";
import { useFormik } from "formik";
import React, { useState } from "react";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { getAllDepartmentLogic } from "app/services/reception";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { Check, Close } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import {
  addEmpPayrollHead,
  deleteEmpPayrollHead,
  getAllEmpPayrollHead,
  getDesignationDropdown,
  updateEmpPayrollHead,
} from "app/services/schoolService/hr.service";
import CustomLoader from "app/components/common/CustomLoader";
import TableActionButtons from "app/components/common/TableActionButtons";
import DeleteConfirmationDialog from "app/components/common/Dialogs/DeleteConfirmationDialog";
import { PAYMENT_MODE } from "app/config/constants";

const ViewEmploymentDialog = ({
  viewOpen,
  handleCloseView,
  staffDocId,
  refetch,
}) => {
  const [state, setState] = useState({
    employmentInfo: {},
    departments: [],
    editEmploymentInfo: false,
    headType: "Earning",
    headName: "",
    perday: "",
    amount: "",
    paymentMode: "",
    payrollHeadDocId: "",
    deleteDocId: false,
    editPayrollHeadDocId: false,
    edit_headType: "Earning",
    edit_headName: "",
    edit_paymentMode: "",
    edit_perday: "",
  });
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const updateEmployeeFormik = useFormik({
    initialValues: {
      departmentDocId: "",
      employmentType: "",
      primaryRole: "",
      designation: "",
      paymentMode: "",
      otherDesignation: "",
      eps: "",
      epf: "",
      joiningDate: new Date(),
      showEditDialog: false,
      salaryHead: [],
    },
    validationSchema: yup.object({
      departmentDocId: yup.string().required("Select a Department"),
      employmentType: yup.string().required("Select an Employment Type"),
      designation: yup.string().required("Select a Designation"),

      otherDesignation: yup.string().when("designation", {
        is: "Other",
        then: yup.string().required("Other Designation Required"),
        otherwise: yup.string(),
      }),
    }),
    onSubmit: (values) => {
      const obj = {
        staffDocId: state?.employmentInfo?.employmentInfo?.staffDocId,
        departmentDocId: values.departmentDocId,
        employmentType: values.employmentType,
        joiningDate: values?.joiningDate,
        designation:
          values.designation === "Other"
            ? values.otherDesignation
            : values.designation,
        epfNumber: values.epf,
        epsNumber: values.eps,
      };
      updateEmployeeInfoMutate(obj);
    },
  });

  const { data: getDesignationDropdownData } = useQuery({
    queryKey: ["getDesignationDropdown"],
    queryFn: () => getDesignationDropdown(),
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: state.editEmploymentInfo ? true : false,
  });

  const totalEarning = () => {
    let total = 0;
    getAllEmpPayrollHeadtData?.data?.list?.forEach((item) => {
      if (item?.headType === "Earning") {
        total += Number(item?.amount);
      }
    });
    return total;
  };
  const totalDeduction = () => {
    let total = 0;
    getAllEmpPayrollHeadtData?.data?.list?.forEach((item) => {
      if (item?.headType === "Deduction") {
        total += Number(item?.amount);
      }
    });
    return total;
  };
  const handleAddEmpPayrollHead = () => {
    if (!String(state.amount).trim()) {
      toast.error("Amount is required");
      return;
    }
    addEmpPayrollHeadMutate({
      payrollHeadDocId: state?.payrollHeadDocId,
      staffDocId: staffDocId,
      amount: Number(state?.amount),
      paymentMode: state.paymentMode,
      perday: state.perday ? state.perday : 0,
    });
  };
  const handleCloseEdit = () => {
    setState((prev) => ({
      ...prev,
      editPayrollHeadDocId: false,
      edit_headType: "Earning",
      edit_headName: "",
      edit_amount: "",
      edit_perday: "",
      edit_paymentMode: "",
    }));
  };
  const handleUpdateEmpPayrollHead = () => {
    if (!String(state.edit_amount).trim()) {
      toast.error("Amount is required");
      return;
    }
    updateEmpPayrollHeadMutate({
      payrollEmpHeadDocId: state.editPayrollHeadDocId,
      amount: Number(state?.edit_amount),
      paymentMode: state.edit_paymentMode,
      perday: state.edit_perday ?? 0,
    });
  };
  const handleCloseDialog = () => {
    updateEmployeeFormik.resetForm();
    handleCloseView();
    setState((prev) => ({
      ...prev,
      employmentInfo: {},
      departments: [],
      editEmploymentInfo: false,
      headType: "Earning",
      headName: "",
      paymentMode: "",
      perday: "",
      amount: "",
      payrollHeadDocId: "",
      deleteDocId: false,
      editPayrollHeadDocId: false,
      edit_headType: "Earning",
      edit_headName: "",
      edit_amount: "",
      edit_paymentMode: "",
      edit_perday: "",
    }));
  };
  const handleDeleteEmpPayrollHead = () => {
    deleteEmpPayrollHeadMutate(state.deleteDocId);
  };
  const handleCloseDelete = () => {
    setState((prev) => ({ ...prev, deleteDocId: false }));
  };
  const {
    mutate: updateEmployeeInfoMutate,
    isLoading: updateEmployeeInfoLoading,
  } = useMutation(updateEmployeeInfo, {
    onSuccess: (res) => {
      getStaffDetailByStaffDocIdRefetch();
      getAllEmpPayrollHeadRefetch();
      toast.success(res?.data?.message);
      refetch();
      setState((prev) => ({
        ...prev,
        editEmploymentInfo: false,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const {
    isLoading: getEmployeeInfoLoading,
    isFetching: getEmployeeInfoFetching,
    refetch: getStaffDetailByStaffDocIdRefetch,
  } = useQuery({
    queryKey: ["getStaffDetailByStaffDocId"],
    queryFn: () => getStaffDetailByStaffDocId(staffDocId),
    onSuccess: (success) => {
      const employmentInfo = success?.data?.obj || {};
      setState((prev) => ({ ...prev, employmentInfo }));
      updateEmployeeFormik.setValues((prev) => ({
        ...prev,
        departmentDocId: employmentInfo?.employmentInfo?.departmentDocId,
        joiningDate: employmentInfo?.employmentInfo?.joiningDate ?? new Date(),
        employmentType: employmentInfo?.employmentInfo?.employmentType,
        primaryRole: employmentInfo?.employmentInfo?.primaryRole,
        designation: employmentInfo?.employmentInfo?.designation,

        eps: employmentInfo?.employmentInfo?.epsNumber,
        epf: employmentInfo?.employmentInfo?.epfNumber,
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: viewOpen ? true : false,
  });
  const { data: getAllPayrollHeadsData } = useQuery({
    queryKey: ["getPayrollHead"],
    queryFn: () => getPayrollHeads(),
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: viewOpen ? true : false,
  });
  const { data: getDepartmentData } = useQuery({
    queryKey: ["getAllDepartmentLogic"],
    queryFn: () =>
      getAllDepartmentLogic({
        sessionMedium: selectedMedium,
        selectedSession: selectedSession,
      }),
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, departments: res?.data?.list }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: viewOpen ? true : false,
  });

  const {
    isLoading: getAllEmpPayrollHeadtLoading,
    isFetching: getAllEmpPayrollHeadtFetching,
    data: getAllEmpPayrollHeadtData,
    refetch: getAllEmpPayrollHeadRefetch,
  } = useQuery({
    queryKey: ["getAllEmpPayrollHeadtData"],
    queryFn: () =>
      getAllEmpPayrollHead({
        staffDocId: staffDocId,
      }),

    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: viewOpen ? true : false,
  });

  const {
    mutate: addEmpPayrollHeadMutate,
    isLoading: addEmpPayrollHeadLoading,
  } = useMutation({
    mutationKey: ["addEmpPayrollHead"],
    mutationFn: addEmpPayrollHead,
    onSuccess: (success) => {
      updateEmployeeFormik.resetForm();
      getStaffDetailByStaffDocIdRefetch();
      getAllEmpPayrollHeadRefetch();
      toast.success(success?.data?.message);
      setState((prev) => ({
        ...prev,
        headName: "",
        paymentMode: "",
        amount: "",
        perday: "",
        payrollHeadDocId: "",
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    mutate: updateEmpPayrollHeadMutate,
    isLoading: updateEmpPayrollHeadLoading,
  } = useMutation({
    mutationKey: ["updateEmpPayrollHead"],
    mutationFn: updateEmpPayrollHead,
    onSuccess: (success) => {
      getAllEmpPayrollHeadRefetch();
      toast.success(success?.data?.message);
      handleCloseEdit();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const {
    mutate: deleteEmpPayrollHeadMutate,
    isLoading: deleteEmpPayrollHeadLoading,
  } = useMutation({
    mutationKey: ["deleteEmpPayrollHead"],
    mutationFn: deleteEmpPayrollHead,
    onSuccess: (success) => {
      getAllEmpPayrollHeadRefetch();
      toast.success(success?.data?.message);
      handleCloseDelete();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  return (
    <>
      <CustomLoader
        show={
          getAllEmpPayrollHeadtLoading ||
          getAllEmpPayrollHeadtFetching ||
          addEmpPayrollHeadLoading ||
          updateEmpPayrollHeadLoading
        }
      />
      <DeleteConfirmationDialog
        handleDelete={handleDeleteEmpPayrollHead}
        loading={deleteEmpPayrollHeadLoading}
        onClose={handleCloseDelete}
        open={state.deleteDocId ? true : false}
      />
      {/* View employment dialog */}
      <CommonDialog
        title="View Employment"
        minWidth="900px"
        open={viewOpen}
        onClose={handleCloseDialog}
      >
        {(getEmployeeInfoLoading || getEmployeeInfoFetching) && (
          <Stack direction="row" justifyContent="center" sx={{ py: 1 }}>
            <CircularProgress size={30} />
          </Stack>
        )}
        <DialogContent>
          <form
            onSubmit={updateEmployeeFormik.handleSubmit}
            id="edit-employee-info-form"
          >
            <Title>Staff Information</Title>
            <CustomBox>
              <Grid container spacing={1}>
                <Grid item xs={6} md={2}>
                  <Box>
                    <img
                      src={
                        state?.employmentInfo?.profilePic ??
                        "/image-placeholder.jpeg"
                      }
                      alt="ekalsutra"
                      style={{ height: "70px", width: "70px" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={6} md={2}>
                  <LabelTextComponent
                    label="Name"
                    text={
                      `${state?.employmentInfo?.firstName} ${state?.employmentInfo?.middleName} ${state?.employmentInfo?.lastName}` ||
                      "--"
                    }
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <LabelTextComponent
                    label="Employee Id"
                    text={state?.employmentInfo?.employmentInfo?.employeeId}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <LabelTextComponent
                    label="Contact No."
                    text={state?.employmentInfo?.mobileNumber}
                  />
                </Grid>

                <Grid item xs={6} md={2}>
                  <LabelTextComponent
                    label="Email"
                    text={state?.employmentInfo?.email}
                  />
                </Grid>
                <Grid item xs={6} md={2}>
                  <LabelTextComponent
                    label="PAN Number"
                    text={state?.employmentInfo?.PAN}
                  />
                </Grid>
              </Grid>
            </CustomBox>
            <Title style={{ marginTop: "10px" }}>Employment Information</Title>
            <CustomBox>
              {state.editEmploymentInfo ? (
                <>
                  <Grid container rowSpacing={1} columnSpacing={2}>
                    <Grid item xs={6} md={4}>
                      <TextFieldLabel title="Joining Date" />

                      <DesktopDatePicker
                        inputFormat="DD MMM, YYYY"
                        name="joiningDate"
                        value={updateEmployeeFormik?.values?.joiningDate}
                        onChange={(e) => {
                          updateEmployeeFormik.setValues((prev) => ({
                            ...prev,
                            joiningDate: e,
                          }));
                        }}
                        renderInput={(params) => (
                          <CustomTextField {...params} size="small" fullWidth />
                        )}
                      />
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextFieldLabel title="Employment Type" />
                      <CustomSelectBox
                        fullWidth
                        size="small"
                        name="employmentType"
                        displayEmpty
                        value={updateEmployeeFormik.values.employmentType}
                        onChange={updateEmployeeFormik.handleChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        <MenuItem value="Part Time">Part Time</MenuItem>
                        <MenuItem value="Full Time">Full Time</MenuItem>
                      </CustomSelectBox>
                      {updateEmployeeFormik.errors.employmentType &&
                        updateEmployeeFormik.touched.employmentType && (
                          <Typography color="error">
                            {updateEmployeeFormik.errors.employmentType}
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextFieldLabel title="Department" />
                      <CustomSelectBox
                        size="small"
                        fullWidth
                        displayEmpty
                        name="departmentDocId"
                        value={updateEmployeeFormik.values.departmentDocId}
                        onChange={updateEmployeeFormik.handleChange}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {getDepartmentData?.data?.list?.map((item) => (
                          <MenuItem value={item?._id}>
                            {item?.departmentName}
                          </MenuItem>
                        ))}
                      </CustomSelectBox>
                      {updateEmployeeFormik.errors.departmentDocId &&
                        updateEmployeeFormik.touched.departmentDocId && (
                          <Typography color="error">
                            {updateEmployeeFormik.errors.departmentDocId}
                          </Typography>
                        )}
                    </Grid>

                    <Grid item xs={6} md={4}>
                      <TextFieldLabel title="Primary Role" />
                      <CustomSelectBoxDisabled
                        size="small"
                        fullWidth
                        displayEmpty
                        name="primaryRole"
                        onBlur={updateEmployeeFormik.handleBlur}
                        value={updateEmployeeFormik.values.primaryRole}
                        onChange={(e) => {
                          updateEmployeeFormik.setValues((prev) => ({
                            ...prev,
                            primaryRole: e.target.value,
                            designation: "",
                          }));
                        }}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {Object.keys(primaryRoles)?.map((role, i) => (
                          <MenuItem value={role} key={i}>
                            {role}
                          </MenuItem>
                        ))}
                      </CustomSelectBoxDisabled>
                      {updateEmployeeFormik.errors.primaryRole &&
                        updateEmployeeFormik.touched.primaryRole && (
                          <Typography color="error">
                            {updateEmployeeFormik.errors.primaryRole}
                          </Typography>
                        )}
                    </Grid>
                    <Grid item xs={6} md={4}>
                      <TextFieldLabel title="Designation" />
                      <CustomSelectBox
                        size="small"
                        fullWidth
                        displayEmpty
                        name="designation"
                        value={updateEmployeeFormik.values.designation}
                        onChange={updateEmployeeFormik.handleChange}
                        MenuProps={MenuProps}
                      >
                        <MenuItem value="" disabled>
                          Select
                        </MenuItem>
                        {getDesignationDropdownData?.data?.list?.map((item) => (
                          <MenuItem value={item}>{item}</MenuItem>
                        ))}
                        <MenuItem value="Other">Other</MenuItem>
                      </CustomSelectBox>
                      {updateEmployeeFormik.errors.designation &&
                        updateEmployeeFormik.touched.designation && (
                          <Typography color="error">
                            {updateEmployeeFormik.errors.designation}
                          </Typography>
                        )}
                    </Grid>
                    {updateEmployeeFormik.values.designation === "Other" && (
                      <Grid item xs={6} md={4}>
                        <TextFieldLabel title="Other Designation" />
                        <CustomTextField
                          size="small"
                          fullWidth
                          name="otherDesignation"
                          value={updateEmployeeFormik.values.otherDesignation}
                          onChange={updateEmployeeFormik.handleChange}
                          placeholder="type designation"
                          error={
                            Boolean(
                              updateEmployeeFormik.errors.otherDesignation
                            ) && updateEmployeeFormik.touched.otherDesignation
                          }
                          helperText={
                            updateEmployeeFormik.touched.otherDesignation &&
                            updateEmployeeFormik.errors.otherDesignation
                          }
                        />
                      </Grid>
                    )}

                    <Grid item xs={4}>
                      <TextFieldLabel title="EPS Number" />
                      <CustomTextField
                        size="small"
                        fullWidth
                        placeholder="Enter EPS Number"
                        name="eps"
                        value={updateEmployeeFormik.values.eps}
                        onBlur={updateEmployeeFormik.handleBlur}
                        onChange={updateEmployeeFormik.handleChange}
                      />
                    </Grid>
                    <Grid item xs={4}>
                      <TextFieldLabel title="EPF Number" />
                      <CustomTextField
                        size="small"
                        fullWidth
                        placeholder="Enter EPF Number"
                        name="epf"
                        value={updateEmployeeFormik.values.epf}
                        onBlur={updateEmployeeFormik.handleBlur}
                        onChange={updateEmployeeFormik.handleChange}
                      />
                    </Grid>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid container rowSpacing={1} columnSpacing={2} columns={10}>
                    <Grid item xs={6} md={2}>
                      <LabelTextComponent
                        label="Joining Date"
                        text={dayjs(
                          state?.employmentInfo?.employmentInfo?.joiningDate
                        ).format("DD MMM, YYYY")}
                      />
                    </Grid>
                    <Grid item xs={6} md={2}>
                      <LabelTextComponent
                        label="Employment Type"
                        text={
                          state?.employmentInfo?.employmentInfo?.employmentType
                        }
                      />
                    </Grid>{" "}
                    <Grid item xs={6} md={2}>
                      <LabelTextComponent
                        label="Department"
                        text={
                          state?.employmentInfo?.employmentInfo?.departmentName
                        }
                      />
                    </Grid>{" "}
                    <Grid item xs={6} md={2}>
                      <LabelTextComponent
                        label="Primary Role"
                        text={
                          state?.employmentInfo?.employmentInfo?.primaryRole
                        }
                      />
                    </Grid>{" "}
                    <Grid item xs={6} md={2}>
                      <LabelTextComponent
                        label="Designation"
                        text={
                          state?.employmentInfo?.employmentInfo?.designation
                        }
                      />
                    </Grid>{" "}
                    <Grid item xs={6} md={2}>
                      <LabelTextComponent
                        label="EPS Number"
                        text={state?.employmentInfo?.employmentInfo?.epsNumber}
                      />
                    </Grid>{" "}
                    <Grid item xs={6} md={2}>
                      <LabelTextComponent
                        label="EPF Number"
                        text={state?.employmentInfo?.employmentInfo?.epfNumber}
                      />
                    </Grid>{" "}
                  </Grid>
                </>
              )}
              {state.editEmploymentInfo ? (
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  mt={1}
                  gap={2}
                >
                  <Button
                    sx={{
                      background: "#fff",
                      ":hover": {
                        background: "#fff",
                      },
                    }}
                    color="error"
                    variant="outlined"
                    size="large"
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        editEmploymentInfo: false,
                      }))
                    }
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    size="large"
                    loading={updateEmployeeInfoLoading}
                    type="submit"
                  >
                    Update
                  </LoadingButton>
                </Stack>
              ) : (
                <Stack
                  direction={"row"}
                  justifyContent={"flex-end"}
                  mt={1}
                  gap={2}
                >
                  <LoadingButton
                    color="secondary"
                    variant="contained"
                    size="large"
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        editEmploymentInfo: true,
                      }))
                    }
                  >
                    Edit
                  </LoadingButton>
                </Stack>
              )}
            </CustomBox>
          </form>
          <Stack marginBottom="10px">
            <Title sx={{ mt: "10px" }}>Salary Detail</Title>
            <CustomBox>
              <Box style={{ padding: "5px" }}>
                <Grid container columnSpacing={1} sx={{ pb: 0.5 }} columns={14}>
                  <Grid item xs={14} md={1}>
                    <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
                      Sr. No.
                    </Typography>
                  </Grid>
                  <Grid item xs={14} md={2}>
                    <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
                      Head Type
                    </Typography>
                  </Grid>
                  <Grid item xs={14} md={3}>
                    <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
                      Head Name
                    </Typography>
                  </Grid>
                  <Grid item xs={14} md={2}>
                    <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
                      Amount
                    </Typography>
                  </Grid>
                  <Grid item xs={14} md={2}>
                    <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
                      Mode
                    </Typography>
                  </Grid>
                  <Grid xs={14} md={2}>
                    <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
                      Per Day
                    </Typography>
                  </Grid>
                  <Grid item xs={14} md={2}>
                    <Typography sx={{ fontWeight: 600, fontSize: "13px" }}>
                      Action
                    </Typography>
                  </Grid>
                </Grid>

                {getAllEmpPayrollHeadtData?.data?.list?.map((item, i) =>
                  state.editPayrollHeadDocId === item?._id ? (
                    <Grid
                      container
                      columnSpacing={1}
                      sx={{ pb: 0.5 }}
                      columns={14}
                      alignItems={"center"}
                    >
                      <Grid item xs={14} md={1}>
                        <Typography>{i + 1}</Typography>
                      </Grid>
                      <Grid item xs={14} md={2}>
                        <CustomSelectBox
                          size="small"
                          displayEmpty
                          fullWidth
                          value={state.edit_headType}
                          onChange={(e) => {
                            setState((prev) => ({
                              ...prev,
                              edit_headType: e.target.value,
                            }));
                          }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          <MenuItem value="Earning">Earning</MenuItem>
                          <MenuItem value="Deduction">Deduction</MenuItem>
                        </CustomSelectBox>
                      </Grid>
                      <Grid item xs={14} md={3}>
                        <CustomSelectBox
                          MenuProps={MenuProps}
                          value={state.edit_headName}
                          size="small"
                          displayEmpty
                          sx={{ minWidth: "150px" }}
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {getAllPayrollHeadsData?.data?.list
                            ?.filter(
                              (res) => res?.headType === state?.edit_headType
                            )
                            ?.map((item, ind) => (
                              <MenuItem
                                key={ind}
                                value={item?.headName}
                                onClick={(e) =>
                                  setState((prev) => ({
                                    ...prev,
                                    edit_headName: item?.headName,
                                    payrollHeadDocId: item?._id,
                                  }))
                                }
                              >
                                {item?.headName}
                              </MenuItem>
                            ))}
                        </CustomSelectBox>
                      </Grid>

                      <Grid item xs={14} md={2}>
                        <CustomTextField
                          type="number"
                          placeholder="Type Amount"
                          size="small"
                          value={state.edit_amount}
                          onChange={(e) =>
                            setState((prev) => ({
                              ...prev,
                              edit_amount: e.target.value,
                            }))
                          }
                        />
                      </Grid>
                      <Grid item xs={14} md={2}>
                        <CustomSelectBox
                          native
                          displayEmpty
                          size="small"
                          fullWidth
                          value={state.edit_paymentMode}
                          onChange={(e) =>
                            setState((prev) => ({
                              ...prev,
                              edit_paymentMode: e.target.value,
                            }))
                          }
                        >
                          <MenuItem value="" component="option">
                            Select
                          </MenuItem>
                          {PAYMENT_MODE?.map((item) => (
                            <MenuItem
                              value={item?.value}
                              key={item?.value}
                              component="option"
                            >
                              {item?.name}
                            </MenuItem>
                          ))}
                        </CustomSelectBox>
                      </Grid>
                      <Grid item xs={14} md={2}>
                        <CustomTextField
                          type="number"
                          placeholder="Type Amount"
                          size="small"
                          value={state.edit_perday}
                          onChange={(e) =>
                            setState((prev) => ({
                              ...prev,
                              edit_perday: e.target.value,
                            }))
                          }
                        />
                      </Grid>

                      <Grid item xs={14} md={2}>
                        <Stack direction={"row"} alignItems={"center"} gap={1}>
                          <IconButton onClick={handleUpdateEmpPayrollHead}>
                            <Check color="success" />
                          </IconButton>
                          <IconButton onClick={handleCloseEdit}>
                            <Close color="error" />
                          </IconButton>
                        </Stack>
                      </Grid>
                    </Grid>
                  ) : (
                    <Grid
                      columns={14}
                      container
                      columnSpacing={1}
                      rowSpacing={1}
                      key={i}
                      sx={{ pb: 0.5 }}
                      alignItems="center"
                    >
                      <Grid item xs={14} md={1}>
                        <Typography>{i + 1}</Typography>
                      </Grid>
                      <Grid item xs={14} md={2}>
                        <Typography>{item?.headType}</Typography>
                      </Grid>
                      <Grid item xs={14} md={3}>
                        <Typography>{item?.headName}</Typography>
                      </Grid>
                      <Grid item xs={14} md={2}>
                        <Typography
                          sx={{
                            fontWeight: 600,
                            color:
                              item?.headType === "Earning"
                                ? colors.green[500]
                                : colors.red[600],
                          }}
                        >
                          {IndianCurrency(item?.amount)}
                        </Typography>
                      </Grid>
                      <Grid item xs={14} md={2}>
                        <Typography>{item?.paymentMode}</Typography>
                      </Grid>
                      <Grid item xs={14} md={2}>
                        <Typography>{item?.perday}</Typography>
                      </Grid>

                      <Grid item xs={14} md={2}>
                        <TableActionButtons
                          showDelete
                          showEdit
                          handleDelete={() => {
                            setState((prev) => ({
                              ...prev,
                              deleteDocId: item?._id,
                            }));
                          }}
                          handleEdit={() =>
                            setState((prev) => ({
                              ...prev,
                              editPayrollHeadDocId: item?._id,
                              edit_headType: item?.headType,
                              edit_headName: item?.headName,
                              edit_amount: item?.amount,
                              edit_paymentMode: item?.paymentMode,
                              edit_perday: item?.perday,
                            }))
                          }
                        />
                      </Grid>
                    </Grid>
                  )
                )}

                <Grid
                  container
                  columnSpacing={1}
                  sx={{ pb: 0.5 }}
                  columns={14}
                  alignItems={"center"}
                >
                  <Grid item xs={14} md={1} />
                  <Grid item xs={14} md={2}>
                    <CustomSelectBox
                      size="small"
                      displayEmpty
                      fullWidth
                      value={state.headType}
                      onChange={(e) => {
                        setState((prev) => ({
                          ...prev,
                          headType: e.target.value,
                        }));
                      }}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      <MenuItem value="Earning">Earning</MenuItem>
                      <MenuItem value="Deduction">Deduction</MenuItem>
                    </CustomSelectBox>
                  </Grid>
                  <Grid item xs={14} md={3}>
                    <CustomSelectBox
                      MenuProps={MenuProps}
                      value={state.headName}
                      size="small"
                      displayEmpty
                      sx={{ minWidth: "150px" }}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {getAllPayrollHeadsData?.data?.list
                        ?.filter(
                          (res) =>
                            !getAllEmpPayrollHeadtData?.data?.list
                              ?.map((res) => res?.payrollHeadDocId)
                              ?.includes(res?._id)
                        )
                        ?.filter((res) => res?.headType === state?.headType)
                        ?.map((item, ind) => (
                          <MenuItem
                            key={ind}
                            value={item?.headName}
                            onClick={(e) =>
                              setState((prev) => ({
                                ...prev,
                                headName: item?.headName,
                                payrollHeadDocId: item?._id,
                              }))
                            }
                          >
                            {item?.headName}
                          </MenuItem>
                        ))}
                    </CustomSelectBox>
                  </Grid>
                  <Grid item xs={14} md={2}>
                    <CustomTextField
                      type="number"
                      placeholder="Type Amount"
                      size="small"
                      value={state.amount}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          amount: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={14} md={2}>
                    <CustomSelectBox
                      native
                      displayEmpty
                      size="small"
                      fullWidth
                      value={state.paymentMode}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          paymentMode: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value="" component="option">
                        Select
                      </MenuItem>
                      {PAYMENT_MODE?.map((item) => (
                        <MenuItem
                          value={item?.value}
                          key={item?.value}
                          component="option"
                        >
                          {item?.name}
                        </MenuItem>
                      ))}
                    </CustomSelectBox>
                  </Grid>
                  <Grid item xs={14} md={2}>
                    <CustomTextField
                      type="number"
                      placeholder="Type Amount"
                      size="small"
                      value={state.perday}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          perday: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                  <Grid item xs={14} md={2}>
                    <IconButton onClick={handleAddEmpPayrollHead}>
                      <Check color="success" />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            </CustomBox>
          </Stack>
          <Stack
            direction={"row"}
            gap={2}
            justifyContent={"space-between"}
            my={2}
          >
            <Box>
              <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
                Total Earning
              </Typography>
              <Typography
                sx={{
                  color: colors.green[500],
                  fontWeight: 600,
                  textAlign: "end",
                }}
              >
                {IndianCurrency(totalEarning())}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
                Total Deduction
              </Typography>
              <Typography
                sx={{
                  color: colors.red[500],
                  fontWeight: 600,
                  textAlign: "end",
                }}
              >
                {IndianCurrency(totalDeduction())}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontWeight: 500, fontSize: "14px" }}>
                Net Pay
              </Typography>
              <Typography sx={{ fontWeight: 600, textAlign: "end" }}>
                {IndianCurrency(totalEarning() - totalDeduction())}
              </Typography>
            </Box>
          </Stack>
        </DialogContent>
      </CommonDialog>
      {/* update emaployment dialog */}
    </>
  );
};

export default ViewEmploymentDialog;
const CustomBox = styled("div")`
  background: rgba(208, 208, 226, 0.08);
  border: 1px solid #d0d0e2;
  border-radius: 15px;
  width: 100%;
  padding: 10px;

  .sub-title {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 5px;
    opacity: 0.75;
  }

  .sub-title-content {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
  }
`;

const Title = styled(Typography)`
  font-weight: 600;
  font-size: 16px;
  color: #0c2f49;
  margin-bottom: 10px;
  margin-left: 10px;
`;
