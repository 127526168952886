import { Typography } from "@mui/material";
import moment from "moment";
import styled from "styled-components";

export default function ParentInfo({ data }) {
  const TypoConfigBold = {
    fontWeight: "bold",
    fontSize: "14px",
  };
  const TypoConfig = {
    fontWeight: "semi-bold",
    fontSize: "14px",
  };
  return (
    <MainWrapper>
      <div
        style={{
          width: "40%",
        }}
      >
        <h3>Father Information</h3>
        <ChildDiv>
          <Typography {...TypoConfigBold}>FirstName : </Typography>
          <Typography {...TypoConfig}>{data?.fatherInfo?.firstName}</Typography>
        </ChildDiv>

        <ChildDiv>
          <Typography {...TypoConfigBold}>LastName : </Typography>
          <Typography {...TypoConfig}>{data?.fatherInfo?.lastName}</Typography>
        </ChildDiv>
      </div>

      <div
        style={{
          width: "40%",
        }}
      >
        <h3>Mother Information</h3>
        <ChildDiv>
          <Typography {...TypoConfigBold}>FirstName : </Typography>
          <Typography {...TypoConfig}>{data?.motherInfo?.firstName}</Typography>
        </ChildDiv>

        <ChildDiv>
          <Typography {...TypoConfigBold}>LastName : </Typography>
          <Typography {...TypoConfig}>{data?.motherInfo?.lastName}</Typography>
        </ChildDiv>
      </div>
    </MainWrapper>
  );
}

const MainWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 2rem;
`;
const ChildDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

const FullDiv = styled.div`
  width: 90%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;
