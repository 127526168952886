import React, { useEffect, useRef } from "react";
import CommonDialog from "app/components/common/Dialogs/CommonDialog";
import uploadFile from "app/utils/uploadFile";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "react-query";
import { getClassDropdownLogic } from "app/services/reception";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { Distrct, States } from "app/config/administrativeConstants";
import PaymentModeComponent from "app/components/common/PaymentModeComponent";
import {
  GenderChoice,
  Community,
  Category,
} from "app/config/administrativeConstants";
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import * as yup from "yup";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";
import {
  CustomSelectBox,
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import PreviewDownloadImage from "app/components/common/PreviewDownloadImage";
import { MenuProps } from "app/utils/helper";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import CustomLoader from "app/components/common/CustomLoader";
import {
  addAdmissionEnquiry,
  updateAdmissionEnquiry,
} from "app/services/schoolService/admissionEnquiry.service";
const validationSchema = yup.object({
  classDocId: yup.string().required("classDocId's name is required"),
  firstName: yup.string().required("firstName is required"),
  father_firstName: yup.string().required("firstName is required"),
  mother_firstName: yup.string().required("firstName is required"),
  // current_address: yup.string().required("Address is required"),
  // current_dist: yup.string().required("Dist is required"),
  // current_pinCode: yup.number().required("PINCODE is required"),
  // current_state: yup.string().required("state is required"),
});

const ErrorText = ({ text }) => {
  return <Typography color="error">{text}</Typography>;
};

const AddEnquiryForm = ({
  edit = false,
  editData = {},
  add = false,
  refetch,
  handleClose = () => {},
}) => {
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const formik = useFormik({
    initialValues: {
      imageloading: false,
      enquiryDate: new Date(),
      formNumber: "",
      classDocId: "",

      // Personal Information
      profilePic: "",
      firstName: "",
      middleName: "",
      lastName: "",

      mobileNumber: "",
      gender: "",
      community: "",
      category: "",

      // Parent’s information
      father_firstName: "",
      father_middleName: "",
      father_lastName: "",
      father_mobileNumber: "",
      father_occupation: "",
      father_annualIncome: 0,

      mother_firstName: "",
      mother_middleName: "",
      mother_lastName: "",
      mother_mobileNumber: "",
      mother_occupation: "",
      mother_annualIncome: 0,

      guardianInfo_firstName: "",
      guardianInfo_middleName: "",
      guardianInfo_lastName: "",
      guardianInfo_mobileNumber: "",
      guardianInfo_occupation: "",
      guardianInfo_annualIncome: 0,
      guardianInfo_relation: "",

      // Address Information
      current_address: "",
      current_dist: "",
      current_pinCode: "",
      current_state: "",

      // Payment Related
      formAmount: 0,
      paymentDetails: {},
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let isAddressEmpty =
        values?.current_address === "" &&
        values?.current_dist === "" &&
        values?.current_pinCode === "" &&
        values?.current_state === "";
      let body = {
        session: selectedSession,
        enquiryDate: new Date(values?.enquiryDate),
        formNumber: values?.formNumber,
        classDocId: values?.classDocId,

        // Personal Information
        profilePic: values?.profilePic,
        firstName: values?.firstName,
        middleName: values?.middleName,
        lastName: values?.lastName,
        mobileNumber: values?.mobileNumber,
        gender: values?.gender,
        community: values?.community,
        category: values?.category,

        // Parent’s information
        fatherInfo: {
          firstName: values?.father_firstName,
          middleName: values?.father_middleName,
          lastName: values?.father_lastName,
          mobileNumber: values?.father_mobileNumber,
          occupation: values?.father_occupation,
          annualIncome: values?.father_annualIncome,
        },
        motherInfo: {
          firstName: values?.mother_firstName,
          middleName: values?.mother_middleName,
          lastName: values?.mother_lastName,
          mobileNumber: values?.mother_mobileNumber,
          occupation: values?.mother_occupation,
          annualIncome: values?.mother_annualIncome,
        },
        guardianInfo: {
          firstName: values?.guardianInfo_firstName,
          middleName: values?.guardianInfo_middleName,
          lastName: values?.guardianInfo_lastName,
          mobileNumber: values?.guardianInfo_mobileNumber,
          occupation: values?.guardianInfo_occupation,
          // annualIncome: values?.guardianInfo_annualIncome,
          relation: values?.guardianInfo_relation ?? "",
        },
        // Address Information
        currentAddress: isAddressEmpty
          ? null
          : {
              address: values?.current_address,
              dist: values?.current_dist,
              pinCode: values?.current_pinCode,
              state: values?.current_state,
            },
        //payement
        paymentDetails: values?.paymentDetails,
      };
      if (add) {
        addAdmissionEnquiryMutate(body);
      } else if (edit) {
        let editBody = {
          session: selectedSession,
          enquiryDate: new Date(values?.enquiryDate),
          classDocId: values?.classDocId,

          // Personal Information
          profilePic: values?.profilePic,
          firstName: values?.firstName,
          middleName: values?.middleName,
          lastName: values?.lastName,
          mobileNumber: values?.mobileNumber,
          gender: values?.gender,
          community: values?.community,
          category: values?.category,

          // Parent’s information
          fatherInfo: {
            firstName: values?.father_firstName,
            middleName: values?.father_middleName,
            lastName: values?.father_lastName,
            mobileNumber: values?.father_mobileNumber,
            occupation: values?.father_occupation,
            annualIncome: values?.father_annualIncome,
          },
          motherInfo: {
            firstName: values?.mother_firstName,
            middleName: values?.mother_middleName,
            lastName: values?.mother_lastName,
            mobileNumber: values?.mother_mobileNumber,
            occupation: values?.mother_occupation,
            annualIncome: values?.mother_annualIncome,
          },
          guardianInfo: {
            firstName: values?.guardianInfo_firstName,
            middleName: values?.guardianInfo_middleName,
            lastName: values?.guardianInfo_lastName,
            mobileNumber: values?.guardianInfo_mobileNumber,
            occupation: values?.guardianInfo_occupation,
            // annualIncome: values?.guardianInfo_annualIncome,
            relation: values?.guardianInfo_relation ?? "",
          },
          // Address Information
          currentAddress: {
            address: values?.current_address,
            dist: values?.current_dist,
            pinCode: values?.current_pinCode,
            state: values?.current_state,
          },
          //payement
          paymentDetails: values?.paymentDetails,
        };
        updateAdmissionEnquiryMutate({
          ...editBody,
          admissionEnquiryDocId: editData?._id,
        });
      }
    },
  });

  const handleUpload = async (e) => {
    formik.setValues((prev) => ({
      ...prev,
      imageloading: true,
    }));
    try {
      let s3FilePath = `reception/${schoolDocId}/${e.target.files[0].name}`;
      let attachmentUrl = await uploadFile(
        e.target.files[0],
        s3FilePath,
        e.target.files[0].type
      );
      formik.setValues((prev) => ({
        ...prev,
        profilePic: attachmentUrl.fileURL,
        imageloading: false,
      }));
    } catch (error) {
      toast.error(error);
      formik.setValues((prev) => ({
        ...prev,
        imageloading: false,
      }));
    }
  };

  const closeDialog = () => {
    handleClose();
    formik.resetForm();
  };

  const { data: classData } = useQuery({
    queryKey: ["getClassInfo", selectedSession, selectedMedium],
    queryFn: () =>
      getClassDropdownLogic({ schoolDocId, selectedMedium, selectedSession }),
  });
  const {
    mutate: addAdmissionEnquiryMutate,
    isLoading: addAdmissionEnquiryLoading,
  } = useMutation(addAdmissionEnquiry, {
    onSuccess: ({ data }) => {
      handleClose();
      toast.success(data?.message);
      refetch();
      closeDialog();
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  const {
    mutate: updateAdmissionEnquiryMutate,
    isLoading: updateAdmissionEnquiryLoading,
  } = useMutation(updateAdmissionEnquiry, {
    onSuccess: ({ data }) => {
      handleClose();
      toast.success(data?.message);
      refetch();
      closeDialog();
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });

  useEffect(() => {
    if (edit) {
      formik.setValues((prev) => ({
        ...prev,
        enquiryDate: editData?.enquiryDate,
        formNumber: editData?.formNumber,
        classDocId: editData?.classDocId,

        profilePic: editData?.profilePic,
        firstName: editData?.firstName,
        middleName: editData?.middleName,
        lastName: editData?.lastName,

        mobileNumber: editData?.mobileNumber,
        gender: editData?.gender,
        community: editData?.community,
        category: editData?.category,

        // Parent’s information
        father_firstName: editData?.fatherInfo?.firstName,
        father_middleName: editData?.fatherInfo?.middleName,
        father_lastName: editData?.fatherInfo?.lastName,
        father_mobileNumber: editData?.fatherInfo?.mobileNumber,
        father_occupation: editData?.fatherInfo?.occupation,
        father_annualIncome: editData?.fatherInfo?.annualIncome,

        mother_firstName: editData?.motherInfo?.firstName,
        mother_middleName: editData?.motherInfo?.middleName,
        mother_lastName: editData?.motherInfo?.lastName,
        mother_mobileNumber: editData?.motherInfo?.mobileNumber,
        mother_occupation: editData?.motherInfo?.occupation,
        mother_annualIncome: editData?.motherInfo?.annualIncome,

        guardianInfo_firstName: editData?.guardianInfo?.firstName,
        guardianInfo_middleName: editData?.guardianInfo?.middleName,
        guardianInfo_lastName: editData?.guardianInfo?.lastName,
        guardianInfo_mobileNumber: editData?.guardianInfo?.mobileNumber,
        guardianInfo_occupation: editData?.guardianInfo?.occupation,
        guardianInfo_annualIncome: editData?.guardianInfo?.annualIncome,
        guardianInfo_relation: editData?.guardianInfo?.annualIncome,

        // Address Information
        current_address: editData?.currentAddress?.address,
        current_dist: editData?.currentAddress?.dist,
        current_pinCode: editData?.currentAddress?.pinCode,
        current_state: editData?.currentAddress?.state,

        formAmount: editData?.formAmount,
        paymentDetails: editData?.paymentDetails,
      }));
    }
  }, [edit]);
  useEffect(() => {
    if (add) {
      formik.setValues((prev) => ({ ...prev, addDialog: true }));
    }
  }, [add]);
  const districts = Distrct[States.indexOf(formik.values.current_state) + 1];

  return (
    <>
      <CustomLoader
        show={addAdmissionEnquiryLoading || updateAdmissionEnquiryLoading}
      />
      <CommonDialog
        open={add || edit}
        onClose={closeDialog}
        title={
          formik.values.edit
            ? "Edit Admission Enquiry Form"
            : "Admission Enquiry Form"
        }
        minWidth="85%"
      >
        <DialogContent>
          <form onSubmit={formik.handleSubmit}>
            {/* Student Info  */}
            <Box>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Student Information
              </Typography>
              <Stack
                sx={{
                  border: "1px solid #3D398933",
                  backgroundColor: "##FBFBFF",
                  p: 1,
                  borderRadius: "10px",
                }}
              >
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* Photo  */}
                  <Grid item xs={3}>
                    {!formik.values.edit && (
                      <Box sx={{ pt: 2 }}>
                        <Stack
                          direction="column"
                          gap={1}
                          justifyContent="center"
                        >
                          <Box sx={{ ml: 2.5 }}>
                            {formik.values.profilePic ? (
                              <PreviewDownloadImage
                                src={formik.values.profilePic}
                                deleteIcon={true}
                                handleDelete={() =>
                                  formik.setValues((prev) => ({
                                    ...prev,
                                    profilePic: "",
                                  }))
                                }
                              />
                            ) : (
                              <img
                                alt="ekalsutra"
                                src="/image-placeholder.jpeg"
                                style={{ height: "100px", width: "100px" }}
                              />
                            )}
                          </Box>
                          <Box>
                            <LoadingButton
                              loading={formik.values.imageloading}
                              variant="outlined"
                              color="secondary"
                              size="large"
                              sx={{
                                borderRadius: "10px",
                                backgroundColor: "#fff",
                                textTransform: "capitalize",
                              }}
                              component="label"
                            >
                              <input
                                type="file"
                                accept="image/*"
                                hidden
                                onChange={(e) => handleUpload(e)}
                              />
                              Upload
                            </LoadingButton>
                          </Box>
                        </Stack>
                      </Box>
                    )}
                  </Grid>
                  {/* Student Info  */}
                  <Grid item xs={9}>
                    <Grid container spacing={2}>
                      {/* Enquiry Date *  */}
                      <Grid item xs={4}>
                        <TextFieldLabel title="Enquiry Date" />
                        <DesktopDatePicker
                          inputFormat="DD-MM-YYYY"
                          onChange={(e) =>
                            formik.setValues((prev) => ({
                              ...prev,
                              enquiryDate: e,
                            }))
                          }
                          value={formik.values.enquiryDate}
                          renderInput={(params) => (
                            <CustomTextField
                              {...params}
                              size="small"
                              fullWidth
                            />
                          )}
                        />
                      </Grid>
                      {/* Class-Stream *  */}
                      <Grid item xs={4}>
                        <TextFieldLabel title="Class-Stream" required />
                        <CustomSelectBox
                          displayEmpty
                          fullWidth
                          size="small"
                          name="classDocId"
                          MenuProps={MenuProps}
                          value={formik.values.classDocId}
                          error={
                            formik.touched.classDocId &&
                            Boolean(formik.errors.classDocId)
                          }
                          helperText={
                            formik.touched.classDocId &&
                            formik.errors.classDocId
                          }
                        >
                          <MenuItem value="" disabled>
                            Select
                          </MenuItem>
                          {classData?.data?.list?.map((item, idx) => (
                            <MenuItem
                              key={item.classDocId}
                              value={item.classDocId}
                              onClick={() =>
                                formik.setValues((prev) => ({
                                  ...prev,
                                  classDocId: item?.classDocId,
                                }))
                              }
                            >
                              {item.className_stream}
                            </MenuItem>
                          ))}
                        </CustomSelectBox>
                      </Grid>
                      {/* formNumber  */}
                      <Grid item xs={4}>
                        <TextFieldLabel title="Form Number" />
                        {add && (
                          <CustomTextField
                            size="small"
                            id="formNumber"
                            name="formNumber"
                            placeholder="Type Form Number"
                            fullWidth
                            value={formik.values.formNumber}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.formNumber &&
                              Boolean(formik.errors.formNumber)
                            }
                            helperText={
                              formik.touched.formNumber &&
                              formik.errors.formNumber
                            }
                          />
                        )}
                        {edit && (
                          <CustomTextFieldDisabled
                            size="small"
                            id="formNumber"
                            name="formNumber"
                            placeholder="Type Form Number"
                            fullWidth
                            value={formik.values.formNumber}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.formNumber &&
                              Boolean(formik.errors.formNumber)
                            }
                            helperText={
                              formik.touched.formNumber &&
                              formik.errors.formNumber
                            }
                          />
                        )}
                      </Grid>
                      {/* firstName  */}
                      <Grid item xs={4}>
                        <TextFieldLabel title="First Name" required />
                        <CustomTextField
                          size="small"
                          id="firstName"
                          name="firstName"
                          placeholder="First Name"
                          fullWidth
                          value={formik.values.firstName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.firstName &&
                            Boolean(formik.errors.firstName)
                          }
                          helperText={
                            formik.touched.firstName && formik.errors.firstName
                          }
                        />
                      </Grid>
                      {/* middleName  */}
                      <Grid item xs={4}>
                        <TextFieldLabel title="Middle Name" />
                        <CustomTextField
                          size="small"
                          id="middleName"
                          name="middleName"
                          placeholder="Middle Name"
                          fullWidth
                          value={formik.values.middleName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.middleName &&
                            Boolean(formik.errors.middleName)
                          }
                          helperText={
                            formik.touched.middleName &&
                            formik.errors.middleName
                          }
                        />
                      </Grid>
                      {/* lastName  */}
                      <Grid item xs={4}>
                        <TextFieldLabel title="Last Name" />
                        <CustomTextField
                          size="small"
                          id="lastName"
                          name="lastName"
                          placeholder="Last Name"
                          fullWidth
                          value={formik.values.lastName}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.lastName &&
                            Boolean(formik.errors.lastName)
                          }
                          helperText={
                            formik.touched.lastName && formik.errors.lastName
                          }
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* mobileNumber  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Mobile Number"} />
                    <CustomTextField
                      fullWidth
                      type="mobileNumber"
                      size="small"
                      placeholder="Enter Mobile Number"
                      name="mobileNumber"
                      value={formik.values.mobileNumber}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          formik.handleChange(e);
                        }
                      }}
                    />
                    {formik.touched.mobileNumber &&
                      formik.errors.mobileNumber && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.mobileNumber}
                        </Typography>
                      )}
                  </Grid>
                  {/* Gender  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Gender"} />
                    <CustomSelectBox
                      displayEmpty
                      native
                      size="small"
                      fullWidth
                      name="gender"
                      onChange={formik.handleChange}
                      value={formik.values.gender}
                      error={
                        formik.touched.gender && Boolean(formik.errors?.gender)
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem component="option" value="" disabled>
                        Select
                      </MenuItem>
                      {GenderChoice.map((gender) => (
                        <MenuItem
                          component="option"
                          key={gender}
                          value={gender}
                        >
                          {gender}
                        </MenuItem>
                      ))}
                    </CustomSelectBox>
                    {formik.touched.gender &&
                      Boolean(formik.errors?.gender) && (
                        <ErrorText text={formik.errors.gender} />
                      )}
                  </Grid>
                  {/* Community  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title="Community" />
                    <CustomSelectBox
                      fullWidth
                      size="small"
                      displayEmpty
                      value={formik.values.community}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {Community.map((community, idx) => (
                        <MenuItem key={idx} value={community}>
                          {community}
                        </MenuItem>
                      ))}
                    </CustomSelectBox>
                  </Grid>
                  {/* Category  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title="Category" />
                    <CustomSelectBox
                      fullWidth
                      size="small"
                      displayEmpty
                      value={formik.values.category}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {Category.map((category, idx) => (
                        <MenuItem key={idx} value={category}>
                          {category}
                        </MenuItem>
                      ))}
                    </CustomSelectBox>
                  </Grid>
                </Grid>
              </Stack>
            </Box>
            {/* parent Info  */}
            <Box>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Parent’s Information
              </Typography>
              <Stack
                sx={{
                  border: "1px solid #3D398933",
                  backgroundColor: "##FBFBFF",
                  p: 1,
                  borderRadius: "10px",
                }}
              >
                {/* Mother Info  */}
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Mother's Info
                </Typography>
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* First Name *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"First Name"} required />
                    <CustomTextField
                      fullWidth
                      type="mother_firstName"
                      size="small"
                      placeholder="First Name"
                      name="mother_firstName"
                      value={formik.values.mother_firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.mother_firstName &&
                        Boolean(formik.errors.mother_firstName)
                      }
                      helperText={
                        formik.touched.mother_firstName &&
                        formik.errors.mother_firstName
                      }
                    />
                  </Grid>
                  {/* Middle Name *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Middle Name"} />
                    <CustomTextField
                      fullWidth
                      type="mother_middleName"
                      size="small"
                      placeholder="Middle Name"
                      name="mother_middleName"
                      value={formik.values.mother_middleName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.mother_middleName &&
                        Boolean(formik.errors.mother_middleName)
                      }
                      helperText={
                        formik.touched.mother_middleName &&
                        formik.errors.mother_middleName
                      }
                    />
                    {formik.touched.mother_middleName &&
                      formik.errors.mother_middleName && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.mother_middleName}
                        </Typography>
                      )}
                  </Grid>
                  {/* Last Name *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Last Name"} />
                    <CustomTextField
                      fullWidth
                      type="mother_lastName"
                      size="small"
                      placeholder="Last Name"
                      name="mother_lastName"
                      value={formik.values.mother_lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.mother_lastName &&
                        Boolean(formik.errors.mother_lastName)
                      }
                      helperText={
                        formik.touched.mother_lastName &&
                        formik.errors.mother_lastName
                      }
                    />
                    {formik.touched.mother_lastName &&
                      formik.errors.mother_lastName && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.mother_lastName}
                        </Typography>
                      )}
                  </Grid>
                  {/* mobileNumber  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Mobile Number"} />
                    <CustomTextField
                      fullWidth
                      type="mother_mobileNumber"
                      size="small"
                      placeholder="Enter Mobile Number"
                      name="mother_mobileNumber"
                      value={formik.values.mother_mobileNumber}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          formik.handleChange(e);
                        }
                      }}
                    />
                    {formik.touched.mother_mobileNumber &&
                      formik.errors.mother_mobileNumber && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.mother_mobileNumber}
                        </Typography>
                      )}
                  </Grid>
                  {/* occupation *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Occupation"} />
                    <CustomTextField
                      fullWidth
                      type="mother_occupation"
                      size="small"
                      placeholder="Occupation"
                      name="occupation"
                      value={formik.values.mother_occupation}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.mother_occupation &&
                        Boolean(formik.errors.mother_occupation)
                      }
                      helperText={
                        formik.touched.mother_occupation &&
                        formik.errors.mother_occupation
                      }
                    />
                    {formik.touched.mother_occupation &&
                      formik.errors.mother_occupation && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.mother_occupation}
                        </Typography>
                      )}
                  </Grid>
                  {/* Annual Income *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Annual Income"} />
                    <CustomTextField
                      fullWidth
                      type="number"
                      size="small"
                      placeholder="annualIncome"
                      name="mother_annualIncome"
                      value={formik.values.mother_annualIncome}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.mother_annualIncome &&
                        Boolean(formik.errors.mother_annualIncome)
                      }
                      helperText={
                        formik.touched.mother_annualIncome &&
                        formik.errors.mother_annualIncome
                      }
                    />
                    {formik.touched.mother_annualIncome &&
                      formik.errors.mother_annualIncome && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.mother_annualIncome}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
                {/* Father Info  */}
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Father's Info
                </Typography>
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* First Name *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"First Name"} required />
                    <CustomTextField
                      fullWidth
                      type="father_firstName"
                      size="small"
                      placeholder="First Name"
                      name="father_firstName"
                      value={formik.values.father_firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.father_firstName &&
                        Boolean(formik.errors.father_firstName)
                      }
                      helperText={
                        formik.touched.father_firstName &&
                        formik.errors.father_firstName
                      }
                    />
                  </Grid>
                  {/* Middle Name *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Middle Name"} />
                    <CustomTextField
                      fullWidth
                      type="father_middleName"
                      size="small"
                      placeholder="Middle Name"
                      name="father_middleName"
                      value={formik.values.father_middleName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.father_middleName &&
                        Boolean(formik.errors.father_middleName)
                      }
                      helperText={
                        formik.touched.father_middleName &&
                        formik.errors.father_middleName
                      }
                    />
                    {formik.touched.father_middleName &&
                      formik.errors.father_middleName && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.father_middleName}
                        </Typography>
                      )}
                  </Grid>
                  {/* Last Name *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Last Name"} />
                    <CustomTextField
                      fullWidth
                      type="father_lastName"
                      size="small"
                      placeholder="Last Name"
                      name="father_lastName"
                      value={formik.values.father_lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.father_lastName &&
                        Boolean(formik.errors.father_lastName)
                      }
                      helperText={
                        formik.touched.father_lastName &&
                        formik.errors.father_lastName
                      }
                    />
                    {formik.touched.father_lastName &&
                      formik.errors.father_lastName && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.father_lastName}
                        </Typography>
                      )}
                  </Grid>
                  {/* mobileNumber  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Mobile Number"} />
                    <CustomTextField
                      fullWidth
                      type="father_mobileNumber"
                      size="small"
                      placeholder="Enter Mobile Number"
                      name="father_mobileNumber"
                      value={formik.values.father_mobileNumber}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          formik.handleChange(e);
                        }
                      }}
                    />
                    {formik.touched.father_mobileNumber &&
                      formik.errors.father_mobileNumber && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.father_mobileNumber}
                        </Typography>
                      )}
                  </Grid>
                  {/* occupation *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Occupation"} />
                    <CustomTextField
                      fullWidth
                      type="father_occupation"
                      size="small"
                      placeholder="Occupation"
                      name="occupation"
                      value={formik.values.father_occupation}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.father_occupation &&
                        Boolean(formik.errors.father_occupation)
                      }
                      helperText={
                        formik.touched.father_occupation &&
                        formik.errors.father_occupation
                      }
                    />
                    {formik.touched.father_occupation &&
                      formik.errors.father_occupation && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.father_occupation}
                        </Typography>
                      )}
                  </Grid>
                  {/* Annual Income *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Annual Income"} />
                    <CustomTextField
                      fullWidth
                      type="number"
                      size="small"
                      placeholder="annualIncome"
                      name="father_annualIncome"
                      value={formik.values.father_annualIncome}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.father_annualIncome &&
                        Boolean(formik.errors.father_annualIncome)
                      }
                      helperText={
                        formik.touched.father_annualIncome &&
                        formik.errors.father_annualIncome
                      }
                    />
                    {formik.touched.father_annualIncome &&
                      formik.errors.father_annualIncome && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.father_annualIncome}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
                {/* Guardian Info  */}
                <Typography variant="h6" sx={{ mt: 2 }}>
                  Guardian's Info
                </Typography>
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* First Name *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"First Name"} />
                    <CustomTextField
                      fullWidth
                      type="guardianInfo_firstName"
                      size="small"
                      placeholder="First Name"
                      name="guardianInfo_firstName"
                      value={formik.values.guardianInfo_firstName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.guardianInfo_firstName &&
                        Boolean(formik.errors.guardianInfo_firstName)
                      }
                      helperText={
                        formik.touched.guardianInfo_firstName &&
                        formik.errors.guardianInfo_firstName
                      }
                    />
                    {formik.touched.guardianInfo_firstName &&
                      formik.errors.guardianInfo_firstName && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.guardianInfo_firstName}
                        </Typography>
                      )}
                  </Grid>
                  {/* Middle Name *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Middle Name"} />
                    <CustomTextField
                      fullWidth
                      type="guardianInfo_middleName"
                      size="small"
                      placeholder="Middle Name"
                      name="guardianInfo_middleName"
                      value={formik.values.guardianInfo_middleName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.guardianInfo_middleName &&
                        Boolean(formik.errors.guardianInfo_middleName)
                      }
                      helperText={
                        formik.touched.guardianInfo_middleName &&
                        formik.errors.guardianInfo_middleName
                      }
                    />
                    {formik.touched.guardianInfo_middleName &&
                      formik.errors.guardianInfo_middleName && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.guardianInfo_middleName}
                        </Typography>
                      )}
                  </Grid>
                  {/* Last Name *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Last Name"} />
                    <CustomTextField
                      fullWidth
                      type="guardianInfo_lastName"
                      size="small"
                      placeholder="Last Name"
                      name="guardianInfo_lastName"
                      value={formik.values.guardianInfo_lastName}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.guardianInfo_lastName &&
                        Boolean(formik.errors.guardianInfo_lastName)
                      }
                      helperText={
                        formik.touched.guardianInfo_lastName &&
                        formik.errors.guardianInfo_lastName
                      }
                    />
                    {formik.touched.guardianInfo_lastName &&
                      formik.errors.guardianInfo_lastName && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.guardianInfo_lastName}
                        </Typography>
                      )}
                  </Grid>
                  {/* mobileNumber  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Mobile Number"} />
                    <CustomTextField
                      fullWidth
                      type="guardianInfo_mobileNumber"
                      size="small"
                      placeholder="Enter Mobile Number"
                      name="guardianInfo_mobileNumber"
                      value={formik.values.guardianInfo_mobileNumber}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          formik.handleChange(e);
                        }
                      }}
                    />
                    {formik.touched.guardianInfo_mobileNumber &&
                      formik.errors.guardianInfo_mobileNumber && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.guardianInfo_mobileNumber}
                        </Typography>
                      )}
                  </Grid>
                  {/* occupation *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Occupation"} />
                    <CustomTextField
                      fullWidth
                      size="small"
                      placeholder="Occupation"
                      name="occupation"
                      value={formik.values.guardianInfo_occupation}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.guardianInfo_occupation &&
                        Boolean(formik.errors.guardianInfo_occupation)
                      }
                      helperText={
                        formik.touched.guardianInfo_occupation &&
                        formik.errors.guardianInfo_occupation
                      }
                    />
                    {formik.touched.guardianInfo_occupation &&
                      formik.errors.guardianInfo_occupation && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.guardianInfo_occupation}
                        </Typography>
                      )}
                  </Grid>
                  {/* Annual Income *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Annual Income"} />
                    <CustomTextField
                      fullWidth
                      type="number"
                      size="small"
                      placeholder="annualIncome"
                      name="guardianInfo_annualIncome"
                      value={formik.values.guardianInfo_annualIncome}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.guardianInfo_annualIncome &&
                        Boolean(formik.errors.guardianInfo_annualIncome)
                      }
                      helperText={
                        formik.touched.guardianInfo_annualIncome &&
                        formik.errors.guardianInfo_annualIncome
                      }
                    />
                    {formik.touched.guardianInfo_annualIncome &&
                      formik.errors.guardianInfo_annualIncome && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.guardianInfo_annualIncome}
                        </Typography>
                      )}
                  </Grid>
                  {/* Relation *  */}
                  <Grid item xs={3}>
                    <TextFieldLabel title={"Relation"} />
                    <CustomTextField
                      fullWidth
                      size="small"
                      placeholder="Relation"
                      name="guardianInfo_relation"
                      value={formik.values.guardianInfo_relation}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.guardianInfo_relation &&
                        Boolean(formik.errors.guardianInfo_relation)
                      }
                      helperText={
                        formik.touched.guardianInfo_relation &&
                        formik.errors.guardianInfo_relation
                      }
                    />
                    {formik.touched.guardianInfo_relation &&
                      formik.errors.guardianInfo_relation && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.guardianInfo_relation}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              </Stack>
            </Box>
            {/* Address Info  */}
            <Box>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Address Information
              </Typography>
              <Stack
                sx={{
                  border: "1px solid #3D398933",
                  backgroundColor: "##FBFBFF",
                  p: 1,
                  borderRadius: "10px",
                }}
              >
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* adress  */}
                  <Grid item xs={6}>
                    <TextFieldLabel title={"Current Address"} />
                    <CustomTextField
                      fullWidth
                      size="small"
                      placeholder="Enter Adress"
                      name="current_address"
                      value={formik.values.current_address}
                      onChange={(e) => {
                        if (e.target.value.length <= 10) {
                          formik.handleChange(e);
                        }
                      }}
                    />
                    {formik.touched.current_address &&
                      formik.errors.current_address && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.current_address}
                        </Typography>
                      )}
                  </Grid>
                  {/* State  */}
                  <Grid item xs={6}>
                    <TextFieldLabel title={"State"} />
                    <CustomSelectBox
                      native
                      displayEmpty
                      MenuProps={MenuProps}
                      sx={{ width: "100%" }}
                      name="State"
                      value={formik.values.current_state}
                      onChange={(e) => {
                        formik.setValues((prev) => ({
                          ...prev,
                          current_state: e.target.value,
                          current_dist: "",
                        }));
                      }}
                    >
                      <MenuItem value="" disabled component="option">
                        Select
                      </MenuItem>
                      {States.map((state) => (
                        <MenuItem value={state} component="option">
                          {state}
                        </MenuItem>
                      ))}
                    </CustomSelectBox>
                    {formik.errors.current_state &&
                      formik.touched.current_state && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.current_state}
                        </Typography>
                      )}
                  </Grid>
                  {/* Distrivt  */}
                  <Grid item xs={6}>
                    <TextFieldLabel title={"District"} />
                    <CustomSelectBox
                      native
                      displayEmpty
                      MenuProps={MenuProps}
                      sx={{ width: "100%" }}
                      name="current_dist"
                      value={formik.values.current_dist}
                      disabled={!formik.values.current_state}
                      onChange={formik.handleChange}
                    >
                      <MenuItem value="" disabled component="option">
                        Select
                      </MenuItem>
                      {districts.length !== 1 &&
                        districts?.map((dist) => (
                          <MenuItem value={dist} component="option">
                            {dist}
                          </MenuItem>
                        ))}
                    </CustomSelectBox>

                    {formik.errors.current_dist &&
                      formik.touched.current_dist && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.current_dist}
                        </Typography>
                      )}
                  </Grid>
                  {/* PINCODE  */}
                  <Grid item xs={6}>
                    <TextFieldLabel title={"PIN Code"} />
                    <CustomTextField
                      placeholder="Enter Pincode"
                      name="pinCode"
                      fullWidth
                      value={formik.values.current_pinCode}
                      type="number"
                      onChange={(e) => {
                        if (e.target.value.length < 7) {
                          formik.setValues((prev) => ({
                            ...prev,
                            current_pinCode: e.target.value,
                          }));
                        }
                      }}
                    />
                    {formik.errors.current_pinCode &&
                      formik.touched.current_pinCode && (
                        <Typography sx={{ color: "red" }}>
                          {formik.errors.current_pinCode}
                        </Typography>
                      )}
                  </Grid>
                </Grid>
              </Stack>
            </Box>
            {/* Payment Detail  */}
            <Box>
              <Typography variant="h6" sx={{ mt: 2 }}>
                Payment Detail
              </Typography>
              <Stack
                sx={{
                  border: "1px solid #3D398933",
                  backgroundColor: "##FBFBFF",
                  p: 1,
                  borderRadius: "10px",
                }}
              >
                <Grid
                  container
                  rowSpacing={0.8}
                  columnSpacing={2}
                  sx={{ pt: 2 }}
                >
                  {/* Amount  */}
                  <Grid item xs={4}>
                    <TextFieldLabel title={"Form Amount"} />
                    <CustomTextField
                      fullWidth
                      type="number"
                      size="small"
                      placeholder="Enter Amount"
                      name="formAmount"
                      value={formik.values.formAmount}
                      onChange={formik.handleChange}
                    />
                    {formik.touched.formAmount && formik.errors.formAmount && (
                      <Typography sx={{ color: "red" }}>
                        {formik.errors.formAmount}
                      </Typography>
                    )}
                  </Grid>
                </Grid>
              </Stack>
            </Box>
          </form>
          <Stack mt={2}>
            <PaymentModeComponent
              formId="admission-enquiry-form"
              amount={String(formik.values.formAmount)}
              onClickSubmit={(data) => {
                formik.setValues((prev) => ({
                  ...prev,
                  paymentDetails: data,
                }));
                formik.handleSubmit();
              }}
            />
          </Stack>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            color="secondary"
            form="admission-enquiry-form"
            loading={addAdmissionEnquiryLoading}
            variant="contained"
            size="large"
            type="submit"
            sx={{
              width: "10rem",
            }}
          >
            {formik.values.edit ? "Update" : "Submit"}
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddEnquiryForm;
