import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "react-bootstrap";
import { Bell, Person, List } from "react-bootstrap-icons";
import { Box, Stack } from "@mui/material";
import { useLocation } from "react-router-dom";
import appRoutes from "app/config/routes";
import MobileInstituteBrand from "../Header/components/MobileInstituteBrand";
import UserProfileDrawer from "../Header/components/UserProfileDrawer";

const MobileHeader = ({ setShow }) => {
  const { pathname } = useLocation();
  const [state, setState] = useState({
    showSidebar: false,
  });

  return (
    <>
      <UserProfileDrawer state={state} setState={setState} />
      <StyledContainer>
        <Box>
          <div className="inner-nav">
            <Stack direction="row" px={1} alignItems="center">
              <List
                size={30}
                onClick={() => setShow(true)}
                style={{ color: "#fff" }}
              />

              {pathname === appRoutes.schools ? (
                {
                  /* <AppLogo /> */
                }
              ) : (
                <Box flexGrow={1} ml={1.5}>
                  <MobileInstituteBrand />
                </Box>
              )}

              <div className="float-end">
                <Button
                  type="button"
                  variant="outline-link "
                  className="p-2 text-color mb-0"
                >
                  <Bell size={20} />
                </Button>

                <Button
                  type="button"
                  variant="outline-link "
                  className="p-2 text-color mb-0"
                  onClick={() =>
                    setState((prev) => ({ ...prev, showSidebar: true }))
                  }
                >
                  <Person size={20} />
                </Button>
              </div>
            </Stack>
          </div>
        </Box>
      </StyledContainer>
    </>
  );
};

export default MobileHeader;

const StyledContainer = styled.div`
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100%;
  .navbar-bg {
    background: #084c84;
    transition: 0.4s;
    padding-bottom: 0px;
  }
  .inner-nav {
    padding: 15px 0px 7px 0px;
    background: #084c84;
    width: 100%;
    border-bottom: 2px solid grey;
  }
  .text-color {
    color: white;
  }
  ${
    "" /* ${screen.medium.standard`
      width: 100px
    `}; */
  }
`;
const FlexBoxCol = styled.div`
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  p {
    color: black;
    font-weight: 700;
    display: flex;
    font-size: 18px;
    margin: -10px 0px 30px 0px;
    justify-content: center;
    align-items: center;
  }
`;

const Button_styled = styled.button`
  text-align: left;
  font-weight: 500;
  margin: 0px 20px;
  font-size: 14px;
  color: #084c84;
  height: 6%;
  width: 200px;
  border-radius: 10px;
  background: white;
  border: 0;
  &:hover {
    background: #084c84;
    color: white;
    transition: 0.2s;
  }
  span {
    margin-left: 10px;
  }
`;
const FlexBoxRow = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
  p {
    display: flex;
    font-size: 15px;
    margin: 20px;
    margin-right: 120px;
    align-items: center;
  }
`;

const ProfileImg = styled.img`
  height: 70px;
  width: 70px;
  margin: 20px;
  border-radius: 40px;
`;
