import Fallback from "app/components/Fallback";
import React from "react";

const Auth = React.lazy(() => import("app/modules/auth/pages/Auth"));

export const AuthPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Auth {...props} />
  </React.Suspense>
);
