// Component for the header
const reportHeader = ({ doc, selectedSchool }) => {
  // Define the coordinates and dimensions of the box
  let textheight = 32;
  let x = 10;
  let y = 5; // Adjust as needed
  let width = 280; // Adjust as needed
  let height = textheight - 8; // Adjust as needed

  // Set the color for the rectangle's border
  doc.setDrawColor(39, 137, 253, 0.2); // RGB values for black

  // Set the color for the rectangle's fill
  doc.setFillColor(255, 255, 255); // RGB values for white
  // Draw the box
  doc.setLineWidth(0.5);
  doc.rect(x, y, width, height); // 'F' means fill the rectangle
  // Set the color for the text
  doc.setTextColor(255, 0, 0);
  doc.setFontSize(12);
  doc.getLineWidth(1);
  doc.setTextColor("#000000");
  doc.text(selectedSchool?.schoolName, 15, 12);
  doc.setFontSize(10);
  doc.text(
    `${selectedSchool?.schoolAddress?.address}, ${selectedSchool?.schoolAddress?.dist}, ${selectedSchool?.schoolAddress?.state}, ${selectedSchool?.schoolAddress?.pinCode}`,
    15,
    18
  );
  doc.text(`Affiliated to: ${selectedSchool?.affiliatedTo}`, 15, 24);
  // dimensions fpr the applied filter box
};

export default reportHeader;
