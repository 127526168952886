import { Box, Grid, styled, Typography } from "@mui/material";
import React from "react";

const SummaryCommonComponents = ({ summaryArray }) => {
  const width = `${Math.min(summaryArray.length * 15, 100)}%`;
  const colors = ["#29DB5B", "red", "blue"];

  return (
    <>
      <SummaryBox width={width}>
        <Grid container spacing={1} columns={15}>
          {summaryArray.map((item, index) => (
            <Grid item xs={3} key={index}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                {item.key}
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                  color: colors[index % colors.length],
                }}
              >
                {item.value}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </SummaryBox>
    </>
  );
};

export default SummaryCommonComponents;

const SummaryBox = styled(Box)(({ width }) => ({
  borderRadius: "10px",
  border: "1px solid rgba(19, 115, 229, 0.2)",
  background: "rgba(39, 137, 253, 0.02)",
  padding: "5px",
  display: "inline-block",
  marginTop: "10px",
  width: width,
}));
