import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import TextFieldLabel from "../common/TextFieldLabel";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { useQuery } from "react-query";
import { getFeeType } from "app/services/schoolService/newFee.service";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";
import { toast } from "react-toastify";

const ByFeeFilter = ({
  showTransportFee = false,
  prevFeeList,
  setSelectedFees,
}) => {
  const [state, setState] = useState({
    feeType: [],
    feeTypeList: prevFeeList,
  });
  const selectedSession = useSelector(selectActiveSession);
  const {
    isLoading: getFeeTypeLoading,
    isFetching: getFeeTypeFetching,
    data: getFeeTypeData,
  } = useQuery({
    queryKey: ["getFeeType"],
    queryFn: () =>
      getFeeType({ isGroupedByClass: "Yes", session: selectedSession }),
    onSuccess: (success) => {
      console.log("prevFeeList", prevFeeList);
      let updatedFeeTypeList = [];

      if (showTransportFee) {
        updatedFeeTypeList.push({
          feeType: "Transportation Fee",
        });
      }
      updatedFeeTypeList.push(...success?.data?.list);

      if (prevFeeList.length > 0) {
        let checkedUpdatedList = updatedFeeTypeList.map((item) => {
          return {
            ...item,
            checked: prevFeeList.some(
              (prevItem) =>
                prevItem.feeType === item.feeType && prevItem?.checked === true
            ),
          };
        });
        setState((prev) => ({
          ...prev,
          feeTypeList: checkedUpdatedList,
        }));
        console.log("checkedUpdatedList", checkedUpdatedList);
      } else {
        if (showTransportFee) {
          setState((prev) => ({
            ...prev,
            feeTypeList: [
              { feeType: "Transportation Fee" },
              ...success?.data?.list,
            ],
          }));
        } else {
          setState((prev) => ({
            ...prev,
            feeTypeList: [...success?.data?.list],
          }));
        }
      }
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  useEffect(() => {
    setSelectedFees(state.feeTypeList);
  }, [state.feeTypeList]);

  return (
    <>
      <Grid container spacing={1.5}>
        <Grid item xs={12}>
          <TextFieldLabel title={"Select Fee Type"} />
          <CustomSelectBox
            MenuProps={MenuProps}
            multiple
            displayEmpty
            size="small"
            fullWidth
            value={state.feeType}
            renderValue={() => {
              const d = state.feeTypeList?.filter((item) => item?.checked);
              if (d.length > 0) {
                return (
                  <Typography sx={{ fontWeight: 500 }}>
                    {d?.map((item) => item?.feeType)?.join(", ")}
                  </Typography>
                );
              } else {
                return <Typography>Select</Typography>;
              }
            }}
          >
            {state.feeTypeList?.map((item, i) => (
              <Box key={i} sx={{ px: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="secondary"
                      checked={item?.checked}
                      onChange={(e) => {
                        item.checked = !item.checked;
                        setState((prev) => ({ ...prev }));
                      }}
                    />
                  }
                  label={item?.feeType}
                  componentsProps={{
                    typography: {
                      fontSize: "14px",
                      fontWeight: 600,
                    },
                  }}
                />
              </Box>
            ))}
          </CustomSelectBox>
        </Grid>
      </Grid>
    </>
  );
};

export default ByFeeFilter;
