import { Box, Grid, Typography } from "@mui/material";

const NameComponent = ({ label, text }) => {
  return (
    <Box>
      <Typography fontSize={14} color="primary" sx={{ opacity: 0.75 }}>
        {label}
      </Typography>
      <Typography fontSize={16} fontWeight={600} color="primary">
        {text && text !== "undefined" && text !== undefined
          ? String(text).replace(/undefined/g, "")
          : "--"}
      </Typography>
    </Box>
  );
};
export default function AddressInfo({ data }) {
  return (
    <Grid container rowSpacing={1} columnSpacing={2}>
      <Grid item xs={12}>
        <NameComponent label="Address" text={data?.address} />
      </Grid>
      <Grid item xs={4}>
        <NameComponent label="District" text={data?.dist} />
      </Grid>
      <Grid item xs={4}>
        <NameComponent label="Pin Code" text={data?.state} />
      </Grid>
      <Grid item xs={4}>
        <NameComponent label="State" text={data?.pinCode} />
      </Grid>
    </Grid>
  );
}
