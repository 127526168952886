import { Divider, Typography } from "@mui/material";
import React from "react";

const FormSectionTitle = ({ title }) => {
  return (
    <>
      <Typography
        sx={{
          fontSize: "20px",
          fontWeight: 600,
          mb: 0.3,
          letterSpacing: 1,
          opacity: 0.75,
          color: (theme) => theme.palette.primary.main,
        }}
      >
        {title}
      </Typography>
      <Divider
        sx={{ background: (theme) => theme.palette.primary.main, mb: 1 }}
      />
    </>
  );
};

export default FormSectionTitle;
