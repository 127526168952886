import { Grid, MenuItem, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import TextFieldLabel from "../common/TextFieldLabel";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import ClassDropDown from "app/modules/fee-management/fee-structure/ClassDropdown";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import {
  selectedMediumSelector,
  selectSelectedSchoolId,
} from "app/modules/schools/selectors";
import { getClassDropdown } from "app/services/student-management";
import { useFormik } from "formik";

const ByClassFilter = ({ prevClassList, setSelectedClasses }) => {
  const [classList, setClassList] = useState([]);

  const formik = useFormik({
    initialValues: {
      postClassList: prevClassList,
    },
    onSubmit: (values) => {},
  });

  const selectedMedium = useSelector(selectedMediumSelector);
  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  const {
    isLoading: getClassDropdownLoading,
    isFetching: getClassDropdownFetching,
  } = useQuery({
    queryKey: ["getClassDropdown", selectedSchoolId, selectedMedium],
    queryFn: () => getClassDropdown(selectedSchoolId, selectedMedium),
    onSuccess: (res) => {
      if (formik.values.postClassList.length > 0) {
        const transformedList = res?.data?.list.map((classItem) => {
          const body = {
            classDocId: classItem?.classDocId,
            className: classItem?.className_stream,
            queryClassName: classItem?.className,
            sections: [],
            checked: false,
          };
          const updatedSections = classItem?.sections?.map((section) => {
            const isChecked = formik?.values?.postClassList.some(
              (postClass) =>
                postClass?.classDocId === classItem?.classDocId &&
                postClass?.section === section
            );
            return {
              section,
              checked: isChecked,
            };
          });
          body.sections = updatedSections;
          body.checked = updatedSections.every((section) => section.checked);
          return body;
        });

        setClassList(transformedList);
      } else {
        const arr = [];
        res?.data?.list?.forEach((item) => {
          const body = {
            classDocId: item?.classDocId,
            className: item?.className_stream,
            queryClassName: item?.className,
            sections: [],
            checked: false,
          };
          item?.sections?.forEach((res) => {
            body?.sections?.push({
              section: res,
              checked: false,
            });
          });
          arr.push(body);
        });

        setClassList(arr);
      }
    },
  });

  useEffect(() => {
    setSelectedClasses(formik.values.postClassList);
  }, [formik.values.postClassList]);

  return (
    <>
      <Grid container>
        <Grid item xs={12}>
          <TextFieldLabel title={"Class-Stream-Sec"} />
          <CustomSelectBox
            size="small"
            fullWidth
            multiple
            MenuProps={MenuProps}
            value={formik.values.postClassList}
            error={
              formik.touched.postClassList &&
              Boolean(formik.errors.postClassList)
            }
            renderValue={() => {
              if (formik.values?.postClassList?.length !== 0) {
                return (
                  <Stack direction="row" gap={0.5}>
                    <Typography
                      sx={{
                        width: "90%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                    >
                      {formik.values.postClassList
                        .map((cls) => cls?.display)
                        .join(", ")}
                    </Typography>
                  </Stack>
                );
              }
              return <Typography>Select</Typography>;
            }}
          >
            {classList?.map((item, ind) => (
              <ClassDropDown
                item={item}
                index={ind}
                formikSetState={formik.setValues}
                formikState={formik.values}
                key={ind}
              />
            ))}
          </CustomSelectBox>
          {formik.touched.postClassList &&
            Boolean(formik.errors.postClassList) && (
              <Typography color="error">
                {formik.errors.postClassList}
              </Typography>
            )}
        </Grid>
      </Grid>
    </>
  );
};

export default ByClassFilter;
