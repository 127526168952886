import { Box, Grid, Stack, Typography } from "@mui/material";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import dayjs from "dayjs";
import React from "react";
import { useSelector } from "react-redux";

const SchoolPrintHeader = () => {
  const selectedSchool = useSelector(selectSelectedSchool);

  return (
    <Box>
      <Stack direction="row" alignItems="center">
        <Box
          sx={{
            ml: 1,
            height: "70px",
            width: "70px",
          }}
        >
          <img
            src={selectedSchool?.schoolLogo}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            alt="school-logo"
          />
        </Box>
        <Stack
          // direction="row"
          sx={{ width: "calc(100% - 96px)" }}
          justifyContent="center"
        >
          <Box>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "17px",
                textAlign: "center",
              }}
            >
              {selectedSchool?.schoolName}
            </Typography>
            <Typography
              sx={{
                fontWeight: "600",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              {selectedSchool?.schoolAddress?.address}
              <br />
              {selectedSchool?.schoolAddress?.dist},{" "}
              {selectedSchool?.schoolAddress?.state},{" "}
              {selectedSchool?.schoolAddress?.pinCode}
              <br />
            </Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={9}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "12px",
                  textAlign: "end",
                }}
              >
                Affiliated With : {selectedSchool?.affiliatedTo}
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "12px",
                  textAlign: "end",
                }}
              >
                UDISE Code: {selectedSchool?.udiseCode ?? "--"}
              </Typography>
            </Grid>
          </Grid>
        </Stack>
      </Stack>

      <Stack direction="row" alignItems="center" sx={{ pt: 0.7, px: 2 }}>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "12px",
            flex: 1,
          }}
        >
          School No :{" "}
          <span style={{ fontWeight: 600 }}>
            {selectedSchool?.schoolRegisterNumber}
          </span>
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "12px",
            flex: 1,
            textAlign: "center",
          }}
        >
          Affiliation Number :{" "}
          <span style={{ fontWeight: 600 }}>
            {selectedSchool?.affiliationNumber}
          </span>
        </Typography>
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "12px",
            flex: 1,
            textAlign: "end",
          }}
        >
          Affiliation Up To :{" "}
          <span style={{ fontWeight: 600 }}>
            {selectedSchool?.affiliationUpTo
              ? dayjs(selectedSchool?.affiliationUpTo).format("YYYY")
              : "...................."}
          </span>
        </Typography>
      </Stack>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        sx={{ px: 2 }}
      >
        <Typography
          sx={{
            fontWeight: "500",
            fontSize: "12px",
            flex: 1,
          }}
        >
          {/* Website : {selectedSchool?.affiliatedTo} */}
          Website :{" "}
          <span style={{ fontWeight: 600 }}>{selectedSchool?.website}</span>
        </Typography>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "12px",
            flex: 1,
            textAlign: "center",
          }}
        >
          Contact No :{" "}
          <span style={{ fontWeight: 600 }}>
            {selectedSchool?.schoolContactNumber}
          </span>
        </Typography>
        <Typography
          sx={{
            fontWeight: "600",
            fontSize: "12px",
            flex: 1,
            textAlign: "end",
          }}
        >
          Email :{" "}
          <span style={{ fontWeight: 600 }}>{selectedSchool?.email}</span>
        </Typography>
      </Stack>
    </Box>
  );
};

export default SchoolPrintHeader;
