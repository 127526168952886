import React from "react";
import styled from "styled-components";
import SidebarItem from "../Sidebar/components/SidebarItem";
import { Link } from "react-router-dom";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useSidebarMenuItems } from "../Sidebar/hooks";
import Session from "../Header/components/Session";
import { Medium } from "../Header/components/Session";
import {
  setSelectedSession,
  setSelectedMedium,
} from "app/modules/schools/operations";
import {
  selectMedium,
  selectSession,
  selectedMediumSelector,
  selectActiveSession,
} from "app/modules/schools/selectors";
import { useDispatch } from "react-redux";
import { Stack } from "@mui/material";
import SearcMenuInput from "../Sidebar/components/SearcMenuInput";

const MobileSidebar = ({ setShow }) => {
  const menuItems = useSidebarMenuItems();
  const selectedSchool = useSelector(selectSelectedSchool);
  const location = useLocation();
  const pathname = location.pathname;
  const dispatch = useDispatch();
  const selectedSession = useSelector(selectActiveSession);
  const mediumList = useSelector(selectMedium);
  const selectedMedium = useSelector(selectedMediumSelector);

  const sessionlist = useSelector(selectSession);

  const handleSessionSelection = (session) => {
    dispatch(setSelectedSession({ sessionName: session }));
  };

  const handleMediumSelection = (medium) => {
    dispatch(setSelectedMedium({ medium: medium }));
  };

  return (
    <StyledWrapper>
      <div className="side-nav-container">
        <div className="nav-heading">
          <div className="nav-brand">
            <img
              src={selectedSchool.schoolLogo}
              className="nav-brand-img"
              alt="ekalsutra"
            />
          </div>
          <div className="mt-4">
            <div className="ps-1 d-flex flex-column justify-content-center ">
              <p className="mb-3 school-info">{selectedSchool.schoolName}</p>
            </div>
          </div>
        </div>
        <Stack
          direction="row"
          justifyContent="center"
          gap={1}
          sx={{ width: "100%", px: 1 }}
        >
          <Session
            handleSessionSelection={handleSessionSelection}
            selectedSession={selectedSession}
            sessions={sessionlist}
          />

          <Medium
            handleMediumSelection={handleMediumSelection}
            mediumList={mediumList}
            selectedMedium={selectedMedium}
          />
        </Stack>

        <div className="nav-menu">
          <SearcMenuInput setShowMobile={setShow} />
          <div className="ul">
            {menuItems.map((item, index) => (
              <>
                {item.subMenu && item.subMenu.length > 0 ? (
                  <>
                    <div className="ul-li" role="button" key={index}>
                      <SidebarItem
                        key={item.key}
                        item={item}
                        isExpanded={true}
                        setShow={setShow}
                      />
                    </div>
                  </>
                ) : (
                  <div
                    className={
                      pathname.includes(item.link)
                        ? "ul-li active-link"
                        : "ul-li"
                    }
                    key={item.key}
                    role="button"
                  >
                    <Link
                      to={item.link}
                      className="link-hover"
                      onClick={() => setShow(false)}
                    >
                      <div className="align-items-left d-flex margin-4 flex-row flex-lg-row py-3 px-2">
                        <span className="mb-2 mb-lg-0 icon-wrapper">
                          {item.icon}
                        </span>
                        <span className="ms-lg-3 body-15 margin-3 name-wrapper text-wrap text-left text-lg-start text-truncate">
                          {item.name}
                        </span>
                      </div>
                    </Link>
                  </div>
                )}
              </>
            ))}
          </div>
        </div>
      </div>
    </StyledWrapper>
  );
};

export default MobileSidebar;

const StyledWrapper = styled.div`
  background: #084c84;
  width: 250;
  height: 100vh;
  .dropdowns {
    font-size: 9px;
  }

  .school-info {
    padding-right: 10px;
    font-size: 13px;
    word-wrap: break-word;
    text-align: center;
  }
  li:not(.active-link) {
    .sub-menu {
      display: none !important;
    }
  }
  .side-nav-container {
    background: #084c84;
    height: 100%;
    position: relative;
    color: white;
    transition: 0.4s;
    border-radius: 0 0 20px 0;
  }
  .side-nav-container-NX {
    width: 56px;
  }

  .nav-brand-img {
    margin: 30px 10% 0 37%;
  }
  .nav-brand-img-EX {
    width: 100%;
    height: 100%;
    transition: 0.4s;
    margin: 10px;
  }

  .hamburger {
    position: absolute;
    right: 0;
    background: none;
    border: none;
    cursor: pointer;
    margin: -25px -35px 10px -35px;
    background-color: #2789fd;
    border: 1px solid grey;
    padding: 20px;
    border-radius: 90px;
    height: 71px;
    width: 71px;
  }

  .margin-3 {
    margin-left: 10px;
    margin-top: 5px;
  }

  .margin-4 {
    margin-left: 25px;
    margin-top: 5px;
  }

  .hamburger span {
    display: block;
    margin-top: 5px;
    background-color: white;
    border-radius: 15px;
    height: 4px;
    width: 30px;
  }
  .hamburger-in:hover span:nth-child(1) {
    width: 25px;
    transition: 0.4s;
    transform: translateY(4px) rotate(-25deg);
  }
  .hamburger-in:hover span:nth-child(2) {
    width: 40px;
    transition: 0.4s;
  }
  .hamburger-in:hover span:nth-child(3) {
    width: 25px;
    transition: 0.4s;
    transform: translateY(-4px) rotate(25deg);
  }
  .hamburger-out {
    transition: 0.4s;
    margin-left: 24px;
  }
  .hamburger-out:hover span:nth-child(1) {
    width: 25px;
    transition: 0.4s;
    transform: translate(14px, 4px) rotate(-155deg);
  }
  .hamburger-out:hover span:nth-child(2) {
    width: 40px;
    transition: 0.4s;
  }
  .hamburger-out:hover span:nth-child(3) {
    width: 25px;
    transition: 0.4s;
    transform: translate(14px, -4px) rotate(155deg);
  }

  .nav-menu {
    grid-template-rows: repeat(7, 1fr);
    margin-top: 25px;
  }
  .menu-item {
    height: 57px;
    display: flex;
    color: var(--light);
    text-decoration: none;
    text-transform: uppercase;
    margin: auto 20px;
    border-radius: 10px;
  }

  .menu-item-NX {
    margin: auto;
  }
  .menu-item:hover {
    background-color: var(--primary);
  }
  .menu-item img {
    width: 30px;
    padding: 0 20px;
  }

  .nav-footer {
    width: 100%;
    height: 87px;
    position: absolute;
    bottom: 0;
    grid-template-rows: 1fr;
    grid-template-columns: 2fr 1fr;
  }

  .nav-details {
    display: flex;
  }
  .nav-details img {
    width: 50px;
    padding: 0 20px;
  }
  .icon-wrapper {
    svg {
      height: 18px;
      width: 18px;
    }
  }

  .ul {
    background: #084c84;
    padding: 0px 0 0 0px;
    width: 100%;
    transition: 0.5s;
    overflow: hidden;
  }

  .ul-li {
    list-style: none;
    color: white;
    font-size: 15px;
    margin-bottom: 5px;
    cursor: pointer;
    position: relative;
    transition: 0s;
  }
  .ul-li a:hover {
    color: #2789fd;
  }
  .ul-li a {
    text-decoration: none;
    color: white;
  }
  .ul-li ul li :hover {
    color: black;
  }
  img {
    width: 7rem;
    height: 7rem;
    margin: 0 4rem 3rem 4rem;
  }
  .ul:hover {
    width: 100%;
  }

  .sidebar-content {
    padding-top: 0.25rem;
    height: 0;
    overflow: hidden;
  }

  .ul-li.open .sidebar-content {
    padding-top: 0.25rem;
    height: 50px;
  }

  .active-link:before {
    content: "";
    position: absolute;
    top: -20px;
    right: 0px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 10px 10px 0 white;
    transition: 0.5s;
  }

  .active-link:after {
    content: "";
    position: absolute;
    bottom: -20px;
    right: 0px;
    width: 20px;
    height: 20px;
    background: transparent;
    border-radius: 50%;
    box-shadow: 10px -10px 0 white;
    transition: 0.5s;
  }
  .active-link {
    background: white;
    transition: 0.5s;
  }

  .active-link a {
    color: black;
  }

  @include media-breakpoint-up(sm) {
    max-width: 100px;
  }
`;

const StyledContainer = styled.div`
  .navbar-brand {
    svg {
      width: 200px;
    }
  }
  .navbar-bg {
    background: #084c84;
    transition: 0.4s;
    padding-bottom: 0px;
  }
  .inner-nav {
    padding: 15px 0px 7px 0px;
    background: #084c84;
    width: 100%;
    border-bottom: 2px solid grey;
  }
  .text-color {
    color: white;
  }
  ${
    "" /* ${screen.medium.standard`
      width: 100px
    `}; */
  }
`;
const FlexBoxCol = styled.div`
  display: flex;
  margin-left: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 200px;
  p {
    color: black;
    font-weight: 700;
    display: flex;
    font-size: 18px;
    margin: -10px 0px 30px 0px;
    justify-content: center;
    align-items: center;
  }
`;

const Button_styled = styled.button`
  text-align: left;
  font-weight: 500;
  margin: 0px 20px;
  font-size: 14px;
  color: #084c84;
  height: 4%;
  width: 200px;
  border-radius: 10px;
  background: white;
  border: 0;
  &:hover {
    background: #084c84;
    color: white;
    transition: 0.2s;
  }
  span {
    margin-left: 10px;
  }
`;
const FlexBoxRow = styled.div`
  margin: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;
  p {
    display: flex;
    font-size: 15px;
    margin: 20px;
    margin-right: 120px;
    align-items: center;
  }
`;

const ProfileImg = styled.img`
  height: 70px;
  width: 70px;
  margin: 20px;
  border-radius: 40px;
`;
