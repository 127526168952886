import React from "react";
import styled from "styled-components";
import { XLg } from "react-bootstrap-icons";
import moment from "moment";
import { useMutation } from "react-query";
import { putPromoteStudent } from "app/services/student-management";

import "react-notifications/lib/notifications.css";
import { Button, Grid } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import appRoutes from "app/config/routes";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import ShowFeeStructure from "../../pages/CreateStudent/components/ShowFeeStructure";
import dayjs from "dayjs";

export const getNewSessionValues = (session) => {
  const temp = session.split("-");
  const ans = temp
    .map((val) => {
      return parseInt(val) + 1;
    })
    .join("-");
  return ans;
};
const NameComponent = ({ head, data }) => {
  return (
    <div className="container-element">
      <h5>{head}</h5>
      <p>{data ? String(data).replace(/undefined/g, "") : "--"}</p>
    </div>
  );
};
const PreviewForPromotion = ({
  allValues,
  setShowPreview,
  newSession,
  studentData,
  state,
  feeData,
  classList = [],
}) => {
  const selectedSchool = useSelector(selectSelectedSchool);
  const { studentDocId } = useParams();
  const navigate = useNavigate();
  const { mutate, isLoading: promoting } = useMutation(putPromoteStudent, {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      setShowPreview(false);
      navigate(`/${appRoutes.promotionAndMigration}`);
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const submitAdmission = (allValues) => {
    let category = {};
    category.feeRelaxationCategory = state.feeRelaxationCategory;

    if (state.feeRelaxationCategory === "Siblings") {
      const data = [];
      state.siblingDetails?.forEach((item) => {
        if (item?.studentDocId) {
          data.push({
            schoolDocId: selectedSchool?._id,
            studentDocId: item?.studentDocId,
          });
        }
      });
      category.siblingDetails = data;
    }
    if (state.feeRelaxationCategory === "Staff_Ward") {
      category.staffWardDetail = {
        schoolDocId: selectedSchool?._id,
        staffDocId: state.employeeId,
      };
    }
    if (
      !["General", "Siblings", "Staff_Ward"].includes(
        state.feeRelaxationCategory
      )
    ) {
      category.otherFeeRelaxationCategoryDetail = {
        certificateName: state.certificateName,
        certificateLink: state.certificateUrl,
      };
    }
    const finalObject = {
      studentDocId,
      promotionDetail: {
        session: newSession,
        admissionType: allValues?.admissionType,
        admissionDate: allValues?.admissionDate,
        ...category,
        rollNumber: allValues?.rollNumber,
        classDocId: allValues?.classDocId,
        section: allValues?.section,
        transportInfo: {
          using: allValues?.transportInfo?.using,
          routeNumber: allValues.transportInfo?.routeNumber,
          feeAmount:
            allValues?.transportInfo?.using === "Yes"
              ? allValues?.transportInfo.feeAmount
              : null,
          to: allValues?.transportInfo?.to,
          vehicleNumber: allValues?.transportInfo?.vehicleNumber,
          remarks: allValues?.transportInfo?.remarks,
          transportFeeDocId: allValues?.transportInfo?.transportFeeDocId,
        },
        house: {
          ...allValues?.house,
        },
        feeStructureList: feeData?.list?.map((item) => item?._id),
      },
    };
    mutate(finalObject);
  };
  const getClassStream = () => {
    return classList?.find((item) => item?.classDocId === allValues?.classDocId)
      ?.className_stream;
  };
  return (
    <Wrapper>
      <div className="header">
        <h4>Student Information</h4>
        <button className="header-button" onClick={() => setShowPreview(false)}>
          <XLg />
        </button>
      </div>
      <hr></hr>
      <div className="preview">
        {/* Preview Card */}

        {/* Personal Info */}
        <div>
          <h4>Personal Information</h4>
          <div className="element-wrapper">
            <div className="container">
              <NameComponent head="First Name" data={studentData?.firstName} />
              <NameComponent
                head="Middle Name"
                data={studentData?.middleName}
              />
              <NameComponent head="Last Name" data={studentData?.lastName} />
              <NameComponent head="Aadhar Number" data={studentData?.AADHAR} />
              <NameComponent
                head="Date of Birth"
                data={
                  studentData?.DOB
                    ? moment(new Date(studentData?.DOB)).format("DD-MMM-yyyy")
                    : "--"
                }
              />
            </div>
            <div className="container">
              <NameComponent head="Email ID" data={studentData?.email} />
              <NameComponent
                head="Mobile Number"
                data={studentData?.mobileNumber}
              />
              <NameComponent head="Community" data={studentData?.community} />
              <NameComponent head="Category" data={studentData?.category} />
              <NameComponent
                head="Special Category"
                data={studentData?.special_category}
              />
              <NameComponent head="Disability" data={studentData?.disability} />
            </div>
            <div className="container">
              <div className="container-element-extra">
                <h5>Blood Group</h5>
                <p>{studentData?.bloodGroup}</p>
              </div>
            </div>
          </div>
        </div>

        {/* Parent's Info */}
        <div>
          <h4 className="mt-5 mb-2">Parent's Details</h4>
          <div className="element-wrapper">
            <p>Mother's Info</p>
            <div className="container">
              <NameComponent
                head="Full Name"
                data={studentData?.motherInfo?.firstName}
              />
              <NameComponent
                head="Middle Name"
                data={studentData?.motherInfo?.middleName}
              />
              <NameComponent
                head="Last Name"
                data={studentData?.motherInfo?.lastName}
              />
              <NameComponent
                head="Email ID"
                data={studentData?.motherInfo?.email}
              />
              <NameComponent
                head="Mobile Number"
                data={studentData?.motherInfo?.mobileNumber}
              />
              <NameComponent
                head="Occupation"
                data={studentData?.motherInfo?.occupation}
              />
            </div>
            <hr></hr>
            <p>Father's Info</p>
            <div className="container">
              <NameComponent
                head="Full Name"
                data={studentData?.fatherInfo?.firstName}
              />
              <NameComponent
                head="Middle Name"
                data={studentData?.fatherInfo?.middleName}
              />
              <NameComponent
                head="Last Name"
                data={studentData?.fatherInfo?.lastName}
              />
              <NameComponent
                head="Mobile Number"
                data={studentData?.fatherInfo?.mobileNumber}
              />
              <NameComponent
                head="Occupation"
                data={studentData?.fatherInfo?.occupation}
              />
            </div>
            <hr></hr>
            <p>Guardian Info</p>
            <div className="container">
              <NameComponent
                head="Full Name"
                data={studentData?.guardianInfo?.firstName}
              />
              <NameComponent
                head="Middle Name"
                data={studentData?.guardianInfo?.middleName}
              />
              <NameComponent
                head="Last Name"
                data={studentData?.guardianInfo?.lastName}
              />
              <NameComponent
                head="Email ID"
                data={studentData?.guardianInfo?.email}
              />
              <NameComponent
                head="Mobile Number"
                data={studentData?.guardianInfo?.mobileNumber}
              />
              <NameComponent
                head="Relation"
                data={studentData?.guardianInfo?.relation}
              />
            </div>
          </div>
        </div>

        {/* Address Info */}
        <div>
          <h4 className="mt-5 mb-2">Address Information</h4>
          <div className="element-wrapper">
            <p>Current Address</p>
            <div className="container">
              <NameComponent
                head="Address"
                data={studentData?.currentAddress?.address}
              />
              <NameComponent
                head="Pin Code"
                data={studentData?.currentAddress?.pinCode}
              />
              <NameComponent
                head="State"
                data={studentData?.currentAddress?.state}
              />
              <NameComponent
                head="District"
                data={studentData?.currentAddress?.dist}
              />
            </div>
            <p>Permanent Address</p>
            <div className="container">
              <NameComponent
                head="Address"
                data={studentData?.permanentAddress?.address}
              />
              <NameComponent
                head="Pin Code"
                data={studentData?.permanentAddress?.pinCode}
              />
              <NameComponent
                head="State"
                data={studentData?.permanentAddress?.state}
              />
              <NameComponent
                head="District"
                data={studentData?.permanentAddress?.dist}
              />
            </div>
          </div>
        </div>

        {/* Admission Detail */}
        <div>
          <h4 className="mt-5 mb-2">Admission Details</h4>
          <div className="element-wrapper">
            <div className="container">
              <NameComponent
                head="Admission Date"
                data={
                  allValues?.admissionDate
                    ? dayjs(new Date(allValues?.admissionDate)).format(
                        "DD-MM-YYYY"
                      )
                    : "--"
                }
              />
              <NameComponent
                head="Admission Type"
                data={allValues?.admissionType}
              />
              <NameComponent
                head="Reg. Number"
                data={studentData?.registrationNumber}
              />
              <NameComponent
                head="Adm. Number"
                data={studentData?.admissionNumber}
              />
            </div>
            <div className="container">
              <NameComponent head="New Session" data={newSession} />
              <NameComponent head="Roll Number" data={allValues?.rollNumber} />
              <NameComponent
                head="Class-Stream-Sec"
                data={`${getClassStream()}-${allValues?.section}`}
              />
            </div>
          </div>
        </div>

        {/* Transportation */}
        <div>
          <h4 className="mt-5 mb-2">Transportation</h4>
          <div className="element-wrapper">
            <Grid container spacing={1}>
              <Grid item xs={3}>
                <NameComponent
                  head="Using Transport"
                  data={allValues?.transportInfo?.using}
                />
              </Grid>
              <Grid item xs={3}>
                <NameComponent
                  head="Route No. (Route Name)"
                  data={`${allValues?.transportInfo?.routeNumber}:${allValues?.transportInfo?.routeName}`}
                />
              </Grid>
              <Grid item xs={3}>
                <NameComponent
                  head="Area Code"
                  data={allValues?.transportInfo?.to}
                />
              </Grid>
              <Grid item xs={3}>
                <NameComponent
                  head="Transport Vehicle"
                  data={allValues?.transportInfo?.vehicleNumber}
                />
              </Grid>
              <Grid item xs={3}>
                <NameComponent
                  head="Stop Name"
                  data={allValues?.transportInfo?.to}
                />
              </Grid>
              <Grid item xs={3}>
                <NameComponent
                  head="Fee Structure"
                  data={`${allValues?.transportInfo?.feeCode} ${
                    allValues?.transportInfo?.feeAmount
                      ? `-₹${allValues?.transportInfo?.feeAmount}`
                      : "--"
                  }`}
                />
              </Grid>
              <Grid item xs={12}>
                <NameComponent
                  head="Remarks"
                  data={allValues?.transportInfo?.remarks}
                />
              </Grid>
            </Grid>
            {/* <div className="container">
              <NameComponent
                head="Using Transport"
                data={allValues?.transportInfo?.using}
              />
              <NameComponent
                head="Route No. (Route Name)"
                data={allValues?.transportInfo?.routeNumber}
              />
              <NameComponent
                head="Transport Vehicle"
                data={allValues?.transportInfo?.vehicleNumber}
              />
              <NameComponent head="To" data={allValues?.transportInfo?.to} />
              <NameComponent
                head="Remarks"
                data={allValues?.transportInfo?.remarks}
              />
            </div> */}
          </div>
        </div>

        {/* House Details */}
        <div>
          <h4 className="mt-5 mb-2">House</h4>
          <div className="element-wrapper">
            <div className="container-sm">
              <NameComponent
                head="House Color"
                data={allValues?.house?.houseColor}
              />
              <NameComponent
                head="House Name"
                data={allValues?.house?.houseName}
              />
              <NameComponent
                head="Membership Role"
                data={allValues?.house?.membershipRole}
              />
            </div>
          </div>
        </div>

        {/* Fee Details */}
        <div>
          <h4 className="mt-5 mb-2">Fee Structure</h4>
          <ShowFeeStructure
            feeData={feeData}
            showTransportFee={
              allValues?.transportInfo?.using === "Yes" ? true : false
            }
            transportData={allValues?.transportInfo}
            transportFeeData={[
              {
                _id: allValues?.transportInfo?.transportFeeDocId,
                feeAmount: allValues?.transportInfo?.feeAmount,
              },
            ]}
          />
        </div>
      </div>
      <div className="footer">
        <Button
          variant="contained"
          color="primary"
          size="large"
          onClick={() => submitAdmission(allValues)}
          disabled={promoting}
        >
          Promote
        </Button>
      </div>
    </Wrapper>
  );
};

export default PreviewForPromotion;

const Wrapper = styled.div`
  .footer {
    margin: 20px;
    display: flex;
    justify-content: right;
  }
  .approval-btn {
    margin-right: 10px;
    background: #ffffff;
    border: 1px solid #e8a82c;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    color: #e8a82c;
  }
  .submit-btn {
    background: #2789fd;
    border-radius: 10px;
    font-weight: 400;
    font-size: 16px;
    padding: 15px 30px;
    line-height: 19px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    border: 1px solid;
    color: white;
  }
  .header {
    padding: 20px 20px 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .header-button {
    height: 28px;
    width: 28px;
    border-radius: 10px;
    border: 1.7915px solid #0c2f49;
  }
  .preview {
    overflow: auto;
    position: relative;
    padding: 20px;
    width: 800px;
    background: white;
    border-radius: 10px;
  }

  position: relative;
  border-radius: round;
  overflow: auto;
  margin: 2px;
  flex-wrap: wrap;

  .container {
    display: flex;
    flex-direction: row;
    margin-top: 25px;

    justify-content: space-between;
  }
  .container-sm {
    display: flex;
    flex-direction: row;
    margin-top: 25px;

    justify-content: space-around;
  }
  .container-element {
    display: flex;
    flex-direction: column;
  }
  .container-element p {
    font-weight: 600;
    color: #0c2f49;
    font-size: 16px;
  }
  .container-element h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0c2f49;
    opacity: 0.75;
  }
  .container-element-extra {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  .container-element-extra p {
    font-weight: 600;
    color: #0c2f49;
    font-size: 16px;
  }
  .container-element-extra h5 {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #0c2f49;
    opacity: 0.75;
  }
  .element-wrapper {
    overflow: auto;
    padding: 25px;
    background: rgba(208, 208, 226, 0.08);
    border: 1px solid #d0d0e2;
    border-radius: 15px;
  }

  .border {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 12px;
  }
  .accordian-wrapper {
    margin-bottom: 40px;
  }
`;
