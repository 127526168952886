import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let studentReport_url = `${SCHOOL_BASE}/studentReport`;
let student_url = `${SCHOOL_BASE}/student`;
export const getStudentByName = (params) => {
  return schoolTokenAxios.get(`${studentReport_url}/getStudentByName`, {
    params: params,
  });
};
export const getStudentByNameAndSession = (params) => {
  return schoolTokenAxios.get(
    `${studentReport_url}/getStudentByNameAndSession`,
    {
      params: params,
    }
  );
};
export const getClassVsStudent = (params) => {
  return schoolTokenAxios.get(`${student_url}/getClassVsStudent`, {
    params: params,
  });
};
