exports.FeeRelaxationCategoryList = [
  "General",
  "Sibling",
  "Management",
  "Staff Ward",
  "Other",
];
exports.FeeTypeList = [
  "Monthly Fee",
  "Other Fee",
  "Admission Fee",
  "Hostel Fee",
  "Exam Fee",
];
