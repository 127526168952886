import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import React, { useMemo, useState } from "react";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { LoadingButton } from "@mui/lab";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import { useQuery } from "react-query";
import { getFeeReport } from "app/services/student-management";
import { toast } from "react-toastify";
import { IndianCurrency } from "app/utils/helper";
import CommonTable1 from "../../../components/Tables/CommonTable1";
import { useSelector } from "react-redux";
import { userPrimaryRoleSelector } from "app/modules/schools/selectors";
import { Link } from "react-router-dom";
import appRoutes from "app/config/routes";
import * as XLSX from "xlsx";
import DialogHeader from "app/components/common/DialogHeader";
import { THEME } from "app/config/constants";
import TableProfilePic from "app/components/common/TableProfilePic";
import dayjs from "dayjs";
import ExportDialog from "app/components/common/exportDialog";
const FeeReportDeletedFee = () => {
  const [state, setState] = useState({
    from: new Date(),
    to: new Date(),
    dialog: false,
    deleteData: null,
    feeReportList: [],
    checkExportOptions: false,
    totalNetpaid: 0,
  });
  const [openCheckBox, setOpenCheckBox] = useState(false);
  const primaryRole = useSelector(userPrimaryRoleSelector);

  const {
    isLoading: getAllFeeReportLoading,
    data: getAllFeeReportData,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ["getAllFeeReport"],
    queryFn: () =>
      getFeeReport({
        feeReportFrom: state.from,
        feeReportTo: state.to,
        status: "DELETED",
      }),
    onSuccess: ({ data }) => {
      setState((prev) => ({
        ...prev,
        feeReportList: data?.invoiceArray,
        totalNetpaid: data?.totalNetpaid,
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const admincolumns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography>
            {parseInt(cell?.row?.id) + 1 < 10
              ? `0${parseInt(cell?.row?.id) + 1}`
              : parseInt(cell?.row?.id) + 1}
          </Typography>
        ),
        size: 20,
      },

      {
        header: "Invoice Number",
        accessorKey: "invoiceNumber",

        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Link
            to={`/${appRoutes.invoice}/${String(
              original?.invoiceNumber
            ).replace(/\//g, " ")}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontWeight: "bold" }}
          >
            {original?.invoiceNumber}
          </Link>
        ),
        size: 40,
      },

      {
        header: "Class",
        accessorKey: "className",

        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.studentDetail?.className}-
            {original?.studentDetail?.stream}-{original?.studentDetail?.section}
          </Typography>
        ),
        size: 60,
      },
      {
        header: "Student Name",
        accessorKey: "studentDetail.studentName",

        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Link
              to={`/${appRoutes.student_profile}/${original?.studentDetail?.studentDocId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Stack direction="row" alignItems="center" gap={0.6}>
                <TableProfilePic image={original?.studentDetail?.profilePic} />
                <Typography>{original?.studentDetail?.studentName}</Typography>
              </Stack>
            </Link>
          );
        },
        size: 80,
      },

      {
        header: "Total Amount",
        accessorKey: "totalAmount",

        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>&#8377;{original?.totalAmount}</Typography>,
        size: 20,
      },
      {
        header: "Discount",
        accessorKey: "discountAmount",

        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>&#8377;{original?.discountAmount}</Typography>,
        size: 20,
      },
      {
        header: "Balance",
        accessorKey: "balanceAmount",

        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>&#8377;{original?.balanceAmount}</Typography>,
        size: 20,
      },
      {
        header: "Net Paid",
        accessorKey: "paidAmount",

        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>&#8377;{original?.paidAmount}</Typography>,
        size: 20,
      },
      {
        header: "Payment Mode",
        accessorKey: "paymentDetails",

        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.paymentDetails
              ? Object.keys(original?.paymentDetails)?.map((item) => item)
              : ""}
          </Typography>
        ),
        size: 20,
      },
      {
        header: "Created By",
        accessorKey: "createdBy.personName",

        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>{original?.createdBy?.personName}</Typography>,
        size: 40,
      },
    ];
  }, []);
  const staffcolumns = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography>
            {parseInt(cell?.row?.id) + 1 < 10
              ? `0${parseInt(cell?.row?.id) + 1}`
              : parseInt(cell?.row?.id) + 1}
          </Typography>
        ),
        size: 20,
      },

      {
        header: "Invoice Number",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Link
            to={`/${appRoutes.invoice}/${String(
              original?.invoiceNumber
            ).replace(/\//g, " ")}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontWeight: "bold" }}
          >
            {original?.invoiceNumber}
          </Link>
        ),
        size: 40,
      },

      {
        header: "Class",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.studentDetail?.className}-
            {original?.studentDetail?.stream}-{original?.studentDetail?.section}
          </Typography>
        ),
        size: 60,
      },

      {
        header: "Student Name",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography
            style={{
              borderRadius: "8px",
              border: "none",
              outline: "none",
              textOverflow: "ellipsis",
            }}
          >
            {original?.studentDetail?.studentName}
          </Typography>
        ),
        size: 60,
      },

      {
        header: "Total Amount",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>&#8377;{original?.totalAmount}</Typography>,
        size: 20,
      },
      {
        header: "Discount",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>&#8377;{original?.discountAmount}</Typography>,
        size: 20,
      },
      {
        header: "Balance",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>&#8377;{original?.balanceAmount}</Typography>,
        size: 20,
      },
      {
        header: "Net Paid",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>&#8377;{original?.paidAmount}</Typography>,
        size: 20,
      },
      {
        header: "Payment Mode",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {" "}
            {original?.paymentDetails
              ? Object.keys(original?.paymentDetails)?.map((item) => item)
              : ""}
          </Typography>
        ),
        size: 20,
      },
      {
        header: "Created By",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => <Typography>{original?.createdBy?.personName}</Typography>,
        maxSize: 40,
      },
    ];
  }, []);

  let header = [
    "Session",
    "InvoiceRecieverGST",
    "BalanceAmount",
    "DOB",
    "className",
    "fatherName",
    "gender",
    "previousBalance",
    "registrationNumber",
    "rollNumber",
    "MobileNumber",
    "Name",
    "CreatedBy",
    "CreatedOn",
    "DepositorName",
    "DiscountAmount",
    "DiscountRemark",
    "GrandTotalAmount",
    "InvoiceNumber",
    "PaidAmount",
    "address",
    "Status",
    "TransportInfo_feeAmount",
    "TransportInfo_remarks",
    "TransportInfo_routeNumber",
    "TransportInfo_to",
    "TransportInfo_using",
    "TransportInfo_vehicleNumber",
    "TaxableAmount",
    "TotalAmount",
  ];
  let exportArray = state.feeReportList?.map((item) => {
    return {
      Session: item?.session,
      InvoiceRecieverGST: item?.InvoiceRecieverGST,
      BalanceAmount: item?.balanceAmount,
      DOB: item?.studentDetail?.DOB,
      ClassName: `${item?.studentDetail?.className}-${item?.studentDetail?.stream}-${item?.studentDetail?.section}`,
      fatherName: item?.studentDetail?.fatherName,
      gender: item?.studentDetail?.gender,
      previousBalance: item?.studentDetail?.previousBalance,
      registrationNumber: item?.studentDetail?.registrationNumber,
      rollNumber: item?.studentDetail?.rollNumber,
      MobileNumber: item?.studentDetail?.studentMobileNumber,
      Name: item?.studentDetail?.studentName,
      CreatedBy: item?.createdBy?.personName,
      CreatedOn: dayjs(item?.createdOn).format("DD-MM-YYYY"),
      DepositorName: item?.depositorName,
      DiscountAmount: item?.discountAmount,
      DiscountRemark: item?.discountRemark,
      GrandTotalAmount: item?.grandTotalAmount,
      InvoiceNumber: item?.invoiceNumber,
      PaidAmount: item?.paidAmount,
      address: `${item?.schoolAddress?.address}${item?.schoolAddress?.dist}${item?.schoolAddress?.pinCode}${item?.schoolAddress?.state}`,
      Status: item?.status,
      TransportInfo_feeAmount: item?.transportInfo?.feeAmount,
      TransportInfo_remarks: item?.transportInfo?.remarks,
      TransportInfo_routeNumber: item?.transportInfo?.routeNumber,
      TransportInfo_to: item?.transportInfo?.to,
      TransportInfo_using: item?.transportInfo?.using,
      TransportInfo_vehicleNumber: item?.transportInfo?.vehicleNumber,
      TaxableAmount: item?.taxableAmount,
      TotalAmount: item?.totalAmount,
    };
  });

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ExportDialog
          open={openCheckBox}
          handleClose={() => setOpenCheckBox(false)}
          header={header}
          exportArray={exportArray}
          fileName="Fee_Reported_Deleted_Fee"
        />
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="row" gap={1} alignItems="flex-end">
            <Box>
              <TextFieldLabel title="From" />
              <DesktopDatePicker
                inputFormat="DD MMM, YYYY"
                value={state.from}
                onChange={(e) => setState((prev) => ({ ...prev, from: e }))}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    placeholder="Select"
                    size="small"
                  />
                )}
              />
            </Box>
            <Box>
              <TextFieldLabel title="To" />
              <DesktopDatePicker
                inputFormat="DD MMM, YYYY"
                value={state.to}
                onChange={(e) => setState((prev) => ({ ...prev, to: e }))}
                renderInput={(params) => (
                  <CustomTextField
                    {...params}
                    placeholder="Select"
                    size="small"
                  />
                )}
              />
            </Box>
            <Box>
              <LoadingButton
                variant="contained"
                size="large"
                color="secondary"
                onClick={() => refetch()}
                loading={getAllFeeReportLoading || isFetching}
                sx={{ mb: 0.5 }}
              >
                View
              </LoadingButton>
            </Box>
          </Stack>
          <ExportButtonGroup
            handleClickExcel={() => setOpenCheckBox(true)}
            showPdf={false}
          />
        </Stack>
        <Stack sx={{ pt: 1 }} direction="row">
          <Stack
            direction="row"
            alignItems={"center"}
            justifyContent="flex-start"
            gap={0.8}
            sx={{
              backgroundColor: "#fff",
              p: 1,
              borderRadius: "7px",
              boxShadow: THEME.boxShadow1,
            }}
          >
            <Typography
              sx={{
                color: (theme) => theme.palette.primary.main,
                fontSize: "15px",
                opacity: 0.75,
                fontWeight: "500",
              }}
            >
              Total Net Paid :
            </Typography>
            <Typography
              style={{
                fontWeight: 600,
                letterSpacing: 0.5,
                fontSize: "18px",
              }}
            >
              {IndianCurrency(state?.totalNetpaid)}
            </Typography>
          </Stack>
        </Stack>

        <Box sx={{ mt: 2 }}>
          {getAllFeeReportData && (
            <CommonTable1
              columns={
                ["ROLE_ADMIN", "ROLE_MANAGING_DIRECTOR"].includes(primaryRole)
                  ? admincolumns
                  : staffcolumns
              }
              data={state.feeReportList}
            />
          )}
        </Box>
      </LocalizationProvider>
    </>
  );
};

export default FeeReportDeletedFee;
