import { Box, colors, Divider, Grid, styled, Typography } from "@mui/material";
import { IndianCurrency } from "app/utils/helper";
import React from "react";

const PaymentSummaryComponent = ({
  showUpperSection = false,
  showLowerSection = false,
  showRazorpay = false,
  showHdfc = false,
  t_CashMode,
  t_NEFTMode,
  t_UpiMode,
  t_ChequeMode,
  t_CardMode,
  t_Razorpay,
  t_HdfcPG,
  totalDiscount,
  totalNetpaid,
}) => {
  const columns =
    showRazorpay && showHdfc ? 18 : showRazorpay || showHdfc ? 15 : 12;

  const feeBoxWidth =
    showRazorpay && showHdfc
      ? "100%"
      : showRazorpay || showHdfc
      ? "85%"
      : "70%";

  return (
    <>
      <FeeBox
        sx={{
          width: feeBoxWidth,
        }}
      >
        {showUpperSection && (
          <Grid container spacing={2} columns={columns} wrap="wrap">
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                CASH Mode
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(t_CashMode)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                NEFT
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(t_NEFTMode)}
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                UPI
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(t_UpiMode)}
              </Typography>
            </Grid>
            <Grid item xs={2.5}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Cheque
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(t_ChequeMode)}
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                Card
              </Typography>
              <Typography
                style={{
                  fontWeight: 600,
                  fontSize: "15px",
                }}
              >
                {IndianCurrency(t_CardMode)}
              </Typography>
            </Grid>
            {showRazorpay && (
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Razor Pay
                </Typography>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {IndianCurrency(t_Razorpay)}
                </Typography>
              </Grid>
            )}
            {showHdfc && (
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  HDFC PG
                </Typography>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "15px",
                  }}
                >
                  {IndianCurrency(t_HdfcPG)}
                </Typography>
              </Grid>
            )}
          </Grid>
        )}
        {showLowerSection && (
          <>
            <Divider sx={{ backgroundColor: "#0C2F49" }} />
            <Grid container spacing={2}>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Total Discount
                </Typography>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "15px",
                    color: "#29DB5B",
                  }}
                >
                  {IndianCurrency(totalDiscount)}
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Total Net Paid
                </Typography>
                <Typography
                  style={{
                    fontWeight: 600,
                    fontSize: "15px",
                    color: colors.blue[600],
                  }}
                >
                  {IndianCurrency(totalNetpaid)}
                </Typography>
              </Grid>
            </Grid>
          </>
        )}
      </FeeBox>
    </>
  );
};

export default PaymentSummaryComponent;

const FeeBox = styled(Box)`
  border-radius: 10px;
  border: 1px solid rgba(19, 115, 229, 0.2);
  background: rgba(39, 137, 253, 0.02);
  padding: 5px;
  display: inline-block;
  margin-top: 10px;
`;
