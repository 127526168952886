import { Close } from "@mui/icons-material";
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import StaffSearchInput from "app/components/common/StaffSearchInput";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  addDebtTransaction,
  addPayrollSecurity,
} from "app/services/schoolService/hr.service";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import { useFormik } from "formik";
import React from "react";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import * as yup from "yup";
import CommonDialog from "../../CommonDialog";
import { LoadingButton } from "@mui/lab";
const LabelText = ({ label = "", value = "" }) => {
  return (
    <Stack direction={"row"} gap={1}>
      <Typography
        sx={{
          color: "primary.main",
          fontWeight: 600,
          opacity: 0.7,
          fontSize: "12px",
        }}
      >
        {label}{" "}
      </Typography>
      <Typography
        sx={{ color: "primary.main", fontWeight: 600, fontSize: "13px" }}
      >
        {value}{" "}
      </Typography>
    </Stack>
  );
};
export const StaffCard = ({ item }) => {
  return (
    <Box>
      <Stack direction="row" spacing={1} alignItems={"center"} px={2}>
        {/* student profile pic */}
        <div>
          <img
            src={
              item?.profilePic ? item?.profilePic : "/image-placeholder.jpeg"
            }
            alt="student"
            style={{
              width: "40px",
              height: "40px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
        {/* student details */}
        <Stack>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "15px",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {item?.name}
          </Typography>

          <LabelText
            label="Department:- "
            value={item?.departmentName ?? "--"}
          />
          <LabelText label="Designation:-" value={item?.designation ?? "--"} />
        </Stack>
      </Stack>
    </Box>
  );
};
const validationSchema = yup.object({
  staffDocId: yup.string().required("Staff Name is required"),
  amount: yup.number().required(" Amount is required"),
});

const AddSecurityMoneyDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
}) => {
  const {
    values,
    errors,
    touched,
    setValues,
    handleChange,
    resetForm,
    handleSubmit,
  } = useFormik({
    initialValues: {
      staffData: {},
      staffDocId: "",
      amount: "",
      remarks: "",
    },
    validationSchema,
    onSubmit: (formValues) => {
      const body = {
        staffDocId: formValues.staffDocId,
        securityMoney: formValues.amount,
        remarks: formValues.remarks,
      };
      addPayrollSecurityMutate(body);
    },
  });
  const handleClose = () => {
    onClose();
    resetForm();
  };
  const {
    mutate: addPayrollSecurityMutate,
    isLoading: addPayrollSecurityLoading,
  } = useMutation({
    mutationKey: [""],
    mutationFn: addPayrollSecurity,
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      handleClose();
      refetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  return (
    <>
      <CommonDialog
        title="Add Security Money"
        minWidth="600px"
        onClose={handleClose}
        open={open}
      >
        <DialogContent>
          <form onSubmit={handleSubmit} id="add-new-debt-form">
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <TextFieldLabel title={"Staff Name"} required />
                <StaffSearchInput
                  returnResponse={(item) =>
                    setValues((prev) => ({
                      ...prev,
                      staffData: item,
                      staffDocId: item?.staffDocId,
                    }))
                  }
                />
                {errors?.staffDocId && (
                  <Typography color={"error"}>{errors.staffDocId}</Typography>
                )}
              </Grid>
              <Grid item xs={8}>
                <Stack direction={"row"} alignItems={"center"} gap={1}>
                  {Object.keys(values.staffData).length > 0 && (
                    <>
                      <StaffCard item={values.staffData} />
                      <Box>
                        <IconButton
                          sx={{ background: "#f6f6f6" }}
                          onClick={() =>
                            setValues((prev) => ({
                              ...prev,
                              staffData: {},
                              staffDocId: "",
                            }))
                          }
                        >
                          <Close sx={{ color: "red" }} />
                        </IconButton>
                      </Box>
                    </>
                  )}
                </Stack>
              </Grid>
            </Grid>

            <Grid container spacing={1} sx={{ mt: 1 }}>
              <Grid item xs={12}>
                <TextFieldLabel title="Amount" />
                <CustomTextField
                  type="number"
                  size="small"
                  fullWidth
                  name="amount"
                  value={values.amount}
                  onChange={handleChange}
                  placeholder="type amount"
                  error={Boolean(errors.amount) && touched.amount}
                  helperText={touched.amount && errors.amount}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldLabel title="Remarks" required />
                <CustomTextField
                  required
                  size="small"
                  fullWidth
                  name="remarks"
                  value={values.remarks}
                  onChange={handleChange}
                  placeholder="type remarks"
                  error={Boolean(errors.remarks) && touched.remarks}
                  helperText={touched.remarks && errors.remarks}
                />
              </Grid>
            </Grid>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={addPayrollSecurityLoading}
            size="large"
            type="submit"
            form="add-new-debt-form"
            variant="contained"
            color="secondary"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddSecurityMoneyDialog;
