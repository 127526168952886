import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { getClassDropdown } from "app/services/student-management";
import { useSelector } from "react-redux";
import {
  selectSelectedSchoolId,
  selectedMediumSelector,
  selectActiveSession,
  selectSession,
} from "app/modules/schools/selectors";
import { toast } from "react-toastify";
import FilterButton from "../FilterButton";
import CommonFilterDialog from "./CommonFilterDialog";
import {
  Checkbox,
  DialogContent,
  FormControlLabel,
  MenuItem,
  Stack,
  Grid,
  Radio,
  Box,
} from "@mui/material";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import TextFieldLabel from "../TextFieldLabel";
import { DatePicker } from "@mui/x-date-pickers";
import { getSectionList } from "app/services/management";
import ByClassFilter from "app/components/commonSeperateMultipleFilter/ByClassFilter";

const CheckBoxComponent = ({
  onChange = () => {},
  checked,
  label = "Label",
}) => {
  return (
    <FormControlLabel
      checked={checked}
      onChange={onChange}
      control={<Checkbox color="secondary" />}
      label={label}
      componentsProps={{
        typography: {
          sx: { fontSize: "13px", fontWeight: 600 },
        },
      }}
    />
  );
};

const AdmissionEnquiryFilterDialog = ({
  mutate = () => {},
  loading = () => {},
}) => {
  const selectedMedium = useSelector(selectedMediumSelector);
  const selectedSchoolId = useSelector(selectSelectedSchoolId);
  const [classOptions, setClassOptions] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [classListToMutate, setClassListToMutate] = useState([]);

  const [state, setState] = useState({
    dialog: false,
    createdFrom: new Date(),
    createdTo: new Date(),
    enquiryFrom: new Date(),
    enquiryTo: new Date(),
    paymentStatus: "",
    enquiryStatus: "",
  });
  const [filters, setFilters] = useState({
    class: {
      classDocId: "",
      section: "",
    },
  });
  const [checkboxes, setCheckboxes] = useState({
    class: false,
    createdOn: false,
    enquiryOn: false,
    paymentStatus: false,
    enquiryStatus: false,
  });
  const handleCloseFilterDialog = () => {
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const handleView = () => {
    handleCloseFilterDialog();
    const body = {
      ...(checkboxes.class && {
        ...(classListToMutate.length > 0 && {
          classList: JSON.stringify(classListToMutate),
        }),
        // classDocId: filters?.class?.classDocId,
      }),
      ...(checkboxes.paymentStatus && {
        paymentStatus: state.paymentStatus,
      }),
      ...(checkboxes.enquiryStatus && {
        enquiryStatus: state.enquiryStatus,
      }),
      ...(checkboxes.createdOn && {
        createdFrom: new Date(state.createdFrom),
        createdTo: new Date(state.createdTo),
      }),
      ...(checkboxes.enquiryOn && {
        enquiryFrom: new Date(state.enquiryFrom),
        enquiryTo: new Date(state.enquiryTo),
      }),
    };
    mutate(body);
  };
  const {
    isLoading: getClassDropdownLoading,
    isFetching: getClassDropdownFetching,
  } = useQuery({
    queryKey: ["getClassDropdown", selectedSchoolId, selectedMedium],
    queryFn: () => getClassDropdown(selectedSchoolId, selectedMedium),
    onSuccess: (res) => {
      setClassOptions(res?.data?.list);
    },
    enabled: checkboxes.class ? true : false,
  });
  useEffect(() => {
    const filteredList = selectedClasses?.map(({ classDocId, section }) => ({
      classDocId,
      section,
    }));
    setClassListToMutate(filteredList);
  }, [selectedClasses]);

  return (
    <>
      <FilterButton
        hanldeClick={() => setState((prev) => ({ ...prev, dialog: true }))}
        count={Object.values(checkboxes).filter((item) => item === true).length}
      />
      <CommonFilterDialog
        open={state.dialog}
        onClose={handleCloseFilterDialog}
        handleSaveAndView={handleView}
        minWidth="500px"
      >
        <DialogContent>
          {/* Class  */}
          <Box>
            <CheckBoxComponent
              checked={checkboxes.class}
              onChange={(e) => {
                setFilters((prev) => ({
                  ...prev,
                  class: {
                    ...prev.class,
                    classDocId: "",
                    section: "",
                  },
                }));
                setCheckboxes((prev) => ({
                  ...prev,
                  class: !prev.class,
                }));
              }}
              label="Class Info"
            />
            {checkboxes.class && (
              <ByClassFilter
                prevClassList={selectedClasses}
                setSelectedClasses={setSelectedClasses}
                // onClassListChange={handleClassListChange}
              />
              // <Stack direction="row" gap={2} className="left_margin">
              //   <Stack flex={1}>
              //     <TextFieldLabel title="Class-Stream" />
              //     <CustomSelectBox
              //       size="small"
              //       select
              //       MenuProps={MenuProps}
              //       displayEmpty
              //       value={filters?.class?.classDocId}
              //       fullWidth
              //     >
              //       <MenuItem value="" disabled>
              //         Select
              //       </MenuItem>
              //       {classOptions &&
              //         classOptions.map((opt, i) => {
              //           return (
              //             <MenuItem
              //               key={opt?.classDocId}
              //               value={opt?.classDocId}
              //               onClick={() =>
              //                 setFilters((prev) => ({
              //                   ...prev,
              //                   class: {
              //                     classDocId: opt?.classDocId,
              //                     sections: opt?.sections,
              //                     section: "",
              //                   },
              //                 }))
              //               }
              //             >
              //               {opt?.className_stream}
              //             </MenuItem>
              //           );
              //         })}
              //     </CustomSelectBox>
              //   </Stack>
              // </Stack>
            )}
          </Box>

          {/* Created On  */}
          <Box>
            <CheckBoxComponent
              checked={checkboxes.createdOn}
              onChange={(e) => {
                setCheckboxes((prev) => ({
                  ...prev,
                  createdOn: !prev.createdOn,
                }));
              }}
              label="Created On"
            />
            {checkboxes.createdOn && (
              <Grid container spacing={1.5}>
                <Grid xs={6} item>
                  <TextFieldLabel title="From" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.createdFrom}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, createdFrom: e }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextFieldLabel title="To" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.createdTo}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, createdTo: e }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Box>

          {/* Enquiry On  */}
          <Box>
            <CheckBoxComponent
              checked={checkboxes.enquiryOn}
              onChange={(e) => {
                setCheckboxes((prev) => ({
                  ...prev,
                  enquiryOn: !prev.enquiryOn,
                }));
              }}
              label="Enquiry On"
            />
            {checkboxes.enquiryOn && (
              <Grid container spacing={1.5}>
                <Grid xs={6} item>
                  <TextFieldLabel title="From" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.enquiryFrom}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, enquiryFrom: e }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextFieldLabel title="To" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    value={state.enquiryTo}
                    onChange={(e) =>
                      setState((prev) => ({ ...prev, enquiryTo: e }))
                    }
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Box>

          {/* Payment Status  */}
          <Box>
            <CheckBoxComponent
              checked={checkboxes.paymentStatus}
              onChange={(e) => {
                setCheckboxes((prev) => ({
                  ...prev,
                  paymentStatus: !prev.paymentStatus,
                }));
              }}
              label="Payment Status"
            />
            {checkboxes.paymentStatus && (
              <Grid container spacing={1.5}>
                <Grid xs={6} item>
                  <TextFieldLabel title="Payment Status" />
                  <CustomSelectBox
                    displayEmpty
                    size="small"
                    fullWidth
                    value={state.paymentStatus}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        paymentStatus: e.target.value,
                      }))
                    }
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    <MenuItem value="Paid">Paid</MenuItem>
                    <MenuItem value="Not Paid">Not Paid</MenuItem>
                  </CustomSelectBox>
                </Grid>
              </Grid>
            )}
          </Box>

          {/* Enquiry Status  */}
          <Box>
            <CheckBoxComponent
              checked={checkboxes.enquiryStatus}
              onChange={(e) => {
                setCheckboxes((prev) => ({
                  ...prev,
                  enquiryStatus: !prev.enquiryStatus,
                }));
              }}
              label="Enquiry Status"
            />
            {checkboxes.enquiryStatus && (
              <Grid container spacing={1.5}>
                <Grid xs={6} item>
                  <TextFieldLabel title="Enquiry Status" />
                  <CustomSelectBox
                    displayEmpty
                    size="small"
                    fullWidth
                    value={state.enquiryStatus}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        enquiryStatus: e.target.value,
                      }))
                    }
                  >
                    <MenuItem disabled value="">
                      Select
                    </MenuItem>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Won">Won</MenuItem>
                    <MenuItem value="Lost">Lost</MenuItem>
                  </CustomSelectBox>
                </Grid>
              </Grid>
            )}
          </Box>
        </DialogContent>
      </CommonFilterDialog>
    </>
  );
};

export default AdmissionEnquiryFilterDialog;
