import { Refresh } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";

import {
  getInvoice,
  getTransactionBySessionOrder,
  purchaseSessionOrder,
  saveFailedPaymentDetail,
  saveSuccessPaymentDetail,
} from "app/services/orgService/school.service";

import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CustomLoader from "../../CustomLoader";
import CommonDialog from "../CommonDialog";
import appRoutes from "app/config/routes";
import { IndianCurrency } from "app/utils/helper";
import { LoadingButton } from "@mui/lab";
import TextFieldLabel from "../../TextFieldLabel";
import { useSelector } from "react-redux";
import {
  selectSelectedSchool,
  userDetailSelector,
  userNameSelector,
} from "app/modules/schools/selectors";

const RazorPayPaymentDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  data,
  setCheckedItems,
  schoolDocId,
  // state,
  // setState,
  session,
}) => {
  const handleClose = () => {
    onClose();
  };
  const [paymentDetail, setPaymentDetail] = useState({});
  const [paymentDetailSuccess, setPaymentDetailSuccess] = useState({});
  const [paymentDetailFailure, setPaymentDetailFailure] = useState({});
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.async = true;
    document.body.appendChild(script);
  }, []);

  //API call at puchaseSessionOrder
  const {
    mutate: purchaseSessionOrderMutate,
    isLoading: purchaseSessionOrderLoading,
  } = useMutation(purchaseSessionOrder, {
    onSuccess: ({ data }) => {
      // toast.success(data?.message);
      setPaymentDetail(data);
    },
    onError: (error) => {
      toast.error(error.response.data?.message);
    },
  });

  //API call at success of payment
  const {
    mutate: saveSuccessPaymentDetailMutate,
    isLoading: saveSuccessPaymentDetailLoading,
  } = useMutation(saveSuccessPaymentDetail, {
    onSuccess: async ({ data }) => {
      toast.success(data?.message);
      setPaymentDetailSuccess({});
      setPaymentDetailFailure({});
      setCheckedItems([]);
      // const updatedList = state.data.list.map((item) => {
      //   return {
      //     ...item,
      //     checked: false,
      //   };
      // });

      // setState((prevState) => ({
      //   ...prevState,
      //   data: {
      //     ...prevState.data,
      //     list: updatedList,
      //   },
      // }));
      handleClose();
      refetch();
    },
    onError: (error) => {
      toast.error(error.response.data?.message);
    },
  });

  //API call at success of failure

  const {
    mutate: saveFailedPaymentDetailMutate,
    isLoading: saveFailedPaymentDetailLoading,
  } = useMutation(saveFailedPaymentDetail, {
    onSuccess: ({ data }) => {
      toast.success(data?.message);
      setPaymentDetailSuccess({});
      setPaymentDetailFailure({});
      refetch();
    },
    onError: (error) => {
      toast.error(error.response.data?.message);
    },
  });

  const totalPayingAmount = data?.reduce(
    (total, item) =>
      total +
      Number(item?.netOrderAmount ?? 0) -
      Number(item?.paidAmount ?? 0) -
      Number(item?.totalExtraDiscount ?? 0),
    0
  );
  const totalTaxAmount = data?.reduce(
    (total, item) =>
      total +
      ((Number(item?.sgstRate ?? 0) +
        Number(item?.cgstRate ?? 0) +
        Number(item?.igstRate ?? 0)) /
        100) *
        (Number(item?.amount ?? 0) -
          Number(item?.paidAmount ?? 0) -
          Number(item?.t_extraDiscount ?? 0)),
    0
  );
  const totalTransactionAmount = totalPayingAmount + totalTaxAmount;
  const selectedSchool = useSelector(selectSelectedSchool);
  const userDetail = useSelector(userDetailSelector);
  const userName = useSelector(userNameSelector);

  useEffect(() => {
    if (data?.length > 0 && open) {
      let selectedSessionOrders = data.map((item) => {
        const amount =
          Number(item?.netOrderAmount ?? 0) -
          Number(item?.paidAmount ?? 0) -
          Number(item?.totalExtraDiscount ?? 0);
        const tax =
          ((Number(item?.sgstRate ?? 0) +
            Number(item?.cgstRate ?? 0) +
            Number(item?.igstRate ?? 0)) /
            100) *
          amount;
        const totalAmount = amount + tax;

        return {
          sessionOrderDocId: item?._id ?? "",
          referalCode: item?.referalCode ?? "",
          extraDiscountAmount: item?.t_extraDiscount ?? 0,
          taxableAmount: amount,
          sgstRate: item?.sgstRate ?? 0,
          cgstRate: item?.cgstRate ?? 0,
          igstRate: item?.igstRate ?? 0,
          taxAmount: tax,
          totalAmount: totalAmount,
        };
      });
      let query = {
        schoolDocId: schoolDocId,
        session: session,
        selectedSessionOrders: selectedSessionOrders,
        totalPayingAmount: totalPayingAmount,
        totalTaxAmount: totalTaxAmount,
        totalTransactionAmount: totalTransactionAmount,
      };
      purchaseSessionOrderMutate(query);
    }
  }, [open]);

  const openRazorpay = () => {
    var options = {
      key: paymentDetail?.key,
      order_id: paymentDetail?.order_id,
      amount: paymentDetail?.amount,
      currency: paymentDetail?.currency,
      name: paymentDetail?.companyName,
      description: paymentDetail?.description,
      image: paymentDetail?.companyLogo,
      handler: function (response) {
        setPaymentDetailSuccess(response);
        // alert(response.razorpay_payment_id);
        // alert(response.razorpay_order_id);
        // alert(response.razorpay_signature);
      },
      prefill: {
        name: userName,
        email: userDetail?.email ?? "",
        contact: userDetail?.mobileNumber ?? "",
      },
      notes: {
        address: `${selectedSchool?.schoolAddress?.address} ${selectedSchool?.schoolAddress?.dist} ${selectedSchool?.schoolAddress?.state} ${selectedSchool?.schoolAddress?.pinCode}`,
      },
      theme: {
        color: "#3399cc",
      },
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.on("payment.failed", function (response) {
      setPaymentDetailFailure(response);
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      // alert(response.error.reason);
      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
    rzp1.open();
  };

  useEffect(() => {
    if (
      paymentDetailSuccess &&
      Object.keys(paymentDetailSuccess).length !== 0
    ) {
      let query = {
        razorpay_payment_id: paymentDetailSuccess?.razorpay_payment_id,
        order_id: paymentDetailSuccess?.razorpay_order_id,
        razorpay_signature: paymentDetailSuccess?.razorpay_signature,
      };
      saveSuccessPaymentDetailMutate(query);
    }
    if (
      paymentDetailFailure &&
      Object.keys(paymentDetailFailure).length !== 0
    ) {
      let query = {
        errorDescription: paymentDetailFailure?.description,
        order_id: paymentDetailFailure?.metaData?.order_id,
        payment_id: paymentDetailFailure?.metaData?.payment_id,
        reason: paymentDetailFailure?.reason,
        source: paymentDetailFailure?.source,
        step: paymentDetailFailure?.step,
      };
      saveFailedPaymentDetailMutate(query);
    }
  }, [paymentDetailSuccess, paymentDetailFailure]);

  const renderGridItem = (value) => (
    <Grid item xs={2}>
      <Typography className="value_text">
        {IndianCurrency(Number(value))}
      </Typography>
    </Grid>
  );

  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="800px"
        title="Payment Details"
      >
        <DialogContent>
          <Typography
            align="center"
            sx={{ color: "secondary", fontSize: "18px", fontWeight: "bold" }}
          >
            Confirm the following payment details and Click on Pay:
          </Typography>
          <Grid container spacing={1} sx={{ p: 1, mt: 2 }}>
            <Grid item xs={2}>
              <TextFieldLabel title="Taxable amount" />
            </Grid>
            <Grid item xs={2}>
              <TextFieldLabel title="sgstRate" />
            </Grid>
            <Grid item xs={2}>
              <TextFieldLabel title="cgstRate" />
            </Grid>
            <Grid item xs={2}>
              <TextFieldLabel title="igstRate" />
            </Grid>
            <Grid item xs={2}>
              <TextFieldLabel title="taxAmount" />
            </Grid>
            <Grid item xs={2}>
              <TextFieldLabel title="totalAmount" />
            </Grid>
          </Grid>
          {data?.map((item, index) => {
            const amount =
              Number(item?.netOrderAmount ?? 0) -
              Number(item?.paidAmount ?? 0) -
              Number(item?.totalExtraDiscount ?? 0);
            const tax =
              ((Number(item?.sgstRate ?? 0) +
                Number(item?.cgstRate ?? 0) +
                Number(item?.igstRate ?? 0)) /
                100) *
              amount;
            const totalAmount = amount + tax;

            return (
              <Grid container spacing={1} sx={{ p: 1 }} key={index}>
                {renderGridItem(amount)}
                {renderGridItem(item?.sgstRate ?? 0)}
                {renderGridItem(item?.cgstRate ?? 0)}
                {renderGridItem(item?.igstRate ?? 0)}
                {renderGridItem(tax)}
                {renderGridItem(totalAmount ?? 0)}
              </Grid>
            );
          })}
          {data?.length === 0 && (
            <Typography align="center" sx={{ mt: 2, color: "royalblue" }}>
              No Product selected for payment
            </Typography>
          )}
          <Grid container spacing={1} sx={{ p: 1, mt: 2 }}>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: 600 }}>
                Total Paying Amount: {IndianCurrency(totalPayingAmount)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: 600 }}>
                Total Tax Amount: {IndianCurrency(totalTaxAmount)}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: 600 }}>
                Total Transaction Amount:{" "}
                {IndianCurrency(totalTransactionAmount)}
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {data?.length > 0 && (
            <LoadingButton
              loading={purchaseSessionOrderLoading}
              loadingIndicator="Loading..."
              variant="contained"
              color="secondary"
              size="large"
              onClick={openRazorpay}
            >
              Pay
            </LoadingButton>
          )}
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default RazorPayPaymentDialog;
