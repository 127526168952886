import {
  Box,
  Button,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import styled from "styled-components";
import { useRef, useState } from "react";
import "react-notifications/lib/notifications.css";

//import for our files
import { useReactToPrint } from "react-to-print";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  selectActiveSession,
  selectSelectedSchool,
} from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import { IndianCurrency, NumberInWord } from "app/utils/helper";
import dayjs from "dayjs";
import { getFeeInvoice } from "app/services/schoolService/newFee.service";
import CustomLoader from "../common/CustomLoader";
import { THEME } from "app/config/constants";
import { getArrearInvoice } from "app/services/schoolService/arrearService";

export default function ArrearInvoice() {
  const invoicePrintRef = useRef(null);
  const [data, setData] = useState({});
  const [invoiceItems, setInvoiceItems] = useState([]);
  const { invoiceId } = useParams();
  const [state, setState] = useState({
    school: true,
    student: true,
  });
  const selectedSchool = useSelector(selectSelectedSchool);
  const handlePrint = useReactToPrint({
    content: () => invoicePrintRef.current,
  });

  const {
    data: getArrearInvoiceData,
    isLoading: getArrearInvoiceLoading,
    refetch: getArrearInvoiceRefetch,
    isRefetching: getArrearInvoiceRefetching,
  } = useQuery({
    queryKey: ["getArrearInvoice"],
    queryFn: () =>
      getArrearInvoice({
        // invoiceDocId: invoiceId,
        invoiceNumber: String(invoiceId).replace(/ /g, "/"),
      }),
    onSuccess: (data) => {
      setData(data?.data?.obj);
      setInvoiceItems(data?.data?.obj?.invoiceItems);
    },
    onError: (res) => {
      toast.error(res?.response?.data?.message);
    },
  });
  const calculateGrandTotal = () => {
    let total = 0;
    invoiceItems?.forEach((item) => {
      total += item?.totalAmount;
    });
    return total;
  };

  const InvoiceDetail = ({ invoiceText }) => {
    return (
      <LeftSideDiv>
        <InvoiceHeader>
          <div
            style={{
              width: "100%",
            }}
          >
            <Grid container>
              <Grid item xs={2}>
                <img
                  src={selectedSchool?.schoolLogo}
                  style={{
                    height: "60px",
                    width: "60px",
                    objectFit: "contain",
                    marginLeft: "5px",
                  }}
                  alt="school-logo"
                />
              </Grid>
              <Grid item xs={10}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "16px",
                    fontWeight: 600,
                  }}
                >
                  {selectedSchool?.schoolName}
                </Typography>

                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "14px",
                    fontWeight: 500,
                  }}
                >
                  {`${selectedSchool?.schoolAddress?.address}, ${selectedSchool?.schoolAddress?.dist}, ${selectedSchool?.schoolAddress?.state}, ${selectedSchool?.schoolAddress?.pinCode}`}
                </Typography>

                <Typography
                  sx={{
                    textAlign: "center",
                    fontSize: "10px",
                    fontWeight: 500,
                  }}
                >
                  {selectedSchool?.schoolContactNumber}, {selectedSchool?.email}
                </Typography>
              </Grid>
            </Grid>
            <div
              style={{
                width: "100%",
                fontWeight: "600",
                fontSize: "16px",
                textTransform: "capitalize",
                textAlign: "center",
                color: "#FFFFFF",
                backgroundColor: "#0C4B7E",
              }}
            >
              {invoiceText} - {`${data?.session}`}
            </div>
          </div>
        </InvoiceHeader>
        <InvoiceBody>
          <Grid container sx={{ px: 1, pt: 0.5 }}>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                Invoice No.
              </Typography>
              <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                {data?.invoiceNumber}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                Invoice Date
              </Typography>
              <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                {dayjs(data?.createdOn).format("DD MMM, YYYY")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                Depositor Name
              </Typography>
              <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                {data?.depositorName}
              </Typography>
            </Grid>
            {/* student detail */}
            <StudentDetailBox>
              <Grid container spacing={1}>
                <Grid item xs={3}>
                  <img
                    src={
                      data?.studentDocId?.profilePic ?? "/image-placeholder.jpg"
                    }
                    style={{
                      height: "60px",
                      width: "60px",
                      objectFit: "contain",
                      marginLeft: "5px",
                    }}
                    alt="student-pic"
                  />
                </Grid>
                <Grid item xs={9}>
                  <Grid container spacing={1}>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                        Name :
                      </Typography>
                      <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                        {data?.studentDocId?.firstName}{" "}
                        {data?.studentDocId?.middleName}{" "}
                        {data?.studentDocId?.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                        Reg No :
                      </Typography>
                      <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                        {data?.studentDocId?.registrationNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                        Adm No :
                      </Typography>
                      <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                        {data?.studentDocId?.admissionNumber}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                        Class-Str-Sec :
                      </Typography>
                      <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                        {data?.className}-{data?.section}-{data?.stream}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                        Father Name :
                      </Typography>
                      <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                        {data?.studentDocId?.fatherInfo?.firstName}{" "}
                        {data?.studentDocId?.fatherInfo?.middleName}{" "}
                        {data?.studentDocId?.fatherInfo?.lastName}
                      </Typography>
                    </Grid>
                    <Grid item xs={4}>
                      <Typography sx={{ fontWeight: 600, fontSize: "10px" }}>
                        Mobile Number :
                      </Typography>
                      <Typography sx={{ fontWeight: 400, fontSize: "10px" }}>
                        {data?.studentDocId?.mobileNumber}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </StudentDetailBox>
          </Grid>

          <Box sx={{ background: "#ffaf0d", py: 1, px: 1 }}>
            <Grid container>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  Arrear Type
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  Discount
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  Paying
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                  Balance
                </Typography>
              </Grid>
            </Grid>
          </Box>
          {invoiceItems?.map((invoice, i) => (
            <Box key={i}>
              <Grid container sx={{ px: 1 }}>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    {invoice?.arrearsType}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    {IndianCurrency(invoice?.discountAmount)}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    {IndianCurrency(invoice?.transactionAmount)}
                  </Typography>
                  <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                    ({invoice?.paymentMode})
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: "14px", fontWeight: 600 }}>
                    {IndianCurrency(invoice?.balanceAmount)}
                  </Typography>
                </Grid>
              </Grid>
              {invoiceItems?.length !== i + 1 && (
                <Divider
                  sx={{ background: (theme) => theme.palette.primary.main }}
                />
              )}
            </Box>
          ))}
          {/* <Divider sx={{ background: (theme) => theme.palette.primary.main }} /> */}
          {/* <Grid container sx={{ px: 1 }}>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                Grand Total
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                {IndianCurrency(calculateGrandTotal())}
              </Typography>
            </Grid>
          </Grid> */}
          <Divider sx={{ background: (theme) => theme.palette.primary.main }} />
          {/* <Grid container sx={{ px: 1 }}> */}
          {/* <Grid item xs={4}>
              {calculateTotalDiscount() !== 0 && (
                <>
                  <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                    Total Discount
                  </Typography>
                  <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                    {IndianCurrency(calculateTotalDiscount())}
                  </Typography>
                </>
              )}
            </Grid> */}
          {/* <Grid item xs={4}>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                Total Fine
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                {IndianCurrency(calculateTotalFine())}
              </Typography>
            </Grid> */}
          {/* <Grid item xs={4}>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                Total Balance
              </Typography>
              <Typography sx={{ fontSize: "13px", fontWeight: 600 }}>
                {IndianCurrency(calculateTotalBalance())}
              </Typography>
            </Grid> */}
          {/* </Grid> */}
          {/* <Divider sx={{ background: (theme) => theme.palette.primary.main }} /> */}
          <Grid container sx={{ px: 1 }}>
            <Grid item xs={9}>
              <Typography sx={{ fontSize: "12px", fontWeight: 600 }}>
                Paid Amount : {NumberInWord(data?.totalPaidAmount) ?? "Zero"}{" "}
                Rupees Only
              </Typography>
            </Grid>
          </Grid>

          <Divider sx={{ background: (theme) => theme.palette.primary.main }} />
          <Stack direction="row" justifyContent="space-between" sx={{ px: 1 }}>
            <Box>
              <Typography sx={{ fontSize: "12px" }}>
                Created By : {data?.createdBy?.personName}
              </Typography>
              <Typography sx={{ fontSize: "12px" }}>
                Created On : {dayjs(data?.createdOn).format("DD MMM YYYY")}
              </Typography>
            </Box>
            <Box>
              <Typography sx={{ fontSize: "12px" }}>For,</Typography>
              <Typography sx={{ fontSize: "12px" }}>
                {data?.schoolName}
              </Typography>
              <Typography sx={{ fontSize: "12px", pt: 2, pb: 1 }}>
                Authorised Sign.
              </Typography>
            </Box>
          </Stack>
        </InvoiceBody>
      </LeftSideDiv>
    );
  };

  return (
    <>
      <CustomLoader
        show={getArrearInvoiceLoading || getArrearInvoiceRefetching}
      />
      <Container maxWidth="xl">
        <Stack
          direction={"row"}
          justifyContent={"center"}
          alignItems={"center"}
          gap={1}
        >
          <Stack
            direction={"row"}
            justifyContent={"center"}
            gap={1}
            sx={{
              background: "#fff",
              px: 2,
              py: 0.5,
              borderRadius: "10px",
              boxShadow: THEME.boxShadow1,
              mb: 1,
            }}
          >
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={state.school}
              onChange={() =>
                setState((prev) => ({ ...prev, school: !prev.school }))
              }
              label="School Invoice"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 600,
                },
              }}
            />
            <FormControlLabel
              control={<Checkbox color="secondary" />}
              checked={state.student}
              onChange={() =>
                setState((prev) => ({ ...prev, student: !prev.student }))
              }
              label="Student Invoice"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                  fontWeight: 600,
                },
              }}
            />
          </Stack>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              handlePrint();
            }}
          >
            Print
          </Button>
        </Stack>

        <Stack direction="row" sx={{ width: "60%", m: "auto" }}>
          <InvoiceDetail invoiceText={"Invoice"} />
        </Stack>
        <Box sx={{ display: "none" }}>
          <Stack direction="row" sx={{ p: 1 }} gap={0.5} ref={invoicePrintRef}>
            {state.school && <InvoiceDetail invoiceText={"School Invoice"} />}
            {state.student && <InvoiceDetail invoiceText={"Student Invoice"} />}
          </Stack>
        </Box>
      </Container>
    </>
  );
}

const LeftSideDiv = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
  border: 1px solid black;
  background-color: #fff;
`;

const InvoiceHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InvoiceBody = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;
const StudentDetailBox = styled.div`
  border-radius: 8px;
  border: 1px solid #d0d0e2;
  background: rgba(208, 208, 226, 0.08);
  padding: 10px;
  width: 100%;
  margin: 10px 0;
`;
