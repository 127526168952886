import { useSelector } from "react-redux";

import { sidebarItems } from "app/config/sidebar";
import {
  selectedInstituteTypeSelector,
  selectRoles,
} from "app/modules/auth/selectors";
import { useMemo } from "react";
import { sideBarOptionsSelector } from "app/modules/schools/selectors";

export const useSidebarMenuItems = () => {
  const roles = useSelector(selectRoles);
  const instituteType = useSelector(selectedInstituteTypeSelector);
  const sidebarOptions = useSelector(sideBarOptionsSelector);

  return useMemo(() => {
    // Initialize sidebarItems
    let modules_list = [];

    let final_sidebarItems = [];

    // if sidebarOptions is not empty
    if (sidebarOptions) {
      modules_list = Object.keys(sidebarOptions);
    }

    // return sidebarItems;
    return sidebarItems.reduce((final_sidebarItems, item) => {
      // Menu item
      const menuItem = { ...item };
      // check if subMenu is present
      if (item.subMenu && item.subMenu.length > 0) {
        menuItem.subMenu = item.subMenu.filter((subItem) => {
          // if module and screenName is present in sidebarOptions
          if (
            modules_list.includes(subItem.module) &&
            sidebarOptions[subItem.module].includes(subItem.screenName)
          ) {
            return subItem;
          }
        });

        final_sidebarItems.push(menuItem);
      } else {
        // if subMenu is not present
        if (modules_list.includes(item.module)) {
          // push meun item in final_sidebarItems
          final_sidebarItems.push(menuItem);
        }
      }
      return final_sidebarItems;
    }, []);
  }, [roles, instituteType]);
};
