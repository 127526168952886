import API_ENDPOINTS from "app/config/endpoints";
import { schoolTokenAxios } from "../axios";

export const getTransportFeeStructure = (session) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.transport.getFeeStructure}`, {
    params: { session: session },
  });
};
export const getAllRouteList = (payload) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.transport.getAllRouteList}`);
};
export const getTransport = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.transport.getTransport}`, {
    params,
  });
};
export const getInactiveTransport = (payload) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.transport.getTransport}`, {
    params: { status: "IN_ACTIVE" },
  });
};

export const addTransportDetail = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.transport.addTransportDetail}`,
    payload
  );
};
export const updateTransportDetail = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.transport.updateTransportDetail}`,
    payload
  );
};
export const updateStatusOfTransport = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.transport.updateStatusOfTransport}`,
    payload
  );
};

export const editFeeStructureLogic = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.transport.editFeeStructure}`,
    payload
  );
};
export const addFeeStructureLogic = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.transport.AddFeeStructure}`,
    payload
  );
};

export const addRouteDetail = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.transport.addRouteDetail}`,
    payload
  );
};
export const updateTransportRoute = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.transport.updateTransportRoute}`,
    payload
  );
};

export const replaceVehicle = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.transport.replaceVehicle}`,
    payload
  );
};
export const deleteTransportRoute = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.transport.deleteTransportRoute}/${id}`
  );
};
export const deleteTransportFeeStructure = (id) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.transport.deleteTransportFeeStructure}/${id}`
  );
};
