import Fallback from "app/components/Fallback";
import React from "react";

const WebsitePopup = React.lazy(() =>
  import("app/modules/website-builder/WebsitePopup")
);
const WebsiteSlider = React.lazy(() =>
  import("app/modules/website-builder/WebSiteSlider")
);
const Achievements = React.lazy(() =>
  import("app/modules/website-builder/Achievements")
);
const AlumniAchiever = React.lazy(() =>
  import("app/modules/website-builder/AlumniAchiever")
);
const ChiefGuest = React.lazy(() =>
  import("app/modules/website-builder/ChiefGuest")
);
const Founder = React.lazy(() => import("app/modules/website-builder/Founder"));
const SchoolTopper = React.lazy(() =>
  import("app/modules/website-builder/SchoolTopper")
);
const SportAchiever = React.lazy(() =>
  import("app/modules/website-builder/SportAchiever")
);
const ClassPhoto = React.lazy(() =>
  import("app/modules/website-builder/ClassPhoto")
);
const SchoolTeam = React.lazy(() =>
  import("app/modules/website-builder/SchoolTeam")
);
const SchoolGallery = React.lazy(() =>
  import("app/modules/website-builder/SchoolGallery")
);
const ThoughtOfTheDay = React.lazy(() =>
  import("app/modules/website-builder/ThoughtOfTheDay")
);
export const ThoughtOfTheDayPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <ThoughtOfTheDay {...props} />
  </React.Suspense>
);
export const SchoolGalleryPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SchoolGallery {...props} />
  </React.Suspense>
);
export const SchoolTeamPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SchoolTeam {...props} />
  </React.Suspense>
);
export const WebsiteSliderPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <WebsiteSlider {...props} />
  </React.Suspense>
);
export const ClassPhotoPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <ClassPhoto {...props} />
  </React.Suspense>
);
export const WebsitePopupPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <WebsitePopup {...props} />
  </React.Suspense>
);
export const AchievementsPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Achievements {...props} />
  </React.Suspense>
);
export const AlumniAchieverPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <AlumniAchiever {...props} />
  </React.Suspense>
);
export const ChiefGuestPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <ChiefGuest {...props} />
  </React.Suspense>
);
export const FounderPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Founder {...props} />
  </React.Suspense>
);
export const SchoolTopperPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SchoolTopper {...props} />
  </React.Suspense>
);
export const SportAchieverPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SportAchiever {...props} />
  </React.Suspense>
);
