import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

const chart = {
  border: "1px solid #333",
  borderRadius: "10px",
  background: "#fff",
};

export default function Chart({ type, data }) {
  let changedData = [];
  if (type === "collection") {
    changedData = data?.map((item) => {
      return {
        name: item?.collectionHeadName
          ? item?.collectionHeadName?.slice(0, 10)
          : "--",
        amount: item?.totalAmount || 0,
      };
    });
  } else {
    changedData = data?.map((item) => {
      return {
        name: item?.expenseHeadName
          ? item?.expenseHeadName?.slice(0, 10)
          : "--",
        amount: item?.totalAmount || 0,
      };
    });
  }

  return (
    <BarChart
      width={window.innerWidth - 300}
      height={500}
      data={changedData}
      margin={{
        top: 50,
        right: 50,
        left: 50,
        bottom: 30,
      }}
      barSize={25}
      style={chart}
    >
      <CartesianGrid vertical={false} />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />

      <Bar
        dataKey="amount"
        fill={type === "collection" ? "green" : "red"}
        radius={[10, 10, 0, 0]}
      />
    </BarChart>
  );
}
