import { Box, Grid, Stack, Typography } from "@mui/material";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import React from "react";
import { useSelector } from "react-redux";
import EmailIcon from "@mui/icons-material/Email";

const SchoolPrintHeaderForBulkPrint = () => {
  const selectedSchool = useSelector(selectSelectedSchool);

  return (
    <Stack
      direction={"row"}
      sx={{
        p: 1,
      }}
    >
      <Stack direction={"row"} gap={2}>
        <Box
          sx={{
            ml: 1,
            border: "1px solid #000000",
            height: "75px",
            width: "100px",
          }}
        >
          <img
            src={selectedSchool?.schoolLogo}
            style={{
              height: "100%",
              width: "100%",
              objectFit: "contain",
            }}
            alt="school-logo"
          />
        </Box>
        <Grid container>
          <Grid item xs={12}>
            <Stack gap={1}>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "16px",
                  textDecoration: "underline",
                }}
              >
                {selectedSchool?.schoolName}
              </Typography>
              <Typography
                sx={{
                  fontWeight: "600",
                  fontSize: "11px",
                }}
              >
                {selectedSchool?.schoolAddress?.address}
                <br />
                {selectedSchool?.schoolAddress?.dist},{" "}
                {selectedSchool?.schoolAddress?.state},{" "}
                {selectedSchool?.schoolAddress?.pinCode}
                {/* <br />
            UDISE Code: {selectedSchool?.udiseCode} */}
              </Typography>
            </Stack>
          </Grid>
          <Grid container>
            <Grid item xs={4}>
              <Stack direction={"row"}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "10px",
                  }}
                >
                  Website :{" "}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "10px",
                  }}
                >
                  {selectedSchool?.website}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    fontSize: "10px",
                  }}
                >
                  Phone No.{"  "}:{" "}
                </Typography>
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "10px",
                  }}
                >
                  {selectedSchool?.schoolContactNumber}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <Stack direction={"row"} gap={1} alignItems={"center"}>
                <EmailIcon
                  sx={{
                    fontSize: "10px",
                  }}
                />
                <Typography
                  sx={{
                    fontWeight: "600",
                    fontSize: "10px",
                  }}
                >
                  {selectedSchool?.email}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
};

export default SchoolPrintHeaderForBulkPrint;
