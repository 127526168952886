import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;
let school = `${SCHOOL_BASE}/school`;
let schoolSettings = `${SCHOOL_BASE}/schoolSettings`;

export const updateStatusOfLegal = (body) => {
  return schoolTokenAxios.put(`${school}/updateStatusOfLegal`, body);
};
export const updateDefaultFeeInvoice = (body) => {
  return schoolTokenAxios.put(
    `${schoolSettings}/updateDefaultFeeInvoice`,
    body
  );
};

export const getFeeReminderTemplate = (params) => {
  return schoolTokenAxios.get(`${schoolSettings}/getFeeReminderTemplate`, {
    params,
  });
};

export const getSMSTemplate = (params) => {
  return schoolTokenAxios.get(`${schoolSettings}/getSMSTemplate`, {
    params,
  });
};
