import React, { useState } from "react";
import CommonDialog from "../CommonDialog";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  DialogActions,
  DialogContent,
  MenuItem,
  Stack,
  Tab,
  Typography,
  styled,
} from "@mui/material";
import { LoadingButton, TabContext, TabList, TabPanel } from "@mui/lab";
import {
  CustomSelectBox,
  CustomTextField,
  TabsWrapper,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { useMutation, useQuery } from "react-query";
import {
  addCollectionHeadLogic,
  addCollectionSubHead,
  getAllCollectionHead,
} from "app/services/ledgerAccount";
import { toast } from "react-toastify";
import CustomLoader from "../../CustomLoader";
const AddCollectionHeadDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
}) => {
  const [state, setState] = useState({
    cHeads: null,
  });
  const [value, setValue] = useState("1");
  const addCSubHeadformik = useFormik({
    initialValues: {
      name: "",
      sName: "",
      desc: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Head name is required"),
      sName: yup.string().required("Sub Head name is required"),
      desc: yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      const obj = {
        collectionHeadDocId: values.name,
        collectionSubHeadName: values.sName,
        description: values.desc,
      };
      addCollectionSubHeadMutate(obj);
    },
  });
  const addCHeadformik = useFormik({
    initialValues: {
      name: "",
    },
    validationSchema: yup.object({
      name: yup.string().required("Head name is required"),
    }),
    onSubmit: (values) => {
      createCollectionHead({ collectionHeadName: values.name });
    },
  });
  const handleClose = () => {
    onClose();
    addCHeadformik.resetForm();
    addCSubHeadformik.resetForm();
  };
  const { mutate: createCollectionHead, isLoading: creatingCollectionHead } =
    useMutation(addCollectionHeadLogic, {
      onSuccess: (res) => {
        refetch();
        toast.success(res?.data?.message);
        handleClose();
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    });
  const {
    mutate: addCollectionSubHeadMutate,
    isLoading: addCollectionSubHeadLoading,
  } = useMutation(addCollectionSubHead, {
    onSuccess: (res) => {
      refetch();
      toast.success(res?.data?.message);
      handleClose();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const { isLoading: getAllCollectionHeadLoading } = useQuery({
    queryKey: ["getAllCollectionHead"],
    queryFn: () => getAllCollectionHead(),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        cHeads: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.collectionHeadName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: open ? true : false,
  });
  return (
    <>
      <CustomLoader show={getAllCollectionHeadLoading} />
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="400px"
        title="Add Collection Head"
      >
        <DialogContent>
          <TabContext value={value}>
            <TabsWrapper>
              <TabList
                onChange={(e, newValue) => setValue(newValue)}
                aria-label="basic tabs example"
              >
                <Tab label="Collection Sub Head" value="1" />
                <Tab label="Collection Head" value="2" />
              </TabList>
            </TabsWrapper>
            <TabPanel value="1" sx={{ p: "0 !important" }}>
              <form id="c-sub-head" onSubmit={addCSubHeadformik.handleSubmit}>
                <Stack mt={2}>
                  <Label>Collection Head Name</Label>
                  <CustomSelectBox
                    name="name"
                    MenuProps={MenuProps}
                    onBlur={addCSubHeadformik.handleBlur}
                    displayEmpty
                    value={addCSubHeadformik.values.name}
                    onChange={addCSubHeadformik.handleChange}
                  >
                    <MenuItem value="">Select</MenuItem>
                    {state?.cHeads?.map((item) => (
                      <MenuItem value={item.id}>{item.name}</MenuItem>
                    ))}
                  </CustomSelectBox>
                  {addCSubHeadformik.touched.name &&
                    addCSubHeadformik.errors.name && (
                      <Typography sx={{ color: "red" }}>
                        {addCSubHeadformik.errors.name}
                      </Typography>
                    )}
                </Stack>
                <Stack mt={2}>
                  <Label>Sub Head Name</Label>
                  <CustomTextField
                    name="sName"
                    placeholder="Enter SubHead name"
                    onBlur={addCSubHeadformik.handleBlur}
                    value={addCSubHeadformik.values.sName}
                    onChange={addCSubHeadformik.handleChange}
                  />
                  {addCSubHeadformik.touched.sName &&
                    addCSubHeadformik.errors.sName && (
                      <Typography sx={{ color: "red" }}>
                        {addCSubHeadformik.errors.sName}
                      </Typography>
                    )}
                </Stack>
                <Stack mt={2}>
                  <Label>Description</Label>
                  <CustomTextField
                    name="desc"
                    placeholder="Enter Description"
                    onBlur={addCSubHeadformik.handleBlur}
                    value={addCSubHeadformik.values.desc}
                    onChange={addCSubHeadformik.handleChange}
                  />
                  {addCSubHeadformik.touched.desc &&
                    addCSubHeadformik.errors.desc && (
                      <Typography sx={{ color: "red" }}>
                        {addCSubHeadformik.errors.desc}
                      </Typography>
                    )}
                </Stack>
              </form>
            </TabPanel>
            <TabPanel value="2" sx={{ p: "0 !important" }}>
              <form id="add-cHead" onSubmit={addCHeadformik.handleSubmit}>
                <Stack mt={2}>
                  <Label>Collection Head Name</Label>
                  <CustomTextField
                    name="name"
                    placeholder="Enter Head Name"
                    onBlur={addCHeadformik.handleBlur}
                    value={addCHeadformik.values.name}
                    onChange={addCHeadformik.handleChange}
                  />
                  {addCHeadformik.touched.name &&
                    addCHeadformik.errors.name && (
                      <Typography sx={{ color: "red" }}>
                        {addCHeadformik.errors.name}
                      </Typography>
                    )}
                </Stack>
              </form>
            </TabPanel>
          </TabContext>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            type="submit"
            loading={
              value === "2"
                ? creatingCollectionHead
                : addCollectionSubHeadLoading
            }
            form={value === "2" ? "add-cHead" : "c-sub-head"}
            variant="contained"
            color="secondary"
            size="large"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddCollectionHeadDialog;
const Label = styled(Typography)(({ theme }) => ({
  fontSize: "1rem",
  lineHeight: "1.2rem",
  marginBottom: "10px",
  fontWeight: 600,
  color: "#0C2F49",
}));
