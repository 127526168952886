import React, { useState, useEffect } from "react";
import {
  Typography,
  Modal,
  Box,
  Button,
  Drawer,
  Card,
  MenuItem,
  Stack,
} from "@mui/material";
import { Formik, Form, Field, useFormikContext } from "formik";
import styled from "styled-components";

import { X } from "react-bootstrap-icons";
import * as Yup from "yup";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

//import for our files
import TextFieldComponent from "./FormUi/TextfieldComponent";
import SelectComponent from "./FormUi/SelectComponent";
import MultipleSelectComponent from "./FormUi/MuiltipleSelectComponent";
import { FeeIncrementTypeUnit } from "../../../../config/administrativeConstants";
import {
  CategoryForFee,
  GenderChoice,
} from "../../../../config/administrativeConstants";
import RadioComponent from "./FormUi/RadioComponent";
import FieldArrayComponent from "./FormUi/FieldArrayComponent";
import { addNewFeeStructure } from "../../../../services/management/index";
import {
  selectedMediumSelector,
  selectActiveSession,
} from "app/modules/schools/selectors";
import { mobile } from "app/modules/reception/components/responsive";
import { LoadingButton } from "@mui/lab";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import {
  FeeRelaxationCategoryList,
  FeeTypeList,
} from "app/config/ledger.constant";
import { toast } from "react-toastify";
import DialogHeader from "app/components/common/DialogHeader";

const INITIAL_STATE = {
  session: "",
  feeType: "",
  category: "",
  feeAmount: "",
  feeRelaxationCategory: "",
  otherFeeRelaxationCategory: "",
  dueDay: "",
  fineIncrementUnit: "",
  fineIncrementUnitType: "",
  fineRate: "",
  class: [],
  feeDetails: [
    {
      feeCategory: "",
      description: "",
      amount: "",
    },
  ],
};

const VALIDATION_SCHEMA = Yup.object().shape({
  feeType: Yup.string().required("Required"),
  category: Yup.string().required("Required"),
  feeRelaxationCategory: Yup.string().required("Required"),
  otherFeeRelaxationCategory: Yup.string().when("feeRelaxationCategory", {
    is: "Other",
    then: Yup.string().required("Other Fee Relaxation required"),
  }),
  dueDay: Yup.number().typeError("Enter Numbers only").required("Required"),
  fineIncrementUnit: Yup.string().required("Required"),
  fineRate: Yup.number().typeError("Enter Numbers only").required("Required"),
  feeDetails: Yup.array().of(
    Yup.object().shape({
      feeCategory: Yup.string().required("Fee Category required"),
      description: Yup.string().required("Fee Category required"),
      amount: Yup.number()
        .typeError("Enter Numbers only")
        .required("Amount required"),
    })
  ),
});

export default function AddNewFeeModal({
  show,
  setShow,
  classOptions,
  classStreamObj,
  isEditing,
  editingValues,
  fetchFeeStructure,
}) {
  const [radioValues, setRadioValues] = useState("");
  const session = useSelector(selectActiveSession);

  const {
    mutate,
    isLoading: addingFeeStructure,
    error,
    isSuccess: SuccessAdd,
    data,
    isError: feeStructureError,
  } = useMutation(addNewFeeStructure, {
    onSuccess: (data) => {
      toast.success(data?.data?.message);
      setShow(false);
      fetchFeeStructure();
    },

    onError: (error) => {
      toast.error(error?.response.data?.message);
    },
  });

  // let finalClassValueObj = [];
  const handleClose = () => {
    setShow(false);
  };

  const addClassDocIdToClassName = (values, setStatus) => {
    let tempArray = [];
    values?.map((obj) => {
      classStreamObj?.map((option) => {
        if (option?.classStreamSection === obj) {
          const newElement = {
            section: option?.section,
            classDocId: option?.classDocId,
          };
          tempArray.push(newElement);
        }
      });
    });

    return tempArray;
  };

  const handleAddFeeStructure = (obj) => {
    mutate(obj);
  };

  // useEffect(() => {
  //   if (SuccessAdd) {
  //   } else if (feeStructureError) {
  //   }
  // }, [SuccessAdd, feeStructureError, addingFeeStructure, data]);

  return (
    <ModalWrapper>
      <Drawer
        open={show}
        onClose={handleClose}
        anchor="right"
        PaperProps={{
          sx: { minWidth: { xs: "80vw", sm: "60vw" } },
        }}
      >
        <Box sx={{ mb: 1 }}>
          <DialogHeader
            title={`Fee Details: ${session}`}
            handleClose={handleClose}
          />
        </Box>

        <Formik
          initialValues={
            isEditing
              ? { ...editingValues }
              : {
                  ...INITIAL_STATE,
                }
          }
          validationSchema={VALIDATION_SCHEMA}
          onSubmit={(values, { resetForm }) => {
            let sum = 0;
            values.feeDetails?.map((detail) => {
              sum += parseInt(detail.amount);
            });

            if (values.fineIncrementUnit === "Fixed Amount") {
              values.fineIncrementUnitType = "";
            }

            const feeIncrementTypeUnit =
              values.fineIncrementUnit === "Fixed Amount"
                ? FeeIncrementTypeUnit.FixedAmount
                : values.fineIncrementUnitType === "Fixed Rate/day"
                ? FeeIncrementTypeUnit.PerDayFixedRate
                : FeeIncrementTypeUnit.PerDayPercent;

            const newFeeStructure = {
              session: session,
              feeType: values.feeType,
              category: values.category,
              feeAmount: parseInt(sum),
              feeRelaxationCategory:
                values.feeRelaxationCategory === "Other"
                  ? values.otherFeeRelaxationCategory
                  : values.feeRelaxationCategory,
              dueDay: parseInt(values.dueDay),
              fineIncreamentUnit: feeIncrementTypeUnit,
              fineRate: parseInt(values.fineRate),
              classList: addClassDocIdToClassName(values.class),
              feeDetails: values.feeDetails,
            };

            handleAddFeeStructure(newFeeStructure);
            if (SuccessAdd) {
              resetForm();
            }
          }}
        >
          {({ values, handleSubmit, errors }) => {
            return (
              <Form>
                <FormWrapper>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    gap={1}
                    sx={{ width: "100%" }}
                  >
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <SelectComponent
                        name="feeType"
                        label="Fee Type"
                        options={FeeTypeList}
                      />
                    </div>
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <SelectComponent
                        name="category"
                        label="Caste Category"
                        options={CategoryForFee}
                      />
                    </div>
                    <div
                      style={{
                        flex: 1,
                      }}
                    >
                      <SelectComponent
                        name="feeRelaxationCategory"
                        label="Relaxation Category"
                        options={FeeRelaxationCategoryList}
                      />
                    </div>
                    {values?.feeRelaxationCategory === "Other" && (
                      <div
                        style={{
                          flex: 1,
                        }}
                      >
                        <TextFieldComponent
                          name="otherFeeRelaxationCategory"
                          label="Other Relaxation Category"
                        />
                      </div>
                    )}
                  </Stack>
                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    sx={{ width: "100%" }}
                  >
                    <Box sx={{ width: { xs: "100%", sm: "200px" } }}>
                      <Typography className="modal_textfield__label">
                        Class-Stream
                      </Typography>
                      <MultipleSelectComponent
                        displayEmpty
                        name="class"
                        // label="Class Stream Section"
                        classOptions={classOptions}
                      />
                    </Box>
                  </Stack>

                  <Stack
                    direction={{ xs: "column", sm: "row" }}
                    justifyContent="space-between"
                    gap={1}
                    sx={{
                      width: "100%",
                      marginTop: "15px",
                    }}
                  >
                    <Box
                      sx={{
                        width: { xs: "100%", sm: "30%" },
                      }}
                    >
                      <TextFieldComponent name="dueDay" label="Due Day" />
                    </Box>
                    <Box sx={{ marginTop: { sm: "-15px" } }}>
                      <Typography className="modal_textfield__label">
                        Fine Rate
                      </Typography>
                      <RadioComponent
                        name="fineIncrementUnit"
                        label="fineIncrementUnit"
                        options={["Fixed Amount", "Per Day"]}
                        setRadioValues={setRadioValues}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      />
                    </Box>
                    {radioValues === "Fixed Amount" ? (
                      ""
                    ) : (
                      <Box
                        style={{
                          width: { xs: "100%", sm: "22%" },
                        }}
                      >
                        <SelectComponent
                          fullWidth
                          name="fineIncrementUnitType"
                          label="Rate/day"
                          options={["Fixed Rate/day", "Percentage/day"]}
                        />
                      </Box>
                    )}
                    <Box
                      sx={{
                        width: { xs: "100%", sm: "20%" },
                      }}
                    >
                      <TextFieldComponent
                        name="fineRate"
                        label="Fine Rate"
                        fullWidth
                      />
                    </Box>
                  </Stack>

                  <FeeDetailsWrapper>
                    <Typography className="modal_textfield__label">
                      Fee Details
                    </Typography>
                    <FieldArrayComponent name="feeDetails" />
                  </FeeDetailsWrapper>

                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "end",
                      gap: "15px",
                    }}
                  >
                    <LoadingButton
                      type="submit"
                      variant="contained"
                      loading={addingFeeStructure}
                    >
                      Submit
                    </LoadingButton>

                    <Button variant="outlined" onClick={handleClose}>
                      Cancel
                    </Button>
                  </div>
                </FormWrapper>
              </Form>
            );
          }}
        </Formik>
      </Drawer>
    </ModalWrapper>
  );
}

const ModalWrapper = styled.div`
  top: 20vh;
  overflow: auto;
`;

const FeeDetailsWrapper = styled.div`
  width: 100%;
  border: 1px solid #b6c8d6;
  border-radius: 15px;
  padding: 2%;
  margin-top: 2%;
  margin-bottom: 2%;
`;

const FormWrapper = styled.div`
  padding: 0 10px;
  color: #0c2f49;
  .modal_textfield__label {
    margin-top: 10px;
    margin-bottom: 3px;
    font-size: 14px;
    font-weight: 500;
  }
`;
