import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import CustomLoader from "app/components/common/CustomLoader";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import TableActionButtons from "app/components/common/TableActionButtons";

import {
  deleteFeeStructureGroup,
  getFeeStructureGroup,
} from "app/services/management";
import React, { useMemo, useRef, useState } from "react";
import { useMutation, useQuery } from "react-query";
import AddGroupFeeStructureDialog from "./AddGroupFeeStructureDialog";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";
import { IndianCurrency } from "app/utils/helper";
import * as XLSX from "xlsx";

import ViewGroupedFeeStructure from "./ViewGroupedFeeStructure";
import DialogHeader from "app/components/common/DialogHeader";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";

const GroupedView = () => {
  const [state, setState] = useState({
    viewDialog: false,
    deleteDialog: false,
    classDocId: "",
    feeStructureDocId: "",
  });
  const selectedSession = useSelector(selectActiveSession);
  const heightRef = useRef(null);
  const columns = useMemo(
    () => [
      {
        Cell: ({ cell }) =>
          Number(cell.row.id) + 1 < 10
            ? `0${Number(cell.row.id) + 1}`
            : Number(cell.row.id) + 1,
        header: "Sr.No",
        maxSize: 10,
      },
      {
        accessorKey: "className",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>{`${original?.className}-${original?.stream}-${original?.section}`}</Typography>
        ),
        header: "Class-Stream-Section",
        maxSize: 20,
      },
      {
        accessorFn: (row) => row?.feeType,
        accessorKey: "feeType",
        header: "Fee Type",
        maxSize: 20,
      },
      {
        accessorKey: "studentAdmissionType",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.studentAdmissionType === "newStudent"
              ? "New Student"
              : original?.studentAdmissionType === "oldStudent"
              ? "Old Student"
              : "All Student"}
          </Typography>
        ),
        header: "Admission Type",
        maxSize: 20,
      },
      {
        accessorKey: "gender",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.gender === "allGender" ? "All" : original?.gender}
          </Typography>
        ),
        header: "Gender",
        maxSize: 20,
      },
      {
        accessorKey: "generalFeeAmount",
        accessorFn: (row) => IndianCurrency(row?.generalFeeAmount),
        header: "General Fee Amount",
        maxSize: 20,
      },

      {
        accessorKey: "feeOccurrence",
        header: "Fee Occurrence",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.feeOccurrence === "recurring" ? "Recurring" : "One Time"}
          </Typography>
        ),
        maxSize: 20,
      },
      {
        accessorKey: "createdBy.personName",
        accessorFn: (row) => row?.createdBy?.personName,
        header: "Created By",
        maxSize: 20,
      },

      {
        header: "Action",
        maxSize: 10,
        Cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <TableActionButtons
              showView
              showDelete
              handleDelete={() => handleOpenDeleteDialog(original?._id)}
              // handleDelete={() => deleteFeeStructureGroupMutate(original?._id)}
              handleView={() =>
                setState((prev) => ({
                  ...prev,
                  viewDialog: true,
                  classDocId: original?.classDocId,
                }))
              }
            />
          );
        },
      },
    ],
    []
  );

  const {
    isLoading: getFeeStructureGroupLoading,
    isFetching: getFeeStructureGroupFetching,
    refetch: getFeeStructureGroupRefetch,
    data: getFeeStructureGroup_List,
  } = useQuery({
    queryKey: ["getFeeStructureGroup", selectedSession],
    queryFn: () => getFeeStructureGroup({ session: selectedSession }),
    onSuccess: (success) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const {
    mutate: deleteFeeStructureGroupMutate,
    isLoading: deleteFeeStructureGroupLoading,
  } = useMutation(deleteFeeStructureGroup, {
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      getFeeStructureGroupRefetch();
      handleCloseDeleteDialog();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const handleOpenDeleteDialog = (data) => {
    setState((prev) => ({
      ...prev,
      feeStructureDocId: data,
      deleteDialog: true,
    }));
  };
  const handleCloseDeleteDialog = () => {
    setState((prev) => ({
      ...prev,
      deleteDialog: false,
      feeStructureDocId: "",
    }));
  };
  const handledeleteFeeStructure = () => {
    deleteFeeStructureGroupMutate(state.feeStructureDocId);
  };
  let header = [
    "#",
    "ClassName",
    "Fee_Type",
    "Admission_Type",
    "Gender",
    "General_Fee_Amount",
    "Fee_Occurrence",
    "Status",
  ];
  const [selectedOptions, setSelectedOptions] = useState(header);
  const [openCheckBox, setOpenCheckBox] = useState(false);

  let exportArray = getFeeStructureGroup_List?.data?.list?.map(
    (item, index) => {
      return {
        "#": index < 9 ? `0${index + 1}` : index + 1,
        ClassName: `${item?.className}-${item?.stream}-${item?.section}`,
        Fee_Type: item?.feeType,
        Admission_Type: item?.studentAdmissionType,
        Gender: item?.gender,
        General_Fee_Amount: item?.generalFeeAmount,
        Fee_Occurrence: item?.feeOccurrence,
        Status: item?.status,
      };
    }
  );

  const handleExport = () => {
    const filteredExportArray = exportArray?.map((item) => {
      const filteredItem = {};
      selectedOptions.forEach((option) => {
        filteredItem[option] = item[option];
      });
      return filteredItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredExportArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Grouped Fee Structure");
    XLSX.writeFile(workbook, "GroupedFeeStructure.xlsx");
  };
  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };

  return (
    <>
      <CustomLoader
        show={
          getFeeStructureGroupLoading ||
          getFeeStructureGroupFetching ||
          deleteFeeStructureGroupLoading
        }
      />

      {/* Delete Dialog Confirmation */}
      <DeleteDialog
        open={state.deleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleDelete={handledeleteFeeStructure}
        loading={deleteFeeStructureGroupLoading}
      />

      <Dialog
        open={openCheckBox}
        onClose={() => setOpenCheckBox(false)}
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          title="Select Information to show in the excel file"
          handleClose={() => setOpenCheckBox(false)}
        />
        <DialogContent>
          <Box>
            {header.map((option) => (
              <Stack direction="row">
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      onChange={(e) =>
                        handleCheckboxChange(option, e.target.checked)
                      }
                    />
                  }
                  label={option}
                />
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="primary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              setSelectedOptions(header);
              handleExport();
              setOpenCheckBox(false);
            }}
          >
            DownLoad
          </Button>
        </DialogActions>
      </Dialog>

      {/* view dialog */}
      <ViewGroupedFeeStructure
        classDocId={state.classDocId}
        handleClose={() =>
          setState((prev) => ({ ...prev, viewDialog: false, classDocId: "" }))
        }
        open={state.viewDialog}
        refetch={() => getFeeStructureGroupRefetch()}
      />
      {/* add new fee group dialog */}
      <AddGroupFeeStructureDialog
        refetch={() => getFeeStructureGroupRefetch()}
        showDialog={state.dialog}
        onClose={() => setState((prev) => ({ ...prev, dialog: false }))}
      />
      <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
        {/* filter, add new or export buttons */}
        <Stack
          direction={"row"}
          alignItems={"center"}
          gap={2}
          justifyContent={"flex-end"}
          py={1.5}
        >
          <Button
            onClick={() => setState((prev) => ({ ...prev, dialog: true }))}
            size="large"
            variant="outlined"
            color="secondary"
            sx={{ backgroundColor: "#fff", py: 1, borderRadius: "10px" }}
          >
            + New Fee Structure Group
          </Button>
          <Box sx={{ mt: "-10px" }}>
            <ExportButtonGroup
              handleClickExcel={() => setOpenCheckBox(true)}
              showPdf={false}
            />
          </Box>
        </Stack>
        <Box sx={{ height: "90%" }} ref={heightRef}>
          <CommonTable1
            columns={columns}
            data={getFeeStructureGroup_List?.data?.list}
            maxHeight={`${heightRef?.current?.clientHeight - 70}px`}
          />
        </Box>
      </Box>
    </>
  );
};

export default GroupedView;
