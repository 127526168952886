import { Stack, Typography } from "@mui/material";
import { FormatFullName } from "app/utils/helper";
import moment from "moment";
import React, { useState } from "react";
import styled from "styled-components";

const StyledTypography = styled(Typography)`
  text-align: justify;
  align-items: center;
  font-family: "Play", sans-serif;
`;

const StyledSpan = styled.span`
  font-weight: 700;
  font-size: 20px;
  font-family: "Play", sans-serif;
`;

export const CertificateHeaderDetailsA4 = ({
  label,
  value,
  showAdm = true,
  showSrNo = true,
  showRegNo = true,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      sx={{
        width: "100%",
        display: "flex",
        alingself: "center",
        flex: 1,
        padding: "8px",
      }}
    >
      {showAdm && (
        <Typography
          sx={{
            fontSize: "16px",
          }}
          style={{ fontFamily: "monospace" }}
        >
          Adm. No:{" "}
          <span style={{ fontWeight: 700 }}>
            {value?.admissionNumber ?? "--"}
          </span>
        </Typography>
      )}
      {showSrNo && (
        <Typography
          sx={{
            fontSize: "16px",
          }}
          style={{ fontFamily: "monospace" }}
        >
          SR No:{" "}
          <span style={{ fontWeight: 700 }}>{value?.srNumber ?? "--"}</span>
        </Typography>
      )}
      {showRegNo && (
        <Typography
          sx={{
            fontSize: "16px",
          }}
          style={{ fontFamily: "monospace" }}
        >
          Reg. No.:{" "}
          <span style={{ fontWeight: 700 }}>
            {value?.registrationNumber ?? "--"}
          </span>
        </Typography>
      )}
    </Stack>
  );
};

export const CertificateHeaderDetails = ({
  label,
  value,
  showAdm = true,
  showSrNo = true,
  showRegNo = true,
}) => {
  return (
    <Stack
      direction="row"
      justifyContent={"space-between"}
      marginTop={2}
      sx={{
        width: "88%",
        display: "flex",
        alingself: "center",
        flex: 1,
        marginLeft: "6%",
      }}
    >
      {showAdm && (
        <Typography className="content" style={{ fontFamily: "monospace" }}>
          Admission No:{" "}
          <span style={{ fontWeight: 700 }}>
            {value?.admissionNumber ?? "--"}
          </span>
        </Typography>
      )}
      {showSrNo && (
        <Typography className="content" style={{ fontFamily: "monospace" }}>
          SR No:{" "}
          <span style={{ fontWeight: 700 }}>{value?.srNumber ?? "--"}</span>
        </Typography>
      )}
      {showRegNo && (
        <Typography className="content" style={{ fontFamily: "monospace" }}>
          Registration No.:{" "}
          <span style={{ fontWeight: 700 }}>
            {value?.registrationNumber ?? "--"}
          </span>
        </Typography>
      )}
    </Stack>
  );
};

export const CertificateBottomDetailsA4 = ({}) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      gap={"55%"}
      alignItems={"center"}
      sx={{}}
    >
      <Typography
        sx={{
          fontSize: "16px",
        }}
        style={{
          fontFamily: "monospace",
        }}
      >
        .........
        <br />
        <span style={{ alignSelf: "center", marginLeft: 15 }}>Date</span>
      </Typography>
      <Typography
        style={{
          fontFamily: "monospace",
        }}
        sx={{
          fontSize: "16px",
        }}
      >
        ...........
        <br />
        <span style={{ alignSelf: "center", marginLeft: 5 }}>(Principal)</span>
      </Typography>
    </Stack>
  );
};
export const CertificateBottomDetails = ({}) => {
  return (
    <Stack
      direction="row"
      justifyContent="center"
      gap={"60%"}
      alignItems={"center"}
      sx={{}}
    >
      <Typography
        className="content"
        style={{
          fontFamily: "monospace",
        }}
      >
        .........
        <br />
        <span style={{ alignSelf: "center", marginLeft: 15 }}>Date</span>
      </Typography>
      <Typography
        style={{
          fontFamily: "monospace",
        }}
        className="content"
      >
        ...........
        <br />
        <span style={{ alignSelf: "center", marginLeft: 5 }}>(Principal)</span>
      </Typography>
    </Stack>
  );
};

const styles = {
  content: {
    fontSize: "18px",
    alingself: "center",
  },
};

const CustomInput = ({
  type = "text",
  value,
  onChange,
  fontSize = 20,
  fontWeight = 700,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      {type === "date" ? (
        <input
          type={isFocused ? "date" : "text"}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={onChange}
          value={
            isFocused ? value : moment(value, "YYYY-MM-DD").format("DD-MM-YYYY")
          }
          style={{
            fontWeight: fontWeight,
            fontSize: fontSize,
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            padding: 0,
            margin: 0,
            width:
              ((isFocused
                ? value
                : moment(value, "YYYY-MM-DD").format("DD-MM-YYYY")
              )?.length || 1) * 13,
          }}
        />
      ) : (
        <input
          type={type}
          onChange={onChange}
          value={value}
          placeholder="0"
          style={{
            fontWeight: fontWeight,
            fontSize: fontSize,
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            padding: 0,
            margin: 0,
            width: (value?.length || 1) * 12,
          }}
        />
      )}
    </>
  );
};

export const CustomInputTC = ({
  type = "text",
  value,
  onChange,
  fontSize = 20,
  fontWeight = 700,
  placeholder = "Type here",
  showPlaceholder = false,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  return (
    <>
      {type === "date" ? (
        <input
          type={isFocused ? "date" : "text"}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={onChange}
          value={
            isFocused ? value : moment(value, "YYYY-MM-DD").format("DD-MM-YYYY")
          }
          {...(showPlaceholder && { placeholder: placeholder })}
          style={{
            fontWeight: fontWeight,
            fontSize: fontSize,
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            padding: 0,
            margin: 0,
            width:
              ((isFocused
                ? value
                : moment(value, "YYYY-MM-DD").format("DD-MM-YYYY")
              )?.length || 1) * 8,
          }}
        />
      ) : (
        <input
          type={type}
          onChange={onChange}
          value={value}
          {...(showPlaceholder && { placeholder: placeholder })}
          style={{
            fontWeight: fontWeight,
            fontSize: fontSize,
            border: "none",
            outline: "none",
            backgroundColor: "transparent",
            padding: 0,
            margin: 0,
            width: value ? (value?.length || 1) * 12 : placeholder.length * 12,
          }}
        />
      )}
    </>
  );
};

export default CustomInput;

export const ContentOfCertificate = ({
  certificateType,
  studentData,
  schoolDetail = " ",
  participationData,
  feeTypes = [],
  feeSummary = {},
}) => {
  let content = "";
  const [fullName, setFullName] = React.useState(
    FormatFullName(
      studentData?.firstName,
      studentData?.middleName,
      studentData?.lastName
    )
  );
  const [state, setState] = React.useState({
    fatherName: FormatFullName(
      studentData?.fatherInfo?.firstName,
      studentData?.fatherInfo?.middleName,
      studentData?.fatherInfo?.lastName
    ),
    motherName: FormatFullName(
      studentData?.motherInfo?.firstName,
      studentData?.motherInfo?.middleName,
      studentData?.motherInfo?.lastName
    ),
    Address: studentData?.currentAddress?.address ?? "--",
    Dist: studentData?.currentAddress?.dist ?? "--",
    PinCode: studentData?.currentAddress?.pinCode ?? "--",
    State: studentData?.currentAddress?.state ?? "--",
    className: `${studentData?.className ?? "--"} ${
      studentData?.stream ?? "--"
    } ${studentData?.section ?? "--"}`,
    DOB: studentData?.DOB ? moment(studentData?.DOB).format("LL") : "--",
    eventDate: participationData?.eventDate ?? "--",
    eventName: participationData?.eventName ?? "--",
    eventDetails: participationData?.eventDetails ?? "--",
    session: studentData?.session ?? "--",
    rollNumber: studentData?.rollNumber ?? "--",
    designation: studentData?.designation ?? "--",
    joiningDate: studentData?.joiningDate ?? "--",
    leavingDate: studentData?.leavingDate ?? new Date(),
    schoolLeavingReason: "whatever legal reason it may concern",
  });
  switch (certificateType) {
    case "character":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          It is certify that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          , son/daughter of{" "}
          <CustomInput
            value={state.fatherName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                fatherName: e.target.value,
              }))
            }
          />
          , address -
          <CustomInput
            value={state.Address}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                Address: e.target.value,
              }))
            }
          />
          {","}
          <CustomInput
            value={state.Dist}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                Dist: e.target.value,
              }))
            }
          />
          {","}
          <CustomInput
            value={state.State}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                State: e.target.value,
              }))
            }
          />
          (
          {
            <CustomInput
              value={state.PinCode}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  PinCode: e.target.value,
                }))
              }
            />
          }
          ) is a bonafide student of class -
          <CustomInput
            value={state.className}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                className: e.target.value,
              }))
            }
          />
          . his/her date of birth is{" "}
          <CustomInput
            type={"date"}
            value={moment(state.DOB, "LL").format("YYYY-MM-DD")}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                DOB: moment(e.target.value, "YYYY-MM-DD").format("LL"),
              }))
            }
          />
          according to the scholar register. I know about him/her that he/she
          has a good character
          <br></br>
          God bless him/her with a good future.
        </Typography>
      );
      break;
    case "schoolLeaving":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          This is to certified that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          , son/daughter of Mr.{" "}
          <CustomInput
            value={state.fatherName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                fatherName: e.target.value,
              }))
            }
          />{" "}
          {studentData?.motherInfo && <span>and Mrs. </span>}
          <CustomInput
            value={state.motherName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                motherName: e.target.value,
              }))
            }
          />
          ,is a bonafide student of this school. His/Her date of birth is{" "}
          <CustomInput
            type={"date"}
            value={moment(state.DOB, "LL").format("YYYY-MM-DD")}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                DOB: moment(e.target.value, "YYYY-MM-DD").format("LL"),
              }))
            }
          />{" "}
          according to the school records. He/She has passed class{" "}
          <CustomInput
            value={state.className}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                className: e.target.value,
              }))
            }
          />{" "}
          <br></br>
          We wish him/her all the best for his/her future. This certificate is
          issued on his/her request for the purpose of{" "}
          <CustomInput
            value={state.schoolLeavingReason}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                schoolLeavingReason: e.target.value,
              }))
            }
          />
        </Typography>
      );
      break;
    case "DOB":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          This is certify that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
          , son/daughter of{" "}
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
            }}
          >
            Mr.{" "}
            <CustomInput
              value={state.fatherName}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  fatherName: e.target.value,
                }))
              }
            />
            and Mrs.{" "}
            <CustomInput
              value={state.motherName}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  motherName: e.target.value,
                }))
              }
            />
          </span>
          , Address -
          <CustomInput
            value={state.Address}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                Address: e.target.value,
              }))
            }
          />
          {","}
          <CustomInput
            value={state.Dist}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                Dist: e.target.value,
              }))
            }
          />
          {","}
          <CustomInput
            value={state.State}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                State: e.target.value,
              }))
            }
          />
          (
          <CustomInput
            value={state.PinCode}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                PinCode: e.target.value,
              }))
            }
          />
          ) is a bonafide student of this school.
          <br></br>
          His/Her date of birth is{" "}
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
            }}
          >
            {studentData?.DOB ? moment(studentData?.DOB).format("LL") : null}
          </span>{" "}
          according to the school records.
          <br></br>
          We wish him/her all the best for his/her future.
        </Typography>
      );
      break;
    case "participation":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          This is certify that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />{" "}
          has actively participated in{" "}
          <CustomInput
            value={state.eventName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                eventName: e.target.value,
              }))
            }
          />
          . In recogniion of his/her active and invaluable participation during
          the conduct of three days workshop on the{" "}
          <CustomInput
            value={state.eventDetails}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                eventDetails: e.target.value,
              }))
            }
          />{" "}
          held on{" "}
          <CustomInput
            type={"date"}
            value={moment(state.eventDate, "DD-MM-YYYY").format("YYYY-MM-DD")}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                eventDate: moment(e.target.value, "YYYY-MM-DD").format(
                  "DD-MM-YYYY"
                ),
              }))
            }
          />
          . He/She has been awarded this certificate.
        </Typography>
      );
      break;
    case "bonafied":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          This is to Certified that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />{" "}
          ,son/daughter of Mr.{" "}
          <CustomInput
            value={state.fatherName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                fatherName: e.target.value,
              }))
            }
          />
          {studentData?.motherInfo && <span>and Mrs. </span>}
          <CustomInput
            value={state.motherName}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                motherName: e.target.value,
              }))
            }
          />
          , bearing roll no{" "}
          <CustomInput
            value={state.rollNumber}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                rollNumber: e.target.value,
              }))
            }
          />
          is a student of class{" "}
          <CustomInput
            value={state.className}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                className: e.target.value,
              }))
            }
          />{" "}
          for the academic year{" "}
          <CustomInput
            value={state.session}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                session: e.target.value,
              }))
            }
          />
          He/She is bonafide student of this school.
        </Typography>
      );
      break;
    case "fee":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          <br></br>
          This is to Certified that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />{" "}
          , son/daughter of{" "}
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
            }}
          >
            Mr.{" "}
            <CustomInput
              value={state.fatherName}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  fatherName: e.target.value,
                }))
              }
            />
            {studentData?.motherInfo && <span>and Mrs. </span>}
            <CustomInput
              value={state.motherName}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  motherName: e.target.value,
                }))
              }
            />
          </span>
          , Address -
          <CustomInput
            value={state.Address}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                Address: e.target.value,
              }))
            }
          />
          {","}
          <CustomInput
            value={state.Dist}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                Dist: e.target.value,
              }))
            }
          />
          {","}
          <CustomInput
            value={state.State}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                State: e.target.value,
              }))
            }
          />
          (
          <CustomInput
            value={state.PinCode}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                PinCode: e.target.value,
              }))
            }
          />
          ) is a bonafide student of class{" "}
          <CustomInput
            value={state.className}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                className: e.target.value,
              }))
            }
          />{" "}
          in this school.
          <br></br>
          He/She has deposited his/her fee of session{" "}
          <CustomInput
            value={state.session}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                session: e.target.value,
              }))
            }
          />{" "}
          is as under.
          <br></br>
          {feeTypes?.length > 0 &&
            feeTypes.map(
              (fee, index) =>
                fee?.paidFeeCount > 0 && (
                  <Stack key={index} direction="row" gap={2}>
                    <Typography
                      className="content"
                      style={{
                        fontFamily: "monospace",
                        width: "250px",
                        fontWeight: 700,
                      }}
                    >
                      {fee?.feeType}
                    </Typography>
                    <Typography
                      className="content"
                      style={{
                        fontFamily: "monospace",
                        fontWeight: 700,
                        width: "15px",
                      }}
                    >
                      {"-"}
                    </Typography>
                    <Typography
                      className="content"
                      style={{
                        fontFamily: "monospace",
                        width: "200px",
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      Rs. {fee?.generalFeeAmount} X {fee?.paidFeeCount ?? 0}
                    </Typography>
                    <Typography
                      className="content"
                      style={{
                        fontFamily: "monospace",
                        width: "200px",
                        fontWeight: 700,
                        textAlign: "center",
                      }}
                    >
                      {"="} Rs.{" "}
                      {fee?.generalFeeAmount * (fee?.paidFeeCount ?? 0)}
                    </Typography>
                  </Stack>
                )
            )}
          {feeSummary?.feeTypeCount > 0 && (
            <Typography
              className="content"
              style={{
                fontFamily: "monospace",
                fontWeight: 400,
              }}
            >
              ----------------------------------------------------------------
              <Stack direction="row" gap={2}>
                {/* fee */}
                <Typography
                  className="content"
                  style={{
                    fontFamily: "monospace",
                    fontWeight: 700,
                  }}
                >
                  Fee Rs:{feeSummary?.totalGeneralFeeSummary ?? 0}
                </Typography>

                {/* discount */}

                <Typography
                  className="content"
                  style={{
                    fontFamily: "monospace",
                    fontWeight: 700,
                  }}
                >
                  Discount Rs.
                  {feeSummary?.totalCategoryDiscountSummary ??
                    0 + feeSummary?.totalRecurringDiscountSummary ??
                    0 + feeSummary?.totalExtraDiscountSummary ??
                    0}
                </Typography>

                {/* paid */}

                <Typography
                  className="content"
                  style={{
                    fontFamily: "monospace",
                    fontWeight: 700,
                  }}
                >
                  Paid Rs. {feeSummary?.totalPaidFeeSummary ?? 0}
                </Typography>

                {/* balance */}

                <Typography
                  className="content"
                  style={{
                    fontFamily: "monospace",
                    fontWeight: 700,
                  }}
                >
                  Balance Rs.{feeSummary?.totalBalanceFeeSummary ?? 0}
                </Typography>
              </Stack>
              {/* ----------------------------------- */}
            </Typography>
          )}
        </Typography>
      );
      break;
    case "teacherExperience":
      content = (
        <Typography
          className="content"
          sx={{
            textAlign: "justify",
            alignItems: "center",
            fontFamily: "monospace",
          }}
        >
          It is certify that{" "}
          <CustomInput
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />{" "}
          ,as worked as a{" "}
          <CustomInput
            value={state.designation}
            onChange={(e) =>
              setState((prev) => ({ ...prev, designation: e.target.value }))
            }
          />{" "}
          ,from{" "}
          <CustomInput
            type={"date"}
            value={moment(state.joiningDate, "DD-MM-YYYY").format("YYYY-MM-DD")}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                joiningDate: moment(e.target.value, "YYYY-MM-DD").format(
                  "DD-MM-YYYY"
                ),
              }))
            }
          />{" "}
          to
          <CustomInput
            type={"date"}
            value={moment(state.leavingDate, "DD-MM-YYYY").format("YYYY-MM-DD")}
            onChange={(e) =>
              setState((prev) => ({
                ...prev,
                leavingDate: moment(e.target.value, "YYYY-MM-DD").format(
                  "DD-MM-YYYY"
                ),
              }))
            }
          />{" "}
          at
          <span
            style={{
              fontWeight: 700,
              fontSize: 20,
            }}
          >
            {" "}
            {schoolDetail?.schoolName ?? "--"}
          </span>
          . During this period, his/her conduct was good and his/her work was
          satisfactory. He/She is a dedicated and hardworking teacher. We wish
          him/her all the best for his/her future.
          {"\n"}
          He/She had a good command over the subject he/she thaught. He/She has
          good communication skills and was able to explain the concepts to the
          students in a very simple and effective manner. He/She was always
          punctual and regular in his/her work.
        </Typography>
      );
      break;
    default:
      content = "";
  }
  return content;
};
