import Fallback from "app/components/Fallback";
import React from "react";

const Homework = React.lazy(() =>
  import("app/modules/academics/pages/Homework/HomeworkIndex")
);

const AttendanceAndLeave = React.lazy(() =>
  import("app/modules/academics/pages/AttendanceAndLeave")
);
const TimeTable = React.lazy(() =>
  import("app/modules/academics/pages/TimeTable")
);
const Syllabus = React.lazy(() =>
  import("app/modules/academics/pages/Syllabus")
);
const AcademicCalendar = React.lazy(() =>
  import("app/modules/academics/pages/AcademicCalendar/index")
);
const SubjectDetails = React.lazy(() =>
  import("app/modules/academics/pages/SubjectDetails")
);

export const HomeWorkPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Homework {...props} />
  </React.Suspense>
);
export const AttendanceAndLeavePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <AttendanceAndLeave {...props} />
  </React.Suspense>
);
export const TimeTablePage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <TimeTable {...props} />
  </React.Suspense>
);
export const SyllabusPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <Syllabus {...props} />
  </React.Suspense>
);
export const SubjectDetailsPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <SubjectDetails {...props} />
  </React.Suspense>
);
export const AcademicCalendarPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <AcademicCalendar {...props} />
  </React.Suspense>
);
