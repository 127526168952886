import { Typography, Grid } from "@mui/material";
const NameComponent = ({ label, text }) => {
  return (
    <Grid item xs={4}>
      <Typography fontSize={12} color="primary" sx={{ opacity: 0.75 }}>
        {label}
      </Typography>
      <Typography fontSize={14} fontWeight={600} color="primary">
        {text && text !== "undefined" && text !== undefined
          ? String(text).replace(/undefined/g, "")
          : "--"}
      </Typography>
    </Grid>
  );
};
export default function AddressInfo({ data }) {
  return (
    <>
      <Grid container spacing={1}>
        <NameComponent
          label="Current Address"
          text={data?.currentAddress?.address}
        />
        <NameComponent label="District" text={data?.currentAddress?.dist} />
        <NameComponent label="Pincode" text={data?.currentAddress?.pinCode} />
        <NameComponent label="State" text={data?.currentAddress?.state} />
      </Grid>
      <hr
        style={{
          width: "100%",
          backgroundColor: "#B6C8D6",
        }}
      />
      <Grid container spacing={1}>
        <NameComponent
          label="Permanent Address"
          text={data?.currentAddress?.address}
        />
        <NameComponent label="District" text={data?.permanentAddress?.dist} />
        <NameComponent label="Pincode" text={data?.permanentAddress?.pinCode} />
        <NameComponent label="State" text={data?.permanentAddress?.state} />
      </Grid>
    </>
  );
}
