import { Box, Button, Stack, styled } from "@mui/material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import CustomLoader from "app/components/common/CustomLoader";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import TableActionButtons from "app/components/common/TableActionButtons";
import {
  deleteFeeRelaxationCategory,
  getFeeRelaxationCategory,
} from "app/services/management";
import React, { useMemo, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import AddFeeRelaxationDialog from "./AddFeeRelaxationDialog";
import ExportDialog from "app/components/common/exportDialog";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";

const FeeRelaxationCategory = () => {
  const heightRef = useRef(null);

  const [state, setState] = useState({
    addDialog: false,
    deleteDialog: false,
    deleteId: "",
  });
  const [openCheckBox, setOpenCheckBox] = useState(false);
  const {
    isLoading: getFeeRelaxationCategoryLoading,
    isFetching: getFeeRelaxationCategoryFetching,
    data: getFeeRelaxationCategoryData,
    refetch: getFeeRelaxationCategoryRefetch,
  } = useQuery({
    queryKey: ["getFeeRelaxationCategory"],
    queryFn: () => getFeeRelaxationCategory(),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });

  const {
    isLoading: deleteFeeRelaxationCategoryLoading,
    mutate: deleteFeeRelaxationCategoryMutate,
  } = useMutation(deleteFeeRelaxationCategory, {
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      getFeeRelaxationCategoryRefetch();
      handleCloseDeleteDialog();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const deleteFeeCategory = (id) => {
    deleteFeeRelaxationCategoryMutate(id);
  };

  const columns = useMemo(
    () => [
      {
        Cell: ({ cell }) =>
          Number(cell.row.id) + 1 < 10
            ? `0${Number(cell.row.id) + 1}`
            : Number(cell.row.id) + 1,
        header: "Sr.No",
        maxSize: 40,
      },
      {
        accessorKey: "feeRelaxationCategory",
        accessorFn: (row) => row?.feeRelaxationCategory,
        header: "Fee Relaxation Category",
      },

      {
        header: "Action",
        maxSize: 100,
        Cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <TableActionButtons
              showDelete
              handleDelete={
                () => handleOpenDeleteDialog(original?._id)
                // deleteFeeCategory(original?._id)
              }
            />
          );
        },
      },
    ],
    []
  );
  // export stuff start
  let header = ["#", "Fee_Relaxation_Category"];
  let exportArray = getFeeRelaxationCategoryData?.data?.list.map(
    (item, index) => {
      return {
        "#": index < 9 ? `0${index + 1}` : index + 1,
        Fee_Relaxation_Category: item?.feeRelaxationCategory ?? "--",
      };
    }
  );
  // export stuff end
  const handleOpenDeleteDialog = (data) => {
    setState((prev) => ({
      ...prev,
      deleteId: data,
      deleteDialog: true,
    }));
  };
  const handleCloseDeleteDialog = () => {
    setState((prev) => ({
      ...prev,
      deleteId: "",
      deleteDialog: false,
    }));
  };

  const handledeleteFeeStructure = () => {
    deleteFeeCategory(state.deleteId);
  };

  return (
    <>
      <Helmet>
        <title>Fee Relaxation Category</title>
      </Helmet>

      {/* Delete Dialog Confirmation */}
      <DeleteDialog
        open={state.deleteDialog}
        handleClose={handleCloseDeleteDialog}
        handleDelete={handledeleteFeeStructure}
        loading={deleteFeeRelaxationCategoryLoading}
      />

      <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Fee Relaxation Category"
      />
      {/* add new fee relaxation category */}
      <CustomLoader
        show={
          getFeeRelaxationCategoryLoading ||
          getFeeRelaxationCategoryFetching ||
          deleteFeeRelaxationCategoryLoading
        }
      />
      {/* add new Dailog */}
      <AddFeeRelaxationDialog
        refetch={() => getFeeRelaxationCategoryRefetch()}
        onDialogClose={() =>
          setState((prev) => ({ ...prev, addDialog: false }))
        }
        showDialog={state.addDialog}
      />
      {/* first render content */}
      <StyledWrapper>
        {/* button or export section */}
        <Stack
          direction={"row"}
          justifyContent={"flex-end"}
          gap={2}
          alignItems={"center"}
        >
          <Button
            onClick={() => setState((prev) => ({ ...prev, addDialog: true }))}
            size="large"
            variant="outlined"
            color="secondary"
            sx={{
              background: "#fff",
              borderRadius: "10px",
              textTransform: "capitalize",
              py: 1.3,
            }}
          >
            + Add New Category
          </Button>
          <ExportButtonGroup
            handleClickExcel={() => setOpenCheckBox(true)}
            showPdf={false}
          />
        </Stack>
        <Box sx={{ height: "100%", mt: 1 }} ref={heightRef}>
          <CommonTable1
            columns={columns}
            data={getFeeRelaxationCategoryData?.data?.list}
            maxHeight={`${heightRef?.current?.clientHeight - 50}px`}
          />
        </Box>
      </StyledWrapper>
    </>
  );
};

export default FeeRelaxationCategory;
const StyledWrapper = styled(Box)`
  height: 100%;
  display: flex;
  flex-direction: column;
`;
