export const selectIsAuthorised = (state) => state.auth.isAuthenticated;
export const selectRoles = (state) => state.auth.authData.roles;
export const selectedInstituteTypeSelector = (state) =>
  state.auth.selectedInstituteType;
export const selectIsValidatingSession = (state) =>
  state.auth.status === "validatingSession";

export const userDocIdSelector = (state) => state.auth?.authData?.userDocId;

export const authAccessTokenSelector = (state) =>
  state.auth.authData?.accessToken;

export const errorMsgSelector = (state) => state.auth.errorMsg;

export const loggedInUserProfileSelector = (state) =>
  state.auth.loggedInUserProfile;
