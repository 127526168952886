import React, { useState } from "react";
import CommonDialog from "../CommonDialog";
import { useMutation, useQuery } from "react-query";
import { getStudents1 } from "app/services/schoolService/studentProfile";
import { toast } from "react-toastify";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import {
  applyOptionalFee,
  getStudentByFeeStructure,
} from "app/services/schoolService/newFee.service";
import { LoadingButton } from "@mui/lab";

const SpecificStudentDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  session = "",
  classDocId = "",
  section = "",
  feeStructureDocId = "",
}) => {
  const [state, setState] = useState({
    list: [],
    checkedStudentList: [],
  });
  const handleClose = () => {
    onClose();
  };
  const validateSelected = () => {
    let isValid = false;
    state.list?.forEach((item) => {
      if (item.checked) {
        isValid = true;
        return;
      }
    });
    return isValid;
  };
  const { isLoading: getStudents1Loading, isFetching: getStudents1Fetching } =
    useQuery({
      queryKey: ["getStudents1"],
      queryFn: () => getStudents1({ classDocId, section, session }),
      onSuccess: (success) => {
        setState((prev) => ({ ...prev, list: success?.data?.list }));
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
      enabled: open ? true : false,
    });
  const {
    isLoading: getStudentByFeeStructureLoading,
    isFetching: getStudentByFeeStructureFetching,
  } = useQuery({
    queryKey: ["getStudentByFeeStructure"],
    queryFn: () =>
      getStudentByFeeStructure({ feeStructureDocId: feeStructureDocId }),
    onSuccess: (success) => {
      const checkedStudentIds = new Set(
        success?.data?.list?.map((item) => item?._id)
      );
      setState((prev) => ({
        ...prev,
        list: prev?.list?.map((item) => ({
          ...item,
          checked: checkedStudentIds.has(item?._id) ? true : false,
        })),
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: open ? true : false,
  });
  const handleApplyOnAllStudent = () => {
    mutate({
      session,
      feeStructureDocId,
      reqStudentDocIdList: state.list?.map((item) => item?._id),
    });
  };

  const handleApplyOnSpecific = () => {
    mutate({
      session,
      feeStructureDocId,
      reqStudentDocIdList: state.list
        ?.filter((item) => item?.checked)
        .map((item) => item?._id),
    });
  };
  const { mutate, isLoading: applyOptionalFeeLoading } = useMutation(
    "applyOptionalFee",
    applyOptionalFee,
    {
      onSuccess: (success) => {
        toast.success(success?.data?.message);
        handleClose();
        refetch();
      },
      onError: (error) => {
        toast.error(error?.response?.data?.message);
      },
    }
  );
  return (
    <CommonDialog
      open={open}
      onClose={handleClose}
      title="Add Student"
      minWidth="350px"
    >
      {getStudents1Loading || getStudents1Fetching ? (
        <Stack direction="row" justifyContent="center" sx={{ py: 2 }}>
          <CircularProgress />
        </Stack>
      ) : (
        <>
          <DialogContent>
            <Wrapper>
              {state.list?.map((item, ind) => (
                <Box key={ind}>
                  <Grid container spacing={1}>
                    <Grid item xs={10}>
                      <Stack direction="row" alignItems="center" gap={1.5}>
                        {item?.profilePic ? (
                          <Box>
                            <img src={item?.profilePic} alt="profilePic" />
                          </Box>
                        ) : (
                          <Box className="noImage_box">
                            <Typography className="noImage_text">
                              {String(item?.firstName).charAt(0)}
                            </Typography>
                          </Box>
                        )}
                        <Box>
                          <Typography className="name_text">{`${item?.firstName} ${item?.middleName} ${item?.lastName}`}</Typography>
                          <Typography className="class_text">
                            {`${item?.className}-${item?.stream} ${item?.section}`}
                          </Typography>
                          <Typography className="name_text">
                            Admission No :-{item?.admissionNumber}
                          </Typography>
                          <Typography className="name_text">
                            Reg No :-{item?.registrationNumber}
                          </Typography>
                        </Box>
                      </Stack>
                    </Grid>
                    <Grid item xs={2}>
                      <Checkbox
                        color="secondary"
                        checked={item?.checked ? true : false}
                        onChange={() => {
                          item.checked = !item?.checked;
                          setState((prev) => ({ ...prev }));
                        }}
                      />
                    </Grid>
                  </Grid>
                  {ind + 1 !== state.list.length && <Box className="divider" />}
                </Box>
              ))}
            </Wrapper>
          </DialogContent>
          <DialogActions>
            <LoadingButton
              loading={applyOptionalFeeLoading}
              onClick={handleApplyOnAllStudent}
              variant="outlined"
              sx={{ textTransform: "capitalize" }}
            >
              Apply on All Student
            </LoadingButton>
            <LoadingButton
              loading={applyOptionalFeeLoading}
              onClick={handleApplyOnSpecific}
              variant="contained"
              color="secondary"
              sx={{ textTransform: "capitalize" }}
              disabled={!validateSelected()}
            >
              Apply on Selected Student
            </LoadingButton>
          </DialogActions>
        </>
      )}
    </CommonDialog>
  );
};

export default SpecificStudentDialog;
const Wrapper = styled(Box)`
  width: 350px;
  img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    object-fit: cover;
  }
  .noImage_box {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    background-color: #b6c8d6;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .noImage_text {
    font-weight: 500;
    font-size: 18px;
    color: #0c2f49;
  }
  .name_text {
    font-weight: 500;
    font-size: 14px;
    color: #0c2f49;
  }
  .class_text {
    font-weight: 400;
    font-size: 12px;
    color: #0c2f49;
  }
  .divider {
    background: #ffffff;
    border: 0.8px solid #b6c8d6;
    border-radius: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
  }
`;
