import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { schoolListSelector } from "../../selectors";
import { loggedInUserProfileSelector } from "../../../auth/selectors";
import ShortcutIcon from "@mui/icons-material/Shortcut";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  Dialog,
  DialogActions,
  Divider,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import DialogHeader from "app/components/common/DialogHeader";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomTextField,
  CustomTextFieldMultiline,
} from "assets/styles/globalStyledComponent";
import { LoadingButton } from "@mui/lab";
import { useMutation, useQuery } from "react-query";
import // applyToSchoolLogic,
// getAllJoinedSchoolLogic,
// verifySchoolCodeLogic,
// withdrawJoinedSchoolRequestLogic,
"app/services/management";
import { toast } from "react-toastify";
import {
  WithdrawJoiningRequest,
  getAllJoiningRequestByPersonDocId,
  getSchoolInfoByCode,
  schoolApplyByStaff,
} from "app/services/schoolService/user.service";

const ApplySchool = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [state, setState] = useState({
    applyCodeModal: false,
    schoolCode: "",
    schoolDetail: null,
    message: "",
    requestedSchoolData: [],
    withdrawModel: null,
  });

  const schoolList = useSelector(schoolListSelector);
  const handleCloseApplyModal = () => {
    setState((prev) => ({
      ...prev,
      applyCodeModal: false,
      message: "",
      schoolCode: "",
      schoolDetail: null,
    }));
  };
  // LoggedIn Profile
  let loggedInUserProfile = useSelector(loggedInUserProfileSelector);

  const {
    mutate: verifyMutate,
    isLoading: verifySchoolCodeLoading,
    refetch: verifySchoolCodeFetch,
  } = useMutation(getSchoolInfoByCode, {
    onSuccess: ({ data }) => {
      setState((prev) => ({
        ...prev,
        schoolDetail: data?.obj,
      }));
      toast.success(data?.message);
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: false,
    retry: false,
  });
  const {
    isLoading: getAllJoinedSchoolLoading,
    refetch: getAllJoinedSchoolFetch,
  } = useQuery({
    queryKey: ["getAllJoinedSchool"],
    queryFn: () => getAllJoiningRequestByPersonDocId(),
    onSuccess: ({ data }) => {
      if (data?.list && data?.list?.length === 0) {
        setState((prev) => ({ ...prev, applyCodeModal: true }));
      } else {
        setState((prev) => ({
          ...prev,
          requestedSchoolData: data?.list,
        }));
      }
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });
  const {
    isLoading: withdrawJoinedSchoolRequestLoading,
    refetch: withdrawJoinedSchoolRequestFetch,
  } = useQuery({
    queryKey: ["withdrawJoinedSchoolRequest"],
    queryFn: () =>
      WithdrawJoiningRequest({ requestDocId: state.withdrawModel }),
    onSuccess: ({ data }) => {
      setState((prev) => ({
        ...prev,
        withdrawModel: null,
      }));
      toast.success(data?.message);
      getAllJoinedSchoolFetch();
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: false,
    retry: false,
  });
  const { mutate: applyToSchool, isLoading: applyToSchoolLoading } =
    useMutation(schoolApplyByStaff, {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        handleCloseApplyModal();
        getAllJoinedSchoolFetch();
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    });

  return (
    <>
      <Dialog open={getAllJoinedSchoolLoading}>
        <CircularProgress size={30} sx={{ m: 2 }} />
      </Dialog>
      {/* apply school dialog */}
      <Dialog
        open={state.applyCodeModal}
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader title="Apply" handleClose={handleCloseApplyModal} />
        <DialogWrapper>
          {state.schoolDetail ? (
            <>
              <Box className="cover_img__wrapper">
                {/* cover photo */}
                <img
                  src={
                    state.schoolDetail?.coverPhoto ?? "/banner-placeholder.png"
                  }
                  alt="ekalsutra-school-cover"
                />
                {/* logo */}
                <Stack
                  direction="row"
                  justifyContent="center"
                  className="logo_container"
                  sx={{ width: "100%" }}
                >
                  <Box className="logo_wrap">
                    <img
                      src={
                        state.schoolDetail?.schoolLogo ??
                        "image-placeholder.jpeg"
                      }
                    />
                  </Box>
                </Stack>
              </Box>
              <Box className="location_container">
                <Stack
                  direction="row"
                  alignItems="center"
                  sx={{ px: 2 }}
                  gap={1}
                >
                  <LocationOnIcon sx={{ color: "#0D2553", opacity: 0.5 }} />
                  <Typography className="location_text">
                    {state.schoolDetail?.schoolAddress?.address},{" "}
                    {state.schoolDetail?.schoolAddress?.dist},{" "}
                    {state.schoolDetail?.schoolAddress?.state},{" "}
                    {state.schoolDetail?.schoolAddress?.pinCode}
                  </Typography>
                </Stack>
              </Box>
              <CustomTextFieldMultiline
                fullWidth
                placeholder="Write your message here..."
                value={state.message}
                multiline
                maxRows={5}
                minRows={5}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, message: e.target.value }))
                }
                sx={{ mt: 1, mb: 2, fontSize: "15px" }}
              />
              <DialogActions>
                <LoadingButton
                  variant="contained"
                  color="secondary"
                  size="large"
                  sx={{ width: "40%" }}
                  loading={applyToSchoolLoading}
                  onClick={() =>
                    applyToSchool({
                      schoolCode: state.schoolCode,
                      requestedFor: state.message,
                    })
                  }
                >
                  Apply
                </LoadingButton>
              </DialogActions>
            </>
          ) : (
            <>
              <TextFieldLabel title="Enter School Code" />
              <CustomTextField
                sx={{ width: "70%", mt: 0.5, mb: 2 }}
                placeholder="Type school code"
                size="small"
                value={state.schoolCode}
                onChange={(e) =>
                  setState((prev) => ({ ...prev, schoolCode: e.target.value }))
                }
              />
              <DialogActions>
                <LoadingButton
                  variant="contained"
                  disabled={!state.schoolCode}
                  color="secondary"
                  size="large"
                  sx={{ width: "40%" }}
                  loading={verifySchoolCodeLoading}
                  onClick={() => verifyMutate(state.schoolCode)}
                >
                  Search
                </LoadingButton>
              </DialogActions>
            </>
          )}
        </DialogWrapper>
      </Dialog>
      {/* withdraw dialog */}
      <Dialog
        open={state.withdrawModel}
        PaperProps={{
          sx: { minWidth: "300px" },
        }}
      >
        <DialogHeader
          title="Withdraw Application"
          handleClose={() =>
            setState((prev) => ({ ...prev, withdrawModel: null }))
          }
        />
        <Box sx={{ p: 2 }}>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "26px",
              color: (theme) => theme.palette.primary.main,
              textAlign: "center",
              my: 2,
            }}
          >
            Are you sure want to withdraw your Application
          </Typography>
          <Stack direction="row" justifyContent="center" gap={2}>
            <Button
              color="secondary"
              variant="outlined"
              size="large"
              sx={{ width: "30%" }}
              onClick={() =>
                setState((prev) => ({ ...prev, withdrawModel: null }))
              }
            >
              No
            </Button>
            <LoadingButton
              loading={withdrawJoinedSchoolRequestLoading}
              color="secondary"
              variant="contained"
              size="large"
              sx={{ width: "30%" }}
              onClick={() => withdrawJoinedSchoolRequestFetch()}
            >
              Yes
            </LoadingButton>
          </Stack>
        </Box>
      </Dialog>
      <Wrapper>
        <Container maxWidth="lg">
          <Typography className="top_title">
            Welcome back,{" "}
            {loggedInUserProfile?.firstName +
              " " +
              loggedInUserProfile?.lastName}
          </Typography>
          <Typography className="subtitle_text">
            Please select the institute you want to proceed with!
          </Typography>
          <Box className="not_access_box">
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography className="applied_text">Applied</Typography>
              <Button
                color="secondary"
                variant="outlined"
                size="large"
                onClick={() =>
                  setState((prev) => ({ ...prev, applyCodeModal: true }))
                }
                sx={{
                  textTransform: "capitalize",
                  backgroundColor: "#fff",
                }}
              >
                Apply to Institute
              </Button>
            </Stack>
            {state.requestedSchoolData?.map((item, ind) => (
              <Box className="applied_school_card__wrap" key={ind}>
                <Stack direction="row" gap={1}>
                  <Box>
                    <img
                      className="logo"
                      src={item?.schoolLogo ?? "image-placeholder.jpeg"}
                    />
                  </Box>
                  <Box>
                    <Stack direction="row" justifyContent="space-between">
                      <Typography className="school_name">
                        {item?.schoolName}
                      </Typography>
                      {item?.status === "REQUESTED" && (
                        <Stack direction="row" alignItems="center" gap={1.5}>
                          <ShortcutIcon
                            sx={{
                              transform: "rotate(180deg)",
                              cursor: "pointer",
                            }}
                            color="error"
                            onClick={() =>
                              setState((prev) => ({
                                ...prev,
                                withdrawModel: item?._id,
                              }))
                            }
                          />
                          <Box className="requested_button">
                            <Typography className="text">REQUESTED</Typography>
                          </Box>
                        </Stack>
                      )}
                      {item?.status === "APPROVED" && (
                        <Box className="approved_button">
                          <Typography className="text">APPROVED</Typography>
                        </Box>
                      )}
                      {item?.status === "WITHDRAWN" && (
                        <Box className="withdraw_button">
                          <Typography className="text">WITHDRAWN</Typography>
                        </Box>
                      )}
                    </Stack>
                    <Stack
                      direction="row"
                      gap={0.5}
                      alignItems="center"
                      sx={{ my: 1 }}
                    >
                      <LocationOnIcon sx={{ color: "#0D2553", mb: "-3px" }} />
                      <Typography className="location_text">
                        {item?.schoolAddress?.address},{" "}
                        {item?.schoolAddress?.dist},{" "}
                        {item?.schoolAddress?.state},{" "}
                        {item?.schoolAddress?.pinCode}
                      </Typography>
                    </Stack>
                    <Typography>{item?.requestedFor}</Typography>
                  </Box>
                </Stack>
              </Box>
            ))}
          </Box>
        </Container>
      </Wrapper>
    </>
  );
};

export default ApplySchool;
const Wrapper = styled(Box)`
  margin-top: 80px;
  .top_title {
    margin-top: 20px;
    font-weight: 700;
    font-size: 30px;

    text-align: center;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #0c2f49;
  }
  .subtitle_text {
    font-weight: 500;
    font-size: 18px;
    text-align: center;
    color: #0c2f49;
    margin-top: 14px;
  }
  .not_access_box {
    background: #fbfbff;
    border: 1px solid rgba(61, 57, 137, 0.1);
    box-shadow: 0px 4px 10px rgba(61, 57, 137, 0.05);
    border-radius: 20px;
    width: 600px;

    margin: 20px auto;
    padding: 10px;
  }
  .not_access_text {
    font-weight: 700;
    font-size: 34.1191px;
    text-align: center;
    letter-spacing: 0.03em;

    color: #0c2f49;
  }
  .applied_text {
    font-weight: 700;
    font-size: 25px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #0c2f49;
  }
  .applied_school_card__wrap {
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 15px;
    padding: 15px;
    margin-top: 15px;
    .logo {
      height: 100px;
      width: 100px;
      object-fit: contain;
    }
    .school_name {
      font-weight: 500;
      font-size: 18px;

      color: #0d2553;
    }
    .location_text {
      font-weight: 500;
      font-size: 14px;
      color: #0d2553;
      width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .requested_button {
      background: rgba(227, 164, 0, 0.05);
      border: 1.15293px solid rgba(227, 164, 0, 0.2);
      border-radius: 20px;
      width: 96.63px;
      height: 30.16px;
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        font-weight: 600;
        font-size: 12.8066px;
        text-align: center;
        color: #e3a400;
      }
    }
    .approved_button {
      background: rgba(41, 219, 91, 0.15);
      border: 1.15293px solid rgba(7, 177, 3, 0.2);
      border-radius: 20px;
      width: 96.63px;
      height: 30.16px;
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        font-weight: 600;
        font-size: 12.8066px;
        text-align: center;
        color: #29db5b;
      }
    }
    .withdraw_button {
      background: #fff;
      border: 1.15293px solid #0c2f49;
      border-radius: 20px;
      width: 96.63px;
      height: 30.16px;
      display: flex;
      justify-content: center;
      align-items: center;
      .text {
        font-weight: 600;
        font-size: 12.8066px;
        text-align: center;
        color: #0b1f32;
      }
    }
  }
`;
const DialogWrapper = styled(Box)`
  padding: 20px;
  .cover_img__wrapper {
    width: 100%;
    height: 130px;
    margin: 10px auto;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 10;
    }
    .logo_container {
      position: absolute;
      bottom: 0;
      .logo_wrap {
        width: 82.17px;
        height: 82.17px;
        background: #f6f8fc;
        border: 1px solid rgba(182, 200, 214, 0.2);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: -40px;
      }
      img {
        width: 50px;
        height: 50px;
      }
    }
  }
  .location_container {
    height: 46px;
    background: #ffffff;
    border: 1px solid #b6c8d6;
    border-radius: 8px;
    margin-top: 50px;
    display: flex;
    align-items: center;
    .location_text {
      font-weight: 500;
      font-size: 14px;
      color: #0d2553;
      opacity: 0.5;
    }
  }
`;
