import { Box, styled } from "@mui/material";
import React from "react";

import { Close } from "@mui/icons-material";
const PreviewImage = ({
  src = "",
  deleteIcon = false,
  handleDelete,
  height = 100,
  width = 100,
}) => {
  return (
    <Box>
      <ImageWrapper sx={{ height: `${height}px`, width: `${width}px` }}>
        <img src={src} className="image" />
        {deleteIcon && (
          <Box className="close_box">
            <Close
              sx={{
                fontSize: "15px",
                cursor: "pointer",
                color: "rgba(241, 15, 15, 1)",
              }}
              onClick={() => handleDelete()}
            />
          </Box>
        )}
      </ImageWrapper>
    </Box>
  );
};

export default PreviewImage;

const ImageWrapper = styled(Box)`
  position: relative;
  padding: 1px;
  background-color: #f5f5f5;
  transition: 0.5s ease;

  border: 1px solid rgba(182, 200, 214, 1);
  :hover .download_box {
    opacity: 1;
  }
  .image {
    height: 100%;
    width: 100%;
    object-fit: contain;
    overflow: hidden;
  }
  .close_box {
    position: absolute;
    top: 0;
    right: 0;
    width: 20.82px;
    height: 20.82px;
    background: #ffd7d7;
    border: 0.699798px solid #ffb4b4;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -12px;
    margin-right: -12px;
    cursor: pointer;
  }
`;
