import React, { useEffect, useState } from "react";
import {
  Box,
  Stack,
  Checkbox,
  FormControlLabel,
  DialogContent,
  MenuItem,
  RadioGroup,
  Radio,
  Menu,
  Typography,
} from "@mui/material";
import CommonFilterDialog from "../../CommonFilterDialog";
import { DatePicker } from "@mui/x-date-pickers";
import TextFieldLabel from "../../../TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { getClassDropdownLogic } from "app/services/reception";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  getAllExpenseHead,
  getAllExpenseSubHead,
} from "app/services/ledgerAccount";
import { getAllFirm } from "app/services/schoolService/firm.service";
import { getActiveStaffBySchoolDocId } from "app/services/hr";
import CustomLoader from "../../../CustomLoader";
import { useSearchParams } from "react-router-dom";
import FilterButton from "../../../FilterButton";
import { getTransport } from "app/services/student-management";
import StudentSearchInput2 from "app/components/common/StudentSearchInput2";
import { StaffCardDropDown } from "app/components/common/StaffCardDropDown";
import ByVehicleFilter from "app/components/commonSeperateMultipleFilter/ByVehicleFilter";
import StaffSeachDialogDashboard from "../../StaffSeachDialogDashboard";
import ByExpenseHeadAndSubHead from "app/components/commonSeperateMultipleFilter/ByExpenseHeadAndSubHead";

const ExpenseFilter = ({ mutate = () => {}, extraData = () => {} }) => {
  const selectedSession = useSelector(selectActiveSession);
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const selectedMedium = useSelector(selectedMediumSelector);
  const [getSearchParams] = useSearchParams();
  const dt = new Date();
  const [selectedVehicles, setSelectedVehicles] = useState([]);
  const [selectedHead, setSelectedHead] = useState([]);
  const [headToMutate, setHeadToMutate] = useState([]);
  const [state, setState] = useState({
    tableData: null,
    classDocId: "",
    section: "",
    classList: [],
    sectionList: [],
    subHeads: [],
    heads: [],
    dialog: false,
  });
  const [checkboxes, setCheckboxes] = useState({
    expense: false,
    created: false,
    approved: false,
    head: false,
    vehicle: false,
    name: false,
    via: false,
  });
  const [filters, setFilters] = useState({
    expense: {
      from: dt,
      to: dt,
    },
    created: {
      from: dt,
      to: dt,
    },
    approved: {
      from: dt,
      to: dt,
    },
    head: {
      head: "",
      headName: "",
      subHead: "",
      subHeadName: "",
    },
    vehicle: "",
    nameRadio: "Staff",
    payingPersonDocId: "",
    viaPersonDocId: "",
  });
  const handleCloseDialog = () => {
    setState((prev) => ({ ...prev, dialog: false }));
  };
  const handleSave = () => {
    handleCloseDialog();
    mutate({
      ...(checkboxes.expense && {
        expenseFrom: filters?.expense?.from,
        expenseTo: filters?.expense?.to,
      }),
      ...(checkboxes.approved && {
        approvedFrom: filters?.approved?.from,
        approvedTo: filters?.approved?.to,
      }),
      ...(checkboxes.created && {
        createdFrom: filters?.created?.from,
        createdTo: filters?.created?.to,
      }),
      // ...(checkboxes.head && {
      //   expenseHeadDocId: filters?.head?.head,
      //   expenseSubHeadDocId: filters?.head?.subHead,
      // }),
      ...(checkboxes.head &&
        selectedHead.length > 0 && {
          reqExpenseHeadList: JSON.stringify(headToMutate),
        }),
      ...(checkboxes.vehicle &&
        selectedVehicles.length > 0 && {
          reqVehicleNumberList: JSON.stringify(
            selectedVehicles
              ?.filter((item) => item?.checked)
              ?.map((item) => item?.vehicleNumber)
          ),
          // vehicleNumber: filters?.vehicle,
        }),
      ...(checkboxes.name && {
        recipientPersonDocId: filters?.name?.selected?.id,
      }),
      ...(checkboxes.name &&
        filters.payingPersonDocId && {
          recipientPersonDocId: filters.payingPersonDocId,
        }),
      ...(checkboxes.via &&
        filters.viaPersonDocId && {
          viaPersonDocId: filters?.viaPersonDocId,
        }),
    });
    extraData({
      ...(checkboxes.head && {
        headName: filters?.head?.headName,
        subHeadName: filters?.head?.subHeadName,
      }),
    });
  };
  const handleViaResponse = (res) => {
    setFilters((prev) => ({ ...prev, viaPersonDocId: res?.staffDocId }));
  };
  const { isLoading: classLoading, isFetching: classFetching } = useQuery({
    queryKey: ["getClassInfo", selectedSession, selectedMedium],
    queryFn: () =>
      getClassDropdownLogic({ schoolDocId, selectedSession, selectedMedium }),
    onSuccess: (success) => {
      setState((prev) => ({
        ...prev,
        classList: success?.data?.list,
        section: "",
        sectionList: [],
      }));
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: checkboxes.byClass ? true : false,
  });

  const { isLoading: getStaffLoading, data: getStaffData } = useQuery({
    queryKey: ["getActiveStaff"],
    queryFn: getActiveStaffBySchoolDocId,

    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: filters.nameRadio === "Staff" ? true : false,
  });

  const {
    isLoading: getAllExpenseHeadLoading,
    isFetching: getAllExpenseHeadFetching,
  } = useQuery({
    queryKey: ["getAllExpenseHead"],
    queryFn: getAllExpenseHead,
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        heads: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.expenseHeadName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: checkboxes.head ? true : false,
  });
  const {
    isLoading: getAllExpenseSubHeadLoading,
    isFetching: getAllExpenseSubHeadFetching,
  } = useQuery({
    queryKey: ["getAllExpenseSubHead", filters.head.head],
    queryFn: () =>
      getAllExpenseSubHead({ expenseHeadDocId: filters.head.head }),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        subHeads: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.expenseSubHeadName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: filters.head.head ? true : false,
  });

  const { isLoading: getTransportLoading, isFetching: getTransportFetching } =
    useQuery({
      queryKey: ["getTransport", checkboxes.vehicle],
      queryFn: () => getTransport({ status: "ACTIVE" }),
      onSuccess: (res) => {
        setState((prev) => ({
          ...prev,
          transportList: res?.data?.list?.map((item) => item.vehicleNumber),
        }));
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
      enabled: checkboxes.vehicle ? true : false,
    });
  const { data: getAllFirmData, isLoading: getAllFirmLoading } = useQuery({
    queryKey: ["getAllFirm"],
    queryFn: () => getAllFirm(),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        firms: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.firmName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: filters.nameRadio === "Firm" ? true : false,
  });
  useEffect(() => {
    const search_head = getSearchParams.get("head");
    const search_subhead = getSearchParams.get("subhead");
    if (search_head && search_subhead) {
      setCheckboxes((prev) => ({ ...prev, head: true }));
      setFilters((prev) => ({
        ...prev,
        head: { head: search_head, subHead: search_subhead },
      }));
    }
  }, [getSearchParams]);
  useEffect(() => {
    const filteredList = selectedHead?.map(
      ({ expenseHeadDocId, expenseSubHeadDocId }) => ({
        expenseHeadDocId: expenseHeadDocId,
        expenseSubHeadDocId: expenseSubHeadDocId,
      })
    );
    setHeadToMutate(filteredList);
  }, [selectedHead]);

  return (
    <div>
      <CustomLoader
        show={
          classLoading ||
          classFetching ||
          getTransportLoading ||
          getTransportFetching ||
          getAllExpenseSubHeadLoading ||
          getAllExpenseSubHeadFetching ||
          getAllExpenseHeadLoading ||
          getAllExpenseHeadFetching ||
          getStaffLoading ||
          getAllFirmLoading
        }
      />
      <CommonFilterDialog
        open={state.dialog}
        onClose={handleCloseDialog}
        minWidth="500px"
        handleSaveAndView={handleSave}
      >
        <DialogContent>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.expense}
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      expense: !prev.expense,
                    }));
                    checkboxes.expense === true
                      ? setFilters((prev) => ({
                          ...prev,
                          expense: { from: dt, to: dt },
                        }))
                      : setFilters((prev) => ({ ...prev }));
                  }}
                />
              }
              label="Expense Date"
            />
            {checkboxes.expense && (
              <Stack direction="row" alignItems="center" gap={2} ml={2.5}>
                <Stack>
                  <TextFieldLabel title="From" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    name="collectionDate"
                    value={filters?.expense?.from}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        expense: { ...prev.expense, from: e },
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <TextFieldLabel title="To" />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    name="collectionDate"
                    value={filters?.expense?.to}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        expense: { ...prev.expense, to: e },
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
              </Stack>
            )}
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.created}
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      created: !prev.created,
                    }));
                    checkboxes.created === true
                      ? setFilters((prev) => ({
                          ...prev,
                          created: { from: dt, to: dt },
                        }))
                      : setFilters((prev) => ({ ...prev }));
                  }}
                />
              }
              label="Created Date"
            />
            {checkboxes.created && (
              <Stack direction="row" alignItems="center" gap={2} ml={2.5}>
                <Stack>
                  <TextFieldLabel title={"From"} />

                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    name="collectionDate"
                    value={filters?.created?.from}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        created: { ...prev.created, from: e },
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <TextFieldLabel title={"To"} />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    name="collectionDate"
                    value={filters?.created?.to}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        created: { ...prev.created, to: e },
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
              </Stack>
            )}
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.approved}
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      approved: !prev.approved,
                    }));
                    checkboxes.approved === true
                      ? setFilters((prev) => ({
                          ...prev,
                          approved: { from: dt, to: dt },
                        }))
                      : setFilters((prev) => ({ ...prev }));
                  }}
                />
              }
              label="Approved Date"
            />
            {checkboxes.approved && (
              <Stack direction="row" alignItems="center" gap={2} ml={2.5}>
                <Stack>
                  <TextFieldLabel title={"From"} />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    name="collectionDate"
                    value={filters?.approved?.from}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        approved: { ...prev.approved, from: e },
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
                <Stack>
                  <TextFieldLabel title={"To"} />
                  <DatePicker
                    inputFormat="DD-MM-YYYY"
                    name="collectionDate"
                    value={filters?.approved?.to}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        approved: { ...prev.approved, to: e },
                      }));
                    }}
                    renderInput={(params) => (
                      <CustomTextField
                        {...params}
                        placeholder="Select"
                        size="small"
                      />
                    )}
                  />
                </Stack>
              </Stack>
            )}
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.head}
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      head: !prev.head,
                    }));
                  }}
                />
              }
              label="Expense Head"
            />
            {checkboxes.head && (
              <Stack direction="row" alignItems="center" gap={2} ml={2.5}>
                <ByExpenseHeadAndSubHead
                  prevHeadList={selectedHead}
                  setSelectedHead={setSelectedHead}
                  filter={filters?.head}
                />
                {/* <Stack flex={1}>
                  <TextFieldLabel title={"Head Name"} />
                  <CustomSelectBox
                    displayEmpty
                    MenuProps={MenuProps}
                    value={filters.head.head}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        head: { ...prev.head, head: e.target.value },
                      }));
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {state?.heads?.map((head) => (
                      <MenuItem
                        value={head.id}
                        onClick={() =>
                          setFilters((prev) => ({
                            ...prev,
                            head: { ...prev.head, headName: head?.name },
                          }))
                        }
                      >
                        {head.name}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Stack>
                <Stack flex={1}>
                  <TextFieldLabel title={"Sub Head Name"} />
                  <CustomSelectBox
                    displayEmpty
                    MenuProps={MenuProps}
                    value={filters.head.subHead}
                    onChange={(e) => {
                      setFilters((prev) => ({
                        ...prev,
                        head: { ...prev.head, subHead: e.target.value },
                      }));
                    }}
                  >
                    <MenuItem value="" disabled>
                      Select
                    </MenuItem>
                    {state?.subHeads?.map((subhead) => (
                      <MenuItem
                        value={subhead.id}
                        onClick={() =>
                          setFilters((prev) => ({
                            ...prev,
                            head: { ...prev.head, subHeadName: subhead?.name },
                          }))
                        }
                      >
                        {subhead.name}
                      </MenuItem>
                    ))}
                  </CustomSelectBox>
                </Stack> */}
              </Stack>
            )}
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.vehicle}
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      vehicle: !prev.vehicle,
                    }));

                    checkboxes.vehicle === true
                      ? setFilters((prev) => ({ ...prev, vehicle: "" }))
                      : setFilters((prev) => ({ ...prev }));
                  }}
                />
              }
              label="By Vehicle Number"
            />
            {checkboxes.vehicle && (
              <Stack ml={2.5}>
                <TextFieldLabel title={"Select Vehicle Number"} />
                <ByVehicleFilter
                  prevVehicleList={selectedVehicles}
                  setSelectedVehicles={setSelectedVehicles}
                />
                {/* <CustomSelectBox
                  displayEmpty
                  sx={{ width: "15rem" }}
                  value={filters?.vehicle}
                  MenuProps={MenuProps}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      vehicle: e.target.value,
                    }))
                  }
                >
                  <MenuItem value="">Select</MenuItem>
                  {state?.transportList?.map((vehicle) => (
                    <MenuItem value={vehicle}>{vehicle}</MenuItem>
                  ))}
                </CustomSelectBox> */}
              </Stack>
            )}
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.name}
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      name: !prev.name,
                    }));
                  }}
                />
              }
              label="By Recipient Name"
            />
            {checkboxes.name && (
              <Box ml={2.5}>
                <RadioGroup
                  row
                  value={filters.nameRadio}
                  onChange={(e) =>
                    setFilters((prev) => ({
                      ...prev,
                      nameRadio: e.target.value,
                      payingPersonDocId: "",
                    }))
                  }
                >
                  {/* <FormControlLabel
                    value="StudentName"
                    control={<Radio color="secondary" />}
                    label="Student Name"
                  /> */}
                  <FormControlLabel
                    value="Staff"
                    control={<Radio color="secondary" />}
                    label="Staff"
                  />
                  <FormControlLabel
                    value="Firm"
                    control={<Radio color="secondary" />}
                    label="Firm"
                  />
                </RadioGroup>
                {filters.nameRadio === "Staff" && (
                  <Box sx={{ width: "200px" }}>
                    <TextFieldLabel title={"Select Staff"} />
                    <CustomSelectBox
                      fullWidth
                      size="small"
                      displayEmpty
                      MenuProps={MenuProps}
                      value={filters.payingPersonDocId}
                      renderValue={() =>
                        filters?.payingPersonDocId
                          ? (
                              getStaffData?.data?.list?.find(
                                (item) =>
                                  item.staffDocId === filters.payingPersonDocId
                              ) || {}
                            ).firstName +
                            " " +
                            (
                              getStaffData?.data?.list?.find(
                                (item) =>
                                  item.staffDocId === filters.payingPersonDocId
                              ) || {}
                            ).middleName +
                            " " +
                            (
                              getStaffData?.data?.list?.find(
                                (item) =>
                                  item.staffDocId === filters.payingPersonDocId
                              ) || {}
                            ).lastName
                          : "Select"
                      }
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          payingPersonDocId: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {getStaffData?.data?.list?.map((item) => (
                        <MenuItem value={item.staffDocId}>
                          <StaffCardDropDown item={item} />
                        </MenuItem>
                      ))}
                    </CustomSelectBox>
                  </Box>
                )}
                {filters.nameRadio === "Firm" && (
                  <Box sx={{ width: "200px" }}>
                    <TextFieldLabel title={"Select Firm"} />
                    <CustomSelectBox
                      fullWidth
                      size="small"
                      displayEmpty
                      MenuProps={MenuProps}
                      value={filters.payingPersonDocId}
                      onChange={(e) =>
                        setFilters((prev) => ({
                          ...prev,
                          payingPersonDocId: e.target.value,
                        }))
                      }
                    >
                      <MenuItem value="" disabled>
                        Select
                      </MenuItem>
                      {getAllFirmData?.data?.list?.map((item) => (
                        <MenuItem value={item._id}>{item.firmName}</MenuItem>
                      ))}
                    </CustomSelectBox>
                  </Box>
                )}
                {filters.nameRadio === "StudentName" && (
                  <StudentSearchInput2
                    returnStudentDocId={(studentId) => {
                      setFilters((prev) => ({
                        ...prev,
                        payingPersonDocId: studentId,
                      }));
                    }}
                  />
                )}
              </Box>
            )}
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  checked={checkboxes.via}
                  onClick={() => {
                    setCheckboxes((prev) => ({
                      ...prev,
                      via: !prev.via,
                    }));
                  }}
                />
              }
              label="Via/Through Person"
            />
            {checkboxes.via && (
              <Box ml={2.5}>
                <Box sx={{ width: "200px" }}>
                  <TextFieldLabel title={"Search Via Person"} />
                  <StaffSeachDialogDashboard
                    returnResponse={handleViaResponse}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </DialogContent>
      </CommonFilterDialog>
      <FilterButton
        hanldeClick={() => setState((prev) => ({ ...prev, dialog: true }))}
        count={Object.values(checkboxes).filter((item) => item === true).length}
      />
    </div>
  );
};

export default ExpenseFilter;
