import { CachedTwoTone } from "@mui/icons-material";

import { Box, IconButton, Stack, Typography, colors } from "@mui/material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import CustomLoader from "app/components/common/CustomLoader";
import ConvertInvoiceDialog from "app/components/common/Dialogs/fee/ConvertInvoiceDialog";
import ConvertInvoiceFilter from "app/components/common/Dialogs/fee/ConvertInvoiceFilter";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";

import appRoutes from "app/config/routes";
import { selectActiveSession } from "app/modules/schools/selectors";
import { getOldInvoice } from "app/services/management";
import React, { useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

const ConvertInvoice = () => {
  const selectedSession = useSelector(selectActiveSession);

  const [state, setState] = useState({
    filterDialog: false,
    invoiceDocId: "",
    filteredPayload: false,
    fetch: false,
  });
  const {
    isLoading: getOldInvoiceLoading,
    isFetching: getOldInvoiceFetching,
    data: getOldInvoiceData,
    refetch: getOldInvoiceRefetch,
  } = useQuery({
    queryKey: ["getOldInvoice", state.fetch],
    queryFn: () =>
      getOldInvoice({
        session: selectedSession,
        ...(state.filteredPayload && { ...state.filteredPayload }),
      }),
    onSuccess: (success) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const handleFilter = (payload) => {
    setState((prev) => ({
      ...prev,
      fetch: !prev.fetch,
      filteredPayload: payload,
    }));
  };
  const [openCheckBox, setOpenCheckBox] = useState(false);
  //   let header = [
  //     "Reg_no",
  //     "className_stream_section",
  //     "Student_Name",
  //     "Recurring_Discount_Detail",
  //   ];
  //   let exportArray = getOldInvoiceData?.data?.list?.map((item) => {
  //     return {
  //       Reg_no: item?.registrationNumber ?? "--",
  //       className_stream_section: item?.className_stream_section ?? "--",
  //       Student_Name: item?.studentDetail?.studentName ?? "--",
  //       Recurring_Discount_Detail:
  //         item?.feeTypeList
  //           ?.map(
  //             (list) =>
  //               `${item?.feeType}-${IndianCurrency(
  //                 item?.recurringDiscountAmount ?? 0
  //               )}-${item?.status}`
  //           )
  //           .join(",") ?? "--",
  //     };
  //   });

  const column = useMemo(() => {
    return [
      {
        header: "Sr.No",
        Cell: ({ cell }) => (
          <Typography>
            {parseInt(cell?.row?.id) + 1 < 10
              ? `0${parseInt(cell?.row?.id) + 1}`
              : parseInt(cell?.row?.id) + 1}
          </Typography>
        ),
        size: 20,
      },

      {
        header: "Invoice Number",
        accessorKey: "invoiceNumber",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Link
            to={`/${appRoutes.invoice}/${String(
              original?.invoiceNumber
            ).replace(/\//g, " ")}`}
            target="_blank"
            rel="noopener noreferrer"
            style={{ fontWeight: "bold" }}
          >
            {original?.invoiceNumber}
          </Link>
        ),
        size: 30,
      },
      {
        header: "Class Name",
        accessorKey: "studentDetail.className",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>
            {original?.studentDetail?.className}-
            {original?.studentDetail?.stream}-{original?.studentDetail?.section}
          </Typography>
        ),
        size: 20,
      },
      {
        header: "Student Name",
        accessorKey: "studentDetail.studentName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Link
              to={`/${appRoutes.student_profile}/${original?.studentDetail?.studentDocId}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <Typography>{original?.studentDetail?.studentName}</Typography>
            </Link>
          );
        },
        size: 20,
      },
      {
        header: "Total Amt.",
        accessorKey: "totalAmount",
        size: 20,
      },
      {
        header: "Discount Amt.",
        accessorKey: "discountAmount",
        size: 20,
      },
      {
        header: "Balance",
        accessorKey: "balanceAmount",
        size: 20,
      },
      {
        header: "Net Paid",
        accessorKey: "paidAmount",
        size: 20,
      },
      {
        header: "Converted",
        accessorKey: "convertedToNewInvoice",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography
            sx={{
              fontWeight: 600,
              color: original?.convertedToNewInvoice
                ? colors.green[600]
                : colors.red[600],
            }}
          >
            {original?.convertedToNewInvoice ? "Yes" : "No"}
          </Typography>
        ),
        size: 20,
      },
      {
        header: "Converted By",
        accessorKey: "createdBy.personName",
        size: 20,
      },
      {
        header: "Convert",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <IconButton
            onClick={() =>
              setState((prev) => ({ ...prev, invoiceDocId: original?._id }))
            }
          >
            <CachedTwoTone color="primary" sx={{ fontWeight: 600 }} />
          </IconButton>
        ),
        size: 20,
      },
    ];
  }, []);
  return (
    <>
      <CustomLoader show={getOldInvoiceLoading || getOldInvoiceFetching} />
      {/* export dialog */}
      {/* <ExportDialog
        open={openCheckBox}
        handleClose={() => setOpenCheckBox(false)}
        header={header}
        exportArray={exportArray}
        fileName="Recurring_Discount"
      /> */}
      {/* Conver Fee Invoice Dialog */}
      <ConvertInvoiceDialog
        open={state.invoiceDocId}
        onClose={() => setState((prev) => ({ ...prev, invoiceDocId: "" }))}
        refetch={getOldInvoiceRefetch}
      />
      <Stack
        direction="row"
        justifyContent={"space-between"}
        alignItems={"center"}
        pb={1}
      >
        <ConvertInvoiceFilter mutate={handleFilter} />
        <Box>
          <ExportButtonGroup
            handleClickExcel={() => setOpenCheckBox(true)}
            showPdf={false}
          />
        </Box>
      </Stack>
      <CommonTable1
        columns={column}
        data={getOldInvoiceData?.data?.list}
        maxHeight="65vh"
      />
    </>
  );
};

export default ConvertInvoice;
