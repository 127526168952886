import { LoadingButton } from "@mui/lab";
import {
  Button,
  Dialog,
  DialogContent,
  Stack,
  Typography,
} from "@mui/material";
import Transition from "app/modules/student/pages/Certificate/components/utils/Transition";
import React from "react";

const DeleteConfirmationDialog = ({
  open = false,
  onClose = () => {},
  handleDelete = () => {},
  loading = false,
}) => {
  return (
    <Dialog TransitionComponent={Transition} open={open} onClose={onClose}>
      <DialogContent>
        <Typography
          sx={{
            fontSize: "1.7rem",
            fontWeight: "500",
            color: "text.primary",
            textTransform: "capitalize",
          }}
        >
          Are you sure you want to delete.
        </Typography>
        <Stack direction={"row"} justifyContent={"center"} gap={2} mt={2}>
          <Button
            color="secondary"
            variant="outlined"
            size="medium"
            type="submit"
            onClick={() => onClose()}
          >
            CANCEL
          </Button>
          <LoadingButton
            color="error"
            loading={loading}
            variant="contained"
            size="medium"
            type="submit"
            onClick={() => handleDelete()}
          >
            CONFIRM
          </LoadingButton>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
