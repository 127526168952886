import { Box, Stack, Typography } from "@mui/material";
import React from "react";
import imagePlaceHolder from "assets/media/images/idCard-Images/userImage.png";
import { FormatFullName } from "app/utils/helper";

const StudentCard = ({ item, so = true, regNo = true, admNo = true }) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
        }}
      >
        <Stack direction="row" spacing={1} alignItems={"center"}>
          {/* student profile pic */}
          <Stack direction="column">
            <img
              src={item?.profilePic ?? imagePlaceHolder}
              alt="student"
              style={{
                width: "30px",
                height: "30px",
                borderRadius: "50%",
                objectFit: "cover",
              }}
            />
          </Stack>
          {/* student details */}
          <Stack direction="column" style={{}}>
            <Typography
              sx={{
                fontWeight: 600,
                fontSize: "15px",
                whiteSpace: "normal",
                overflowWrap: "break-word",
                width: "80%",
              }}
            >
              {item?.name ??
                FormatFullName(
                  item?.firstName,
                  item?.middleName,
                  item?.lastName
                )}
            </Typography>
            {so && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  width: "80%",
                }}
              >
                S/O:-{" "}
                {item?.fatherInfo
                  ? item?.fatherInfo?.firstName ??
                    "--" + " " + item?.fatherInfo?.middleName ??
                    "--" + " " + item?.fatherInfo?.lastName ??
                    "--"
                  : item?.fatherName ?? "--"}
              </Typography>
            )}
            {regNo && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  width: "80%",
                }}
              >
                Reg No:- {item?.registrationNumber}
              </Typography>
            )}

            {admNo && (
              <Typography
                sx={{
                  fontWeight: 500,
                  fontSize: "12px",
                  whiteSpace: "normal",
                  overflowWrap: "break-word",
                  width: "80%",
                }}
              >
                Adm No:- {item?.admissionNumber}
              </Typography>
            )}
          </Stack>
        </Stack>
      </Box>
    </>
  );
};

export default StudentCard;
