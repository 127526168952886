import {
  Box,
  CircularProgress,
  InputAdornment,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { CustomTextField } from "assets/styles/globalStyledComponent";
import React, { useEffect, useState } from "react";
import TextFieldLabel from "./TextFieldLabel";
import { useSelector } from "react-redux";
import { selectActiveSession } from "app/modules/schools/selectors";
import { useQuery } from "react-query";
import { getStudentByName } from "app/services/schoolService/studentReport.service";
import { toast } from "react-toastify";

const StudentSearchInput2 = ({
  returnStudentDocId = () => {},
  prevSession,
}) => {
  const selectedSession = useSelector(selectActiveSession);
  const [state, setState] = useState({
    anchor: null,
    studentData: {},
    page: 1,
    name: "",
    selectedStudentDocId: "",
    studentName: "",
  });
  const handleCloseMenu = () => {
    setState((prev) => ({ ...prev, anchor: null }));
  };
  const {
    isFetching: getStudentByNameFetching,
    isLoading: getStudentByNameLoading,
  } = useQuery({
    queryKey: ["getStudentByName", state.name, state.page],
    queryFn: () =>
      getStudentByName({
        session: prevSession ?? selectedSession,
        studentName: state.name,
        page: state.page,
      }),
    onSuccess: (data) => {
      if (state.page !== 1) {
        setState((prev) => ({
          ...prev,
          studentData: { ...prev.studentData, ...data?.data },
        }));
      } else {
        setState((prev) => ({ ...prev, studentData: data?.data }));
      }
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: state.name ? true : false,
  });
  useEffect(() => {
    if (state.selectedStudentDocId) {
      returnStudentDocId(state.selectedStudentDocId);
    }
  }, [state.selectedStudentDocId]);

  return (
    <>
      <Menu
        open={Boolean(state.anchor)}
        anchorEl={state.anchor}
        onClose={handleCloseMenu}
        slotProps={{
          paper: {
            sx: { width: "200px" },
          },
        }}
      >
        <Box sx={{ px: 1 }}>
          <CustomTextField
            sx={{ mb: 0.5 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {(getStudentByNameFetching || getStudentByNameLoading) && (
                    <CircularProgress size={20} />
                  )}
                </InputAdornment>
              ),
            }}
            placeholder="Type Name"
            onChange={(e) => {
              setState((prev) => ({
                ...prev,
                name: e.target.value,
                ...(state.page !== 1 && { page: 1 }),
                ...(!e.target.value && {
                  studentData: {},
                  studentName: "",
                  selectedStudentDocId: "",
                }),
              }));
            }}
            value={state.name}
            fullWidth
            size="small"
          />
        </Box>
        <Box
          sx={{ maxHeight: "180px", overflowY: "auto", overflowX: "hidden" }}
        >
          {state.studentData?.list?.map((item, ind) => (
            <MenuItem
              divider
              value={item?.studentDocId}
              onClick={() => {
                setState((prev) => ({
                  ...prev,
                  selectedStudentDocId: item?.studentDocId,
                  studentName: item?.name,
                }));
                handleCloseMenu();
              }}
              key={item?.studentDocId}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Box>
                  <img
                    src={item?.profilePic}
                    alt=""
                    style={{
                      width: "30px",
                      height: "30px",
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
                  />
                </Box>
                <Box sx={{ ml: 1 }}>
                  <Typography
                    sx={{
                      fontWeight: 600,
                      fontSize: "14px",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                      width: "80%",
                    }}
                  >
                    {item?.name}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                      width: "80%",
                    }}
                  >
                    S/O:- {item?.fatherName}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                      width: "80%",
                    }}
                  >
                    Reg No:- {item?.registrationNumber}
                  </Typography>
                  <Typography
                    sx={{
                      fontWeight: 500,
                      fontSize: "12px",
                      whiteSpace: "normal",
                      overflowWrap: "break-word",
                      width: "80%",
                    }}
                  >
                    Adm No:- {item?.admissionNumber}
                  </Typography>
                </Box>
              </Box>
            </MenuItem>
          ))}
          {state.studentData?.pages !== state.studentData?.page && (
            <Stack direction={"row"} justifyContent={"center"}>
              <Typography
                sx={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                  textAlign: "center",
                  display: "inline-block",
                  mb: 1,
                }}
                onClick={() =>
                  setState((prev) => ({ ...prev, page: prev.page + 1 }))
                }
              >
                more
              </Typography>
            </Stack>
          )}
        </Box>
      </Menu>
      <Box>
        <TextFieldLabel title={"Search Student"} />
        <div
          onClick={(e) =>
            setState((prev) => ({ ...prev, anchor: e.currentTarget }))
          }
          style={{
            width: "200px",
            border: "1.5px solid #000",
            cursor: "pointer",
            padding: "8px 5px",
            borderRadius: "10px",
          }}
        >
          <Typography
            sx={{
              ml: 2,
              fontSize: "14px",
              fontWeight: 600,
              opacity: state.studentName ? 1 : 0.6,
            }}
          >
            {state.studentName ? state.studentName : "Search Student"}
          </Typography>
        </div>
      </Box>
    </>
  );
};

export default StudentSearchInput2;
