import { schoolTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let ledger_url = `${SCHOOL_BASE}/ledger`;

export const getAllCollectionHeadAndSubHead = (params) => {
  return schoolTokenAxios.get(`${ledger_url}/getAllCollectionHeadAndSubHead`, {
    params: params,
  });
};
export const getAllExpenseHeadAndSubHead = (params) => {
  return schoolTokenAxios.get(`${ledger_url}/getAllExpenseHeadAndSubHead`, {
    params: params,
  });
};
