import Amplify from "@aws-amplify/core";
import { Storage } from "aws-amplify";

export function configureAmplify() {
  Amplify.configure({
    Auth: {
      identityPoolId: "ap-south-1:bd0f39f4-c3f8-4299-88e7-08265ac72da5",
      region: "ap-south-1",
      userPoolId: "ap-south-1_se7i3f0wX",
      userPoolWebClientId: "6hheu8q03lg1j7uqd109u54ntp",
    },
    Storage: {
      bucket: "ekalsutra",
      region: "ap-south-1",
      identityPoolId: "ap-south-1:bd0f39f4-c3f8-4299-88e7-08265ac72da5",
    },
  });
}

//Configure Storage with S3 bucket information
export function SetS3Config(bucket, level) {
  Storage.configure({
    bucket: bucket,
    level: level,
    region: "us-east-1",
    identityPoolId: process.env.REACT_APP_identityPoolId,
  });
}
