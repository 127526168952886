import React, { useState, useEffect } from "react";
import { TextField, MenuItem } from "@mui/material";
import { useField, useFormikContext } from "formik";

export default function ClassSelectComponent({
  name,
  options,
  setStreamValue,
  ...otherProps
}) {
  const { setFieldValue } = useFormikContext();
  const [field, metaData] = useField(name);

  const handleChange = (e) => {
    setFieldValue(name, e.target.value);
  };
  const selectConfig = {
    ...field,
    ...otherProps,
    select: true,
    variant: "outlined",
    fullWidth: false,
    onChange: handleChange,
  };

  if (metaData && metaData.touched && metaData.error) {
    selectConfig.error = true;
    selectConfig.helperText = metaData.error;
  }
  return (
    <TextField {...selectConfig}>
      {options &&
        options?.map((opt, index) => {
          return (
            <MenuItem
              key={opt?.classDocId}
              value={opt?.classDocId}
              name={opt?.className_stream}
              onClick={() => {
                setStreamValue(opt?.classDocId);
              }}
            >
              {opt?.className_stream}
            </MenuItem>
          );
        })}
    </TextField>
  );
}
