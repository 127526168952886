import { FilterAlt } from "@mui/icons-material";
import { Button, Stack, Typography } from "@mui/material";
import React from "react";

const FilterButton = ({
  hanldeClick = () => {},
  count = 0,
  showCout = true,
}) => {
  return (
    <Button
      variant="contained"
      onClick={hanldeClick}
      color="secondary"
      sx={{
        width: { xs: "11rem", md: "20rem" },
        height: "4rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",
        borderRadius: "10px",
        background: "#0099ff",
        fontSize: { xs: "1rem", md: "1.2rem" },
      }}
    >
      <Stack direction={"row"} alignItems={"center"} gap={{ xs: 1, md: 3 }}>
        <Typography sx={{ fontSize: { xs: "1rem", md: "1.2rem" } }}>
          Filter
        </Typography>
        <FilterAlt />
        {showCout && (
          <Typography
            sx={{
              padding: "0 5px",
              background: "#fff",
              color: "black",
              borderRadius: "50%",
            }}
          >
            {count}
          </Typography>
        )}
      </Stack>
    </Button>
  );
};

export default FilterButton;
