import { Refresh } from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material";

import {
  getInvoice,
  getTransactionBySessionOrder,
} from "app/services/orgService/school.service";

import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CustomLoader from "../../CustomLoader";
import CommonDialog from "../CommonDialog";
import appRoutes from "app/config/routes";
import { IndianCurrency } from "app/utils/helper";

const TransactionDetailDialog = ({
  open = false,
  onClose = () => {},
  id = "",
  transactionList = [],
}) => {
  const [state, setState] = useState({
    list: [],
    invoiceIndex: 0,
    invoiceNumber: "",
  });
  const handleClose = () => {
    onClose();
    setState((prev) => ({
      ...prev,
      list: [],
      invoiceIndex: 0,
      invoiceNumber: "",
    }));
  };

  const { isLoading: getInvoiceLoading, isFetching: getInvoiceFetching } =
    useQuery({
      queryKey: ["getInvoice", state.invoiceNumber],
      queryFn: () => getInvoice({ invoiceNumber: state.invoiceNumber }),
      onSuccess: (success) => {
        state.list[state.invoiceIndex].createdBy =
          success?.data?.obj?.createdBy?.personName;
        state.list[state.invoiceIndex].createdOn =
          success?.data?.obj?.createdOn;
        setState((prev) => ({ ...prev, invoiceNumber: "", invoiceIndex: 0 }));
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
      enabled: state.invoiceNumber ? true : false,
    });
  useEffect(() => {
    setState((prev) => ({ ...prev, list: transactionList }));
  }, [open]);
  return (
    <>
      <CustomLoader show={getInvoiceLoading || getInvoiceFetching} />
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="800px"
        title="Transaction Detail"
      >
        <InvoiceHeading>
          <Grid container spacing={1} p={1} columns={13}>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">
                Invoice Date
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Typography className="invoice_head__text">
                Invoice Number
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">Paid Amt</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">Created By</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">Created On</Typography>
            </Grid>
            <Grid item xs={2}>
              <Typography className="invoice_head__text">Status</Typography>
            </Grid>
          </Grid>
          {state.list?.map((item, i) => (
            <Box key={i}>
              <Grid
                container
                spacing={1}
                p={1}
                columns={13}
                display={"flex"}
                alignItems={"center"}
              >
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {dayjs(item?.invoiceCreatedOn).format("DD MMM, YYYY")}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Link
                    to={`/${appRoutes.sessionOrderInvoice}/${String(
                      item?.invoiceNumber
                    ).replace(/\//g, " ")}`}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ fontWeight: "bold" }}
                  >
                    <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                      {item?.invoiceNumber}
                    </Typography>
                  </Link>
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {IndianCurrency(item?.totalAmount)}
                  </Typography>
                </Grid>
                <Grid item xs={2}>
                  {item?.createdBy ? (
                    <>
                      <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                        {item?.createdBy}
                      </Typography>
                    </>
                  ) : (
                    <IconButton
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          invoiceIndex: i,
                          invoiceNumber: item?.invoiceNumber,
                        }))
                      }
                    >
                      <Refresh sx={{ color: "blue" }} />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={2}>
                  {item?.createdOn ? (
                    <>
                      <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                        {dayjs(item?.createdOn).format("DD MMM, YYYY")}
                      </Typography>
                    </>
                  ) : (
                    <IconButton
                      onClick={() =>
                        setState((prev) => ({
                          ...prev,
                          invoiceIndex: i,
                          invoiceNumber: item?.invoiceNumber,
                        }))
                      }
                    >
                      <Refresh sx={{ color: "blue" }} />
                    </IconButton>
                  )}
                </Grid>
                <Grid item xs={2}>
                  <Typography sx={{ fontSize: "13px", fontWeight: 500 }}>
                    {item?.status}
                  </Typography>
                </Grid>
              </Grid>
              <Divider
                sx={{ background: (theme) => theme.palette.primary.main }}
              />
            </Box>
          ))}
        </InvoiceHeading>
      </CommonDialog>
    </>
  );
};

export default TransactionDetailDialog;
const InvoiceHeading = styled(Box)`
  background-color: rgba(246, 248, 252, 1);
  .invoice_head__text {
    color: #0c2f49;
    font-size: 14px;
    font-weight: 500;
  }
`;
