import { authTokenAxios } from "../axios";

const SCHOOL_BASE = process.env.REACT_APP_SCHOOL_BASE;

let user_url = `${SCHOOL_BASE}/user`;

export const getUserProfile = () => {
  return authTokenAxios.get(`${user_url}/getUserProfile`);
};

export const updateProfilePic = (payload) => {
  return authTokenAxios.put(`${user_url}/updateProfilePic`, payload);
};

export const updatePersonalInfo = (payload) => {
  return authTokenAxios.put(`${user_url}/updatePersonalInfo`, payload);
};

export const updateParentInfo = (payload) => {
  return authTokenAxios.put(`${user_url}/updateParentInfo`, payload);
};

export const updateAddressInfo = (payload) => {
  return authTokenAxios.put(`${user_url}/updateAddressInfo`, payload);
};
export const updateBankInfo = (payload) => {
  return authTokenAxios.put(`${user_url}/updateBankInfo`, payload);
};

export const addEducationInfo = (payload) => {
  return authTokenAxios.post(`${user_url}/addEducationInfo`, payload);
};

export const getEducationInfo = () => {
  return authTokenAxios.get(`${user_url}/getEducationInfo`);
};

export const deleteEducationInfo = (id) => {
  return authTokenAxios.delete(`${user_url}/deleteEducationInfo/${id}`);
};

export const addExperienceInfo = (payload) => {
  return authTokenAxios.post(`${user_url}/addExperienceInfo`, payload);
};

export const getExperienceInfo = () => {
  return authTokenAxios.get(`${user_url}/getExperienceInfo`);
};

export const deleteExperience = (id) => {
  return authTokenAxios.delete(`${user_url}/deleteExperience/${id}`);
};

export const getEmployementInfoByUser = (params) => {
  return authTokenAxios.get(`${user_url}/getEmployementInfoByUser`, {
    params: params,
  });
};

export const getUserAttendance = (params) => {
  return authTokenAxios.get(`${user_url}/getUserAttendance`, {
    params: params,
  });
};

export const getUserPayroll = (params) => {
  return authTokenAxios.get(`${user_url}/getUserPayroll`, {
    params: params,
  });
};

// Staff Leave Related

export const addStaffLeave = (payload) => {
  return authTokenAxios.post(`${user_url}/addStaffLeave`, payload);
};

export const getLeaveBySelf = (params) => {
  return authTokenAxios.get(`${user_url}/getLeaveBySelf`, {
    params: params,
  });
};

export const withdrawLeave = (id) => {
  return authTokenAxios.put(`${user_url}/withdrawLeave/${id}`);
};

export const updateStaffLeave = (payload) => {
  return authTokenAxios.put(`${user_url}/updateStaffLeave`, payload);
};

// Apply to School

export const getSchoolInfoByCode = (code) => {
  return authTokenAxios.get(`${user_url}/getSchoolInfoByCode/${code}`);
};

export const schoolApplyByStaff = (payload) => {
  return authTokenAxios.post(`${user_url}/schoolApplyByStaff`, payload);
};

export const getAllJoiningRequestByPersonDocId = (params) => {
  return authTokenAxios.get(`${user_url}/getAllJoiningRequestByPersonDocId`, {
    params: params,
  });
};

export const WithdrawJoiningRequest = (params) => {
  return authTokenAxios.get(`${user_url}/WithdrawJoiningRequest`, {
    params: params,
  });
};
