import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  Stack,
  Typography,
} from "@mui/material";
import { MenuProps2 } from "app/utils/helper";
import profilePlaceHolder from "assets/media/images/idCard-Images/userImage.png";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import appRoutes from "app/config/routes";
import { getStaffByName } from "app/services/schoolService/dashboard.service";
const StaffSeachDialogDashboard = ({
  isNavigateToProfile = false,
  returnResponse = () => {},
}) => {
  const [state, setState] = useState({
    name: "",
    page: 1,
    staffData: {},
    menuOpen: false,
    staffDetail: {},
  });
  const handleClickStaffCard = (item) => {
    setState((prev) => ({ ...prev, staffDetail: item }));
    returnResponse(item);
  };
  const {
    isFetching: getStaffByNameFetching,
    isLoading: getStaffByNameLoading,
  } = useQuery({
    queryKey: ["getStaffByName", state.name, state.page],
    queryFn: () =>
      getStaffByName({
        staffName: state.name,
      }),
    onSuccess: (data) => {
      if (state.page !== 1) {
        setState((prev) => ({
          ...prev,
          staffData: { ...prev.staffData, ...data?.data },
        }));
      } else {
        setState((prev) => ({ ...prev, staffData: data?.data }));
      }
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: state.name ? true : false,
  });

  return (
    <Box>
      <CustomSelectBox
        MenuProps={MenuProps2}
        value={state.staffDetail?.name ?? ""}
        fullWidth
        open={state.menuOpen}
        onOpen={() => setState((prev) => ({ ...prev, menuOpen: true }))}
        onClose={() => setState((prev) => ({ ...prev, menuOpen: false }))}
        placeholder="Select"
        onChange={(e) => e.stopPropagation()}
        renderValue={() => {
          return <Typography>{state.staffDetail?.name ?? ""}</Typography>;
        }}
      >
        <Box sx={{ px: 1 }}>
          <CustomTextField
            autoFocus
            sx={{ mb: 0.5 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {(getStaffByNameFetching || getStaffByNameLoading) && (
                    <CircularProgress size={20} />
                  )}
                </InputAdornment>
              ),
            }}
            placeholder={`Type Staff Name`}
            onChange={(e) => {
              e.stopPropagation();
              setState((prev) => ({
                ...prev,
                name: e.target.value,
                ...(state.page !== 1 && { page: 1 }),
                ...(!e.target.value && { staffData: {} }),
              }));
            }}
            value={state.name}
            fullWidth
            size="small"
          />
          {state.staffData?.list?.map((item, ind) =>
            isNavigateToProfile ? (
              <>
                <Box key={ind}>
                  <Link
                    style={{ textDecoration: "none" }}
                    to={`/${appRoutes.staffProfile}/${item?.staffDocId}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Grid
                      container
                      spacing={1}
                      display={"flex"}
                      alignItems={"center"}
                    >
                      <Grid
                        item
                        xs={10}
                        alignItems={"center"}
                        gap={2}
                        display={"flex"}
                        spacing={2}
                      >
                        <Grid item xs={2}>
                          <img
                            src={item?.profilePic ?? profilePlaceHolder}
                            alt="profile"
                            style={{
                              width: "40px",
                              height: "40px",
                              borderRadius: "50%",
                            }}
                          />
                        </Grid>
                        <Grid
                          item
                          xs={10}
                          sx={{
                            ml: 1,
                          }}
                        >
                          <Typography
                            sx={{ fontWeight: 600, fontSize: "14px" }}
                          >
                            {item?.name}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: 500, fontSize: "12px" }}
                          >
                            S/O:- {item?.fatherName}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: 500, fontSize: "12px" }}
                          >
                            Department:- {item?.departmentName ?? "--"}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: 500, fontSize: "12px" }}
                          >
                            Designation:- {item?.designation ?? "--"}
                          </Typography>
                          <Typography
                            sx={{ fontWeight: 500, fontSize: "12px" }}
                          >
                            Gender:- {item?.gender}
                          </Typography>
                        </Grid>
                      </Grid>
                      {/* check box */}
                      {/* <Grid item xs={2}>
                      <Checkbox
                        color="secondary"
                        checked={state.selectedStudent
                          .map((id) => id.studentDocId)
                          .includes(item?.studentDocId)}
                        onChange={() => {
                          if (
                            state.selectedStudent
                              .map((id) => id.studentDocId)
                              .includes(item?.studentDocId)
                          ) {
                            state.selectedStudent =
                              state.selectedStudent.filter(
                                (res) =>
                                  res?.studentDocId !== item?.studentDocId
                              );
                          } else {
                            state.selectedStudent.push(item);
                          }
                          if (!button) {
                            setSelectedStudent(
                              state.selectedStudent.map(
                                (res) => res.studentDocId
                              )
                            );
                          }
                          setState((prev) => ({ ...prev }));
                        }}
                      />
                    </Grid> */}
                    </Grid>
                  </Link>
                  <Divider
                    sx={{
                      my: 0.5,
                      backgroundColor: "#3b3939",
                    }}
                  />
                </Box>
              </>
            ) : (
              <>
                <Box
                  key={ind}
                  onClick={() => handleClickStaffCard(item)}
                  sx={{ cursor: "pointer" }}
                >
                  <Grid
                    container
                    spacing={1}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Grid
                      item
                      xs={10}
                      alignItems={"center"}
                      gap={2}
                      display={"flex"}
                      spacing={2}
                    >
                      <Grid item xs={2}>
                        <img
                          src={item?.profilePic ?? profilePlaceHolder}
                          alt="profile"
                          style={{
                            width: "40px",
                            height: "40px",
                            borderRadius: "50%",
                          }}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={10}
                        sx={{
                          ml: 1,
                        }}
                      >
                        <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                          {item?.name}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          S/O:- {item?.fatherName}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          Department:- {item?.departmentName ?? "--"}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          Designation:- {item?.designation ?? "--"}
                        </Typography>
                        <Typography sx={{ fontWeight: 500, fontSize: "12px" }}>
                          Gender:- {item?.gender}
                        </Typography>
                      </Grid>
                    </Grid>
                    {/* check box */}
                    {/* <Grid item xs={2}>
                      <Checkbox
                        color="secondary"
                        checked={state.selectedStudent
                          .map((id) => id.studentDocId)
                          .includes(item?.studentDocId)}
                        onChange={() => {
                          if (
                            state.selectedStudent
                              .map((id) => id.studentDocId)
                              .includes(item?.studentDocId)
                          ) {
                            state.selectedStudent =
                              state.selectedStudent.filter(
                                (res) =>
                                  res?.studentDocId !== item?.studentDocId
                              );
                          } else {
                            state.selectedStudent.push(item);
                          }
                          if (!button) {
                            setSelectedStudent(
                              state.selectedStudent.map(
                                (res) => res.studentDocId
                              )
                            );
                          }
                          setState((prev) => ({ ...prev }));
                        }}
                      />
                    </Grid> */}
                  </Grid>

                  <Divider
                    sx={{
                      my: 0.5,
                      backgroundColor: "#3b3939",
                    }}
                  />
                </Box>
              </>
            )
          )}
          {state.staffData?.pages !== state.staffData?.page && (
            <Stack direction={"row"} justifyContent={"center"}>
              <Typography
                sx={{
                  color: "blue",
                  textDecoration: "underline",
                  cursor: "pointer",
                  textAlign: "center",
                  display: "inline-block",
                  mb: 1,
                }}
                onClick={() =>
                  setState((prev) => ({ ...prev, page: prev.page + 1 }))
                }
              >
                more
              </Typography>
            </Stack>
          )}
        </Box>
      </CustomSelectBox>
    </Box>
  );
};

export default StaffSeachDialogDashboard;
