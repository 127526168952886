import React from "react";
import styled from "styled-components";
import certificateBG from "../../../../../../../assets/media/images/certificate-Images/CertificateBG.png";
import SchoolPrintHeader3 from "app/components/common/SchoolPrintHeader3";
import CertificateContentLabel from "app/components/common/CertificateContentLabel";
import { Box, Divider, Stack } from "@mui/material";
import { selectSelectedSchool } from "app/modules/schools/selectors";
import { useSelector } from "react-redux";
import {
  CertificateBottomDetails,
  ContentOfCertificate,
} from "../utils/CertificateHelper";

const TeacherExperienceCertificatePrint = ({
  studentData,
  color,
  printRef,
}) => {
  const schoolDetail = useSelector(selectSelectedSchool);
  return (
    <Wrapper ref={printRef}>
      <Box
        style={{
          width: "1056px",
          height: "816px",
          backgroundImage: `url(${certificateBG})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundBlendMode: "normal",
          backgroundRepeat: "no-repeat",
        }}
        sx={{
          alignSelf: "center",
          margin: "10px",
        }}
      >
        <Stack direction="row" justifyContent="center" height={"213px"}>
          <SchoolPrintHeader3 />
        </Stack>
        <Divider
          sx={{
            backgroundColor: "#000",
            height: "1px",
            width: "91%",
            margin: "0 auto",
            alignSelf: "center",
          }}
        />

        {/* title of the certificate */}
        <Stack direction="row" justifyContent="center" marginTop={1}>
          <Box>
            <Box className="heading">Teacher Experience Certificate</Box>
          </Box>
        </Stack>
        {/* certificate label */}
        <Stack direction="row" justifyContent="center">
          <CertificateContentLabel contentTittle={"TO WHOM IT MAY CONCERN"} />
        </Stack>

        {/* content for certificate */}
        <Stack
          direction="row"
          justifyContent="center"
          sx={{
            marginTop: 3,
            width: "88%",
            height: "270px",
            alignSelf: "center",
            alignItems: "center",
            marginLeft: "6%",
          }}
        >
          <ContentOfCertificate
            certificateType={"teacherExperience"}
            studentData={studentData}
            schoolDetail={schoolDetail}
          />
        </Stack>

        <CertificateBottomDetails />
      </Box>
    </Wrapper>
  );
};

export default TeacherExperienceCertificatePrint;

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;

  .heading {
    font-weight: 700;
    font-size: 38px;
  }
  .sub-heading {
    font-weight: 700;
    font-size: 10px;
  }
  .content {
    font-size: 20px;
    alingself: center;
  }
  .content-bold {
    font-weight: 700;
    font-size: 16px;
  }
  .content-italic {
    font-style: italic;
    font-size: 10px;
  }
  .content-underline {
    text-decoration: underline;
    font-size: 10px;
  }
  .content-uppercase {
    text-transform: uppercase;
    font-size: 10px;
  }

  @media (max-width: 768px) {
    .heading {
      font-size: 20px;
    }
    .sub-heading {
      font-size: 8px;
    }
    .content {
      font-size: 10px;
    }
    .content-bold {
      font-size: 8px;
    }
    .content-italic {
      font-size: 8px;
    }
    .content-underline {
      font-size: 8px;
    }
    .content-uppercase {
      font-size: 8px;
    }
  }
`;
