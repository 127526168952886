import { LoadingButton } from "@mui/lab";
import {
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Radio,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import DialogHeader from "app/components/common/DialogHeader";
import { selectActiveSession } from "app/modules/schools/selectors";
import {
  getFeeStructureGroup,
  linkFeeStructureWithGroup,
} from "app/services/management";
import { IndianCurrency } from "app/utils/helper";
import React, { useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const FeeLinkingDialog = ({
  open,
  handleClose,
  linkDialogData,
  refetch,
  feeStructureGroupDocId,
  feeStructureDocId,
}) => {
  const session = useSelector(selectActiveSession);
  const [state, setState] = useState({
    feeStructureGroupDocId: feeStructureGroupDocId,
    feeStructureDocId: feeStructureDocId,
  });
  const {
    isLoading: getFeeStructureGroupLoading,
    isFetching: getFeeStructureGroupFetching,
    data: getFeeStructureGroup_List,
  } = useQuery({
    queryKey: ["getFeeStructureGroup", open],
    queryFn: () =>
      getFeeStructureGroup({
        session: session,
        classDocId: linkDialogData?.classDocId,
        feeType: linkDialogData?.feeType,
      }),
    onSuccess: (success) => {},
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: open ? true : false,
  });
  const {
    isLoading: linkFeeStructureWithGroupLoading,
    mutate: linkFeeStructureWithGroupMutate,
  } = useMutation(linkFeeStructureWithGroup, {
    onSuccess: (success) => {
      toast.success(success?.data?.message);
      handleClose();
      refetch();
    },
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
  });
  const linkingColumns = useMemo(
    () => [
      {
        Cell: ({ cell }) =>
          Number(cell.row.id) + 1 < 10
            ? `0${Number(cell.row.id) + 1}`
            : Number(cell.row.id) + 1,
        header: "Sr.No",
        maxSize: 10,
      },
      {
        accessorKey: "className",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => (
          <Typography>{`${original?.className}-${original?.stream}-${original?.section}`}</Typography>
        ),
        header: "Class-Stream-Section",
        maxSize: 20,
      },
      {
        accessorFn: (row) => row?.feeType,
        accessorKey: "feeType",
        header: "Fee Type",
        maxSize: 20,
      },
      {
        accessorKey: "studentAdmissionType",
        accessorFn: (row) => row?.studentAdmissionType,
        header: "Admission Type",
        maxSize: 20,
      },
      {
        accessorKey: "gender",
        accessorFn: (row) => row?.gender,
        header: "Gender",
        maxSize: 20,
      },
      {
        accessorKey: "generalFeeAmount",
        accessorFn: (row) => IndianCurrency(row?.generalFeeAmount),
        header: "General Fee Amount",
        maxSize: 20,
      },
      // {
      //   accessorKey: "feeStructureCount",
      //   accessorFn: (row) => row?.feeStructureCount,
      //   header: "Fee Structure Count",
      //   maxSize: 20,
      // },
      {
        accessorKey: "feeOccurrence",
        header: "Fee Occurrence",
        maxSize: 20,
      },
      {
        accessorKey: "createdBy.personName",
        accessorFn: (row) => row?.createdBy?.personName,
        header: "Created By",
        maxSize: 20,
      },

      {
        header: "Select",
        maxSize: 10,
        Cell: ({ cell }) => {
          const { original } = cell.row;
          return (
            <Radio
              color="secondary"
              checked={
                state.feeStructureGroupDocId === original?._id ? true : false
              }
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  feeStructureGroupDocId: original?._id,
                }))
              }
            />
          );
        },
      },
    ],
    [state.feeStructureGroupDocId]
  );
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      PaperProps={{
        sx: { minWidth: "80vw" },
      }}
    >
      <DialogTitle sx={{ p: "0 !important" }}>
        <DialogHeader title="Link Fee Structure" handleClose={handleClose} />
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mt: 1 }}>
          <Typography sx={{ fontWeight: 600, fontSize: "16px", mb: 0.5 }}>
            Fee Structure Detail :
          </Typography>
          <LinkingHeaderWrapper>
            <Grid container spacing={1.3}>
              <Grid item xs={3}>
                <LinkingLabel>Fee Type</LinkingLabel>
                <LinkingText>{linkDialogData?.feeType}</LinkingText>
              </Grid>
              <Grid item xs={3}>
                <LinkingLabel>Fee Relaxation Category</LinkingLabel>
                <LinkingText>
                  {linkDialogData?.feeRelaxationCategory}
                </LinkingText>
              </Grid>
              <Grid item xs={3}>
                <LinkingLabel>Class-Stream-Section</LinkingLabel>
                <LinkingText>{linkDialogData?.class_stream_sec}</LinkingText>
              </Grid>
              <Grid item xs={3}>
                <LinkingLabel>General Fee Amount</LinkingLabel>
                <LinkingText>
                  {IndianCurrency(linkDialogData?.generalFeeAmount)}
                </LinkingText>
              </Grid>
              <Grid item xs={3}>
                <LinkingLabel>Category Discount</LinkingLabel>
                <LinkingText>
                  {IndianCurrency(linkDialogData?.categoryDiscountAmount)}
                </LinkingText>
              </Grid>
              <Grid item xs={3}>
                <LinkingLabel>Tax Rate</LinkingLabel>
                <LinkingText>{linkDialogData?.taxRate}%</LinkingText>
              </Grid>
              <Grid item xs={3}>
                <LinkingLabel>Final Fee Amount</LinkingLabel>
                <LinkingText>
                  {IndianCurrency(linkDialogData?.feeAmount)}
                </LinkingText>
              </Grid>
            </Grid>
          </LinkingHeaderWrapper>
          <Box>
            {getFeeStructureGroupLoading || getFeeStructureGroupFetching ? (
              <Stack direction={"row"} justifyContent={"center"} py={2}>
                <CircularProgress />
              </Stack>
            ) : (
              <Box sx={{ mt: 1 }}>
                <CommonTable1
                  columns={linkingColumns}
                  data={getFeeStructureGroup_List?.data?.list}
                  maxHeight="40vh"
                />
                {state.feeStructureGroupDocId && (
                  <Stack direction={"row"} justifyContent={"flex-end"} py={2}>
                    <LoadingButton
                      onClick={() =>
                        linkFeeStructureWithGroupMutate({
                          feeStructureDocId: feeStructureDocId,
                          feeStructureGroupDocId: state.feeStructureGroupDocId,
                        })
                      }
                      loading={linkFeeStructureWithGroupLoading}
                      color="secondary"
                      size="large"
                      variant="outlined"
                      sx={{ fontSize: "14px", textTransform: "capitalize" }}
                    >
                      Submit
                    </LoadingButton>
                  </Stack>
                )}
              </Box>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default FeeLinkingDialog;
const LinkingHeaderWrapper = styled(Box)`
  border-radius: 15px;
  border: 1px solid #b6c8d6;
  background: #fff;
  padding: 15px;
`;
const LinkingLabel = styled(Typography)`
  color: rgba(27, 50, 60, 0.75);
  font-size: 14px;
  font-weight: 400;
`;
const LinkingText = styled(Typography)`
  color: rgba(27, 50, 60, 1);
  font-size: 16px;
  font-weight: 600;
`;
