import React, { useEffect } from "react";
import CommonDialog from "./CommonDialog";
import {
  DialogActions,
  DialogContent,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { useMutation, useQuery } from "react-query";
import { toast } from "react-toastify";
import {
  addCollectionSubHead,
  getAllCollectionHead,
} from "app/services/ledgerAccount";
import TextFieldLabel from "../TextFieldLabel";
import CustomLoader from "../CustomLoader";
const AddCollectionSubHead = ({
  refetch = () => {},
  open = false,
  handleClose = () => {},
  headId = "",
}) => {
  const formik = useFormik({
    initialValues: {
      heads: [],
      headId: "",
      sName: "",
      desc: "",
    },
    validationSchema: yup.object({
      headId: yup.string().required("Head name is required"),
      sName: yup.string().required("Sub Head name is required"),
      desc: yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      const obj = {
        collectionHeadDocId: values.headId,
        collectionSubHeadName: values.sName,
        description: values.desc,
      };
      addCollectionSubHeadMutate(obj);
    },
  });
  const handleCloseDialog = () => {
    handleClose();
    formik.resetForm();
  };

  const {
    mutate: addCollectionSubHeadMutate,
    isLoading: addCollectionSubHeadLoading,
  } = useMutation(addCollectionSubHead, {
    onSuccess: (res) => {
      refetch();
      toast.success(res?.data?.message);
      handleCloseDialog();
      formik.resetForm();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const {
    isLoading: getAllCollectionHeadLoading,
    isFetching: getAllCollectionHeadFetching,
  } = useQuery({
    queryKey: ["getAllCollectionHead", open],
    queryFn: () => getAllCollectionHead(),
    onSuccess: (res) => {
      formik.setValues((prev) => ({
        ...prev,
        heads: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.collectionHeadName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
    enabled: open ? true : false,
  });
  useEffect(() => {
    if (headId) {
      formik.setValues((prev) => ({ ...prev, headId: headId }));
    }
  }, [headId, open]);

  return (
    <>
      <CustomLoader
        show={getAllCollectionHeadLoading || getAllCollectionHeadFetching}
      />
      <CommonDialog
        open={open}
        onClose={handleCloseDialog}
        title="Add Sub Head"
      >
        <DialogContent>
          <form id="c-sub-head" onSubmit={formik.handleSubmit}>
            <Stack mt={2}>
              <TextFieldLabel title={"Collection Head Name"} required />
              <CustomSelectBox
                name="headId"
                MenuProps={MenuProps}
                onBlur={formik.handleBlur}
                displayEmpty
                value={formik.values.headId}
                onChange={formik.handleChange}
                error={formik.touched.headId && formik.errors.headId}
              >
                <MenuItem value="">Select</MenuItem>
                {formik.values.heads?.map((item) => (
                  <MenuItem value={item.id}>{item.name}</MenuItem>
                ))}
              </CustomSelectBox>
              {formik.touched.headId && formik.errors.headId && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.headId}
                </Typography>
              )}
            </Stack>
            <Stack mt={2}>
              <TextFieldLabel title={"Sub Head Name"} required />
              <CustomTextField
                name="sName"
                placeholder="Enter SubHead name"
                error={formik.touched.sName && formik.errors.sName}
                value={formik.values.sName}
                onChange={formik.handleChange}
              />
              {formik.touched.sName && formik.errors.sName && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.sName}
                </Typography>
              )}
            </Stack>
            <Stack mt={2}>
              <TextFieldLabel title={"Description"} required />
              <CustomTextField
                name="desc"
                placeholder="Enter Description"
                value={formik.values.desc}
                onChange={formik.handleChange}
                error={formik.touched.desc && formik.errors.desc}
              />
              {formik.touched.desc && formik.errors.desc && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.desc}
                </Typography>
              )}
            </Stack>
          </form>
        </DialogContent>
        <DialogActions>
          <LoadingButton
            loading={addCollectionSubHeadLoading}
            form="c-sub-head"
            type="submit"
            variant="contained"
            color="secondary"
            size="large"
          >
            Submit
          </LoadingButton>
        </DialogActions>
      </CommonDialog>
    </>
  );
};

export default AddCollectionSubHead;
