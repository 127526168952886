import {
  Stack,
  Button,
  MenuItem,
  Box,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { selectActiveSession } from "app/modules/schools/selectors";
import {
  deleteExpenseSubHead,
  getAllExpenseHead,
  getAllExpenseSubHead,
} from "app/services/ledgerAccount";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import React, { useEffect, useState } from "react";
import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import CustomLoader from "app/components/common/CustomLoader";
import { queryClient } from "App";
import DialogHeader from "app/components/common/DialogHeader";
import * as XLSX from "xlsx";
import { format } from "date-fns";
import CommonTable1 from "app/components/Tables/CommonTable1";
import AddExpenseSubHead from "app/components/common/Dialogs/AddExpenseSubHead";
import ExportButtonGroup from "app/components/common/ExportButtonGroup";
import appRoutes from "app/config/routes";
import TableActionButtons from "app/components/common/TableActionButtons";
import DeleteDialog from "app/components/ConfirmationDialog/DeleteDialog";
import ExpensesDialog from "../expenses/ExpensesDialog";

const ExpenseSubHeadsPage = ({ id }) => {
  const selectedSession = useSelector(selectActiveSession);
  const [expensesDialog, setExpenseDialogs] = useState({
    add: false,
    headId: "",
    subHead: "",
  });
  const [state, setState] = useState({
    sub: id,
    tableData: null,
  });

  const formatDate = (data) => {
    const date = new Date(data);
    return format(date, "yyyy-MM-dd");
  };

  const [dialogs, setDialogs] = useState({
    add: false,
    expenseSubHeadDelete: false,
    expenseSubHeadId: "",
  });

  const {
    isLoading,
    isFetching,
    refetch: getAllExpenseSubHeadRefetch,
  } = useQuery({
    queryKey: ["getAllExpenseSubHead"],
    queryFn: () =>
      getAllExpenseSubHead({
        expenseHeadDocId: id,
        withAmount: "YES",
        session: selectedSession,
      }),
    onSuccess: (res) => {
      setState((prev) => ({ ...prev, tableData: res?.data?.list }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  useQuery({
    queryKey: ["getAllExpenseHead"],
    queryFn: () => getAllExpenseHead(),
    onSuccess: (res) => {
      setState((prev) => ({
        ...prev,
        heads: res?.data?.list?.map((item) => ({
          id: item._id,
          name: item.expenseHeadName,
        })),
      }));
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const { mutate: getAllExpenseSubHeadMutate } = useMutation(
    getAllExpenseSubHead,
    {
      onSuccess: (res) => {
        setState((prev) => ({ ...prev, tableData: res?.data?.list }));
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    }
  );

  const {
    mutate: deleteExpenseSubHeaddMutate,
    isLoading: deleteExpenseSubHeaddLoading,
  } = useMutation(deleteExpenseSubHead, {
    onSuccess: (res) => {
      toast.success(res?.data?.message);
      setDialogs((prev) => ({
        ...prev,
        expenseSubHeadDelete: false,
        expenseSubHeadId: "",
      }));
      getAllExpenseSubHeadRefetch();
    },
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const columns = [
    {
      header: "Sr.No",
      Cell: ({ cell }) => (
        <Typography>
          {parseInt(cell?.row?.id) < 9
            ? `0${parseInt(cell?.row?.id) + 1}`
            : parseInt(cell?.row?.id) + 1}
        </Typography>
      ),
      size: 30,
    },

    {
      header: "Sub Head Name",
      accessorKey: "subhead",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original?.sub}</Typography>;
      },
      size: 60,
    },
    {
      header: "Description",
      accessorKey: "desc",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original.desc}</Typography>;
      },
      size: 70,
    },
    {
      header: "Amount",
      accessorKey: "amt",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return (
          <Stack direction={"row"} gap={1}>
            <Link
              to={`/${appRoutes.expenses}?head=${id}&subhead=${original?.id}`}
            >
              {original.amt}
            </Link>
            <Typography
              sx={{
                color: "blue",
                fontStyle: "italic",
                cursor: "pointer",
                textDecoration: "underline",
              }}
              onClick={() =>
                setExpenseDialogs((prev) => ({
                  ...prev,
                  subHead: original?.id,
                  add: true,
                }))
              }
            >
              +Add more
            </Typography>
          </Stack>
        );
      },
      size: 40,
    },
    {
      header: "Created By",
      accessorKey: "by",
      Cell: ({
        cell: {
          row: { original },
        },
      }) => {
        return <Typography>{original.by}</Typography>;
      },
      size: 50,
    },
    {
      header: "Action",
      maxSize: 50,
      Cell: ({ cell }) => {
        return (
          <TableActionButtons
            showDelete
            handleDelete={() => handleDelete(cell?.row?.original)}
          />
        );
      },
    },
  ];

  const handleDelete = (data) => {
    setDialogs((prev) => ({
      ...prev,
      expenseSubHeadDelete: true,
      expenseSubHeadId: data.id,
    }));
  };

  const tableData = state?.tableData?.map((item) => ({
    id: item._id,
    head: item?.expenseHeadDetail?.expenseHeadName || "--",
    sub: item?.expenseSubHeadName || "--",
    desc: item?.description || "--",
    amt: IndianCurrency(item?.totalAmount),
    by: item?.createdBy?.personName || "--",
  }));

  let exportArray = state.tableData?.map((item) => {
    return {
      Expense_Head_Name: item?.expenseHeadDetail?.expenseHeadName || "",
      Head_Description: item?.expenseHeadDetail?.description || "",
      Updated_By: item?.expenseHeadDetail?.updatedBy?.personName || "",
      Updated_On: item?.expenseHeadDetail?.updatedOn
        ? formatDate(item?.expenseHeadDetail?.updatedOn)
        : "",
      Updated_Times: item?.expenseHeadDetail?.updatedTimes || 0,
      Expense_Sub_Head_Name: item?.expenseSubHeadName || "",
      Created_By: item?.createdBy?.personName || "",
      Created_On: item?.createdOn ? formatDate(item?.createdOn) : "",
      Description: item?.description || "",
      Total_Amount: item?.totalAmount || 0,
    };
  });

  let header = [
    "Expense_Head_Name",
    "Head_Description",
    "Updated_By",
    "Updated_On",
    "Updated_Times",
    "Expense_Sub_Head_Name",
    "Created_By",
    "Created_On",
    "Description",
    "Total_Amount",
  ];

  const [selectedOptions, setSelectedOptions] = useState(header);

  const handleExport = () => {
    const filteredExportArray = exportArray.map((item) => {
      const filteredItem = {};
      selectedOptions.forEach((option) => {
        filteredItem[option] = item[option];
      });
      return filteredItem;
    });

    const worksheet = XLSX.utils.json_to_sheet(filteredExportArray);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "All Collections");
    XLSX.writeFile(workbook, "Collections.xlsx");
  };
  const handleCloseDeleteExpenseDialog = () => {
    setDialogs((prev) => ({
      ...prev,
      expenseSubHeadDelete: false,
    }));
  };

  const handledeleteExpenseHead = () => {
    deleteExpenseSubHeaddMutate(dialogs?.expenseSubHeadId);
  };
  const handleCheckboxChange = (option, isChecked) => {
    if (isChecked) {
      setSelectedOptions((prevSelectedOptions) => [
        ...prevSelectedOptions,
        option,
      ]);
    } else {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter(
          (selectedOption) => selectedOption !== option
        )
      );
    }
  };
  useEffect(() => {
    setExpenseDialogs((prev) => ({ ...prev, headId: id }));
  }, []);

  return (
    <>
      <CustomLoader show={isLoading || isFetching} />
      <ExpensesDialog
        dialogs={expensesDialog}
        setDialogs={setExpenseDialogs}
        refetch={() => getAllExpenseSubHeadRefetch()}
      />
      <AddExpenseSubHead
        open={dialogs.add}
        handleClose={() => {
          setDialogs((prev) => ({ ...prev, add: false }));
        }}
        headId={id}
        refetch={() => getAllExpenseSubHeadRefetch()}
      />
      {/* Delete Dialog Confirmation of Collection*/}
      <DeleteDialog
        open={dialogs.expenseSubHeadDelete}
        handleClose={handleCloseDeleteExpenseDialog}
        handleDelete={handledeleteExpenseHead}
        loading={deleteExpenseSubHeaddLoading}
      />
      <Dialog
        open={dialogs.export}
        onClose={() =>
          setDialogs((prev) => ({
            ...prev,
            export: false,
          }))
        }
        PaperProps={{
          sx: { minWidth: "400px" },
        }}
      >
        <DialogHeader
          title="Select Information to export"
          handleClose={() =>
            setDialogs((prev) => ({
              ...prev,
              export: false,
            }))
          }
        />
        <DialogContent>
          <Box>
            {header.map((option, index) => (
              <Stack direction="row" key={index}>
                <FormControlLabel
                  key={option}
                  control={
                    <Checkbox
                      checked={selectedOptions.includes(option)}
                      onChange={(e) =>
                        handleCheckboxChange(option, e.target.checked)
                      }
                    />
                  }
                  label={option}
                />
              </Stack>
            ))}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            sx={{ mb: 1 }}
            onClick={() => {
              setSelectedOptions(header);
              handleExport();
              setDialogs((prev) => ({
                ...prev,
                export: false,
              }));
            }}
          >
            DownLoad
          </Button>
        </DialogActions>
      </Dialog>

      <Stack gap={2} p={2}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
        >
          <CustomSelectBox
            displayEmpty
            sx={{ width: "15rem" }}
            size="medium"
            MenuProps={MenuProps}
            value={state?.sub}
            onChange={(e) => {
              setState((prev) => ({ ...prev, sub: e.target.value }));
              getAllExpenseSubHeadMutate({
                expenseHeadDocId: e.target.value,
                withAmount: "YES",
                session: selectedSession,
              });
            }}
          >
            <MenuItem value="" disabled>
              Select
            </MenuItem>
            {state?.heads?.map((item, index) => (
              <MenuItem value={item.id} key={index}>
                {item.name}
              </MenuItem>
            ))}
          </CustomSelectBox>
          <Stack direction={"row"} alignItems={"center"} gap={1}>
            <Button
              variant="outlined"
              color="secondary"
              size="large"
              onClick={() => {
                // getAllExpenseHeadQ()
                queryClient.invalidateQueries({
                  queryKey: "getAllExpenseHead",
                });
                setDialogs((prev) => ({ ...prev, add: true }));
              }}
            >
              + Add New Sub Head
            </Button>

            <ExportButtonGroup
              showExcel
              showPdf={false}
              handleClickExcel={() =>
                setDialogs((prev) => ({ ...prev, export: true }))
              }
            />
          </Stack>
        </Stack>
        <CommonTable1
          columns={columns}
          data={tableData || []}
          maxHeight="70vh"
        />
      </Stack>
    </>
  );
};

export default ExpenseSubHeadsPage;
