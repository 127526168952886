import { Box, styled } from "@mui/material";
import { THEME } from "app/config/constants";

const BackgroundBox = styled(Box)`
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: ${THEME.boxShadow1};
  margin-bottom: 8px;
`;
const FeeStructureTableWrapper = styled(Box)`
  border-radius: 15px;
  border: 1px solid #b6c8d6;
  background: #fff;
  margin: 10px auto;
  .inner_box__head {
    background-color: #f6f8fc;
    border-radius: 15px 15px 0 0;
    padding: 10px;
    border-bottom: 1px solid #b6c8d6;
  }
  .inner_box_head_title {
    color: #0c2f49;
    font-size: 14px;
    font-weight: 500;
  }
  .inner_box_notFound {
    color: #0c2f49;
    font-size: 16px;
    font-weight: 600;
    opacity: 0.75;
    text-align: center;
    padding: 20px;
  }
`;
export { BackgroundBox, FeeStructureTableWrapper };
