import { Close } from "@mui/icons-material";
import {
  Box,
  colors,
  Divider,
  Drawer,
  Grid,
  IconButton,
  Stack,
  Typography,
} from "@mui/material";
import CommonTable1 from "app/components/Tables/CommonTable1";
import { FormatFullName } from "app/utils/helper";
import dayjs from "dayjs";
import React from "react";
import styled from "styled-components";
const PayrollDrawer = ({ open, handleClose, data, staffData }) => {
  console.log(data, "in data");
  const columns = [
    {
      header: "Sr.No",
      Cell: ({ cell }) => (
        <Typography>
          {parseInt(cell?.row?.id) < 9
            ? `0${parseInt(cell?.row?.id) + 1}`
            : parseInt(cell?.row?.id) + 1}
        </Typography>
      ),
      size: 20,
    },

    {
      accessorKey: "date",
      header: "Date",
      Cell: ({ cell }) => {
        const { original } = cell?.row;
        return (
          <Typography>
            {original?.date
              ? dayjs(original?.date).format("DD MMM, YYYY")
              : "--"}
          </Typography>
        );
      },
      size: 20,
    },
    {
      accessorKey: "inTime",
      header: "First Punch",
      Cell: ({ cell }) => {
        const { original } = cell?.row;
        return (
          <Typography>
            {original?.inTime
              ? dayjs(original?.inTime).format("DD MMM, hh:mm:ss A")
              : "--"}
          </Typography>
        );
      },
      size: 20,
    },
    {
      header: "Last Punch",
      accessorKey: "outTime",
      Cell: ({ cell }) => {
        const { original } = cell?.row;
        return (
          <Typography>
            {original?.outTime
              ? dayjs(original?.outTime).format("DD MMM, hh:mm:ss A")
              : "--"}
          </Typography>
        );
      },
      size: 50,
    },
    // {
    //   header: "Medium",
    //   Cell: ({ cell }) => {
    //     const { original } = cell?.row;
    //     return <Typography>{original?.medium ?? "--"}</Typography>;
    //   },
    //   size: 50,
    // },
    {
      header: "Attendance",
      Cell: ({ row }) => (
        <Typography>
          {row?.original?.isHoliday ? (
            <Typography className="text_typo" sx={{ color: colors.blue[600] }}>
              Holiday
            </Typography>
          ) : row?.original?.onLeave === true ? (
            <Typography
              className="text_typo"
              sx={{ color: colors.yellow[600] }}
            >
              On Leave
            </Typography>
          ) : row?.original?.onHalfDay ? (
            <Typography className="text_typo">Half Day</Typography>
          ) : row?.original?.attendance === "" ||
            row?.original?.attendance === "Absent" ? (
            <Typography className="text_typo" sx={{ color: colors.red[600] }}>
              Absent
            </Typography>
          ) : row?.original?.attendance === "Present" ? (
            <Typography className="text_typo" sx={{ color: colors.green[600] }}>
              Present
            </Typography>
          ) : (
            "--"
          )}
        </Typography>
      ),
      size: 20,
    },
  ];
  return (
    <>
      <Drawer
        anchor="right"
        onClose={handleClose}
        open={open}
        PaperProps={{
          sx: {
            maxWidth: "85%",
          },
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          sx={{
            mt: 1,
            ml: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              fontSize: "18px",
            }}
          >
            Attendance List
          </Typography>
          <IconButton onClick={() => handleClose()}>
            <Close
              sx={{
                fontWeight: "bold",
                border: "1px solid",
                width: "20px",
                height: "20px",
              }}
            />
          </IconButton>
        </Stack>
        <Divider sx={{ backgroundColor: "#0C2F49" }} />
        <Stack
          sx={{
            m: 1,
          }}
        >
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            Staff Information
          </Typography>
        </Stack>

        <DialogStyledWrapper>
          <Stack className="student_detail__boxWrap">
            <Grid container spacing={1}>
              <Grid item xs={2} display={"flex"} justifyContent={"center"}>
                {staffData?.staffDetail?.profilePic ? (
                  <img
                    alt=""
                    src={staffData?.staffDetail?.profilePic}
                    className="demo_image"
                    style={{ objectFit: "contain" }}
                  />
                ) : (
                  <Box className="demo_image" />
                )}
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    color: "#0C2F49",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Staff Name
                </Typography>
                <Typography
                  sx={{
                    color: "0C2F49",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {FormatFullName(
                    staffData?.staffDetail?.firstName,
                    staffData?.staffDetail?.middleName,
                    staffData?.staffDetail?.lastName
                  )}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    color: "#0C2F49",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Employee iD
                </Typography>
                <Typography
                  sx={{
                    color: "0C2F49",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {staffData?.employeeId}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    color: "#0C2F49",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Mobile number
                </Typography>
                <Typography
                  sx={{
                    color: "0C2F49",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {staffData?.staffDetail?.mobileNumber ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    color: "#0C2F49",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Email ID
                </Typography>
                <Typography
                  sx={{
                    color: "0C2F49",
                    fontWeight: 600,
                    fontSize: "14px",

                    overflowWrap: "anywhere",
                  }}
                >
                  {staffData?.staffDetail?.email ?? "--"}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  sx={{
                    color: "#0C2F49",
                    fontWeight: 500,
                    fontSize: "14px",
                  }}
                >
                  Pan number
                </Typography>
                <Typography
                  sx={{
                    color: "0C2F49",
                    fontWeight: 600,
                    fontSize: "14px",
                  }}
                >
                  {staffData?.staffDetail?.PAN ?? "--"}
                </Typography>
              </Grid>
            </Grid>
          </Stack>
        </DialogStyledWrapper>
        <Data>
          {/* data */}
          <div>
            <div className="sub">Total Working Days</div>
            <div className="content">{data?.attendenceSummary?.totalDays}</div>
          </div>
          <div>
            <div className="sub">Total Present Days</div>
            <div className="content">
              {data?.attendenceSummary?.presentDays}
            </div>
          </div>
          <div>
            <div className="sub">Total Half Days</div>
            <div className="content">{data?.attendenceSummary?.halfDays}</div>
          </div>
          <div>
            <div className="sub">Total Absent</div>
            <div className="content">{data?.attendenceSummary?.absentDays}</div>
          </div>
          <div>
            <div className="sub">Total Paid Leave</div>
            <div className="content">{data?.attendenceSummary?.leaveDays}</div>
          </div>
          <div>
            <div className="sub">Total Holidays</div>
            <div className="content">{data?.attendenceSummary?.holidays}</div>
          </div>

          <div></div>
        </Data>
        <Wrapper>
          <Box>
            <CommonTable1
              columns={columns}
              data={data?.attendanceList}
              maxHeight="60vh"
            />
          </Box>
        </Wrapper>
      </Drawer>
    </>
  );
};

export default PayrollDrawer;

const Wrapper = styled.div`
  padding: 10px;
`;
const Data = styled.div`
  padding: 5px 20px;
  font-family: "Inter";
  font-style: normal;
  color: #0c2f49;
  display: flex;
  gap: 20px;
  .sub {
    opacity: 0.75;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
  }
  .content {
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;
    margin-bottom: 20px;
  }
`;
const DialogStyledWrapper = styled("div")`
  .student_detail__boxWrap {
    border-radius: 15px;
    border: 1px solid #d0d0e2;
    background: rgba(208, 208, 226, 0.08);
    padding: 10px;
    margin: 10px;
  }
  .demo_image {
    border-radius: 11px;
    background: #d9d9d9;
    height: 60px;
    width: 60px;
  }
`;
