import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Container, Tab, Typography, useMediaQuery } from "@mui/material";
import { TabsWrapper } from "assets/styles/globalStyledComponent";
import React from "react";
import { Helmet } from "react-helmet";
import FirmInvoiceTable from "./FirmInvoiceTable";

const FirmInvoice = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const [value, setValue] = React.useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Helmet>
        <title>Firm Invoice</title>
      </Helmet>

      <Container maxWidth="xl" disableGutters={isMobile ? true : false}>
        <TabContext value={value}>
          <TabsWrapper>
            <TabList onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Firm Invoice" value="1" />
              {/* <Tab label="Firm Summary" value="2" />
              <Tab label="Classwise Summary" value="3" />
              <Tab label="Staffwise Summary" value="4" /> */}
            </TabList>
          </TabsWrapper>
          <TabPanel value="1" sx={{ p: "0 !important" }}>
            <Box sx={{ my: 1.5, px: 1 }}>
              <FirmInvoiceTable />
            </Box>
          </TabPanel>
        </TabContext>
      </Container>
    </>
  );
};

export default FirmInvoice;
