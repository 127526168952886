import React, { useState } from "react";
import CommonDialog from "../../CommonDialog";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { TabsWrapper } from "assets/styles/globalStyledComponent";
import { Box, DialogContent, Tab } from "@mui/material";
import BuyBookContent from "./BuyBookContent";
import CustomizeBookContent from "./CustomizeBookContent";
const AddBookDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  classData = [],
}) => {
  const [value, setValue] = useState("1");

  const handleClose = () => {
    onClose();
  };

  return (
    <>
      <CommonDialog
        open={open}
        onClose={handleClose}
        minWidth="700px"
        title="Book Account Detail"
      >
        <TabContext value={value}>
          <Box sx={{ px: 4 }}>
            <TabsWrapper>
              <TabList
                onChange={(e, newValue) => setValue(newValue)}
                aria-label="basic tabs example"
              >
                <Tab label="Buy Book" value="1" />
                <Tab label="Customize" value="2" />
              </TabList>
            </TabsWrapper>
          </Box>
          <DialogContent sx={{ pt: "0 !important" }}>
            <TabPanel value="1" sx={{ p: "0 !important" }}>
              <BuyBookContent
                classData={classData}
                refetch={refetch}
                dialogClose={handleClose}
                open={open}
              />
            </TabPanel>
            <TabPanel value="2" sx={{ p: "0 !important" }}>
              <CustomizeBookContent
                classData={classData}
                refetch={refetch}
                dialogClose={handleClose}
              />
            </TabPanel>
          </DialogContent>
        </TabContext>
      </CommonDialog>
    </>
  );
};

export default AddBookDialog;
