import React, { useEffect } from "react";
import Typography from "@mui/material/Typography";
import {
  Button,
  useMediaQuery,
  Container,
  Grid,
  MenuItem,
  FormControlLabel,
  Checkbox,
  Box,
  Stack,
} from "@mui/material";
import { useMutation, useQuery } from "react-query";
import styled from "styled-components";
import { selectActiveSession } from "app/modules/schools/selectors";
import { useNavigate } from "react-router-dom";
import { addStaffBySchoolAdmin } from "app/services/hr";
import * as yup from "yup";
import { useSelector } from "react-redux";
import { useFormik } from "formik";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { MenuProps, emailRegex } from "app/utils/helper";
import {
  Distrct,
  GenderChoice,
  States,
  primaryRoles,
} from "app/config/administrativeConstants";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { getAllDepartments } from "app/services/management";
import { toast } from "react-toastify";
import { THEME } from "app/config/constants";
import { LoadingButton } from "@mui/lab";
import PreviewImage from "app/components/common/PreviewImage";
import uploadFile from "app/utils/uploadFile";
import CustomLoader from "app/components/common/CustomLoader";
import { getDesignationDropdown } from "app/services/schoolService/hr.service";
const validationSchema = yup.object({
  firstName: yup.string("Enter First Name").required("First Name is required"),
  // DOB: yup.string("Enter Date of Birth").required("Date of Birth is required"),
  email: yup
    .string()
    .matches(emailRegex, "Invalid email")
    .required("Email is required"),
  gender: yup.string("Select Gender").required("Gender is required"),

  mobileNumber: yup
    .string("Enter Mobile Number")
    .required("Mobile Number is required")
    .matches(/^\d{10}$/, "Invalid Mobile Number"),

  mother_firstName: yup
    .string("Enter Mother's First Name")
    .required("Mother's First Name is required"),
  mother_mobileNumber: yup
    .string("Enter Mobile Number")
    .matches(/^\d{10}$/, "Invalid Mother's Mobile Number"),

  father_firstName: yup
    .string("Enter Father's First Name")
    .required("Father's First Name is required"),
  father_mobileNumber: yup
    .string("Enter Mobile Number")
    .matches(/^\d{10}$/, "Invalid Father's Mobile Number"),

  current_address: yup.string().required(" Address is required"),
  current_state: yup.string().required("State is required"),
  current_dist: yup.string().required("District is required"),
  current_pinCode: yup
    .string()
    .required("Pincode is required")
    .matches(/^\d{6}$/, "Invalid PinCode"),
  permanent_address: yup.string().required(" Address is required"),
  permanent_state: yup.string().required("State is required"),
  permanent_dist: yup.string().required("District is required"),
  permanent_pinCode: yup
    .string()
    .required("Pincode is required")
    .matches(/^\d{6}$/, "Invalid PinCode"),

  primaryRole: yup.string().required("Primary Role is Required"),
  employmentType: yup.string().required("Employement is required"),
  departmentDocId: yup.string().required("Department is required"),
  designation: yup.string().required("Designation is required"),
  otherDesignation: yup.string().when("designation", {
    is: "Other",
    then: yup.string().required("Other Designation Required"),
    otherwise: yup.string(),
  }),
});
const FormSectionTitle = ({ title }) => {
  return (
    <Typography
      sx={{
        fontSize: "18px",
        fontWeight: 600,
        mb: 1,
        opacity: 0.4,
        color: "#000",
      }}
    >
      {title}
    </Typography>
  );
};
const ErrorText = ({ text }) => {
  return <Typography color="error">{text}</Typography>;
};
const initialState = {
  firstName: "",
  middleName: "",
  lastName: "",
  gender: "",
  DOB: null,
  email: "",
  mobileNumber: "",
  alternateNumber: "",
  profilePic: "",
  PAN: "",
  maritalStatus: "",

  father_firstName: "",
  father_middleName: "",
  father_lastName: "",

  mother_firstName: "",
  mother_middleName: "",
  mother_lastName: "",

  current_address: "",
  current_dist: "",
  current_pinCode: "",
  current_state: "",
  same_as_checked: false,
  permanent_address: "",
  permanent_dist: "",
  permanent_pinCode: "",
  permanent_state: "",

  accountNumber: "",
  bankName: "",
  branch: "",
  holderName: "",
  ifscCode: "",

  clAvailable: 0,
  slAvailable: 0,
  plAvailable: 0,

  departmentDocId: "",
  designation: "",
  employmentType: "",
  joiningDate: new Date(),
  primaryRole: "",
  imageUrl: "",
  imageFile: "",
  loading: false,
};

const AddNewStaffPage = () => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const selectedSession = useSelector(selectActiveSession);
  const navigate = useNavigate();
  const {
    isLoading: departmentListLoading,
    isFetching: departmentListFetching,
    data: departmentList,
  } = useQuery({
    queryKey: ["getAllDepartments"],
    queryFn: () => getAllDepartments(),
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });
  const { data: getDesignationDropdownData } = useQuery({
    queryKey: ["getDesignationDropdown"],
    queryFn: () => getDesignationDropdown(),
    onError: (err) => {
      toast.error(err?.response?.data?.message);
    },
  });

  const {
    values,
    setValues,
    errors,
    touched,
    handleChange,
    handleSubmit,
    isSubmitting,
    resetForm,
  } = useFormik({
    initialValues: initialState,
    validationSchema: validationSchema,
    onSubmit: (value) => {
      const body = {
        firstName: value.firstName,
        middleName: value.middleName,
        lastName: value.lastName,
        gender: value.gender,
        DOB: value.DOB,
        email: value.email,
        mobileNumber: value.mobileNumber,
        alternateNumber: value.alternateNumber,
        PAN: value.PAN,
        maritalStatus: value.maritalStatus,
        fatherInfo: {
          firstName: value.father_firstName,
          middleName: value.father_middleName,
          lastName: value.father_lastName,
        },
        motherInfo: {
          firstName: value.mother_firstName,
          middleName: value.mother_middleName,
          lastName: value.mother_lastName,
        },
        currentAddress: {
          address: value.current_address,
          dist: value.current_dist,
          pinCode: value.current_pinCode,
          state: value.current_state,
        },
        permanentAddress: {
          address: value.permanent_address,
          dist: value.permanent_dist,
          pinCode: value.permanent_pinCode,
          state: value.permanent_state,
        },
        bankDetails: {
          accountNumber: value.accountNumber,
          bankName: value.bankName,
          branch: value.branch,
          holderName: value.holderName,
          ifscCode: value.ifscCode,
        },
        employmentInfo: {
          departmentDocId: value.departmentDocId,
          designation:
            values.designation === "Other"
              ? values.otherDesignation
              : values.designation,
          employmentType: value.employmentType,
          joiningDate: value.joiningDate,
          primaryRole: value.primaryRole,
        },
        staffLeave: {
          session: selectedSession,
          clAvailable: value.clAvailable,
          slAvailable: value.slAvailable,
          plAvailable: value.plAvailable,
        },
      };
      if (
        value.accountNumber === "" &&
        value.bankName === "" &&
        value.branch === "" &&
        value.ifscCode === "" &&
        value.holderName === ""
      ) {
        body["bankDetails"] = null;
      }
      if (value.imageFile) {
        handleSubmitFormWithImage(body);
      } else {
        mutate({ ...body, profilePic: "" });
      }
    },
  });

  const { mutate, isLoading: addStaffBySchoolAdminLoading } = useMutation(
    addStaffBySchoolAdmin,
    {
      onSuccess: (successData) => {
        toast.success(successData?.data?.message);
        resetForm();
        navigate("/hr/staff-management", { replace: true });
      },
      onError: (err) => {
        toast.error(err?.response?.data?.message);
      },
    }
  );
  const handleSubmitFormWithImage = async (data) => {
    setValues((prev) => ({ ...prev, loading: true }));
    try {
      let s3FilePath = `addNewStaff/${values.imageFile[0].name}`;
      let attachmentUrl = await uploadFile(
        values.imageFile[0],
        s3FilePath,
        values.imageFile[0].type
      );
      mutate({ ...data, profilePic: attachmentUrl.fileURL });
    } catch (error) {}
    setValues((prev) => ({ ...prev, loading: false }));
  };
  useEffect(() => {
    const keys = Object.keys(errors);
    if (keys.length > 0) {
      const selector = `[name=${keys[0]}]`;
      const errorElement = document.querySelector(selector);
      if (errorElement) {
        errorElement.scrollIntoView({ behavior: "smooth", block: "center" });
        errorElement.focus({ preventScroll: true });
      }
    }
  }, [isSubmitting]);
  return (
    <>
      <CustomLoader show={departmentListLoading || departmentListFetching} />
      <Container maxWidth="xl">
        {/* Personal Info Box */}
        <form onSubmit={handleSubmit}>
          <BackgroundBox>
            <FormSectionTitle title="Personal Information" />
            <Grid container spacing={1.5}>
              <Grid
                item
                xs={12}
                md={2.5}
                display={"flex"}
                flexDirection={"column"}
                alignItems={"center"}
                justifyContent={"center"}
              >
                <TextFieldLabel title={"Profile Photo"} />
                <Box>
                  <PreviewImage
                    height={130}
                    width={130}
                    deleteIcon={values.imageUrl ? true : false}
                    src={
                      values.imageUrl
                        ? values.imageUrl
                        : "/image-placeholder.jpeg"
                    }
                    handleDelete={() =>
                      setValues((prev) => ({
                        ...prev,
                        imageFile: "",
                        imageUrl: "",
                      }))
                    }
                  />
                </Box>
                <Button
                  component="label"
                  variant="outlined"
                  color="secondary"
                  sx={{
                    backgroundColor: "#fff",
                    borderRadius: "10px",
                    mt: 0.5,
                  }}
                >
                  <input
                    type="file"
                    hidden
                    accept="image/*"
                    onChange={(e) =>
                      setValues((prev) => ({
                        ...prev,
                        imageFile: e.target.files,
                        imageUrl: URL.createObjectURL(e.target.files[0]),
                      }))
                    }
                  />
                  Upload
                </Button>
              </Grid>
              <Grid item xs={12} md={9.5}>
                <Grid container columnSpacing={1.5} rowSpacing={1}>
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"First Name"} required />
                    <CustomTextField
                      placeholder="Type First Name"
                      size="small"
                      fullWidth
                      id="firstName"
                      value={values.firstName}
                      name="firstName"
                      onChange={handleChange}
                      error={touched.firstName && Boolean(errors.firstName)}
                    />
                    {touched.firstName && errors.firstName && (
                      <ErrorText text={errors.firstName} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"Middle Name"} />
                    <CustomTextField
                      placeholder="Type Middle Name"
                      size="small"
                      fullWidth
                      id="middleName"
                      value={values.middleName}
                      name="middleName"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"Last Name"} />
                    <CustomTextField
                      placeholder="Type Last Name"
                      size="small"
                      fullWidth
                      id="lastName"
                      value={values.lastName}
                      name="lastName"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"Gender"} />
                    <CustomSelectBox
                      displayEmpty
                      native
                      size="small"
                      fullWidth
                      name="gender"
                      onChange={handleChange}
                      value={values.gender}
                      error={touched.gender && Boolean(errors?.gender)}
                      MenuProps={MenuProps}
                    >
                      <MenuItem component="option" value="">
                        Select
                      </MenuItem>
                      {GenderChoice.map((gender) => (
                        <MenuItem
                          component="option"
                          key={gender}
                          value={gender}
                        >
                          {gender}
                        </MenuItem>
                      ))}
                    </CustomSelectBox>
                    {touched.gender && Boolean(errors?.gender) && (
                      <ErrorText text={errors.gender} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"Marital Status"} />
                    <CustomSelectBox
                      displayEmpty
                      native
                      size="small"
                      fullWidth
                      name="maritalStatus"
                      onChange={handleChange}
                      value={values.maritalStatus}
                      error={
                        touched.maritalStatus && Boolean(errors?.maritalStatus)
                      }
                      MenuProps={MenuProps}
                    >
                      <MenuItem component="option" value="" disabled>
                        Select
                      </MenuItem>
                      {["Married", "Unmarried"].map((maritalStatus) => (
                        <MenuItem
                          component="option"
                          key={maritalStatus}
                          value={maritalStatus}
                        >
                          {maritalStatus}
                        </MenuItem>
                      ))}
                    </CustomSelectBox>
                    {touched.maritalStatus &&
                      Boolean(errors?.maritalStatus) && (
                        <ErrorText text={errors.maritalStatus} />
                      )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"Date of Birth"} />

                    <DesktopDatePicker
                      inputFormat="DD-MM-YYYY"
                      onChange={(e) =>
                        setValues((prev) => ({ ...prev, DOB: e }))
                      }
                      value={values.DOB || null}
                      renderInput={(params) => (
                        <CustomTextField {...params} size="small" fullWidth />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"Email"} required />
                    <CustomTextField
                      placeholder="Type Email Address"
                      size="small"
                      fullWidth
                      value={values.email}
                      name="email"
                      onChange={handleChange}
                      error={touched.email && Boolean(errors?.email)}
                    />
                    {touched.email && Boolean(errors?.email) && (
                      <ErrorText text={errors.email} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"Mobile Number"} required />
                    <CustomTextField
                      placeholder="Type Mobile Number"
                      size="small"
                      type="number"
                      fullWidth
                      value={values.mobileNumber}
                      error={
                        touched.mobileNumber && Boolean(errors.mobileNumber)
                      }
                      onChange={(e) => {
                        if (e.target.value.length < 11) {
                          setValues((prev) => ({
                            ...prev,
                            mobileNumber: e.target.value,
                          }));
                        }
                      }}
                    />
                    {touched.mobileNumber && Boolean(touched.mobileNumber) && (
                      <ErrorText text={errors.mobileNumber} />
                    )}
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"Alternate Mobile Number"} />
                    <CustomTextField
                      placeholder="Type Alternate Number"
                      size="small"
                      type="number"
                      fullWidth
                      value={values.alternateNumber}
                      onChange={(e) => {
                        if (e.target.value.length < 11) {
                          setValues((prev) => ({
                            ...prev,
                            alternateNumber: e.target.value,
                          }));
                        }
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} md={4}>
                    <TextFieldLabel title={"Pan Number"} />
                    <CustomTextField
                      placeholder="Type Pan Number"
                      size="small"
                      type="text"
                      fullWidth
                      value={values.PAN}
                      onChange={(e) => {
                        if (e.target.value.length < 11) {
                          setValues((prev) => ({
                            ...prev,
                            PAN: e.target.value.toUpperCase(),
                          }));
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </BackgroundBox>
          {/* parent info */}
          <BackgroundBox>
            <FormSectionTitle title={"Parent Information"} />
            <Typography sx={{ fontSize: "14px", fontWeight: 600, mb: 0.5 }}>
              Mother Information
            </Typography>
            <Grid container columnSpacing={1.5} rowSpacing={1}>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"FirstName"} required />
                <CustomTextField
                  size="small"
                  placeholder="Type first name"
                  fullWidth
                  value={values.mother_firstName}
                  name="mother_firstName"
                  onChange={handleChange}
                  error={
                    touched.mother_firstName && Boolean(errors.mother_firstName)
                  }
                />
                {touched.mother_firstName && errors.mother_firstName && (
                  <ErrorText text={errors.mother_firstName} />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Middle Name"} />
                <CustomTextField
                  placeholder="Type middle name"
                  size="small"
                  fullWidth
                  name="mother_middleName"
                  value={values.mother_middleName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Last Name"} />
                <CustomTextField
                  placeholder="Type last name"
                  size="small"
                  fullWidth
                  name="mother_lastName"
                  value={values.mother_lastName}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Typography sx={{ fontSize: "14px", fontWeight: 600, my: 0.5 }}>
              Father Information
            </Typography>
            <Grid container columnSpacing={1.5} rowSpacing={1}>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"FirstName"} required />
                <CustomTextField
                  placeholder="Type first name"
                  size="small"
                  fullWidth
                  value={values.father_firstName}
                  name="father_firstName"
                  onChange={handleChange}
                  error={
                    touched.father_firstName && Boolean(errors.father_firstName)
                  }
                />
                {touched.father_firstName && errors.father_firstName && (
                  <ErrorText text={errors.father_firstName} />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Middle Name"} />
                <CustomTextField
                  placeholder="Type middle name"
                  size="small"
                  fullWidth
                  name="father_middleName"
                  value={values.father_middleName}
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Last Name"} />
                <CustomTextField
                  placeholder="Type last name"
                  size="small"
                  fullWidth
                  name="father_lastName"
                  value={values.father_lastName}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </BackgroundBox>

          {/* address info */}
          <BackgroundBox>
            <FormSectionTitle title={"Address Information"} />
            <Typography sx={{ mb: 0.5, fontSize: "14px", fontWeight: 600 }}>
              Current Address
            </Typography>
            <Grid container columnSpacing={1.5} rowSpacing={1}>
              <Grid item xs={12} md={3}>
                <TextFieldLabel title={"Address"} required />
                <CustomTextField
                  placeholder="Type adress"
                  size="small"
                  fullWidth
                  value={values.current_address}
                  name="current_address"
                  onChange={handleChange}
                  error={
                    touched.current_address && Boolean(errors.current_address)
                  }
                />
                {touched.current_address && errors.current_address && (
                  <ErrorText text={errors.current_address} />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldLabel title={"State"} required />
                <CustomSelectBox
                  displayEmpty
                  native
                  size="small"
                  fullWidth
                  MenuProps={MenuProps}
                  value={values.current_state}
                  name="current_state"
                  onChange={handleChange}
                  error={touched.current_state && Boolean(errors.current_state)}
                >
                  <MenuItem component="option" value="">
                    Select
                  </MenuItem>
                  {States.map((state, index) => (
                    <MenuItem component="option" key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
                {touched.current_state && errors.current_state && (
                  <ErrorText text={errors.current_state} />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldLabel title={"District"} required />
                <CustomSelectBox
                  displayEmpty
                  native
                  size="small"
                  fullWidth
                  MenuProps={MenuProps}
                  value={values.current_dist}
                  name="current_dist"
                  onChange={handleChange}
                  error={touched.current_dist && Boolean(errors.current_dist)}
                >
                  <MenuItem component="option" value="">
                    Select
                  </MenuItem>
                  {values.current_state &&
                    Distrct[
                      States.indexOf(String(values.current_state)) + 1
                    ].map((district) => (
                      <MenuItem
                        component="option"
                        key={district}
                        value={district}
                      >
                        {district}
                      </MenuItem>
                    ))}
                </CustomSelectBox>
                {touched.current_dist && errors.current_dist && (
                  <ErrorText text={errors.current_dist} />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldLabel title={"Pin Code"} required />
                <CustomTextField
                  placeholder="Type pin code"
                  type="number"
                  size="small"
                  fullWidth
                  value={values.current_pinCode}
                  name="current_pinCode"
                  onChange={(e) => {
                    if (e.target.value.length < 7) {
                      setValues((prev) => ({
                        ...prev,
                        current_pinCode: e.target.value,
                      }));
                    }
                  }}
                  error={
                    touched.current_pinCode && Boolean(errors.current_pinCode)
                  }
                />
                {touched.current_pinCode && errors.current_pinCode && (
                  <ErrorText text={errors.current_pinCode} />
                )}
              </Grid>
            </Grid>
            <FormControlLabel
              sx={{ my: isMobile && 2 }}
              control={
                <Checkbox
                  color="secondary"
                  checked={values.same_as_checked}
                  onChange={(e) => {
                    if (values.same_as_checked) {
                      setValues((prev) => ({
                        ...prev,
                        same_as_checked: false,
                        permanent_address: "",
                        permanent_dist: "",
                        permanent_pinCode: "",
                        permanent_state: "",
                      }));
                    } else {
                      setValues((prev) => ({
                        ...prev,
                        same_as_checked: true,
                        permanent_address: values.current_address,
                        permanent_dist: values.current_dist,
                        permanent_pinCode: values.current_pinCode,
                        permanent_state: values.current_state,
                      }));
                    }
                  }}
                />
              }
              label="Check the Box, If Current Address is Same as Permanent Address"
            />
            <Typography sx={{ my: 0.5, fontSize: "14px", fontWeight: 600 }}>
              Permanent Address
            </Typography>
            <Grid container columnSpacing={1.5} rowSpacing={1}>
              <Grid item xs={12} md={3}>
                <TextFieldLabel title={"Address"} required />
                <CustomTextField
                  placeholder="Type address"
                  size="small"
                  fullWidth
                  value={values.permanent_address}
                  name="permanent_address"
                  onChange={handleChange}
                  error={
                    touched.permanent_address &&
                    Boolean(errors.permanent_address)
                  }
                />
                {touched.permanent_address && errors.permanent_address && (
                  <ErrorText text={errors.permanent_address} />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldLabel title={"State"} required />
                <CustomSelectBox
                  displayEmpty
                  native
                  size="small"
                  fullWidth
                  MenuProps={MenuProps}
                  value={values.permanent_state}
                  name="permanent_state"
                  onChange={handleChange}
                  error={
                    touched.permanent_state && Boolean(errors.permanent_state)
                  }
                >
                  <MenuItem component="option" value="">
                    Select
                  </MenuItem>
                  {States.map((state, index) => (
                    <MenuItem component="option" key={state} value={state}>
                      {state}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
                {touched.permanent_state && errors.permanent_state && (
                  <ErrorText text={errors.permanent_state} />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldLabel title={"District"} required />
                <CustomSelectBox
                  displayEmpty
                  native
                  size="small"
                  fullWidth
                  MenuProps={MenuProps}
                  value={values.permanent_dist}
                  name="permanent_dist"
                  onChange={handleChange}
                  error={
                    touched.permanent_dist && Boolean(errors.permanent_dist)
                  }
                >
                  <MenuItem component="option" value="">
                    Select
                  </MenuItem>
                  {values.permanent_state &&
                    Distrct[
                      States.indexOf(String(values.permanent_state)) + 1
                    ].map((district) => (
                      <MenuItem
                        component="option"
                        key={district}
                        value={district}
                      >
                        {district}
                      </MenuItem>
                    ))}
                </CustomSelectBox>
                {touched.permanent_dist && errors.permanent_dist && (
                  <ErrorText text={errors.permanent_dist} />
                )}
              </Grid>
              <Grid item xs={12} md={3}>
                <TextFieldLabel title={"Pin Code"} required />
                <CustomTextField
                  placeholder="Type pin code"
                  type="number"
                  size="small"
                  fullWidth
                  value={values.permanent_pinCode}
                  name="permanent_pinCode"
                  onChange={(e) => {
                    if (e.target.value.length < 7) {
                      setValues((prev) => ({
                        ...prev,
                        permanent_pinCode: e.target.value,
                      }));
                    }
                  }}
                  error={
                    touched.permanent_pinCode &&
                    Boolean(errors.permanent_pinCode)
                  }
                />
                {touched.permanent_pinCode && errors.permanent_pinCode && (
                  <ErrorText text={errors.permanent_pinCode} />
                )}
              </Grid>
            </Grid>
          </BackgroundBox>

          {/* Employment information */}
          <BackgroundBox>
            <FormSectionTitle title={"Employment information"} />
            <Grid container columnSpacing={1.5} rowSpacing={1}>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Primary Role"} required />
                <CustomSelectBox
                  size="small"
                  fullWidth
                  displayEmpty
                  native
                  value={values.primaryRole}
                  name="primaryRole"
                  onChange={handleChange}
                  error={touched.primaryRole && Boolean(errors.primaryRole)}
                  MenuProps={MenuProps}
                >
                  <MenuItem component="option" value="">
                    Select
                  </MenuItem>
                  {Object.keys(primaryRoles)?.map((role, idx) => (
                    <MenuItem component="option" value={role} key={idx}>
                      {role}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
                {touched.primaryRole && errors.primaryRole && (
                  <ErrorText text={errors.primaryRole} />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Designation"} required />
                <CustomSelectBox
                  size="small"
                  fullWidth
                  displayEmpty
                  native
                  value={values.designation}
                  name="designation"
                  onChange={handleChange}
                  error={touched.designation && Boolean(errors.designation)}
                  MenuProps={MenuProps}
                >
                  <MenuItem component="option" value="">
                    Select
                  </MenuItem>
                  {getDesignationDropdownData?.data?.list?.map((role, idx) => (
                    <MenuItem component="option" value={role} key={idx}>
                      {role}
                    </MenuItem>
                  ))}
                  <MenuItem component="option" value="Other">
                    Other
                  </MenuItem>
                </CustomSelectBox>
                {touched.designation && errors.designation && (
                  <ErrorText text={errors.designation} />
                )}
              </Grid>
              {values?.designation === "Other" && (
                <Grid item xs={12} sm={6}>
                  <TextFieldLabel title="Other Designation" />
                  <CustomTextField
                    size="small"
                    fullWidth
                    name="otherDesignation"
                    value={values.otherDesignation}
                    onChange={handleChange}
                    placeholder="type designation"
                    error={
                      Boolean(errors.otherDesignation) &&
                      touched.otherDesignation
                    }
                    helperText={
                      touched.otherDesignation && errors.otherDesignation
                    }
                  />
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Joining Date"} required />

                <DesktopDatePicker
                  inputFormat="DD-MM-YYYY"
                  onChange={(e) =>
                    setValues((prev) => ({ ...prev, joiningDate: e }))
                  }
                  value={values.joiningDate}
                  renderInput={(params) => (
                    <CustomTextField {...params} size="small" fullWidth />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Employment Type"} required />
                <CustomSelectBox
                  size="small"
                  fullWidth
                  displayEmpty
                  native
                  value={values.employmentType}
                  name="employmentType"
                  onChange={handleChange}
                  error={
                    touched.employmentType && Boolean(errors.employmentType)
                  }
                  MenuProps={MenuProps}
                >
                  <MenuItem component="option" value="">
                    Select
                  </MenuItem>
                  <MenuItem component="option" value={"Full-Time"}>
                    Full-Time
                  </MenuItem>
                  <MenuItem component="option" value={"Part-Time"}>
                    Part-Time
                  </MenuItem>
                </CustomSelectBox>
                {touched.employmentType && errors.employmentType && (
                  <ErrorText text={errors.employmentType} />
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Department"} required />
                <CustomSelectBox
                  size="small"
                  fullWidth
                  displayEmpty
                  native
                  value={values.departmentDocId}
                  name="departmentDocId"
                  onChange={handleChange}
                  error={
                    touched.departmentDocId && Boolean(errors.departmentDocId)
                  }
                  MenuProps={MenuProps}
                >
                  <MenuItem component="option" value="">
                    Select
                  </MenuItem>
                  {departmentList?.data?.list?.map((department, idx) => (
                    <MenuItem
                      component="option"
                      value={department._id}
                      key={idx}
                    >
                      {department.departmentName}
                    </MenuItem>
                  ))}
                </CustomSelectBox>
                {touched.departmentDocId && errors.departmentDocId && (
                  <ErrorText text={errors.departmentDocId} />
                )}
              </Grid>
            </Grid>
          </BackgroundBox>
          {/* Bank Details */}
          <BackgroundBox>
            <FormSectionTitle title={"Bank information"} />
            <Grid container columnSpacing={1.5} rowSpacing={1}>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Account Number"} />
                <CustomTextField
                  type="text"
                  placeholder="Type account number"
                  size="small"
                  fullWidth
                  value={values.accountNumber}
                  name="accountNumber"
                  onChange={(e) => {
                    setValues((prev) => ({
                      ...prev,
                      accountNumber: e.target.value.toUpperCase(),
                    }));
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Bank Name"} />
                <CustomTextField
                  type="text"
                  placeholder="Type bank name"
                  size="small"
                  fullWidth
                  value={values.bankName}
                  name="bankName"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Branch Name"} />
                <CustomTextField
                  type="text"
                  placeholder="Type branch name"
                  size="small"
                  fullWidth
                  value={values.branch}
                  name="branch"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"IFSC Code"} />
                <CustomTextField
                  placeholder="Type ifsc number"
                  size="small"
                  fullWidth
                  value={values.ifscCode}
                  name="ifscCode"
                  onChange={(e) => {
                    if (e.target.value.length < 12) {
                      setValues((prev) => ({
                        ...prev,
                        ifscCode: e.target.value,
                      }));
                    }
                  }}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Account Holder Name"} />
                <CustomTextField
                  type="text"
                  placeholder="Type account holder name"
                  size="small"
                  fullWidth
                  value={values.holderName}
                  name="holderName"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </BackgroundBox>
          {/* Leave Details */}
          <BackgroundBox>
            <FormSectionTitle title={"Leave information"} />
            <Grid container columnSpacing={1.5} rowSpacing={1}>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Privilege Leave"} />
                <CustomTextField
                  placeholder="Type account number"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.plAvailable}
                  name="plAvailable"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Sick Leave"} />
                <CustomTextField
                  placeholder="Type bank name"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.slAvailable}
                  name="slAvailable"
                  onChange={handleChange}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Casual Leave"} />
                <CustomTextField
                  placeholder="Type branch name"
                  size="small"
                  type="number"
                  fullWidth
                  value={values.clAvailable}
                  name="clAvailable"
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
          </BackgroundBox>
          <Stack
            direction="row"
            justifyContent="flex-end"
            sx={{
              py: 1,
            }}
          >
            <LoadingButton
              loading={addStaffBySchoolAdminLoading || values.loading}
              type="submit"
              sx={{ width: "300px" }}
              onClick={() => handleSubmit()}
              variant="contained"
              size="large"
              color="secondary"
            >
              Submit
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </>
  );
};

export default AddNewStaffPage;
const BackgroundBox = styled(Box)`
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 10px;
  box-shadow: ${THEME.boxShadow1};
  margin-bottom: 8px;
`;
