import React, { useEffect } from "react";
import { LoadingButton } from "@mui/lab";
import {
  Box,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import { addFeeType } from "app/services/management";
import * as yup from "yup";
import DialogHeader from "app/components/common/DialogHeader";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import { useQuery } from "react-query";
import { getClassDropdownWithOnlyClass } from "app/services/reception";
import { useSelector } from "react-redux";
import {
  selectActiveSession,
  selectSelectedSchoolId,
  selectedMediumSelector,
} from "app/modules/schools/selectors";
import { toast } from "react-toastify";
const validationSchema = yup.object({
  feeType: yup.string().required("Fee type required"),
  otheFeeType: yup.string().when("feeType", {
    is: "Other",
    then: yup.string().required("Other fee type required"),
  }),
  admssionType: yup.string().required("Admission type required"),
  feeOccurrence: yup.string().required("Admission type required"),
  classList: yup.array().when("checkAllClass", {
    is: false,
    then: yup.array().min(1, "Atleast one class required"),
  }),
});
const AddFeeTypeDialog = ({ open, handleClose, refetch, getFeeTypeData }) => {
  const schoolDocId = useSelector(selectSelectedSchoolId);
  const selectedSession = useSelector(selectActiveSession);
  const selectedMedium = useSelector(selectedMediumSelector);
  const {
    values,
    errors,
    touched,
    setValues,
    handleSubmit,
    handleChange,
    resetForm,
  } = useFormik({
    initialValues: {
      addNewDialog: false,
      feeType: "",
      otheFeeType: "",
      admssionType: "",
      feeOccurrence: "",
      classList: [],
      checkAllClass: false,
      loading: false,
    },
    validationSchema,
    onSubmit: () => {
      handleSubmitAddForm();
    },
  });
  const handleSubmitAddForm = async () => {
    setValues((prev) => ({ ...prev, loading: true }));
    const commonBody = {
      feeType: values.feeType === "Other" ? values.otheFeeType : values.feeType,
      studentAdmissionType: values.admssionType,
      feeOccurrence: values.feeOccurrence,
      session: selectedSession,
    };
    if (values.checkAllClass) {
      await Promise.allSettled(
        getClassDropdownWithOnlyClassData?.data?.list?.map((item) => {
          return new Promise(async (resolve, reject) => {
            try {
              let callApi = await addFeeType({
                className: item,
                ...commonBody,
              });
              toast.success(callApi.data.message);
              resolve();
            } catch (error) {
              toast.error(error.response.data.message);
              reject();
            }
          });
        })
      );
    } else {
      await Promise.allSettled(
        values?.classList?.map((item) => {
          return new Promise(async (resolve, reject) => {
            try {
              let callApi = await addFeeType({
                className: item,
                ...commonBody,
              });
              toast.success(callApi.data.message);
              resolve();
            } catch (error) {
              toast.error(error.response.data.message);
              reject();
            }
          });
        })
      );
    }
    handleClose();
    resetForm();
    refetch();
  };
  const handleColoseCreateDialog = () => {
    resetForm();
    handleClose();
  };
  const {
    isLoading: getClassDropdownWithOnlyClassLoading,
    data: getClassDropdownWithOnlyClassData,
  } = useQuery({
    queryKey: ["getClassInfo", selectedSession, selectedMedium],
    queryFn: () =>
      getClassDropdownWithOnlyClass({
        schoolDocId,
        medium: selectedMedium,
        onlyClassName: "Yes",
      }),

    enabled: open ? true : false,
  });

  return (
    <Dialog
      onClose={handleColoseCreateDialog}
      open={open}
      PaperProps={{
        sx: { minWidth: { xs: "98%", md: "600px" } },
      }}
    >
      <DialogTitle sx={{ p: "0 !important" }}>
        <DialogHeader
          handleClose={handleColoseCreateDialog}
          title="Fee Type Form"
        />
      </DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit} id="fee_relaxation_form">
          <Box sx={{ mt: 1 }}>
            {getClassDropdownWithOnlyClassLoading && (
              <Stack direction="row" py={2}>
                <CircularProgress />
              </Stack>
            )}
            <Grid container rowSpacing={1} columnSpacing={1.5}>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Fee Type Name"} />
                <CustomSelectBox
                  size="small"
                  fullWidth
                  displayEmpty
                  value={values.feeType}
                  name="feeType"
                  onChange={handleChange}
                  error={touched.feeType && Boolean(errors.feeType)}
                  MenuProps={MenuProps}
                  native
                >
                  <MenuItem value="" disabled component="option">
                    Select
                  </MenuItem>
                  {getFeeTypeData?.data?.list?.map((item, index) => (
                    <MenuItem
                      value={item?.feeType}
                      key={index}
                      component="option"
                    >
                      {item?.feeType}
                    </MenuItem>
                  ))}
                  <MenuItem value="Other" component="option">
                    Other
                  </MenuItem>
                </CustomSelectBox>
                {touched.feeType && Boolean(errors.feeType) && (
                  <Typography color="error">{errors.feeType}</Typography>
                )}
              </Grid>
              {values.feeType === "Other" && (
                <Grid item xs={12} md={4}>
                  <TextFieldLabel title={"Other Fee Type Name"} />
                  <CustomTextField
                    placeholder="Type Fee Type Name"
                    size="small"
                    fullWidth
                    value={values.otheFeeType}
                    name="otheFeeType"
                    onChange={handleChange}
                    error={touched.otheFeeType && Boolean(errors.otheFeeType)}
                  />
                  {touched.otheFeeType && Boolean(errors.otheFeeType) && (
                    <Typography color="error">{errors.otheFeeType}</Typography>
                  )}
                </Grid>
              )}
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Student : Admission Type"} />
                <CustomSelectBox
                  size="small"
                  fullWidth
                  displayEmpty
                  value={values.admssionType}
                  name="admssionType"
                  onChange={handleChange}
                  error={touched.admssionType && Boolean(errors.admssionType)}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="allStudent">All</MenuItem>
                  <MenuItem value="oldStudent">Old</MenuItem>
                  <MenuItem value="newStudent">New</MenuItem>
                </CustomSelectBox>
                {touched.admssionType && Boolean(errors.admssionType) && (
                  <Typography color="error">{errors.admssionType}</Typography>
                )}
              </Grid>

              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Fee Occurrence"} />
                <CustomSelectBox
                  size="small"
                  fullWidth
                  displayEmpty
                  value={values.feeOccurrence}
                  name="feeOccurrence"
                  onChange={handleChange}
                  error={touched.feeOccurrence && Boolean(errors.feeOccurrence)}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <MenuItem value="oneTime">One Time</MenuItem>
                  <MenuItem value="recurring">Recurring </MenuItem>
                </CustomSelectBox>
                {touched.feeOccurrence && Boolean(errors.feeOccurrence) && (
                  <Typography color="error">{errors.feeOccurrence}</Typography>
                )}
              </Grid>
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Select Class"} />
                <CustomSelectBox
                  MenuProps={MenuProps}
                  multiple
                  size="small"
                  fullWidth
                  displayEmpty
                  value={values.classList}
                  name="classDocId"
                  error={touched.classList && Boolean(errors.classList)}
                  renderValue={(selected) => {
                    if (values?.checkAllClass) {
                      return <Typography>All Class</Typography>;
                    }
                    if (selected?.length === 0) {
                      return <em>Select Class</em>;
                    }
                    return selected.join(", ");
                  }}
                >
                  <MenuItem value="" disabled>
                    Select
                  </MenuItem>
                  <Stack direction="row" alignItems={"center"}>
                    <Checkbox
                      color="secondary"
                      checked={values.checkAllClass}
                      onChange={(e) =>
                        setValues((prev) => ({
                          ...prev,
                          checkAllClass: !prev.checkAllClass,
                          classList: [],
                        }))
                      }
                    />
                    <MenuItem value="All">All</MenuItem>
                  </Stack>
                  {getClassDropdownWithOnlyClassData?.data?.list?.map(
                    (item) => (
                      <Stack
                        direction="row"
                        alignItems={"center"}
                        key={item?.classDocId}
                      >
                        <Checkbox
                          color="secondary"
                          checked={
                            values.classList.includes(item) &&
                            !values.checkAllClass
                          }
                          onChange={(e) => {
                            if (values.classList.includes(item)) {
                              setValues((prev) => ({
                                ...prev,
                                classList: prev.classList.filter(
                                  (res) => res !== item
                                ),
                                checkAllClass: false,
                              }));
                            } else {
                              setValues((prev) => ({
                                ...prev,
                                classList: [item, ...prev.classList],
                                checkAllClass: false,
                              }));
                            }
                          }}
                        />
                        <MenuItem value={item}>{item}</MenuItem>
                      </Stack>
                    )
                  )}
                </CustomSelectBox>
                {touched.classList && Boolean(errors.classList) && (
                  <Typography color="error">{errors.classList}</Typography>
                )}
              </Grid>
            </Grid>
          </Box>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          form="fee_relaxation_form"
          loading={values.loading}
          type="submit"
          variant="contained"
          fullWidth
          size="large"
          color="secondary"
          sx={{ width: "30%" }}
        >
          Submit
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
};

export default AddFeeTypeDialog;
