import API_ENDPOINTS from "app/config/endpoints";
import axios from "axios";
import { schoolTokenAxios, authTokenAxios } from "../axios";

export const getActiveStaffBySchoolDocId = () => {
  return schoolTokenAxios.get(API_ENDPOINTS.hr.getActiveStaffBySchoolDocId);
};

export const getActiveStaffByDepartment = (params) => {
  return schoolTokenAxios.get(API_ENDPOINTS.hr.getActiveStaffBySchoolDocId, {
    params,
  });
};

export const getActiveStaffBySchoolDocIdCopy = () => {
  return schoolTokenAxios.get(API_ENDPOINTS.hr.getActiveStaffBySchoolDocId);
};

export const getDeActiveStaff = () => {
  return schoolTokenAxios.get(API_ENDPOINTS.hr.getDeActiveStaff);
};

export const getStaffDetailByStaffDocId = (staffDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.hr.getStaffDetailByStaffDocId}`,
    {
      params: {
        staffDocId: staffDocId,
      },
    }
  );
};
export const getStaffDetailByEmpoyeeId = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.hr.getStaffDetailByStaffDocId}`,
    {
      params,
    }
  );
};

export const updateProfilePic = (body) => {
  return authTokenAxios.put(`${API_ENDPOINTS.hr.updateProfilePic}`, body);
};

export const getPayroll = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.hr.getPayroll}`, {
    params,
  });
};
export const getAllAdvanceSalaryBySelf = (params) => {
  return schoolTokenAxios.get(API_ENDPOINTS.hr.getAllAdvanceSalaryBySelf, {
    params,
  });
};
export const requestAdvanceSalaryBySelf = (body) => {
  return schoolTokenAxios.post(
    API_ENDPOINTS.hr.requestAdvanceSalaryBySelf,
    body
  );
};
///hr/getPayroll
export const getPayrollPageData = (query) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.hr.getPayroll}`, {
    params: {
      ...query,
    },
  });
};
export const getOnlinePayrollForPrint = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.hr.getOnlinePayrollForPrint}`, {
    params,
  });
};

export const getLeave = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.hr.getLeave}`, {
    params,
  });
};

export const getAttendanceByStaffDocIdAndYear = (staffDocId, year) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.hr.getAttendanceByStaffDocIdAndYear}`,
    {
      params: {
        staffDocId: staffDocId,
        year: year,
      },
    }
  );
};

export const getLeaveReportByStaffDocId = (staffDocId, session) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.hr.getLeaveReportByStaffDocId}`,
    {
      params: {
        staffDocId: staffDocId,
        session: session,
      },
    }
  );
};

export const addStaffLeave = (payload) => {
  return schoolTokenAxios.post(`${API_ENDPOINTS.hr.addStaffLeave}`, payload);
};
export const updateStaffLeaveReport = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.hr.updateStaffLeaveReport}`,
    payload
  );
};

export const getAllJoiningRequest = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.hr.getAllJoiningRequest}`, {
    params,
  });
};

export const getDetailRequest = (requestDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.hr.getDetailRequest}?requestDocId=${requestDocId}`
  );
};

export const rejectJoiningRequest = (requestDocId) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.hr.rejectJoiningRequest}?requestDocId=${requestDocId}`
  );
};

export const approveJoiningRequest = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.hr.approveJoiningRequest}`,
    payload
  );
};

export const addStaffBySchoolAdmin = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.hr.addStaffBySchoolAdmin}`,
    payload
  );
};

export const updatePersonalInfo = (payload) => {
  return authTokenAxios.put(`${API_ENDPOINTS.hr.updatePersonalInfo}`, payload);
};

export const updateParentInfo = (payload) => {
  return authTokenAxios.put(`${API_ENDPOINTS.hr.updateParentInfo}`, payload);
};

export const updateAssignedFor = (payload) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.hr.updateAssignedFor}`, payload);
};

export const updateEducationInfo = (payload) => {
  return authTokenAxios.put(`${API_ENDPOINTS.hr.updateEducationInfo}`, payload);
};

export const updateExperienceInfo = (payload) => {
  return authTokenAxios.put(
    `${API_ENDPOINTS.hr.updateExperienceInfo}`,
    payload
  );
};

export const updateAddressInfo = (payload) => {
  return authTokenAxios.put(`${API_ENDPOINTS.hr.updateAddressInfo}`, payload);
};

export const updateStaffLeave = (payload) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.hr.updateStaffLeave}`, payload);
};

export const rejectLeave = (payload) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.hr.rejectLeave}`, payload);
};
export const withdrawLeave = (payload) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.hr.withdrawLeave}/${payload}`);
};

export const deleteApproveLeave = (payload) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.hr.deleteApproveLeave}/${payload}`
  );
};
export const approveLeave = (payload) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.hr.approveLeave}`, payload);
};

export const removeStaff = (payload) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.hr.removeStaff}`, payload);
};

export const getStaffListByDesignation = (payload) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.hr.gatStaffListByDesignation}?designation=${payload}`
  );
};

export const getPayrollHeads = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.hr.getPayrollHeads}`, {
    params,
  });
};

export const updatePayrollHead = (payload) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.hr.updatePayrollHead}`, payload);
};

export const updateStatusPayrollHead = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.hr.updateStatusPayrollHead}`,
    payload
  );
};

export const addPayrollHead = (payload) => {
  return schoolTokenAxios.post(`${API_ENDPOINTS.hr.addPayrollHead}`, payload);
};

export const deletePayrollHead = (id) => {
  return schoolTokenAxios.delete(`${API_ENDPOINTS.hr.deletePayrollHead}/${id}`);
};

export const getAdvancePayrollTransaction = (params) => {
  if (params) {
    return schoolTokenAxios.get(
      `${API_ENDPOINTS.hr.getAdvancePayrollTransaction}`,
      {
        params: {
          ...params,
        },
      }
    );
  } else {
    return schoolTokenAxios.get(
      `${API_ENDPOINTS.hr.getAdvancePayrollTransaction}`
    );
  }
};

export const updateStatusOfRequestedAdvanceSalary = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.hr.updateStatusOfRequestedAdvanceSalary}`,
    payload
  );
};

export const requestAdvanceSalary = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.hr.requestAdvanceSalary}`,
    payload
  );
};

export const payAdvanceSalary = (payload) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.hr.payAdvanceSalary}`, payload);
};

export const createAndViewMonthlyPayroll = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.hr.createAndViewMonthlyPayroll}`,
    payload
  );
};

export const getThreeMonthsAttendance = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.hr.getThreeMonthsAttendance}`, {
    params: {
      ...params,
    },
  });
};
export const getLastThreeMonthSortedDataStaffAttendance = (params) => {
  return schoolTokenAxios.get(
    `${API_ENDPOINTS.hr.getLastThreeMonthSortedDataStaffAttendance}`,
    {
      params: {
        ...params,
      },
    }
  );
};

export const getPayrollTransaction = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.hr.getPayrollTransaction}`, {
    params: {
      ...params,
    },
  });
};

export const addPayrollTransaction = (payload) => {
  return schoolTokenAxios.post(
    `${API_ENDPOINTS.hr.addPayrollTransaction}`,
    payload
  );
};

export const updatePayrollTransaction = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.hr.updatePayrollTransaction}`,
    payload
  );
};
export const deletePayrollTransactionLogic = (payload) => {
  return schoolTokenAxios.delete(
    `${API_ENDPOINTS.hr.deletePayrollTransaction}/${payload}`
  );
};

export const updateMonthlyPayroll = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.hr.updateMonthlyPayroll}`,
    payload
  );
};

export const getEmployeeInfo = (params) => {
  return schoolTokenAxios.get(`${API_ENDPOINTS.hr.getEmployeeInfo}`, {
    params: {
      ...params,
    },
  });
};

export const updateEmployeeInfo = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.hr.updateEmployeeInfo}`,
    payload
  );
};

export const rejectMonthlyPayroll = (payload) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.hr.rejectMonthlyPayroll}`,
    payload
  );
};

export const deletePayroll = (id) => {
  return schoolTokenAxios.delete(`${API_ENDPOINTS.hr.deletePayroll}/${id}`);
};

export const approveMonthlyPayroll = (id) => {
  return schoolTokenAxios.put(
    `${API_ENDPOINTS.hr.approveMonthlyPayroll}/${id}`
  );
};

export const payMonthlyPayroll = (payload) => {
  return schoolTokenAxios.put(`${API_ENDPOINTS.hr.payMonthlyPayroll}`, payload);
};
