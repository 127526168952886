import {
  Box,
  CircularProgress,
  ClickAwayListener,
  Divider,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useState } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { Close } from "@mui/icons-material";
import { motion } from "framer-motion";
import { getStaffByName } from "app/services/schoolService/dashboard.service";
import { Link } from "react-router-dom";
import appRoutes from "app/config/routes";
import { THEME } from "app/config/constants";
const LabelText = ({ label = "", value = "" }) => {
  return (
    <Stack direction={"row"} gap={1}>
      <Typography
        sx={{
          color: "primary.main",
          fontWeight: 600,
          opacity: 0.7,
          fontSize: "12px",
        }}
      >
        {label}{" "}
      </Typography>
      <Typography
        sx={{ color: "primary.main", fontWeight: 600, fontSize: "13px" }}
      >
        {value}{" "}
      </Typography>
    </Stack>
  );
};
export const StaffCard = ({ item }) => {
  return (
    <Box mb={2} sx={{ cursor: "pointer" }}>
      <Stack direction="row" spacing={1} alignItems={"center"} px={2}>
        {/* student profile pic */}
        <div>
          <img
            src={
              item?.profilePic ? item?.profilePic : "/image-placeholder.jpeg"
            }
            alt="student"
            style={{
              width: "60px",
              height: "60px",
              borderRadius: "50%",
              objectFit: "cover",
            }}
          />
        </div>
        {/* student details */}
        <Stack>
          <Typography
            sx={{
              fontWeight: 600,
              fontSize: "15px",
              whiteSpace: "normal",
              overflowWrap: "break-word",
            }}
          >
            {item?.name}
          </Typography>
          <LabelText label="S/O:-" value={item?.fatherName ?? "--"} />
          <LabelText
            label="Department:- "
            value={item?.departmentName ?? "--"}
          />
          <LabelText label="Designation:-" value={item?.designation ?? "--"} />
          <LabelText label="Empoyee Id:-" value={item?.employeeId ?? "--"} />
          <LabelText label="Gender:-" value={item?.gender ?? "--"} />
        </Stack>
      </Stack>
      <Divider sx={{ background: "rgba(0,0,0,0.3)", mt: 1 }} />
    </Box>
  );
};
const StaffSearchInput = ({
  isNavigateToProfile = false,
  returnResponse = () => {},
}) => {
  const [state, setState] = useState({
    name: "",
    page: 1,
    staffData: {},
    menuOpen: false,
    selectedStudent: [],
    isFocus: false,
  });

  const {
    isFetching: getStaffByNameFetching,
    isLoading: getStaffByNameLoading,
  } = useQuery({
    queryKey: ["getStaffByName", state.name, state.page],
    queryFn: () =>
      getStaffByName({
        staffName: state.name,
        page: state.page,
      }),
    onSuccess: (data) => {
      if (state.page !== 1) {
        setState((prev) => ({
          ...prev,
          staffData: { ...prev.staffData, ...data?.data },
        }));
      } else {
        setState((prev) => ({ ...prev, staffData: data?.data }));
      }
    },
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
    enabled: state.name ? true : false,
  });

  return (
    <ClickAwayListener
      onClickAway={() => setState((prev) => ({ ...prev, isFocus: false }))}
    >
      <Wrapper>
        <div
          className="input_box"
          style={{ border: state.isFocus && `2px solid ${THEME.primary}` }}
        >
          <input
            onFocus={() => setState((prev) => ({ ...prev, isFocus: true }))}
            placeholder="Search Staff"
            value={state.name}
            onChange={(e) =>
              setState((prev) => ({ ...prev, name: e.target.value }))
            }
            className="custom_input"
          />
          {state.name && (
            <div
              onClick={() =>
                setState((prev) => ({ ...prev, name: "", staffData: {} }))
              }
            >
              <Close className="close_icon" />
            </div>
          )}
        </div>
        {/* box */}
        {state.name && state.isFocus && (
          <motion.div
            className="student_box__wrapper"
            initial={{ opacity: 0 }}
            animate={{
              display: "block",
              opacity: 1,
            }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.5 }}
          >
            <div className="student_box">
              {(getStaffByNameFetching || getStaffByNameLoading) && (
                <Stack
                  direction={"row"}
                  justifyContent={"center"}
                  sx={{ position: "absolute", left: 0, right: 0 }}
                >
                  <CircularProgress />
                </Stack>
              )}

              {state.staffData?.list?.map((item, ind) => (
                <>
                  {isNavigateToProfile ? (
                    <Link
                      target="_blank"
                      key={ind}
                      style={{ textDecoration: "none" }}
                      to={`/${appRoutes.staffProfile}/${item?.staffDocId}`}
                    >
                      <StaffCard item={item} />
                    </Link>
                  ) : (
                    <Box
                      key={ind}
                      onClick={() => {
                        returnResponse(item);
                        setState((prev) => ({ ...prev, name: "" }));
                      }}
                    >
                      <StaffCard responseFun={returnResponse} item={item} />
                    </Box>
                  )}
                </>
              ))}
              {state.staffData?.pages !== state.staffData?.page && (
                <Stack direction={"row"} justifyContent={"center"}>
                  <Typography
                    sx={{
                      color: "blue",
                      textDecoration: "underline",
                      cursor: "pointer",
                      textAlign: "center",
                      display: "inline-block",
                      mb: 1,
                    }}
                    onClick={() =>
                      setState((prev) => ({ ...prev, page: prev.page + 1 }))
                    }
                  >
                    more
                  </Typography>
                </Stack>
              )}
            </div>
          </motion.div>
        )}
      </Wrapper>
    </ClickAwayListener>
  );
};

export default StaffSearchInput;
const Wrapper = styled(Box)`
  position: relative;
  transition: all 0.5s;
  .input_box {
    padding: 3px;
    border: 0.6px solid rgba(0, 0, 0, 0.2);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 300px;
    background-color: #fff;
    border-radius: 5px;
    :hover {
      border-color: var(--primary);
    }
  }
  .close_icon {
    font-size: 10px;
    opacity: 0.6;
    background-color: #f1f1;
    cursor: pointer;
    height: 16px;
    width: 16px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .custom_input {
    width: 100%;
    outline: none;
    border: none;
    font-size: 14px;
    height: 30px;
  }
  .student_box__wrapper {
    position: absolute;
    width: 400px;
    height: 300px;
    padding-top: 4px;
    z-index: 300;
    .student_box {
      padding-top: 10px;
      height: 100%;
      width: 100%;
      background-color: #fff;
      border-radius: 15px;
      box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
      overflow-y: auto;
    }
  }
`;
