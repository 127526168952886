import React from "react";
import CertificateTittle from "../../../../src/assets/media/images/certificate-Images/CertificateTittle.png";
import { Box, Typography } from "@mui/material";

const CertificateContentLabel = ({ contentTittle }) => {
  return (
    <Box
      style={{
        width: "30%",
        height: "100%",
        backgroundImage: `url(${CertificateTittle})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundBlendMode: "normal",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        allignItems: "center",
        justifyContent: "center",
      }}
      sx={{
        alignSelf: "center",
        margin: "6px",
      }}
    >
      <Typography
        variant="h5"
        sx={{
          margin: "5%",
          textAlign: "center",
          color: "white",
          alignSelf: "center",
        }}
      >
        {contentTittle}
      </Typography>
    </Box>
  );
};
export const CertificateContentLabelA4 = ({ contentTittle }) => {
  return (
    <Box
      style={{
        width: "60%",
        height: "70%",
        backgroundImage: `url(${CertificateTittle})`,
        backgroundSize: "contain",
        backgroundPosition: "center",
        backgroundBlendMode: "normal",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        allignItems: "center",
        justifyContent: "center",
      }}
      sx={{
        alignSelf: "center",
        margin: "6px",
      }}
    >
      <Typography
        variant="h6"
        sx={{
          margin: "5%",
          textAlign: "center",
          color: "white",
          alignSelf: "center",
        }}
      >
        {contentTittle}
      </Typography>
    </Box>
  );
};

export default CertificateContentLabel;
