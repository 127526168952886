import React, { useEffect, useState } from "react";
import CommonDialog from "../CommonDialog";
import { useMutation } from "react-query";
import {
  addFirm,
  updateFirmProfile,
} from "app/services/schoolService/firm.service";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";
import { Distrct, States } from "app/config/administrativeConstants";
import uploadFile from "app/utils/uploadFile";
import {
  CustomSelectBox,
  CustomTextField,
} from "assets/styles/globalStyledComponent";

import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  MenuItem,
  Stack,
  TextareaAutosize,
  Typography,
  Grid,
} from "@mui/material";
import TextFieldLabel from "../../TextFieldLabel";
import { MenuProps } from "app/utils/helper";
import { LoadingButton } from "@mui/lab";
const AddEditFirmDialog = ({
  open = false,
  onClose = () => {},
  refetch = () => {},
  edit = false,
  editData = {},
  businessCategory = [],
  getAllBusinessCategoryRefetch = () => {},
}) => {
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    image: {
      file: "",
      link: "",
    },
    pic: {
      file: "",
      link: "",
    },
  });
  useEffect(() => {
    getAllBusinessCategoryRefetch();
  }, []);

  const formik = useFormik({
    initialValues: {
      dialog: false,
      updateId: "",
      fName: "",
      cat: "",
      otherCat: "",
      first: "",
      middle: "",
      last: "",
      number: "",
      email: "",
      address: "",
      state: "",
      dist: "",
      pin: "",
      desc: "",
    },
    validationSchema: yup.object().shape({
      fName: yup.string().required("Firm name is required"),
      cat: yup.string().required("Category is required"),
      otherCat: yup.string().when("cat", {
        is: "other",
        then: yup.string().required("other category is required"),
      }),
      first: yup.string().required("First name is required"),
      number: yup
        .string()
        .required("Phone number is required")
        .matches(/^[0-9]{10}$/, "Invalid phone number"),
      // email: yup.string().email('Invalid email').required('Email is required'),
      // state: yup.string().required("State is required"),
      // dist: yup.string().required("District is required"),
      // pin: yup
      //   .string()
      //   .required("PIN code is required")
      //   .matches(/^[0-9]{6}$/, "Invalid PIN code"),
      desc: yup.string().required("Description is required"),
    }),
    onSubmit: (values) => {
      const { address, state, dist, pin } = values;
      const allEmpty = [address, state, dist, pin].every((field) => !field);
      const allFilled = [address, state, dist, pin].every((field) => !!field);

      if (!allEmpty && !allFilled) {
        formik.setErrors({
          address:
            "Either fill all fields of address, state, dist and pin or none",
          state:
            "Either fill all fields of address, state, dist and pin or none",
          dist: "Either fill all fields of address, state, dist and pin or none",
          pin: "Either fill all fields of address, state, dist and pin or none",
        });
        return;
      }
      if (values.updateId) {
        handleUpdateUploadServer(values);
      } else {
        handleUploadServer(values);
      }
    },
  });
  const districts = Distrct[States.indexOf(formik.values.state) + 1];

  const handleUploadServer = async (values) => {
    setLoading(true);
    const obj = {
      firmName: values.fName,
      businessCategory: values.cat === "other" ? values.otherCat : values.cat,
      firstName: values.first,
      middleName: values.middle,
      lastName: "",
      profilePic: "",
      firmPic: values?.firmPic,
      mobileNumber: String(values.number),
      email: values.email,
      description: values.desc,
      // firmAddress: {
      //   address: values.address,
      //   dist: values.dist,
      //   pinCode: values.pin,
      //   state: values.state,
      // },
    };
    if (values.address && values.dist && values.pin && values.state) {
      obj.firmAddress = {
        address: values.address,
        dist: values.dist,
        pinCode: values.pin,
        state: values.state,
      };
    }
    try {
      if (state?.image?.file) {
        let s3FilePath = `firm/${state?.image?.file?.name}`;
        let attachmentUrl = await uploadFile(
          state?.image?.file,
          s3FilePath,
          state?.image?.file?.type
        );
        obj["profilePic"] = attachmentUrl.fileURL;
      }
      if (state?.pic?.file) {
        let s3FilePath = `firm/${state?.pic?.file?.name}`;
        let attachmentUrl = await uploadFile(
          state?.pic?.file,
          s3FilePath,
          state?.pic?.file?.type
        );
        obj["firmPic"] = attachmentUrl.fileURL;
      }

      addFirmMutate(obj);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const handleUpdateUploadServer = async (values) => {
    setLoading(true);
    const obj = {
      firmDocId: values.updateId,
      firmName: values.fName,
      businessCategory: values.cat === "other" ? values.otherCat : values.cat,
      firstName: values.first,
      middleName: values.middle,
      lastName: values.last,
      profilePic: values?.profile,
      firmPic: values?.firmPic,
      mobileNumber: String(values.number),
      email: values.email,
      description: values.desc,
      // firmAddress: {
      //   address: values.address,
      //   dist: values.dist,
      //   pinCode: values.pin,
      //   state: values.state,
      // },
    };
    if (values.address && values.dist && values.pin && values.state) {
      obj.firmAddress = {
        address: values.address,
        dist: values.dist,
        pinCode: values.pin,
        state: values.state,
      };
    }
    try {
      if (state?.image?.file) {
        let s3FilePath = `firm/${state?.image?.file?.name}`;
        let attachmentUrl = await uploadFile(
          state?.image?.file,
          s3FilePath,
          state?.image?.file?.type
        );
        obj["profilePic"] = attachmentUrl.fileURL;
      }
      if (state?.pic?.file) {
        let s3FilePath = `firm/${state?.pic?.file?.name}`;
        let attachmentUrl = await uploadFile(
          state?.pic?.file,
          s3FilePath,
          state?.pic?.file?.type
        );
        obj["firmPic"] = attachmentUrl.fileURL;
      }

      updateFirmMutate(obj);
      setLoading(false);
    } catch (error) {
      toast.error(error);
      setLoading(false);
    }
  };

  const handleUpload = (e) => {
    let file = e.target.files[0];
    let fileType = file.type;
    let fileSize = file.size;

    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (!allowedTypes.includes(fileType)) {
      toast.error("Invalid file type");
      return;
    }

    if (fileSize > 5 * 1000000) {
      // fileSize > 5MB then show popup message
      toast.error(
        `File size is too large, please upload image of size less than 5MB.\nSelected File Size: ${
          fileSize / 1000000
        }MB only`
      );
      return;
    }

    setState((prev) => ({
      ...prev,
      image: {
        file: e.target.files[0],
        link: URL.createObjectURL(e.target.files[0]),
      },
    }));
  };

  const handleFirmPicUpload = (e) => {
    let file = e.target.files[0];
    let fileType = file.type;
    let fileSize = file.size;

    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];

    if (!allowedTypes.includes(fileType)) {
      toast.error("Invalid file type");
      return;
    }

    if (fileSize > 5 * 1000000) {
      // fileSize > 5MB then show popup message
      toast.error(
        `File size is too large, please upload image of size less than 5MB.\nSelected File Size: ${
          fileSize / 1000000
        }MB only`
      );
      return;
    }

    setState((prev) => ({
      ...prev,
      pic: {
        file: e.target.files[0],
        link: URL.createObjectURL(e.target.files[0]),
      },
    }));
  };
  const handleCloseAddUpdateFirmDialog = () => {
    formik.resetForm();
    setState((prev) => ({
      ...prev,
      image: { file: "", link: "" },
      pic: { file: "", link: "" },
    }));
    onClose();
  };
  const { mutate: addFirmMutate, loading: addFirmLoading } = useMutation(
    addFirm,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        refetch();
        handleCloseAddUpdateFirmDialog();
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    }
  );

  const { mutate: updateFirmMutate, loading: updateFirmLoading } = useMutation(
    updateFirmProfile,
    {
      onSuccess: ({ data }) => {
        toast.success(data?.message);
        refetch();
        handleCloseAddUpdateFirmDialog();
      },
      onError: ({ response: { data } }) => {
        toast.error(data?.message);
      },
    }
  );

  useEffect(() => {
    if (edit) {
      formik.setValues(() => ({
        updateId: editData?._id,
        dialog: true,
        fName: editData?.firmName,
        cat: editData?.businessCategory,
        first: editData?.firstName,
        middle: editData?.middleName,
        last: editData?.lastName,
        number: editData?.mobileNumber,
        email: editData?.email,
        address: editData?.firmAddress?.address,
        state: editData?.firmAddress?.state,
        dist: editData?.firmAddress?.dist,
        pin: editData?.firmAddress?.pinCode,
        desc: editData?.description,
      }));
      setState((prev) => ({
        ...prev,
        image: {
          link: editData?.profilePic,
        },
        pic: {
          link: editData?.firmPic,
        },
      }));
      getAllBusinessCategoryRefetch();
    }
  }, [edit]);

  return (
    <CommonDialog
      open={open}
      onClose={handleCloseAddUpdateFirmDialog}
      minWidth="700px"
      title={formik.values.updateId ? "Update Firm" : "Add New Firm"}
    >
      <DialogContent>
        <form onSubmit={formik.handleSubmit} id="form">
          <Grid container spacing={1}>
            <Grid item xs={12} md={formik.values.cat === "other" ? 4 : 8}>
              <TextFieldLabel title={"Firm's Name"} required />

              <CustomTextField
                fullWidth
                size="small"
                name="fName"
                value={formik.values.fName}
                onChange={formik.handleChange}
                placeholder="Enter Firm Name"
              />
              {formik.touched.fName && formik.errors.fName && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.fName}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldLabel title={"Business Category"} required />
              <CustomSelectBox
                native
                displayEmpty
                fullWidth
                size="small"
                name="cat"
                value={formik.values.cat}
                onChange={formik.handleChange}
              >
                <MenuItem value={""} disabled component="option">
                  Select
                </MenuItem>
                {businessCategory?.map((item, i) => (
                  <MenuItem value={item} key={i} component="option">
                    {item}
                  </MenuItem>
                ))}
                <MenuItem value={"other"} component="option">
                  Other
                </MenuItem>
              </CustomSelectBox>
              {formik.touched.cat && formik.errors.cat && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.cat}
                </Typography>
              )}
            </Grid>
            {formik.values.cat === "other" && (
              <Grid item xs={12} md={4}>
                <TextFieldLabel title={"Other"} required />
                <CustomTextField
                  fullWidth
                  size="small"
                  placeholder="Other Business Category"
                  name="otherCat"
                  value={formik.values.otherCat}
                  onChange={formik.handleChange}
                />
                {formik.touched.otherCat && formik.errors.otherCat && (
                  <Typography sx={{ color: "red" }}>
                    {formik.errors.otherCat}
                  </Typography>
                )}
              </Grid>
            )}
            <Grid item xs={12} md={4}>
              <TextFieldLabel title={"Owner's First Name"} required />

              <CustomTextField
                fullWidth
                size="small"
                placeholder="Enter First Name"
                name="first"
                value={formik.values.first}
                onChange={formik.handleChange}
              />
              {formik.touched.first && formik.errors.first && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.first}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldLabel title={"Owner's Middle Name"} />
              <CustomTextField
                fullWidth
                size="small"
                placeholder="Enter Middle Name"
                name="middle"
                value={formik.values.middle}
                onChange={formik.handleChange}
              />
              {formik.touched.middle && formik.errors.middle && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.middle}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldLabel title={"Owner's Last Name"} />

              <CustomTextField
                fullWidth
                size="small"
                placeholder="Enter Last Name"
                name="last"
                value={formik.values.last}
                onChange={formik.handleChange}
              />
              {formik.touched.last && formik.errors.last && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.last}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldLabel title={"Mobile Number"} required />
              <CustomTextField
                fullWidth
                type="number"
                size="small"
                placeholder="Enter Mobile Number"
                name="number"
                value={formik.values.number}
                onChange={(e) => {
                  if (e.target.value.length <= 10) {
                    formik.handleChange(e);
                  }
                }}
              />
              {formik.touched.number && formik.errors.number && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.number}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <TextFieldLabel title={"Email"} />

              <CustomTextField
                type="email"
                fullWidth
                size="small"
                placeholder="Enter Email"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.touched.email && formik.errors.email && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.email}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <TextFieldLabel title={"Firm's Address"} />

              <CustomTextField
                fullWidth
                size="small"
                placeholder="Enter Firm's Address"
                name="address"
                value={formik.values.address}
                onChange={formik.handleChange}
              />
              {formik.touched.address && formik.errors.address && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.address}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldLabel title={"State"} />
              <CustomSelectBox
                native
                fullWidth
                size="small"
                displayEmpty
                value={formik.values.state}
                MenuProps={MenuProps}
                name="state"
                onChange={formik.handleChange}
              >
                <MenuItem value="" disabled component="option">
                  Select
                </MenuItem>
                {States.map((state) => (
                  <MenuItem value={state} component="option">
                    {state}
                  </MenuItem>
                ))}
              </CustomSelectBox>
              {formik.errors.state && formik.touched.state && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.state}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldLabel title={"District"} />
              <CustomSelectBox
                native
                fullWidth
                size="small"
                displayEmpty
                value={formik.values.dist}
                MenuProps={MenuProps}
                name="dist"
                disabled={!formik.values.state}
                onChange={formik.handleChange}
              >
                <MenuItem value="" disabled component="option">
                  Select
                </MenuItem>
                {districts?.length > 1 &&
                  districts?.map((dist) => (
                    <MenuItem value={dist} component="option">
                      {dist}
                    </MenuItem>
                  ))}
              </CustomSelectBox>
              {formik.errors.dist && formik.touched.dist && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.dist}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={4}>
              <TextFieldLabel title={"Pin Code"} />
              <CustomTextField
                type="number"
                fullWidth
                size="small"
                name="pin"
                value={formik.values.pin}
                onChange={(e) => {
                  if (e.target.value.length <= 6) {
                    formik.handleChange(e);
                  }
                }}
                placeholder="Enter PIN code"
              />
              {formik.touched.pin && formik.errors.pin && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.pin}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md={5}>
              <Stack direction="row" gap={5}>
                <Stack>
                  <TextFieldLabel title={"Profile Pic"} />

                  <Box>
                    <img
                      src={
                        state?.image?.link
                          ? state?.image?.link
                          : "/image-placeholder.jpeg"
                      }
                      style={{
                        height: "100px",
                        width: "100px",
                        objectFit: "cover",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      alt="ekalsutra"
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      component="label"
                    >
                      <input
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={handleUpload}
                      />
                      Upload
                    </Button>
                  </Box>
                </Stack>
                <Stack>
                  <TextFieldLabel title={"Firm Pic"} />
                  <Box>
                    <img
                      src={
                        state?.pic?.link
                          ? state?.pic?.link
                          : "/image-placeholder.jpeg"
                      }
                      style={{
                        height: "100px",
                        width: "100px",
                        objectFit: "cover",
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                      alt="ekalsutra"
                    />
                  </Box>
                  <Box>
                    <Button
                      variant="contained"
                      color="secondary"
                      component="label"
                    >
                      <input
                        type="file"
                        accept="image/*,application/pdf"
                        hidden
                        onChange={handleFirmPicUpload}
                      />
                      Upload
                    </Button>
                  </Box>
                </Stack>
              </Stack>
            </Grid>
            <Grid item xs={12} md={7}>
              <TextFieldLabel title={"Description"} required />
              <TextareaAutosize
                style={{
                  border: "1px solid #cfcfcf",
                  borderRadius: "10px",
                  padding: "10px",
                  resize: "none",
                  width: "100%",
                  height: "140px",
                }}
                name="desc"
                value={formik.values.desc}
                onChange={formik.handleChange}
                placeholder="Enter Description"
              />
              {formik.touched.desc && formik.errors.desc && (
                <Typography sx={{ color: "red" }}>
                  {formik.errors.desc}
                </Typography>
              )}
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <LoadingButton
          loading={addFirmLoading || loading || updateFirmLoading}
          type="submit"
          form="form"
          variant="contained"
          color="secondary"
          size="large"
        >
          {formik.values.updateId ? "Update" : "SUBMIT"}
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
};

export default AddEditFirmDialog;
