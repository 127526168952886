import { css } from "styled-components";

export const screen = {
  xsmall: {
    standard: (...args) => css`
      @media (max-width: 576px) {
        ${css(...args)};
      }
    `,
  },
  small: {
    standard: (...args) => css`
      @media (max-width: 768px) {
        ${css(...args)};
      }
    `,
  },
  medium: {
    standard: (...args) => css`
      @media (max-width: 992px) {
        ${css(...args)};
      }
    `,
  },
  large: {
    standard: (...args) => css`
      @media (max-width: 1200px) {
        ${css(...args)};
      }
    `,
  },
  xlarge: {
    standard: (...args) => css`
      @media (max-width: 1400px) {
        ${css(...args)};
      }
    `,
  },
  xxlarge: {
    standard: (...args) => css`
      @media (min-width: 1400px) {
        ${css(...args)};
      }
    `,
  },
};
