import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Fallback from "./app/components/AppRoutes";
import { BrowserRouter } from "react-router-dom";
import "./index.css";

import { configureAmplify } from "./app/config/amplify_config";

configureAmplify();

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  // <React.StrictMode>
  <Suspense fallback={<Fallback />}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Suspense>
  // </React.StrictMode>
);

reportWebVitals();
