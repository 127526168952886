import Fallback from "app/components/Fallback";
import React from "react";

const TestExams = React.lazy(() =>
  import("app/modules/exam-control/pages/TestExams/ExamTest")
);
const AdmitCard = React.lazy(() =>
  import("app/modules/exam-control/pages/AdmitCard")
);

const ReportCard = React.lazy(() =>
  import("app/modules/exam-control/pages/ReportCard")
);

const PrintAdmitCard = React.lazy(() =>
  import("app/modules/exam-control/pages/PrintAdmitCard")
);

export const TestExamsPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <TestExams {...props} />
  </React.Suspense>
);
export const AdmitCardPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <AdmitCard {...props} />
  </React.Suspense>
);
export const ReportCardPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <ReportCard {...props} />
  </React.Suspense>
);
export const PrintAdmitCardPage = (props) => (
  <React.Suspense fallback={<Fallback />}>
    <PrintAdmitCard {...props} />
  </React.Suspense>
);
