import {
  Box,
  Button,
  Checkbox,
  Container,
  Dialog,
  DialogContent,
  DialogTitle,
  Drawer,
  Grid,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
} from "@mui/material";
import DialogHeader from "app/components/common/DialogHeader";
import { selectActiveSession } from "app/modules/schools/selectors";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import StudentFilter from "./components/StudentFilter";
import CommonTableHorizontalScrollable from "app/components/Tables/CommonTableHorizontalScrollable";
import TableProfilePic from "app/components/common/TableProfilePic";
import { Link } from "react-router-dom";
import appRoutes from "app/config/routes";
import InstallMobileIcon from "@mui/icons-material/InstallMobile";
import dayjs from "dayjs";
import { DATE_FORMAT } from "app/config/constants";
import { LoadingButton } from "@mui/lab";
import TableActionButtons from "app/components/common/TableActionButtons";
import { Toast } from "react-bootstrap";
import { useReactToPrint } from "react-to-print";

import CharacterCertificatePrint from "./components/character/CharacterCertificatePrint";
import TextFieldLabel from "app/components/common/TextFieldLabel";
import { CustomSelectBox } from "assets/styles/globalStyledComponent";
import DOBCertificatePrint from "./components/DOB/DOBCertificatePrint";
import SchoolLeavingCertificatePrint from "./components/schoolLeaving/SchoolLeavingCertificatePrint";
import ParticipationCertificate from "./components/participation/ParticipationCertificate";
import BonafideCertificatePrint from "./components/bonafied/BonafideCertificate";
import FeeCertificatePrint from "./components/fee/FeeCertificatePrint";
import { getBonafideDetail } from "app/services/schoolService/newFee.service";
import { useQuery } from "react-query";
import { toast } from "react-toastify";

const StudentDrawer = ({
  show,
  setShow,
  certificateName,
  participationData,
}) => {
  const isMobile = useMediaQuery("(max-width: 768px)");
  const session = useSelector(selectActiveSession);
  const selectedSession = useSelector(selectActiveSession);
  const template_1_Ref = useRef(null);

  const [allCheck, setAllCheck] = useState(false);
  const [allPrintCheck, setAllPrintCheck] = useState(false);
  const [falseState, setFalseState] = useState(false);
  const [isPrinting, setIsPrinting] = useState(false);
  const [filteredDataPrint, setFilteredDataPrint] = useState([]);
  const [selectedStudentForPrint, setSelectedStudentForPrint] = useState([]);
  const [state, setState] = useState({
    suspendData: {
      dialog: false,
      studentData: {},
    },
    nameSearchDialog: false,
    verifiedAadharData: "",
    collapse: "student",
    mobileNumber: "",
    mobileVerified: false,
    otp: "",
    verifiedMobileData: "",
    classDocId: "",
    filter: "By Class",
    className: "",
    section: "",
    sectionList: [],
    category: "",
    community: "",
    admissionNumber: "",
    admissionDateFrom: new Date(),
    admissionDateTo: new Date(),
    firstAdmissionDateFrom: new Date(),
    firstAdmissionDateTo: new Date(),
    studentName: "",
    registrationNumber: "",
    searchmobileNumber: "",
    vehicleNumber: "",
    route: "",
    addharNumber: "",
    stopName: "",
    routeNumber: "",
    studentList: [],
    checkExportOptions: false,
    template: "Template 1",
  });
  const handleClose = () => {
    setShow(false);
    setAllPrintCheck(false);
  };

  const handleChangeId = (e, _id) => {
    const isChecked = e.target.checked;
    if (isChecked) {
      setState((prev) => ({
        ...prev,
        studentList: prev.studentList.map((item) =>
          item._id === _id ? { ...item, checked2: true } : item
        ),
      }));
      setSelectedStudentForPrint((prevStudentIds) => [...prevStudentIds, _id]);
    } else {
      setState((prev) => ({
        ...prev,
        studentList: prev.studentList.map((item) =>
          item._id === _id ? { ...item, checked2: false } : item
        ),
      }));
      setSelectedStudentForPrint((prevStudentIds) =>
        prevStudentIds.filter((id) => id !== _id)
      );
    }
  };

  useEffect(() => {
    if (!allCheck) {
      state.studentList?.forEach((item, i) => {
        state.studentList[i]["checked"] = false;
      });
      setFalseState(!falseState);
    } else {
      state.studentList?.forEach((item, i) => {
        state.studentList[i]["checked"] = true;
      });
      setFalseState(!falseState);
    }
  }, [allCheck]);

  useEffect(() => {
    if (!allPrintCheck) {
      state.studentList?.forEach((item, ind) => {
        state.studentList[ind]["checked2"] = false;
      });
      setSelectedStudentForPrint([]);
      setFalseState(!falseState);
    } else {
      // let selectedStudents = [];
      state.studentList?.forEach((item, ind) => {
        state.studentList[ind]["checked2"] = true;
      });
      // setSelectedStudentForPrint(selectedStudents);
      setFalseState(!falseState);
    }
  }, [allPrintCheck]);

  const columns = useMemo(() => {
    return [
      {
        header: "#",
        accessorKey: "#",
        Cell: ({ cell }) => (
          <Typography>{parseInt(cell.row.id) + 1}</Typography>
        ),
        maxSize: 60,
      },
      {
        header: "Roll No",
        accessorKey: "rollNumber",
        // size: isMobile ? 60 : 40,
      },
      {
        header: "Identity",
        accessorKey: "admissionNumber",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Box>
              <div title="SR Number">
                <Typography>{original?.srNumber ?? "--"}</Typography>
              </div>
              <div title="Admission Number">
                <Typography sx={{ color: "#F0C62E" }}>
                  {original?.admissionNumber ?? "--"}
                </Typography>
              </div>
              <div title="Reg. Number">
                <Typography sx={{ color: "#2789FD" }}>
                  {original?.registrationNumber ?? "--"}
                </Typography>
              </div>
            </Box>
          );
        },
        // maxSize: 25,
      },
      {
        header: "Student Name",
        accessorKey: "firstName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <>
              <Stack direction="row" alignItems="center" gap={0.6}>
                <Button
                  onClick={() => {
                    // setOpenUploadDialog(true);
                    // setStudentDocId(original?._id);
                  }}
                >
                  <TableProfilePic image={original?.profilePic} />
                </Button>
                <Link
                  to={`/${appRoutes.student_profile}/${original?._id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Typography>
                    {original?.firstName} {original?.middleName}{" "}
                    {original?.lastName}
                  </Typography>
                </Link>
                {original?.appNotificationToken && (
                  <Tooltip title="This Student have downloaded Ekalsutra App">
                    <InstallMobileIcon />
                  </Tooltip>
                )}
              </Stack>
            </>
          );
        },
        // size: 70,
      },
      {
        header: "Father Name",
        accessorKey: "fatherInfo.firstName",
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography>
              {original?.fatherInfo?.firstName &&
                original?.fatherInfo?.firstName}{" "}
              {original?.fatherInfo?.middleName &&
                String(original?.fatherInfo?.middleName).charAt(0)}{" "}
              {original?.fatherInfo?.lastName && original?.fatherInfo?.lastName}
            </Typography>
          );
        },
        // size: 70,
      },
      {
        header: "Gender",
        accessorKey: "gender",
        // size: isMobile ? 60 : 40,
        enableColumnOrdering: true,
      },

      {
        header: "Category",
        accessorKey: "category",
        // size: isMobile ? 60 : 40,
        enableColumnOrdering: true,
      },
      {
        header: "Admission Date",
        accessorKey: "admissionDate",
        id: "admissionDate",
        accessorfn: (row) => row.createdOn,
        Cell: ({ cell }) => {
          return dayjs(cell.getValue()).format(DATE_FORMAT.withMonthName);
        },
        // size: isMobile ? 60 : 40,
      },
      {
        header: "Transport No",
        accessorKey: "transportInfo.vehicleNumber",
        // size: isMobile ? 60 : 50,
        enableColumnOrdering: true,
      },
      {
        header: "Transport Fee",
        accessorKey: "transportInfo.feeAmount",
        // size: isMobile ? 60 : 40,
        Cell: ({
          cell: {
            row: { original },
          },
        }) => {
          return (
            <Typography>&#8377;{original?.transportInfo?.feeAmount}</Typography>
          );
        },
      },
      {
        id: "Actions",
        accessorKey: "Actions",
        header: "Actions",
        Header: () => {
          return (
            <Stack direction="row" gap={1}>
              <Checkbox
                checked={allPrintCheck}
                size="large"
                color="secondary"
                onChange={(e) => {
                  setAllPrintCheck((prev) => !prev);
                }}
              />
              {/* <input
                type="checkbox"
                id="checkdiv2"
                checked={allPrintCheck}
                value={allPrintCheck}
                style={{ cursor: "pointer", width: "20px", height: "20px" }}
                onClick={(e) => setAllPrintCheck((prev) => !prev)}
              /> */}
              <Typography>Print All</Typography>
            </Stack>
          );
        },
        Cell: ({ cell, row }) => {
          return (
            <>
              <Stack direction={"row"}>
                <div
                  style={{
                    width: "35px",
                    height: "35px",
                    alignItems: "center",
                    display: "flex",
                    justifyContent: "center",
                    borderRadius: "5px",
                    cursor: "pointer",
                  }}
                >
                  <input
                    type="checkbox"
                    id="checkdiv2"
                    checked={row?.original?.checked2}
                    style={{
                      cursor: "pointer",
                      width: "20px",
                      height: "20px",
                    }}
                    onChange={(e) => {
                      handleChangeId(e, row?.original?._id);
                      setFalseState(!falseState);
                    }}
                  />
                </div>
              </Stack>
            </>
          );
        },
        size: 140,
      },
    ];
  }, [allPrintCheck]);

  const template_2_Ref = useRef(null);

  const handlePrintDialog = () => {
    setIsPrinting(true);
  };

  const handleClosePrintDialog = () => {
    setIsPrinting(false);
  };

  const handlePrint = useReactToPrint({
    content: () => template_2_Ref.current,
    documentTitle: `${certificateName}-${new Date().getTime()}`,
    onAfterPrint: () => {
      setSelectedStudentForPrint([]);
      // setAllPrintCheck(false);
      handleClosePrintDialog();
      // setAllPrintCheck(false);
    },
  });

  const {
    isLoading: getBonafideDetailLoading,
    refetch: getBonafideDetailRefetch,
    isFetching: getBonafideDetailFetching,
    data: getBonafideDetailData,
  } = useQuery({
    queryKey: ["getBonafideDetail", selectedSession],
    queryFn: () =>
      getBonafideDetail({
        session: selectedSession,
        reqStudentDocIdList: JSON.stringify(
          state?.studentList
            ?.filter((item) => item?.checked2)
            .map((res) => res?._id)
        ),
      }),
    enabled: false,
    onSuccess: (data) => {},
    onError: ({ response: { data } }) => {
      toast.error(data?.message);
    },
  });

  return (
    <>
      <Drawer
        open={show}
        onClose={() => {
          handleClose();
        }}
        anchor="right"
        PaperProps={{
          sx: { minWidth: { xs: "98%", md: "80vw" } },
        }}
      >
        <DialogHeader handleClose={() => handleClose()} title="Student List" />

        <StyledWrapper
          sx={{
            width: { xs: window.innerWidth - 10, md: "100%" },
            overflow: "auto",
          }}
        >
          <StudentFilter
            state={state}
            setState={setState}
            handlePrint={async () => {
              if (certificateName === "Fee Certificate") {
                await getBonafideDetailRefetch();
              }
              setIsPrinting(true);
              handlePrint();
            }}
          />

          {/* table for student showing */}
          <Box sx={{ mt: 2 }}>
            <CommonTableHorizontalScrollable
              columns={columns}
              data={state?.studentList}
              initialState={{
                columnPinning: {
                  left: ["#"],
                  right: ["Actions"],
                },
              }}
            />
          </Box>

          <Dialog
            open={isPrinting}
            onClose={handleClosePrintDialog}
            PaperProps={{
              sx: { minWidth: "90%" },
            }}
          >
            <DialogTitle sx={{ p: "0 !important" }}>
              <DialogHeader
                title="Print Certificate"
                handleClose={handleClosePrintDialog}
              />
            </DialogTitle>
            <DialogTitle>
              <Stack
                direction="row"
                justifyContent={"space-between"}
                alignItems={"center"}
              >
                <div>
                  <TextFieldLabel title={"Choose Template"} />
                  <CustomSelectBox
                    size="small"
                    value={state.template}
                    onChange={(e) =>
                      setState((prev) => ({
                        ...prev,
                        template: e.target.value,
                      }))
                    }
                    sx={{ width: "250px" }}
                  >
                    <MenuItem value="Template 1">Template 1</MenuItem>
                    {/* <MenuItem value="Template 2">Template 2</MenuItem>
                    <MenuItem value="Template 3">Template 3</MenuItem> */}
                  </CustomSelectBox>
                </div>
                <Button
                  size="large"
                  variant="contained"
                  color="secondary"
                  onClick={handlePrint}
                  sx={{ textTransform: "capitalize", width: "200px" }}
                >
                  Print
                </Button>
              </Stack>
            </DialogTitle>
            <DialogContent>
              {state?.template === "Template 1" &&
                certificateName === "Character Certificate" && (
                  <>
                    <Box ref={template_2_Ref}>
                      {state?.studentList
                        ?.filter((item) => item?.checked2)
                        .map((res) => (
                          <Box
                            sx={{
                              m: 0,
                              mb: 12.2,
                              "@media print": {
                                m: 0,
                                mb: 0,
                              },
                              pageBreakInside: "avoid",
                            }}
                          >
                            <CharacterCertificatePrint
                              key={res?._id}
                              studentData={res}
                              session={selectedSession}
                            />
                          </Box>
                        ))}
                    </Box>
                  </>
                )}
              {state?.template === "Template 1" &&
                certificateName === "Birth Certificate" && (
                  <>
                    <Box ref={template_2_Ref}>
                      {state?.studentList
                        ?.filter((item) => item?.checked2)
                        .map((res) => (
                          <Box
                            sx={{
                              m: 0,
                              mb: 12.2,
                              "@media print": {
                                m: 0,
                                mb: 0,
                              },
                              pageBreakInside: "avoid",
                            }}
                          >
                            <DOBCertificatePrint
                              key={res?._id}
                              studentData={res}
                              session={selectedSession}
                            />
                          </Box>
                        ))}
                    </Box>
                  </>
                )}
              {state?.template === "Template 1" &&
                certificateName === "School Leaving Certificate" && (
                  <>
                    <Box ref={template_2_Ref}>
                      {state?.studentList
                        ?.filter((item) => item?.checked2)
                        .map((res) => (
                          <Box
                            sx={{
                              m: 0,
                              mb: 12.2,
                              "@media print": {
                                m: 0,
                                mb: 0,
                              },
                              pageBreakInside: "avoid",
                            }}
                          >
                            <SchoolLeavingCertificatePrint
                              key={res?._id}
                              studentData={res}
                              session={selectedSession}
                            />
                          </Box>
                        ))}
                    </Box>
                  </>
                )}
              {state?.template === "Template 1" &&
                certificateName === "Participation Certificate" && (
                  <>
                    <Box ref={template_2_Ref}>
                      {state?.studentList
                        ?.filter((item) => item?.checked2)
                        .map((res) => (
                          <Box
                            sx={{
                              m: 0,
                              mb: 12.2,
                              "@media print": {
                                m: 0,
                                mb: 0,
                              },
                              pageBreakInside: "avoid",
                            }}
                          >
                            <ParticipationCertificate
                              key={res?._id}
                              studentData={res}
                              session={selectedSession}
                              participationData={participationData}
                            />
                          </Box>
                        ))}
                    </Box>
                  </>
                )}
              {state?.template === "Template 1" &&
                certificateName === "Bonafide Certificate" && (
                  <>
                    <Box ref={template_2_Ref}>
                      {state?.studentList
                        ?.filter((item) => item?.checked2)
                        .map((res) => (
                          <Box
                            sx={{
                              m: 0,
                              mb: 12.2,
                              "@media print": {
                                m: 0,
                                mb: 0,
                              },
                              pageBreakInside: "avoid",
                            }}
                          >
                            <BonafideCertificatePrint
                              key={res?._id}
                              studentData={res}
                              session={selectedSession}
                            />
                          </Box>
                        ))}
                    </Box>
                  </>
                )}
              {state?.template === "Template 1" &&
                certificateName === "Fee Certificate" && (
                  <>
                    <Box ref={template_2_Ref}>
                      {getBonafideDetailData?.data?.list.map((res) => (
                        <Box
                          sx={{
                            m: 0,
                            mb: 12.2,
                            "@media print": {
                              m: 0,
                              mb: 0,
                            },
                            pageBreakInside: "avoid",
                          }}
                        >
                          <FeeCertificatePrint
                            key={res?._id}
                            studentData={res?.studentprofile}
                            session={selectedSession}
                            feeTypes={res?.feeTypes}
                            feeSummary={{
                              feeTypeCount: res?.feeTypeCount,
                              totalGeneralFeeSummary:
                                res?.totalGeneralFeeSummary,
                              totalCategoryDiscountSummary:
                                res?.totalCategoryDiscountSummary,
                              totalRecurringDiscountSummary:
                                res?.totalRecurringDiscountSummary,
                              totalOneTimeDiscountSummary:
                                res?.totalOneTimeDiscountSummary,
                              totalExtraDiscountSummary:
                                res?.totalExtraDiscountSummary,
                              totalPaidFeeSummary: res?.totalPaidFeeSummary,
                              totalBalanceFeeSummary:
                                res?.totalBalanceFeeSummary,
                            }}
                          />
                        </Box>
                      ))}
                    </Box>
                  </>
                )}
            </DialogContent>
          </Dialog>
        </StyledWrapper>
      </Drawer>
    </>
  );
};

export default StudentDrawer;

const StyledWrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 95vh;
  padding: 30px 15px 15px 15px;
`;

const PageBreak = styled.div`
  @media all {
    display: none;
  }

  @media print {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }

  @media print {
    margin-top: 0;
    display: block;
    page-break-before: always;
  }

  @page {
    size: auto;
    margin: 3mm;
  }
`;
