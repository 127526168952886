import { createAsyncThunk } from "@reduxjs/toolkit";
import API_ENDPOINTS from "app/config/endpoints";
import { authTokenAxios } from "app/services/axios";

import axios from "axios";

export const login = createAsyncThunk(
  "auth/login",
  async (payload, { dispatch, rejectWithValue, fulfillWithValue }) => {
    try {
      const loginResp = await axios.post(API_ENDPOINTS.auth.signIn, payload);
      const authToken = loginResp?.data?.token;
      if (authToken) {
        authTokenAxios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${loginResp.data.token}`;

        // Set Auth Token in local storage
        localStorage.setItem("authAccessToken", loginResp.data.token);

        return fulfillWithValue(loginResp?.data);
      } else {
        return rejectWithValue("Token not found");
      }
    } catch (loginError) {
      if (!loginError.response) throw loginError;
      return rejectWithValue(loginError.response.data);
    }

    // if (loginResp.status === 200) {
    //   return;
    // } else {
    //   return rejectWithValue(Promise.resolve(rejectWithValue(loginResp.data)));
    // }

    // .then((loginResp) => {

    //   authTokenAxios.defaults.headers.common[
    //     "Authorization"
    //   ] = `Bearer ${loginResp.data.token}`;

    //   dispatch(getSchoolList())
    //     .then((schoolListResp) => {
    //       const { count, instituteList, isGroupOfInstitute } =
    //         schoolListResp.payload.obj;
    //       fulfillWithValue({
    //         authData: loginResp.data,
    //         count,
    //         isGroupOfInstitute,
    //         instituteList,
    //       });
    //     })
    //     .catch(rejectWithValue);
    // })
    // .catch((err) => {
    //   rejectWithValue(err);
    // });
  }
);

// AUTH REFRESH TOKEN
export const refreshAccessToken = createAsyncThunk(
  "auth/refreshToken",
  (payload, { dispatch }) => {
    return new Promise((res, rej) => {
      axios
        .post(API_ENDPOINTS.auth.refreshToken, payload)
        .then((resp) => {
          authTokenAxios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${resp.data.accessToken}`;

          //Update Refresh Token in local storage
          localStorage.setItem("authRefreshToken", resp.data.refreshToken);

          // Update Auth Token in local storage
          localStorage.setItem("authAccessToken", resp.data.accessToken);

          // Update TTL in local storage
          localStorage.setItem("authTTL", resp.data.exp);

          res({
            accessToken: resp?.data?.accessToken,
            refreshToken: resp?.data?.refreshToken,
            authTTL: resp?.data?.exp,
          });
        })
        .catch((err) => {
          // status code 401

          // clean up local storage
          localStorage.removeItem("authRefreshToken");
          localStorage.removeItem("authAccessToken");
          localStorage.removeItem("authTTL");
          localStorage.removeItem("schoolToken");
          localStorage.removeItem("schoolRefreshToken");
          localStorage.removeItem("authRefreshToken");
          localStorage.removeItem("selectedSchoolId");

          // dispatch logout action
          rej(err);
        });
    });
  }
);

export const getUserProfile = createAsyncThunk(
  "/staff/getStaffProfile",
  async (payload, { getState, fulfillWithValue, rejectWithValue }) => {
    try {
      const {
        auth: { authData },
      } = getState();
      const resp = await axios.get(API_ENDPOINTS.auth.staffProfile, {
        headers: {
          Authorization: `Bearer ${authData.accessToken}`,
        },
      });
      return fulfillWithValue(resp.data?.obj);
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);
