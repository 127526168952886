import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { ArrowRight, PencilSquare } from "react-bootstrap-icons";
import {
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  OutlinedInput,
  Checkbox,
  Typography,
  Grid,
  InputAdornment,
  Radio,
  Stack,
  styled,
  Divider,
} from "@mui/material";

import {
  getAllFeeStructure,
  getAllRouteList,
  getTransportFeeByStopName,
} from "app/services/student-management";
import { useQuery } from "react-query";
import {
  GenderChoice,
  Category,
  Community,
  BLOOD_GROUP_LIST,
  DISABLITY_LIST,
  SPECIAL_CATEGORY,
  Distrct,
  States,
} from "app/config/administrative.constant.ts";
import { getNewSessionValues } from "./PreviewForPromotion";
import {
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { DesktopDatePicker } from "@mui/x-date-pickers";
import { IndianCurrency, MenuProps } from "app/utils/helper";
import {
  HOUSE_MEMBERSHIP,
  HouseColor,
} from "app/config/administrativeConstants";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectSession, sessionMonths } from "app/modules/schools/selectors";
import CustomLoader from "app/components/common/CustomLoader";
import dayjs from "dayjs";

const validationSchema = yup.object({
  fName: yup.string("Enter your email").required("Email is required"),
  password: yup
    .string("Enter your password")
    .min(8, "Password should be of minimum 8 characters length")
    .required("Password is required"),
});

// Personal Info
export const PersonalDetailsComponent = ({ mainFormik }) => {
  return (
    <Wrapper>
      <form>
        <CustomTextField
          size="small"
          label="First Name"
          required
          id="firstName"
          value={mainFormik.values.firstName}
          name="firstName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        {/* {formik.errors.fName && <div>{formik.errors.fName}</div>} */}
        <CustomTextField
          size="small"
          label="Middle Name"
          id="middleName"
          value={mainFormik.values.middleName}
          name="middleName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          label="Last Name"
          id="lastName"
          size="small"
          value={mainFormik.values.lastName}
          name="lastName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          select
          labelId="demo-controlled-open-select-labe"
          id="gender"
          required
          value={mainFormik.values.gender}
          name="gender"
          onChange={mainFormik.handleChange}
          label="gender"
          sx={{ margin: "10px", width: "200px" }}
          MenuProps={MenuProps}
        >
          {GenderChoice.map((gender) => (
            <MenuItem key={gender} value={gender}>
              {gender}
            </MenuItem>
          ))}
        </CustomTextField>
        <CustomTextField
          size="small"
          label="Addhar Number"
          id="AADHAR"
          required
          value={mainFormik.values.AADHAR}
          name="AADHAR"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />

        <CustomTextField
          size="small"
          id="DOB"
          required
          type="date"
          label="Date of Birth"
          value={mainFormik.values.DOB}
          name="DOB"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Email"
          id="email"
          type="email"
          value={mainFormik.values.email}
          name="email"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Mobile Number"
          id="mobileNumber"
          required
          value={mainFormik.values.mobileNumber}
          name="mobileNumber"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
          inputProps={{ maxLength: 10 }}
        />
        <CustomTextField
          select
          size="small"
          fullWidth
          id="community"
          required
          value={mainFormik.values.community}
          name="community"
          onChange={mainFormik.handleChange}
          label="Community"
          sx={{ margin: "10px", width: "200px", color: "black" }}
          MenuProps={MenuProps}
        >
          {Community.map((community) => (
            <MenuItem key={community} value={community}>
              {community}
            </MenuItem>
          ))}
        </CustomTextField>
        <CustomTextField
          select
          size="small"
          fullWidth
          id="category"
          required
          value={mainFormik.values.category}
          name="category"
          onChange={mainFormik.handleChange}
          label="Category"
          sx={{ margin: "10px", width: "200px", color: "black" }}
          MenuProps={MenuProps}
        >
          {Category.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </CustomTextField>
        <CustomTextField
          select
          size="small"
          fullWidth
          id="special_category"
          value={mainFormik.values.special_category}
          name="special_category"
          onChange={mainFormik.handleChange}
          label="Special Category"
          sx={{ margin: "10px", width: "200px", color: "black" }}
          MenuProps={MenuProps}
        >
          <MenuItem disabled value={" "}>
            Special Category
          </MenuItem>
          {SPECIAL_CATEGORY.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </CustomTextField>

        <CustomTextField
          select
          size="small"
          fullWidth
          id="disability"
          value={mainFormik.values.disability}
          name="disability"
          onChange={mainFormik.handleChange}
          label="Disability"
          sx={{ margin: "10px", width: "200px", color: "black" }}
          MenuProps={MenuProps}
        >
          {DISABLITY_LIST.map((disability_type) => (
            <MenuItem key={disability_type} value={disability_type}>
              {disability_type}
            </MenuItem>
          ))}
        </CustomTextField>
        <CustomTextField
          select
          id="bloodGroup"
          required
          size="small"
          fullWidth
          value={mainFormik.values.bloodGroup}
          name="Blood Group"
          onChange={mainFormik.handleChange}
          label="BloodGroup"
          sx={{ margin: "10px", width: "200px", color: "black" }}
          MenuProps={MenuProps}
        >
          {BLOOD_GROUP_LIST.map((blood_grp) => (
            <MenuItem key={blood_grp} value={blood_grp}>
              {blood_grp}
            </MenuItem>
          ))}
        </CustomTextField>
      </form>
    </Wrapper>
  );
};

export const SiblingDetailsComponent = ({ allValues }) => {
  const formik = useFormik({
    initialValues: {
      schoolName: " ",
      classInfo: " ",
      studentName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  // allValues.siblingDetail = formik.values;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Select
        labelId="demo-controlled-open-select-labe"
        id="schoolName"
        value={formik.values.schoolName}
        name="schooleName"
        onChange={formik.handleChange}
        label="schoolName"
        sx={{ marginRight: "40px", width: "200px", color: "black" }}
        MenuProps={MenuProps}
        input={<OutlinedInput label="Name" />}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value={" "}>
          School Name
        </MenuItem>
        <MenuItem value={"ABC"}>ABC</MenuItem>
        <MenuItem value={"CDE"}>CDE</MenuItem>
        <MenuItem value={"DEF"}>DEF</MenuItem>
      </Select>
      <Select
        labelId="demo-controlled-open-select-labe"
        id="classInfo"
        value={formik.values.classInfo}
        name="classInfo"
        onChange={formik.handleChange}
        label="classInfo"
        sx={{ marginRight: "40px", width: "200px", color: "black" }}
        MenuProps={MenuProps}
        input={<OutlinedInput label="Name" />}
        inputProps={{ "aria-label": "Without label" }}
      >
        <MenuItem disabled value={" "}>
          Class Information
        </MenuItem>
        <MenuItem value={"1st A"}>1st A</MenuItem>
        <MenuItem value={"1st B"}>1st B</MenuItem>
        <MenuItem value={"1st C"}>1st C</MenuItem>
      </Select>

      {/* <InputLabel htmlFor="studentName" sx={{ width: "100px", display: "flex", position: "absolute" }}>
                    Bootstrap
                </InputLabel> */}
      <TextField
        label="Student Name"
        id="studentName"
        value={formik.values.studentName}
        name="studenName"
        sx={{ width: "200px" }}
        onChange={formik.handleChange}
      />
    </form>
  );
};

//  Parent Info
export const ParentDetailsComponent = ({ allValues, mainFormik }) => {
  return (
    <Wrapper>
      <form>
        <Typography variant="h5" fontWeight={600}>
          Mother's Info
        </Typography>

        <CustomTextField
          size="small"
          required
          label="First Name"
          id="motherInfo.firstName"
          value={mainFormik.values?.motherInfo?.firstName}
          name="motherInfo.firstName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Middle Name"
          id="motherInfo.middleName"
          value={mainFormik.values?.motherInfo?.middleName}
          name="motherInfo.middleName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Last Name"
          id="motherInfo.lastName"
          value={mainFormik.values?.motherInfo?.lastName}
          name="motherInfo.lastName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Email"
          id="m_email"
          type="motherInfo.email"
          value={mainFormik.values?.motherInfo?.email}
          name="motherInfo.email"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Mobile"
          id="motherInfo.mobileNumber"
          value={mainFormik.values?.motherInfo?.mobileNumber}
          name="motherInfo.mobileNumber"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
          inputProps={{ maxLength: 10 }}
        />
        <CustomTextField
          size="small"
          label="Occupation"
          id="motherInfo.occupation"
          value={mainFormik.values?.motherInfo?.occupation}
          name="motherInfo.occupation"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />

        {/* Father info */}
        <Typography variant="h5" fontWeight={600}>
          Father's Info
        </Typography>

        <CustomTextField
          required
          size="small"
          label="First Name"
          id="fatherInfo.firstName"
          value={mainFormik.values?.fatherInfo?.firstName}
          name="fatherInfo.firstName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Middle Name"
          id="fatherInfo.middleName"
          value={mainFormik.values?.fatherInfo?.middleName}
          name="fatherInfo.middleName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          label="Last Name"
          size="small"
          id="fatherInfo.lastName"
          value={mainFormik.values?.fatherInfo?.lastName}
          name="fatherInfo.lastName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Email"
          id="fatherInfo.email"
          type="email"
          value={mainFormik.values?.fatherInfo?.email}
          name="fatherInfo.email"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Mobile"
          id="fatherInfo.mobileNumber"
          value={mainFormik.values?.fatherInfo?.mobileNumber}
          name="fatherInfo.mobileNumber"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
          inputProps={{ maxLength: 10 }}
        />
        <CustomTextField
          size="small"
          label="Occupation"
          id="fatherInfo.occupation"
          value={mainFormik.values?.fatherInfo?.occupation}
          name="fatherInfo.occupation"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />

        <Typography variant="h5" fontWeight={600}>
          Guardian's Info
        </Typography>

        <CustomTextField
          size="small"
          label="First Name"
          id="guardianInfo.firstName"
          value={mainFormik.values?.guardianInfo?.firstName}
          name="guardianInfo.firstName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Middle Name"
          id="guardianInfo.middleName"
          value={mainFormik.values?.guardianInfo?.middleName}
          name="guardianInfo.middleName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Last Name"
          id="guardianInfo.lastName"
          value={mainFormik.values?.guardianInfo?.lastName}
          name="guardianInfo.lastName"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Email"
          id="g_email"
          type="guardianInfo.email"
          value={mainFormik.values?.guardianInfo?.email}
          name="guardianInfo.email"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Mobile"
          id="guardianInfo.mobileNumber"
          value={mainFormik.values?.guardianInfo?.mobileNumber}
          name="guardianInfo.mobileNumber"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
          inputProps={{ maxLength: 10 }}
        />
        <CustomTextField
          size="small"
          label="Relation"
          id="guardianInfo.relation"
          value={mainFormik.values?.guardianInfo?.relation}
          name="guardianInfo.relation"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
      </form>
    </Wrapper>
  );
};

// Address Info
export const AddressInfoComponent = ({ allValues, mainFormik }) => {
  const [isSameAddress, setSameAddress] = React.useState(false);
  const [stateIndex1, setStateIndex1] = React.useState(0);
  const [stateIndex2, setStateIndex2] = React.useState(0);

  const CheckBoxhandleChange = (event, values) => {
    if (event.target.checked) {
      mainFormik.values.permanentAddress = mainFormik.values.currentAddress;
    } else {
      mainFormik.values.permanentAddress = {
        address: "",
        dist: "",
        pinCode: "",
        state: "",
      };
    }
    setSameAddress(event.target.checked);
  };
  useEffect(() => {
    if (mainFormik.values.currentAddress.state) {
      States.forEach((item, index) => {
        if (item === mainFormik.values.currentAddress.state) {
          setStateIndex1(index);
        }
      });
    }
    if (mainFormik.values.permanentAddress.state) {
      States.forEach((item, index) => {
        if (item === mainFormik.values.currentAddress.state) {
          setStateIndex2(index);
        }
      });
    }
  }, []);

  return (
    <form>
      <Typography variant="h5" fontWeight={600}>
        Current Address
      </Typography>
      <div className="separate">
        <CustomTextField
          size="small"
          required
          label="Address"
          id="currentAddress.address"
          value={mainFormik.values.currentAddress.address}
          name="currentAddress.address"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          required
          label="Pincode"
          id="currentAddress.pinCode"
          inputProps={{ maxLength: 6 }}
          value={mainFormik.values.currentAddress.pinCode}
          name="currentAddress.pinCode"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          select
          size="small"
          id="currentAddress.state"
          required
          value={mainFormik.values.currentAddress.state}
          name="currentAddress.state"
          onChange={mainFormik.handleChange}
          label="State"
          sx={{ margin: "10px", width: "200px", color: "black" }}
          MenuProps={MenuProps}
        >
          {States.map((state, index) => (
            <MenuItem
              key={state}
              value={state}
              onClick={() => {
                setStateIndex1(index);
              }}
            >
              {state}
            </MenuItem>
          ))}
        </CustomTextField>
        <CustomTextField
          select
          size="small"
          id="currentAddress.dist"
          required
          value={mainFormik.values.currentAddress.dist}
          name="currentAddress.dist"
          onChange={mainFormik.handleChange}
          label="District"
          sx={{ margin: "10px", width: "200px", color: "black" }}
          MenuProps={MenuProps}
        >
          {Distrct[stateIndex1 + 1].map((district) => (
            <MenuItem key={district} value={district}>
              {district}
            </MenuItem>
          ))}
        </CustomTextField>
      </div>

      <FormControlLabel
        control={
          <Checkbox
            checked={isSameAddress}
            onChange={(e) => CheckBoxhandleChange(e)}
          />
        }
        label="Check the Box, If Current Address is Same as Permanent Address"
      />
      <div>
        <Typography variant="h5" fontWeight={600}>
          Permanent Address
        </Typography>

        <CustomTextField
          size="small"
          label="Address"
          id="permanentAddress.address"
          value={mainFormik.values.permanentAddress.address}
          name="permanentAddress.address"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          size="small"
          label="Pincode"
          id="permanentAddress.pinCode"
          inputProps={{ maxLength: 6 }}
          value={mainFormik.values.permanentAddress.pinCode}
          name="permanentAddress.pinCode"
          sx={{ width: "200px", margin: "10px" }}
          onChange={mainFormik.handleChange}
        />
        <CustomTextField
          select
          size="small"
          id="permanentAddress.state"
          required
          value={mainFormik.values.permanentAddress.state}
          name="permanentAddress.state"
          onChange={mainFormik.handleChange}
          label="State"
          sx={{ margin: "10px", width: "200px", color: "black" }}
          MenuProps={MenuProps}
        >
          {States.map((state, index) => (
            <MenuItem
              key={state}
              value={state}
              onClick={() => {
                setStateIndex2(index);
              }}
            >
              {state}
            </MenuItem>
          ))}
        </CustomTextField>
        <CustomTextField
          select
          size="small"
          id="permanentAddress.dist"
          required
          value={mainFormik.values.permanentAddress.dist}
          name="permanentAddress.dist"
          onChange={mainFormik.handleChange}
          label="District"
          sx={{ margin: "10px", width: "200px", color: "black" }}
          MenuProps={MenuProps}
        >
          {Distrct[stateIndex2 + 1].map((district) => (
            <MenuItem key={district} value={district}>
              {district}
            </MenuItem>
          ))}
        </CustomTextField>
      </div>
    </form>
  );
};

// Admission Info
export const AdmissionDetailComponent = ({
  classesResponse,
  mainFormik,
  state,
  setState,
  studentData,
}) => {
  const [sectionList, setSectionList] = useState([]);
  const sessionlist = useSelector(selectSession);
  const classes = classesResponse?.data?.list;
  useEffect(() => {
    if (mainFormik?.values?.classDocId) {
      const sections = classes?.find(
        (item) => item?.classDocId === mainFormik?.values?.classDocId
      );
      setSectionList(sections?.sections);
    }
  }, [mainFormik?.values?.classDocId]);

  return (
    <form onSubmit={mainFormik.validateOnBlur}>
      <Grid container spacing={1.5} sx={{ mt: 0.3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextFieldDisabled
            select
            native
            displayEmpty
            fullWidth
            size="small"
            name="studentAdmissionType"
            value={mainFormik.values.studentAdmissionType}
            onChange={mainFormik.handleChange}
            label="Student Addmission Type"
          >
            <MenuItem value="newStudent" component="option">
              New Student
            </MenuItem>
            <MenuItem value="oldStudent" component="option">
              Old Student
            </MenuItem>
          </CustomTextFieldDisabled>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            label="Fee Relaxation Category"
            // sx={{ backgroundColor: "rgba(208, 208, 226, 0.2)" }}
            contentEditable={false}
            fullWidth
            size="small"
            value={state.feeRelaxationCategory}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <PencilSquare
                    style={{ color: "#000", cursor: "pointer" }}
                    onClick={() =>
                      setState((prev) => ({
                        ...prev,
                        feeRelaxationCategoryDialog: true,
                      }))
                    }
                  />
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            select
            fullWidth
            size="small"
            id="selectedNewSession"
            value={mainFormik.values.selectedNewSession}
            name="selectedNewSession"
            onChange={mainFormik.handleChange}
            label="New Session"
            MenuProps={MenuProps}
            error={mainFormik.errors.selectedNewSession}
          >
            <MenuItem value="">Select</MenuItem>
            {sessionlist?.map((item) => (
              <MenuItem value={item}>{item}</MenuItem>
            ))}
          </CustomTextField>
          {mainFormik.touched.selectedNewSession &&
          mainFormik.errors.selectedNewSession ? (
            <p
              style={{
                color: "red",
              }}
            >
              {mainFormik.errors.selectedNewSession}
            </p>
          ) : null}
        </Grid>
      </Grid>
      <Grid container spacing={1.5} sx={{ mt: 0.3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <DesktopDatePicker
            label="Admission Date"
            inputFormat="DD-MM-YYYY"
            onChange={(e) =>
              mainFormik.setValues((prev) => ({
                ...prev,
                admissionDate: e,
              }))
            }
            value={mainFormik.values.admissionDate}
            renderInput={(params) => (
              <CustomTextField {...params} size="small" fullWidth />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextFieldDisabled
            fullWidth
            size="small"
            label="Admission Number"
            value={studentData?.admissionNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextFieldDisabled
            fullWidth
            size="small"
            label="Registration Number"
            value={studentData?.registrationNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextFieldDisabled
            fullWidth
            size="small"
            label="SR Number"
            value={studentData?.srNumber}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            select
            fullWidth
            size="small"
            id="admissionType"
            value={mainFormik.values.admissionType}
            name="admissionType"
            onChange={mainFormik.handleChange}
            label="Admission Type"
            MenuProps={MenuProps}
            error={mainFormik.errors.admissionType}
          >
            <MenuItem value={"Regular"}>Regular</MenuItem>
            <MenuItem value={"Private"}>Private</MenuItem>
          </CustomTextField>
          {mainFormik.touched.admissionType &&
          mainFormik.errors.admissionType ? (
            <p
              style={{
                color: "red",
              }}
            >
              {mainFormik.errors.admissionType}
            </p>
          ) : null}
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            select
            fullWidth
            size="small"
            id="classDocId"
            value={mainFormik?.values?.classDocId}
            name="classDocId"
            onChange={(e) =>
              mainFormik.setValues((prev) => ({
                ...prev,
                classDocId: e.target.value,
                section: "",
              }))
            }
            label="Class-Stream"
            SelectProps={{
              MenuProps,
            }}
            error={
              mainFormik.touched.classDocId && mainFormik.errors.classDocId
            }
          >
            {classes?.map((res) => (
              <MenuItem key={res.classDocId} value={res.classDocId}>
                {res.className_stream}
              </MenuItem>
            ))}
          </CustomTextField>
          {mainFormik.touched.classDocId && mainFormik.errors.classDocId ? (
            <Typography color="error">
              {mainFormik.errors.classDocId}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            select
            fullWidth
            size="small"
            id="section"
            value={mainFormik.values.section}
            name="section"
            onChange={mainFormik.handleChange}
            label="Section"
            SelectProps={{ MenuProps }}
            error={mainFormik.touched.section && mainFormik.errors.section}
          >
            {sectionList?.map((res, index) => (
              <MenuItem key={index} value={res}>
                {res}
              </MenuItem>
            ))}
          </CustomTextField>
          {mainFormik.touched.section && mainFormik.errors.section ? (
            <Typography
              style={{
                color: "red",
              }}
            >
              {mainFormik.errors.section}
            </Typography>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            fullWidth
            size="small"
            label="Roll Number"
            id="rollNumber"
            value={mainFormik.values.rollNumber}
            name="rollNumber"
            onChange={mainFormik.handleChange}
            error={
              mainFormik.touched.rollNumber && mainFormik.errors.rollNumber
            }
          />
          {mainFormik.touched.rollNumber && mainFormik.errors.rollNumber ? (
            <Typography
              style={{
                color: "red",
              }}
            >
              {mainFormik.errors.rollNumber}
            </Typography>
          ) : null}
        </Grid>
      </Grid>
    </form>
  );
};

// Transport info
export const TransportationComponent = ({
  setSelectedStop,
  mainFormik,
  newSession,
}) => {
  const { data: routeResponse } = useQuery("getAllRouteList", getAllRouteList);
  const [state, setState] = useState({
    isSearchByRouteName: true,
    stopName: [],
    assignedVehicle: [],
  });
  const sessionMonth = useSelector(sessionMonths);
  const [selectedRoute, setSelectedRoute] = React.useState(null);
  const {
    isLoading: getTransportFeeByStopNameLoading,
    isFetching: getTransportFeeByStopNameFetching,
    data: getTransportFeeByStopNameData,
  } = useQuery({
    queryKey: [
      "getTransportFeeByStopName",
      mainFormik.values.transportInfo.to,
      newSession,
    ],
    queryFn: () =>
      getTransportFeeByStopName(mainFormik.values.transportInfo.to, newSession),
    onError: (error) => {
      toast.error(error?.response?.data?.message);
    },
    enabled: mainFormik.values.transportInfo.to ? true : false,
  });
  const calculateFeeAmount = () => {
    if (
      mainFormik.values.transportInfo?.using === "Yes" &&
      mainFormik.values.transportInfo.transportFeeDocId
    ) {
      const filteredDta = getTransportFeeByStopNameData?.data?.list?.find(
        (res) => res?._id === mainFormik.values.transportInfo.transportFeeDocId
      );
      const stDateMonth = dayjs(
        mainFormik.values.transportInfo?.startDate
      ).format("MMMM");
      ["or"].indexOf("or");
      const mont = 12 - sessionMonth?.indexOf(stDateMonth);

      const oneMonthAmount =
        Number(filteredDta?.feeAmount ?? 0) -
        Number(mainFormik.values.transportInfo?.recurringDiscountAmount ?? 0);
      return {
        fee: filteredDta?.feeAmount,
        recurring: mainFormik.values.transportInfo?.recurringDiscountAmount,
        finalAmount: oneMonthAmount * mont,
        month: mont,
      };
    }
  };
  useEffect(() => {
    if (mainFormik.values.transportInfo.using === "No") {
      mainFormik.setValues((prev) => ({
        ...prev,
        transportInfo: {
          using: "No",
          routeNumber: "",
          to: "",
          vehicleNumber: "",
          transportFeeDocId: "",
          feeAmount: null,
          remarks: "",
          routeName: "",
          feeCode: "",
          recurringDiscountAmount: "",
        },
      }));
    }
  }, [mainFormik.values.transportInfo.using]);

  useEffect(() => {
    if (mainFormik.values.transportInfo.to) {
      setSelectedStop(mainFormik.values.transportInfo.to);
    }
  }, []);
  useEffect(() => {
    if (
      mainFormik.values.transportInfo?.routeNumber &&
      routeResponse?.data?.list &&
      routeResponse?.data?.list?.length > 0
    ) {
      const filtered = routeResponse?.data?.list?.find(
        (item) =>
          Number(item?.routeNumber) ===
          Number(mainFormik.values.transportInfo?.routeNumber)
      );
      if (filtered) {
        setSelectedRoute(filtered);
      } else {
        mainFormik.values.routeNo = "";
        mainFormik.values.to = "";
        mainFormik.values.transportVehicle = "";
        mainFormik.values.remarks = "";
      }
    }
  }, [routeResponse]);
  useEffect(() => {
    if (!state.isSearchByRouteName) {
      const newArray = routeResponse?.data?.list
        ?.map((route) => {
          return route?.stopList.map((stop) => {
            return {
              assignedVehicle: route?.assignedVehicle,
              routeNumber: route?.routeNumber,
              distance: stop?.distance,
              duration: stop?.duration,
              stopName: stop?.stopName,
            };
          });
        })
        .flat();
      setState((prev) => ({ ...prev, stopName: newArray }));
    }
  }, [state.isSearchByRouteName, routeResponse]);
  useEffect(() => {
    if (!state.isSearchByRouteName) {
      const findedData = state.stopName?.find(
        (item) => item?.stopName === mainFormik.values.transportInfo.to
      );
      setState((prev) => ({
        ...prev,
        assignedVehicle: findedData?.assignedVehicle,
      }));
      mainFormik.setValues((prev) => ({
        ...prev,
        transportInfo: {
          ...prev.transportInfo,
          routeNumber: findedData?.routeNumber,
          vehicleNumber: "",
        },
      }));
    }
  }, [mainFormik.values.transportInfo.to, state.isSearchByRouteName]);

  return (
    <>
      <CustomLoader
        show={
          getTransportFeeByStopNameLoading || getTransportFeeByStopNameFetching
        }
      />
      <Wrapper>
        <Stack direction={"row"} gap={5} alignItems={"center"}>
          <Typography variant="h6">Search Fee By:</Typography>
          <Stack direction={"row"} gap={2} alignItems={"center"}>
            <FormControlLabel
              checked={state.isSearchByRouteName}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  isSearchByRouteName: true,
                }))
              }
              control={<Radio color="secondary" />}
              label="Route Name"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                },
              }}
            />
            <FormControlLabel
              checked={!state.isSearchByRouteName}
              onChange={(e) =>
                setState((prev) => ({
                  ...prev,
                  isSearchByRouteName: false,
                }))
              }
              control={<Radio color="secondary" />}
              label="Stop Name"
              componentsProps={{
                typography: {
                  fontSize: "14px",
                },
              }}
            />
          </Stack>
        </Stack>
        <form>
          <Grid container spacing={1} sx={{ mt: 0.3 }}>
            <Grid item xs={12} sm={6} md={3}>
              <CustomTextField
                select
                fullWidth
                size="small"
                id="transportInfo.using"
                value={mainFormik.values.transportInfo.using}
                name="transportInfo.using"
                onChange={mainFormik.handleChange}
                label="Using"
                MenuProps={MenuProps}

                // error={mainFormik.errors.transportInfo.using}
              >
                <MenuItem value={"Yes"}>Yes</MenuItem>
                <MenuItem value={"No"}>No</MenuItem>
              </CustomTextField>
              {/* {mainFormik.touched.transportInfo.using &&
        mainFormik.errors.transportInfo.using ? (
          <p>{mainFormik.errors.transportInfo.using}</p>
        ) : null} */}
            </Grid>
            {mainFormik.values.transportInfo.using === "Yes" && (
              <>
                {state.isSearchByRouteName ? (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomTextField
                        size="small"
                        select
                        id="transportInfo.routeNumber"
                        value={mainFormik.values.transportInfo?.routeNumber}
                        name="transportInfo.routeNumber"
                        label="Route No. (Route Name)"
                        fullWidth
                        SelectProps={{
                          MenuProps,
                        }}
                        error={
                          mainFormik.touched.transportInfo?.routeNumber &&
                          mainFormik.errors.transportInfo?.routeNumber
                        }
                      >
                        {routeResponse?.data?.list?.map((route) => (
                          <MenuItem
                            key={route?._id}
                            value={route?.routeNumber}
                            onClick={() => {
                              mainFormik.setValues((prev) => ({
                                ...prev,
                                transportInfo: {
                                  ...prev.transportInfo,
                                  routeNumber: route?.routeNumber,
                                  routeName: route?.routeName,
                                  vehicle: "",
                                  to: "",
                                },
                              }));
                              setSelectedRoute(route);
                            }}
                          >
                            {route?.routeNumber} ({route?.routeName})
                          </MenuItem>
                        ))}
                      </CustomTextField>
                      {mainFormik.touched.transportInfo?.routeNumber &&
                      mainFormik.errors.transportInfo?.routeNumber ? (
                        <Typography color="error">
                          {mainFormik.errors.transportInfo?.routeNumber}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomTextField
                        select
                        size="small"
                        id="transportInfo.to"
                        value={mainFormik.values.transportInfo.to}
                        name="transportInfo.to"
                        onChange={mainFormik.handleChange}
                        label="Pickup Point"
                        fullWidth
                        SelectProps={{
                          MenuProps,
                        }}
                        error={
                          mainFormik.touched.transportInfo?.to &&
                          mainFormik.errors.transportInfo?.to
                        }
                      >
                        <MenuItem disabled value={" "}>
                          Stop Name (Pickup Point)
                        </MenuItem>
                        {selectedRoute ? null : (
                          <MenuItem value={"No result"}>No results</MenuItem>
                        )}
                        {selectedRoute?.stopList?.map((stop) => (
                          <MenuItem
                            value={stop?.stopName}
                            key={stop?._id}
                            onClick={() => {
                              setSelectedStop(stop?.stopName);
                              mainFormik.setValues((prev) => ({
                                ...prev,
                                transportInfo: {
                                  ...prev.transportInfo,
                                  to: "",
                                  vehicleNumber: "",
                                },
                              }));
                            }}
                          >
                            {stop?.stopName}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                      {mainFormik.touched.transportInfo?.to &&
                      mainFormik.errors.transportInfo?.to ? (
                        <Typography color="error">
                          {mainFormik.errors.transportInfo?.to}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomTextField
                        size="small"
                        select
                        id="transportInfo.vehicleNumber"
                        value={mainFormik.values.transportInfo?.vehicleNumber}
                        name="transportInfo.vehicleNumber"
                        onChange={mainFormik.handleChange}
                        label="Transport Vehicle"
                        fullWidth
                        SelectProps={{
                          MenuProps,
                        }}
                        error={
                          mainFormik.touched.transportInfo?.vehicleNumber &&
                          mainFormik.errors.transportInfo?.vehicleNumber
                        }
                      >
                        {selectedRoute ? null : (
                          <MenuItem value={"No result"}>No results</MenuItem>
                        )}
                        {selectedRoute?.assignedVehicle?.map((vehicle) => (
                          <MenuItem
                            key={vehicle?.vehicleNumber}
                            value={vehicle?.vehicleNumber}
                          >
                            {vehicle?.vehicleNumber}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                      {mainFormik.touched.transportInfo?.vehicleNumber &&
                      mainFormik.errors.transportInfo?.vehicleNumber ? (
                        <Typography color="error">
                          {mainFormik.errors.transportInfo?.vehicleNumber}
                        </Typography>
                      ) : null}
                    </Grid>
                  </>
                ) : (
                  <>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomTextField
                        select
                        size="small"
                        id="transportInfo.to"
                        value={mainFormik.values.transportInfo.to}
                        name="transportInfo.to"
                        onChange={mainFormik.handleChange}
                        label="Pickup Point"
                        fullWidth
                        SelectProps={{
                          MenuProps,
                        }}
                        error={
                          mainFormik.touched.transportInfo?.to &&
                          mainFormik.errors.transportInfo?.to
                        }
                      >
                        <MenuItem disabled value={" "}>
                          Stop Name (Pickup Point)
                        </MenuItem>

                        {state.stopName?.map((stop, i) => (
                          <MenuItem
                            value={stop?.stopName}
                            key={i}
                            onClick={() => {
                              setSelectedStop(stop?.stopName);
                            }}
                          >
                            {stop?.stopName}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                      {mainFormik.touched.transportInfo?.to &&
                      mainFormik.errors.transportInfo?.to ? (
                        <Typography color="error">
                          {mainFormik.errors.transportInfo?.to}
                        </Typography>
                      ) : null}
                    </Grid>
                    <Grid item xs={12} sm={6} md={3}>
                      <CustomTextField
                        size="small"
                        select
                        id="transportInfo.vehicleNumber"
                        value={mainFormik.values.transportInfo?.vehicleNumber}
                        name="transportInfo.vehicleNumber"
                        onChange={mainFormik.handleChange}
                        label="Transport Vehicle"
                        fullWidth
                        SelectProps={{
                          MenuProps,
                        }}
                        error={
                          mainFormik.touched.transportInfo?.vehicleNumber &&
                          mainFormik.errors.transportInfo?.vehicleNumber
                        }
                      >
                        {state.assignedVehicle?.map((vehicle) => (
                          <MenuItem
                            key={vehicle?.vehicleNumber}
                            value={vehicle?.vehicleNumber}
                          >
                            {vehicle?.vehicleNumber}
                          </MenuItem>
                        ))}
                      </CustomTextField>
                      {mainFormik.touched.transportInfo?.vehicleNumber &&
                      mainFormik.errors.transportInfo?.vehicleNumber ? (
                        <Typography color="error">
                          {mainFormik.errors.transportInfo?.vehicleNumber}
                        </Typography>
                      ) : null}
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6} md={6}>
                  <CustomTextField
                    select
                    size="small"
                    value={mainFormik.values.transportInfo?.transportFeeDocId}
                    label="Fee Structure"
                    fullWidth
                    SelectProps={{
                      MenuProps,
                    }}
                    error={
                      mainFormik.touched.transportInfo?.transportFeeDocId &&
                      mainFormik.errors.transportInfo?.transportFeeDocId
                    }
                  >
                    {getTransportFeeByStopNameData?.data?.list?.map((item) => (
                      <MenuItem
                        value={item?._id}
                        key={item?._id}
                        onClick={() => {
                          mainFormik.setValues((prev) => ({
                            ...prev,
                            transportInfo: {
                              ...prev.transportInfo,
                              transportFeeDocId: item._id,
                              feeAmount: item.feeAmount,
                              feeCode: item?.feeCode,
                            },
                          }));
                        }}
                      >
                        {item?.feeCode}- &#8377;{item?.feeAmount}
                      </MenuItem>
                    ))}
                  </CustomTextField>
                  {mainFormik.touched.transportInfo?.transportFeeDocId &&
                  mainFormik.errors.transportInfo?.transportFeeDocId ? (
                    <Typography color="error">
                      {mainFormik.errors.transportInfo?.transportFeeDocId}
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <div className="route-details">
                    <span> Route Details :- </span>
                    {selectedRoute?.stopList?.map((stop) => (
                      <>
                        <span key={stop._id}>
                          {stop.stopName} ({stop.distance} -{" "}
                          {Math.floor(stop.duration / 60) != 0
                            ? Math.floor(stop.duration / 60) + "Hrs"
                            : ""}{" "}
                          {stop.duration % 60 != 0
                            ? (stop.duration % 60) + "mins"
                            : ""}
                          )
                        </span>
                        <span key={stop._id}>
                          <ArrowRight color="royalblue" size={20} />
                        </span>
                      </>
                    ))}
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <CustomTextField
                    size="small"
                    label="Remarks (If Any)"
                    id="transportInfo.remarks"
                    value={mainFormik.values.transportInfo.remarks}
                    name="transportInfo.remarks"
                    fullWidth
                    onChange={mainFormik.handleChange}
                  />
                </Grid>
              </>
            )}
          </Grid>
        </form>
        {/* {mainFormik.values.transportInfo.transportFeeDocId && (
          <>
            <Typography sx={{ fontSize: "18px", fontWeight: 600, mt: 2 }}>
              Applied Fee Amount
            </Typography>
            <Divider sx={{ my: 1, background: "#999" }} />
            <FeeWrapper container spacing={2}>
           
              <Grid item xs={4}>
                <Typography className="title"> Fee Amount</Typography>
                <Typography>
                  <span className="amount">{calculateFeeAmount()?.fee}</span> x{" "}
                  <span className="month">{calculateFeeAmount()?.month}</span> ={" "}
                  <span className="totalAmount">
                    {IndianCurrency(
                      calculateFeeAmount()?.fee * calculateFeeAmount()?.month
                    )}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="title">Recurring Discount</Typography>
                <Typography>
                  <span className="amount">
                    {calculateFeeAmount()?.recurring}
                  </span>{" "}
                  x <span className="month">{calculateFeeAmount()?.month}</span>{" "}
                  ={" "}
                  <span className="totalAmount">
                    {IndianCurrency(
                      calculateFeeAmount()?.recurring *
                        calculateFeeAmount()?.month
                    )}
                  </span>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className="title">Final Amount</Typography>
                <Typography>
                  <span className="amount">
                    {calculateFeeAmount()?.fee -
                      calculateFeeAmount()?.recurring}
                  </span>{" "}
                  x <span className="month">{calculateFeeAmount()?.month}</span>{" "}
                  ={" "}
                  <span className="totalAmount">
                    {IndianCurrency(
                      (calculateFeeAmount()?.fee -
                        calculateFeeAmount()?.recurring) *
                        calculateFeeAmount()?.month
                    )}
                  </span>
                </Typography>
              </Grid>
            </FeeWrapper>
          </>
        )} */}
      </Wrapper>
    </>
  );
};

// Hostel Info
export const HostelComponent = ({ allValues, mainFormik }) => {
  return (
    <form>
      <Grid container spacing={1.5} sx={{ mt: 0.3 }}>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            size="small"
            label="Hostel Name"
            id="hostelInfo.hostelName"
            value={mainFormik.values.hostelInfo.hostelName}
            name="hostelInfo.hostelName"
            fullWidth
            onChange={mainFormik.handleChange}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            size="small"
            label="Room Type"
            id="hostelInfo.roomType"
            value={mainFormik.values.hostelInfo.roomType}
            name="hostelInfo.roomType"
            fullWidth
            onChange={mainFormik.handleChange}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <CustomTextField
            size="small"
            label="Room Number"
            id="hostelInfo.roomNumber"
            value={mainFormik.values.hostelInfo.roomNumber}
            name="hostelInfo.roomNumber"
            fullWidth
            onChange={mainFormik.handleChange}
          />
        </Grid>
      </Grid>
    </form>
  );
};

export const HouseComponent = ({ mainFormik }) => {
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={4}>
        <CustomTextField
          select
          size="small"
          label="House Color"
          id="house.houseColor"
          value={mainFormik.values?.house?.houseColor}
          name="house.houseColor"
          fullWidth
          onChange={mainFormik.handleChange}
        >
          {HouseColor?.map((color) => (
            <MenuItem value={color} key={color}>
              {color}
            </MenuItem>
          ))}
        </CustomTextField>
      </Grid>
      <Grid item xs={12} md={4}>
        <CustomTextField
          fullWidth
          size="small"
          label="House Name"
          id="house.houseName"
          value={mainFormik.values?.house?.houseName}
          name="house.houseName"
          onChange={mainFormik.handleChange}
        />
      </Grid>

      <Grid item xs={12} md={4}>
        <CustomTextField
          size="small"
          select
          id="house.membershipRole"
          value={mainFormik.values?.house?.membershipRole}
          name="house.membershipRole"
          onChange={mainFormik.handleChange}
          label="Membership Role"
          fullWidth
        >
          {HOUSE_MEMBERSHIP?.map((member) => (
            <MenuItem value={member} key={member}>
              {member}
            </MenuItem>
          ))}
        </CustomTextField>
      </Grid>
    </Grid>
  );
};

export const FeeStructComponent = ({
  transportFee,
  allValues,
  selectedSession,
  selectedMedium,
  mainFormik,
}) => {
  const [selectedFee, setSelectedFee] = useState({
    selectedMonthlyFee: {},
    selectedHostelFee: {},
    selectedAdmissionFee: {},
    selectedTransportFee: {},
  });
  const newSession = getNewSessionValues(selectedSession);

  const { data: allFeeStruct, refetch: fetchFeeStructureData } = useQuery({
    queryKey: [
      "getAllFeeStructure",
      selectedMedium,
      newSession,
      mainFormik.values.section,
    ],
    queryFn: () =>
      getAllFeeStructure(
        selectedMedium,
        newSession,
        mainFormik.values.classDocId,
        mainFormik.values.section
      ),
    enabled: false,
  });

  useEffect(() => {
    if (allValues?.classDocId && allValues?.section) fetchFeeStructureData();
  }, [allValues?.section, selectedSession]);

  const monthlyFee = [];
  const hostelFee = [];
  const admissionFee = [];

  allFeeStruct?.data?.list?.forEach((fee) => {
    if (fee.feeType == "Monthly Fee") {
      monthlyFee.push(fee);
    }
    if (fee.feeType == "Hostel Fee") {
      hostelFee.push(fee);
    }
    if (fee.feeType == "Admission Fee") {
      admissionFee.push(fee);
    }
  });

  return (
    <form onSubmit={mainFormik.validateOnBlur}>
      <Grid container spacing={1.5} sx={{ mt: 0.3 }}>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            select
            size="small"
            value={mainFormik.values.admissionFeeCode}
            name="admissionFeeCode"
            onChange={mainFormik.handleChange}
            label="admissionFeeStruct"
            fullWidth
            MenuProps={MenuProps}
            input={<OutlinedInput label="Name" />}
            inputProps={{ "aria-label": "Without label" }}
            error={mainFormik.errors.admissionFeeCode}
          >
            {admissionFee?.map((fee) => (
              <MenuItem
                key={fee._id}
                onClick={() => {
                  selectedFee.selectedAdmissionFee = fee;
                  mainFormik.values.admissionFeeRelaxationCategory = `Rs.${
                    fee?.feeAmount
                  } ${
                    fee?.feeRelaxationCategory && fee?.feeRelaxationCategory
                  }`;
                }}
                value={fee.feeCode}
              >
                {fee?.feeCode} - Rs.{fee?.feeAmount}{" "}
                {fee?.feeRelaxationCategory}
              </MenuItem>
            ))}
          </CustomTextField>
          {mainFormik.touched.admissionFeeCode &&
          mainFormik.errors.admissionFeeCode ? (
            <p
              style={{
                color: "red",
              }}
            >
              {mainFormik.errors.admissionFeeCode}
            </p>
          ) : null}
        </Grid>
        <Grid item xs={12} sm={6}>
          <CustomTextField
            select
            size="small"
            labelId="demo-controlled-open-select-labe"
            id="monthlyFeeCode"
            value={mainFormik.values.monthlyFeeCode}
            name="monthlyFeeCode"
            onChange={mainFormik.handleChange}
            label="monthlyFeeStruct"
            fullWidth
            MenuProps={MenuProps}
            error={mainFormik.errors.monthlyFeeCode}
          >
            {monthlyFee?.map((fee) => (
              <MenuItem
                key={fee._id}
                onClick={() => {
                  selectedFee.selectedMonthlyFee = fee;
                  mainFormik.values.monthlyFeeRelaxationCategory = `Rs.${
                    fee.feeAmount
                  } ${
                    fee?.feeRelaxationCategory && fee?.feeRelaxationCategory
                  }`;
                }}
                value={fee.feeCode}
              >
                {fee.feeCode} - Rs.{fee.feeAmount} {fee?.feeRelaxationCategory}
              </MenuItem>
            ))}
          </CustomTextField>
          {mainFormik.touched.monthlyFeeCode &&
          mainFormik.errors.monthlyFeeCode ? (
            <p
              style={{
                color: "red",
              }}
            >
              {mainFormik.errors.monthlyFeeCode}
            </p>
          ) : null}
        </Grid>
        {mainFormik.values?.hostelInfo?.hostelName && (
          <Grid item xs={12} sm={6}>
            <CustomTextField
              size="small"
              select
              id="hostelFeeCode"
              value={mainFormik.values.hostelFeeCode}
              name="hostelFeeCode"
              onChange={mainFormik.handleChange}
              label="Hostel Fee Structure"
              fullWidth
              MenuProps={MenuProps}
              input={<OutlinedInput label="Name" />}
              inputProps={{ "aria-label": "Without label" }}
            >
              {hostelFee?.map((fee) => (
                <MenuItem
                  key={fee._id}
                  onClick={() => {
                    selectedFee.selectedHostelFee = fee;
                    mainFormik.values.hostelFeeRelaxationCategory = ` Rs.${
                      fee.feeAmount
                    } ${
                      fee?.feeRelaxationCategory && fee?.feeRelaxationCategory
                    }`;
                  }}
                  value={fee.feeCode}
                >
                  {fee.feeCode} - Rs.{fee.feeAmount}{" "}
                  {fee?.feeRelaxationCategory}
                </MenuItem>
              ))}
            </CustomTextField>
          </Grid>
        )}

        {/*  Show Transport dropdown */}
        {mainFormik.values.transportInfo.using === "Yes" ? (
          <Grid item xs={12} sm={6}>
            <CustomTextField
              select
              size="small"
              id="transportationFeeCode"
              value={mainFormik.values.transportationFeeCode}
              name="transportationFeeCode"
              onChange={mainFormik.handleChange}
              label="transportFeeStruct"
              fullWidth
              MenuProps={MenuProps}
              error={mainFormik.errors.transportationFeeCode}
            >
              {transportFee?.data?.list?.map((fee) => (
                <MenuItem
                  key={fee._id}
                  onClick={() => {
                    mainFormik.values.transportInfo.feeAmount = fee.feeAmount
                      ? fee.feeAmount
                      : 0;
                    mainFormik.values.transportationFeeRelaxationCategory = `Rs.${
                      fee.feeAmount
                    } ${
                      fee?.feeRelaxationCategory && fee?.feeRelaxationCategory
                    }`;
                  }}
                  value={fee.feeCode}
                >
                  {fee.feeCode} - Rs.{fee.feeAmount}{" "}
                  {fee?.feeRelaxationCategory}
                </MenuItem>
              ))}
            </CustomTextField>
            {mainFormik.touched.transportationFeeCode &&
            mainFormik.errors.transportationFeeCode ? (
              <p
                style={{
                  color: "red",
                }}
              >
                {mainFormik.errors.transportationFeeCode}
              </p>
            ) : null}
          </Grid>
        ) : null}
      </Grid>
    </form>
  );
};

const Wrapper = styled("div")`
  position: relative;
  border-radius: round;
  overflow: hidden !important;
  margin: 2px;
  flex-wrap: wrap;
  .separate {
    margin: 20px 0 10px 0;
  }
  .route-details {
    margin: 7px 7px 5px 12px;
    font-size: 15px;
  }
  .route-details span {
    margin: 3px;
  }
`;
const FeeWrapper = styled(Grid)`
  .title {
    font-size: 18px;
    font-weight: bold;
    opacity: 0.7;
  }
  .amount {
    font-size: 14px;
    font-weight: 500;
  }
  .month {
    font-size: 10px;
  }
  .totalAmount {
    font-size: 14px;
    font-weight: 700;
  }
`;
