import { selectSelectedSchool } from "app/modules/schools/selectors";
import React from "react";
import Image from "react-bootstrap/Image";
import { useSelector } from "react-redux";
import { ReactComponent as AppLogo } from "assets/media/images/logo.svg";

import { Box, Stack, Typography, styled } from "@mui/material";
import { THEME } from "app/config/constants";

const InstituteBrand = ({ onClick }) => {
  const selectedSchool = useSelector(selectSelectedSchool);
  return selectedSchool ? (
    <StyledWrapper direction="row" alignItems="center">
      <div style={{ width: "201px", display: "flex", alignItems: "center" }}>
        <div
          onClick={onClick}
          style={{
            cursor: "pointer",
            height: 20,
            width: 20,
            marginTop: "-13px",
            paddingLeft: "17px",
          }}
        >
          <div className="hamburger">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
      </div>
      <Stack
        direction="row"
        alignItems="center"
        sx={{ width: "calc(100% - 201px)" }}
      >
        <Image
          src={selectedSchool?.schoolLogo}
          roundedCircle={true}
          thumbnail={true}
        />
        <Box sx={{ ml: 1, width: "100%" }}>
          <div title={selectedSchool?.schoolName}>
            <Typography className="school_name_text">
              {selectedSchool?.schoolName}
            </Typography>
          </div>
          <div
            title={`${selectedSchool?.schoolAddress.address}, ${selectedSchool?.schoolAddress.dist}, ${selectedSchool?.schoolAddress?.state} ${selectedSchool?.schoolAddress?.pinCode}`}
          >
            <Typography className="address_text">{`${selectedSchool?.schoolAddress.address}, ${selectedSchool?.schoolAddress.dist}, ${selectedSchool?.schoolAddress?.state} ${selectedSchool?.schoolAddress?.pinCode}`}</Typography>
          </div>
        </Box>
      </Stack>
    </StyledWrapper>
  ) : (
    <AppLogo />
  );
};

export default InstituteBrand;

const StyledWrapper = styled(Stack)`
  width: 100%;
  .hamburger span {
    display: block;
    margin: 5px 0px;
    background-color: #084c84;
    border-radius: 15px;
    height: 5px;
    width: 30px;
  }
  img {
    width: 5rem;
    height: 5rem;
  }
  .school_name_text {
    color: ${THEME.primary};
    font-size: 20px;
    font-weight: 600;
    width: calc(100% - 6vw);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .address_text {
    color: ${THEME.primary};
    font-size: 14px;
    font-weight: 500;
    width: calc(100% - 6vw);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
`;
