import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";
import { Box, Grid, Stack } from "@mui/material";
import { IndianCurrency } from "app/utils/helper";
import { FeeStructureTableWrapper } from "../CreateStudent/style";

const FeeStructureList = ({
  feeData,
  showTransportFee = false,
  transportData,
  transportFeeData,
}) => {
  const [state, setState] = useState({
    totalFee: 0,
    totalDiscount: 0,
    totalFinalFee: 0,
  });
  const [transportFee, setTransportFee] = useState(0);
  useEffect(() => {
    const intervalId = setInterval(() => {
      // let Fee = transportFeeData?.find(
      //   (item) => item?._id === transportData?.transportFeeDocId
      // );
      setTransportFee(transportData?.feeAmount);
    }, 2000);
    return () => clearInterval(intervalId);
  }, [transportData, transportFeeData]);
  useEffect(() => {
    if (feeData?.list?.length > 0) {
      let totalFee = 0;
      let totalDiscount = 0;
      let totalFinalFee = 0;
      feeData?.list?.forEach((item) => {
        totalFee =
          totalFee +
          Number(item?.generalFeeAmount ?? 0) * Number(item?.count ?? 0);
        totalDiscount =
          totalDiscount +
          Number(item?.categoryDiscountAmount ?? 0) * Number(item?.count ?? 0);
        totalFinalFee =
          totalFinalFee +
          Number(item?.feeAmount ?? 0) * Number(item?.count ?? 0);
      });
      if (showTransportFee) {
        totalFee += transportFee;
        totalFinalFee += transportFee;
      }
      setState({
        totalFee,
        totalDiscount,
        totalFinalFee,
      });
    }
  }, [
    feeData,
    showTransportFee,
    transportData,
    transportFeeData,
    transportFee,
  ]);

  return (
    <>
      <FeeStructureTableWrapper>
        <Grid
          container
          className="inner_box__head"
          display={"flex"}
          alignItems={"center"}
        >
          <Grid item xs={6}>
            <Typography className="inner_box_head_title">Fee Type</Typography>
            <Typography className="inner_box_head_title">
              General Fee Amount
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography
              className="inner_box_head_title"
              sx={{ color: "#2789FD !important" }}
            >
              Final Fee Amount
            </Typography>
          </Grid>
        </Grid>
        {feeData?.list?.length === 0 && !showTransportFee && (
          <Typography className="inner_box_notFound">
            No Fee Structure Found
          </Typography>
        )}
        {feeData?.list?.map((item, i) => (
          <Grid container sx={{ px: "10px", py: "5px" }} key={i}>
            <Grid item xs={6}>
              <Typography className="inner_box_head_title">
                {item?.feeType}
              </Typography>
              <Stack direction={"row"} alignItems={"flex-end"} gap={0.3}>
                <Typography className="inner_box_head_title">
                  {IndianCurrency(item?.generalFeeAmount)}
                </Typography>
                <Typography>
                  x{item?.count} ={" "}
                  <span className="inner_box_head_title">
                    {IndianCurrency(
                      Number(item?.count) * Number(item?.generalFeeAmount)
                    )}
                  </span>
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={6}>
              <Stack direction={"row"} alignItems={"flex-end"} gap={0.3}>
                <Typography
                  className="inner_box_head_title"
                  sx={{ color: "#2789FD !important" }}
                >
                  {IndianCurrency(item?.feeAmount)}
                </Typography>

                <Typography>
                  x{item?.count} ={" "}
                  <span className="inner_box_head_title">
                    {IndianCurrency(
                      Number(item?.count) * Number(item?.feeAmount)
                    )}
                  </span>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        ))}
        {showTransportFee && (
          <Grid container sx={{ px: "10px", py: "5px" }}>
            <Grid item xs={6}>
              <Typography className="inner_box_head_title">
                Transport Fee
              </Typography>
              <Stack direction={"row"} alignItems={"flex-end"} gap={0.3}>
                <Typography className="inner_box_head_title">
                  {IndianCurrency(transportFee)}
                </Typography>
                <Typography>
                  x1 ={" "}
                  <span className="inner_box_head_title">
                    {IndianCurrency(transportFee)}
                  </span>
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={6}>
              <Stack direction={"row"} alignItems={"flex-end"} gap={0.3}>
                <Typography
                  className="inner_box_head_title"
                  sx={{ color: "#2789FD !important" }}
                >
                  {IndianCurrency(transportFee)}
                </Typography>

                <Typography>
                  x1 ={" "}
                  <span className="inner_box_head_title">
                    {IndianCurrency(transportFee)}
                  </span>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        )}
      </FeeStructureTableWrapper>
      {feeData?.list?.length > 0 && (
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Box
            mr={5}
            mt={2}
            p={0.5}
            sx={{
              border: "1px solid rgba(0,0,0,0.6)",
              display: "inline-block",
            }}
            mb={2}
          >
            <Stack
              direction="row"
              alignItems={"center"}
              justifyContent={"flex-end"}
              gap={2}
            >
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Total Fee Amount :
                </Typography>
                <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                  {IndianCurrency(state.totalFee)}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Total Discount Amount:
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 600,
                    color: "#F0C62E",
                  }}
                >
                  {IndianCurrency(state.totalDiscount)}
                </Typography>
              </Stack>
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Final Fee Amount :
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 600,
                    color: "#2789FD",
                  }}
                >
                  {IndianCurrency(state.totalFinalFee)}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      )}
      {/* {feeData?.list?.length > 0 && (
        <Stack
          direction="row"
          alignItems={"center"}
          justifyContent={"flex-end"}
        >
          <Box
            mr={5}
            mt={2}
            p={0.5}
            sx={{
              border: "1px solid rgba(0,0,0,0.6)",
              display: "inline-block",
            }}
          >
            <Stack
              direction="column"
              alignItems={"center"}
              justifyContent={"flex-end"}
              gap={2}
            >
              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Total Fee Amount :
                </Typography>
                <Typography sx={{ fontSize: "15px", fontWeight: 600 }}>
                  {IndianCurrency(state.totalFee)}
                </Typography>
              </Stack>

              <Stack direction={"row"} alignItems={"center"} gap={1}>
                <Typography sx={{ fontSize: "14px", fontWeight: 500 }}>
                  Final Fee Amount :
                </Typography>
                <Typography
                  sx={{
                    fontSize: "15px",
                    fontWeight: 600,
                    color: "#2789FD",
                  }}
                >
                  {IndianCurrency(state.totalFinalFee)}
                </Typography>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      )} */}
    </>
  );
};

export default FeeStructureList;
