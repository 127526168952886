import React, { useState } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import CollectionvsExpense from "./CollectionvsExpense";
import TransferReport from "./TransferReport";
import { TabsWrapper } from "assets/styles/globalStyledComponent";
import { Container } from "@mui/material";
const LedgerReport = () => {
  const [value, setValue] = useState("1");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container maxWidth="xl">
      <Box sx={{ typography: "body" }}>
        <TabContext value={value}>
          <TabsWrapper>
            <TabList onChange={handleChange} aria-label="basic tabs example">
              <Tab label="Collection vs Expense" value="1" />
              <Tab label="Internal Transform Report" value="2" />
              {/* <Tab label="Forcast" value="3" /> */}
            </TabList>
          </TabsWrapper>
          <TabPanel value="1" sx={{ p: "0 !important" }}>
            <CollectionvsExpense />
          </TabPanel>
          <TabPanel value="2" sx={{ p: "0 !important" }}>
            <TransferReport />
          </TabPanel>
          {/* <TabPanel value="3">
              <Forcast />
            </TabPanel> */}
        </TabContext>
      </Box>
    </Container>
    //   </Wrapper>
    // </Container>
  );
};

export default LedgerReport;
