import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import Switch from "@mui/material/Switch";
import * as yup from "yup";
import { ArrowRight } from "react-bootstrap-icons";
import { useQuery } from "react-query";
import {
  Select,
  MenuItem,
  FormControlLabel,
  OutlinedInput,
  Checkbox,
  Stack,
  Box,
  Typography,
  Input,
} from "@mui/material";
import styled from "styled-components";
import {
  getAllFeeStructure,
  getAllRouteList,
} from "app/services/student-management";
import { CircularProgress } from "@mui/material";
import DialogContent from "@mui/material/DialogContent";
import Dialog from "@mui/material/Dialog";
import uploadFile from "app/utils/uploadFile";
import DialogTitle from "@mui/material/DialogTitle";
import { PrevSchoolInfoComponent } from "./PrevSchoolInfo";
import {
  GenderChoice,
  Category,
  Community,
  BLOOD_GROUP_LIST,
  DISABLITY_LIST,
  SPECIAL_CATEGORY,
  Distrct,
  States,
} from "app/config/administrative.constant.ts";
import {
  CustomTextField,
  CustomTextFieldDisabled,
} from "assets/styles/globalStyledComponent";
import { MenuProps } from "app/utils/helper";
import {
  HOUSE_MEMBERSHIP,
  HouseColor,
} from "app/config/administrativeConstants";
import CustomLoader from "app/components/common/CustomLoader";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const validationSchema = yup.object({
  fName: yup.string("Enter First Name").required("First Name is required"),
  DOB: yup.string("Enter Date of Birth").required("Date of Birth is required"),
  gender: yup.string("Enter Gender").required("Gender is required"),
  mobile: yup
    .string("Enter Mobile Number")
    .required("Mobile Number is required")
    .min(10, "Invalid Mobile Number")
    .max(10, "Invalid Mobile Number"),
  community: yup.string("Enter Community").required("Community is required"),
  category: yup.string("Enter Category").required("Category is required"),
  m_fName: yup.string("Enter First Name").required("First Name is required"),
  f_fName: yup.string("Enter First Name").required("First Name is required"),
  m_mobile: yup
    .string("Enter Mobile Number")
    .min(10, "Invalid Mobile Number")
    .max(10, "Invalid Mobile Number"),
  f_mobile: yup
    .string("Enter Mobile Number")
    .min(10, "Invalid Mobile Number")
    .max(10, "Invalid Mobile Number"),
  c_address: yup
    .string("Enter Current Address")
    .required("Current Address is required"),
  c_pinCode: yup
    .string("Enter Current Pincode")
    .required("Pincode is required")
    .min(6, "Invalid PinCode")
    .max(6, "Invalid PinCode"),
  classStream: yup
    .string("Enter Class-Stream")
    .required("Class-Stream is required"),
});

export const PersonalDetails = ({ mainFormik }) => {
  const [dummyAadharSwitch, setDummyAadharSwitch] = useState(false);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [attachmentFile, setAttachmentFile] = useState(null);
  const [attachedFileUrl, setAttachedFileUrl] = useState("");
  const [isLoading, setLoading] = useState(false);
  mainFormik.values.profilePic = attachedFileUrl;
  mainFormik.values.dummyAADHAR = dummyAadharSwitch;
  if (dummyAadharSwitch) {
    mainFormik.values.AADHAR = null;
  }
  const handleUploadFile = async () => {
    if (attachmentFile) {
      setLoading(true);
      let s3FilePath = `student/${attachmentFile.name}`;

      let attachmentUrl = await uploadFile(
        attachmentFile,
        s3FilePath,
        attachmentFile.type
      );
      setAttachedFileUrl(attachmentUrl.fileURL);
      setLoading(false);
      setOpenUploadDialog(false);
    }
  };
  return (
    <Wrapper>
      <Dialog
        sx={{ height: "auto" }}
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
      >
        <DialogTitle>
          <h3>Upload Profile Picture</h3>
        </DialogTitle>
        <DialogContent>
          <input
            className="m-2"
            type="file"
            accept="image/*"
            onChange={(e) => setAttachmentFile(e.target.files[0])}
          />
          <ButtonWrapper>
            {isLoading ? (
              <CircularProgress />
            ) : (
              <UploadBtn
                className="mt-5 "
                onClick={() => {
                  handleUploadFile();
                }}
              >
                Upload
              </UploadBtn>
            )}
          </ButtonWrapper>
        </DialogContent>
      </Dialog>
      <form>
        <div className="d-flex flex-row justify-content-start align-items-center">
          {attachedFileUrl ? (
            <ProfilePic src={attachedFileUrl} />
          ) : (
            <UploadProfilePicDiv />
          )}
          <UploadProfilePicBtn
            onClick={() => {
              setOpenUploadDialog(true);
            }}
          >
            <p>Upload</p>
          </UploadProfilePicBtn>
        </div>

        <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 1 }}>
          <CustomTextField
            size="small"
            label="First Name"
            required
            id="firstName"
            value={mainFormik.values.firstName}
            name="firstName"
            sx={{ flex: 1 }}
            onChange={mainFormik.handleChange}
            error={
              mainFormik.touched.firstName && mainFormik?.errors?.firstName
            }
          />
          {mainFormik.errors.fName && <div>{mainFormik.errors.fName}</div>}
          <CustomTextField
            size="small"
            label="Middle Name"
            id="middleName"
            value={mainFormik.values.middleName}
            name="middleName"
            sx={{ flex: 1 }}
            onChange={mainFormik.handleChange}
          />
          <CustomTextField
            size="small"
            label="Last Name"
            id="lastName"
            value={mainFormik.values.lastName}
            name="lastName"
            sx={{ flex: 1 }}
            onChange={mainFormik.handleChange}
          />
          <Box sx={{ flex: 1, color: "#000" }}>
            <CustomTextField
              size="small"
              select
              id="gender"
              required
              fullWidth
              value={mainFormik.values.gender}
              error={mainFormik.touched.gender && mainFormik.errors?.gender}
              name="gender"
              onChange={mainFormik.handleChange}
              label="Gender"
              SelectProps={{
                MenuProps,
              }}
            >
              {GenderChoice.map((gender) => (
                <MenuItem key={gender} value={gender}>
                  {gender}
                </MenuItem>
              ))}
            </CustomTextField>
          </Box>
        </Stack>
        <Stack
          direction={{ xs: "column", md: "row" }}
          gap={2}
          sx={{ pt: 2 }}
          alignItems="center"
        >
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              inputFormat="DD-MM-YYYY"
              onChange={(e) =>
                mainFormik.setValues((prev) => ({ ...prev, DOB: e }))
              }
              value={mainFormik.values.DOB}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  size="small"
                  label="DOB"
                  fullWidth
                  sx={{ width: { xs: "90%", md: "300px" } }}
                />
              )}
            />
          </LocalizationProvider>
          {/* <CustomTextField
            id="DOB"
            size="small"
            required
            label="DOB"
            value={mainFormik.values.DOB}
            name="DOB"
            sx={{ flex: 1 }}
            onChange={mainFormik.handleChange}
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => (e.target.type = "text")}
            error={mainFormik.touched.DOB && mainFormik.errors?.DOB}
          /> */}
          {dummyAadharSwitch ? (
            <CustomTextFieldDisabled
              label="Dummy Aadhar"
              id="AADHAR"
              sx={{ flex: 1 }}
              inputProps={{ maxLength: 12 }}
              size="small"
              fullWidth
            />
          ) : (
            <CustomTextField
              fullWidth
              label="Aadhaar Number"
              id="AADHAR"
              value={mainFormik.values.AADHAR}
              name="AADHAR"
              sx={{ flex: 1 }}
              onChange={mainFormik.handleChange}
              inputProps={{ maxLength: 12 }}
              size="small"
              error={mainFormik.touched.AADHAR && mainFormik.errors?.AADHAR}
            />
          )}
          <Box sx={{ flex: 1 }}>
            <FormControlLabel
              control={
                <Switch
                  defaultChecked={false}
                  onChange={() => setDummyAadharSwitch(!dummyAadharSwitch)}
                />
              }
              label="Dummy Aadhar"
            />
          </Box>
          <CustomTextField
            fullWidth
            label="Email"
            size="small"
            id="email"
            type="email"
            value={mainFormik.values.email}
            name="email"
            sx={{ flex: 1 }}
            onChange={mainFormik.handleChange}
          />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
          <CustomTextField
            label="Mobile Number"
            id="mobileNumber"
            size="small"
            required
            value={mainFormik.values.mobileNumber}
            name="mobileNumber"
            sx={{ flex: 1 }}
            onChange={mainFormik.handleChange}
            inputProps={{ maxLength: 10 }}
            error={
              mainFormik.touched.mobileNumber && mainFormik.errors?.mobileNumber
            }
          />
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              select
              id="community"
              size="small"
              required
              fullWidth
              value={mainFormik.values.community}
              name="community"
              onChange={mainFormik.handleChange}
              label="Community"
              SelectProps={{
                MenuProps,
              }}
              error={
                mainFormik.touched.community && mainFormik.errors?.community
              }
            >
              {Community.map((community) => (
                <MenuItem key={community} value={community}>
                  {community}
                </MenuItem>
              ))}
            </CustomTextField>
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box sx={{ flex: 1 }} />
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              select
              id="category"
              fullWidth
              size="small"
              required
              value={mainFormik.values.category}
              name="category"
              onChange={mainFormik.handleChange}
              label="Category"
              SelectProps={{
                MenuProps,
              }}
              error={mainFormik.touched.category && mainFormik.errors?.category}
            >
              {Category.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </CustomTextField>
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              fullWidth
              select
              id="specialCategory"
              size="small"
              value={mainFormik.values.specialCategory}
              name="specialCategory"
              onChange={mainFormik.handleChange}
              label="Special Category"
              SelectProps={{
                MenuProps,
              }}
            >
              <MenuItem value="">None</MenuItem>
              {SPECIAL_CATEGORY.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </CustomTextField>
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              select
              id="disability"
              fullWidth
              size="small"
              value={mainFormik.values.disability}
              name="disability"
              onChange={mainFormik.handleChange}
              label="Disability"
              SelectProps={{
                MenuProps,
              }}
            >
              <MenuItem value="">None</MenuItem>
              {DISABLITY_LIST.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </CustomTextField>
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              select
              id="bloodGroup"
              fullWidth
              size="small"
              required
              value={mainFormik.values.bloodGroup}
              name="bloodGroup"
              onChange={mainFormik.handleChange}
              label="Blood Group"
              SelectProps={{
                MenuProps,
              }}
            >
              <MenuItem value="">None</MenuItem>
              {BLOOD_GROUP_LIST.map((category) => (
                <MenuItem key={category} value={category}>
                  {category}
                </MenuItem>
              ))}
            </CustomTextField>
          </Box>
        </Stack>
      </form>
    </Wrapper>
  );
};

export const ParentDetails = ({ mainFormik }) => {
  return (
    <Wrapper>
      <form>
        <Typography variant="h5">Mother's Info</Typography>
        <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              size="small"
              fullWidth
              required
              label="First Name"
              id="motherInfo.firstName"
              value={mainFormik.values.motherInfo.firstName}
              name="motherInfo.firstName"
              onChange={mainFormik.handleChange}
              error={
                mainFormik.touched?.motherInfo?.firstName &&
                mainFormik.errors?.motherInfo?.firstName
              }
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              fullWidth
              size="small"
              label="Middle Name"
              id="motherInfo.middleName"
              value={mainFormik.values.motherInfo.middleName}
              name="motherInfo.middleName"
              onChange={mainFormik.handleChange}
            />
          </Box>

          <Box sx={{ flex: 1 }}>
            <CustomTextField
              fullWidth
              size="small"
              label="Last Name"
              id="motherInfo.lastName"
              value={mainFormik.values.motherInfo.lastName}
              name="motherInfo.lastName"
              onChange={mainFormik.handleChange}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              fullWidth
              size="small"
              label="Email"
              id="motherInfo.email"
              type="email"
              value={mainFormik.values.motherInfo.email}
              name="motherInfo.email"
              onChange={mainFormik.handleChange}
            />
          </Box>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              fullWidth
              size="small"
              label="Mobile"
              id="motherInfo.mobileNumber"
              value={mainFormik.values.motherInfo.mobileNumber}
              name="motherInfo.mobileNumber"
              onChange={mainFormik.handleChange}
              error={
                mainFormik.touched?.motherInfo?.mobileNumber &&
                mainFormik.errors?.motherInfo?.mobileNumber
              }
              inputProps={{ maxLength: 10 }}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              fullWidth
              size="small"
              label="Occupation"
              id="motherInfo.occupation"
              value={mainFormik.values.motherInfo.occupation}
              name="motherInfo.occupation"
              onChange={mainFormik.handleChange}
            />
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box sx={{ flex: 1 }} />
        </Stack>

        {/* Father info */}
        <Typography sx={{ mt: 2 }} variant="h5">
          Father's Info
        </Typography>
        <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              required
              label="First Name"
              id="fatherInfo.firstName"
              fullWidth
              size="small"
              value={mainFormik.values.fatherInfo.firstName}
              name="fatherInfo.firstName"
              onChange={mainFormik.handleChange}
              error={
                mainFormik.touched?.fatherInfo?.firstName &&
                mainFormik.errors?.fatherInfo?.firstName
              }
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              label="Middle Name"
              id="fatherInfo.middleName"
              fullWidth
              size="small"
              value={mainFormik.values.fatherInfo.middleName}
              name="fatherInfo.middleName"
              onChange={mainFormik.handleChange}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              label="Last Name"
              id="fatherInfo.lastName"
              value={mainFormik.values.fatherInfo.lastName}
              name="fatherInfo.lastName"
              fullWidth
              size="small"
              onChange={mainFormik.handleChange}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              label="Email"
              id="fatherInfo.email"
              type="email"
              value={mainFormik.values.fatherInfo.email}
              name="fatherInfo.email"
              fullWidth
              size="small"
              onChange={mainFormik.handleChange}
            />
          </Box>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              label="Mobile"
              id="fatherInfo.mobileNumber"
              value={mainFormik.values.fatherInfo.mobileNumber}
              name="fatherInfo.mobileNumber"
              fullWidth
              size="small"
              inputProps={{ maxLength: 10 }}
              onChange={mainFormik.handleChange}
              error={
                mainFormik.touched?.fatherInfo?.mobileNumber &&
                mainFormik.errors?.fatherInfo?.mobileNumber
              }
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              label="Occupation"
              id="fatherInfo.occupation"
              value={mainFormik.values.fatherInfo.occupation}
              name="fatherInfo.occupation"
              fullWidth
              size="small"
              onChange={mainFormik.handleChange}
            />
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box sx={{ flex: 1 }} />
        </Stack>

        <Typography variant="h5" sx={{ mt: 2 }}>
          Guardian's Info
        </Typography>
        <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              label="First Name"
              id="guardianInfo.firstName"
              value={mainFormik.values.guardianInfo.firstName}
              name="guardianInfo.firstName"
              fullWidth
              size="small"
              onChange={mainFormik.handleChange}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              label="Middle Name"
              id="guardianInfo.middleName"
              value={mainFormik.values.guardianInfo.middleName}
              name="guardianInfo.middleName"
              fullWidth
              size="small"
              onChange={mainFormik.handleChange}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              label="Last Name"
              id="guardianInfo.lastName"
              value={mainFormik.values.guardianInfo.lastName}
              name="guardianInfo.lastName"
              fullWidth
              size="small"
              onChange={mainFormik.handleChange}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              label="Email"
              id="g_email"
              type="guardianInfo.email"
              value={mainFormik.values.guardianInfo.email}
              name="guardianInfo.email"
              fullWidth
              size="small"
              onChange={mainFormik.handleChange}
            />
          </Box>
        </Stack>
        <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              label="Mobile"
              id="guardianInfo.mobileNumber"
              value={mainFormik.values.guardianInfo.mobileNumber}
              name="guardianInfo.mobileNumber"
              fullWidth
              size="small"
              inputProps={{ maxLength: 10 }}
              onChange={mainFormik.handleChange}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <CustomTextField
              label="Relation"
              id="guardianInfo.relation"
              value={mainFormik.values.guardianInfo.relation}
              name="guardianInfo.relation"
              fullWidth
              size="small"
              onChange={mainFormik.handleChange}
            />
          </Box>
          <Box sx={{ flex: 1 }} />
          <Box sx={{ flex: 1 }} />
        </Stack>
      </form>
    </Wrapper>
  );
};

export const SiblingDetails = () => {
  const formik = useFormik({
    initialValues: {
      schoolName: " ",
      classInfo: " ",
      studentName: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2));
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <CustomTextField
        select
        id="schoolName"
        value={formik.values.schoolName}
        name="schoolName"
        onChange={formik.handleChange}
        label="School Name"
        sx={{ marginRight: "40px", width: "200px", color: "black" }}
        SelectProps={{
          MenuProps,
        }}
      >
        <MenuItem value={"ABC"}>ABC</MenuItem>
        <MenuItem value={"CDE"}>CDE</MenuItem>
        <MenuItem value={"DEF"}>DEF</MenuItem>
      </CustomTextField>
      <CustomTextField
        select
        fullWidth
        id="classInfo"
        value={formik.values.classInfo}
        name="classInfo"
        onChange={formik.handleChange}
        label="classInfo"
        sx={{ marginRight: "40px", width: "200px", color: "black" }}
        SelectProps={{
          MenuProps,
        }}
      >
        <MenuItem value={"1st A"}>1st A</MenuItem>
        <MenuItem value={"1st B"}>1st B</MenuItem>
        <MenuItem value={"1st C"}>1st C</MenuItem>
      </CustomTextField>

      {/* <InputLabel htmlFor="studentName" sx={{ width: "100px", display: "flex", position: "absolute" }}>
                    Bootstrap
                </InputLabel> */}
      <CustomTextField
        label="Student Name"
        id="studentName"
        value={formik.values.studentName}
        name="studenName"
        sx={{ width: "200px" }}
        onChange={formik.handleChange}
      />
    </form>
  );
};

export const AddressInfo = ({ mainFormik }) => {
  const [checked, setChecked] = React.useState(false);
  const [stateIndex, setStateIndex] = React.useState(0);
  const CheckBoxhandleChange = (event, values) => {
    setChecked(event.target.checked);
    values.permanentAddress.pinCode = values.currentAddress.pinCode;
    values.permanentAddress.state = values.currentAddress.state;
    values.permanentAddress.address = values.currentAddress.address;
    values.permanentAddress.dist = values.currentAddress.dist;
    mainFormik.validateForm();
  };

  useEffect(() => {
    if (checked) {
      mainFormik.values.permanentAddress.pinCode =
        mainFormik.values.currentAddress.pinCode;
      mainFormik.values.permanentAddress.state =
        mainFormik.values.currentAddress.state;
      mainFormik.values.permanentAddress.address =
        mainFormik.values.currentAddress.address;
      mainFormik.values.permanentAddress.dist =
        mainFormik.values.currentAddress.dist;
    }
  }, [checked]);
  return (
    <form>
      <Typography variant="h5">Current Address</Typography>
      <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            required
            label="Address"
            id="currentAddress.address"
            value={mainFormik.values.currentAddress.address}
            name="currentAddress.address"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
            error={
              mainFormik.touched?.currentAddress?.address &&
              mainFormik.errors?.currentAddress?.address
            }
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            required
            label="Pincode"
            id="currentAddress.pinCode"
            value={mainFormik.values.currentAddress.pinCode}
            name="currentAddress.pinCode"
            fullWidth
            size="small"
            inputProps={{ maxLength: 6 }}
            onChange={mainFormik.handleChange}
            error={
              mainFormik.touched?.currentAddress?.pinCode &&
              mainFormik.errors?.currentAddress?.pinCode
            }
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            select
            id="currentAddress.state"
            required
            value={mainFormik.values.currentAddress.state}
            name="currentAddress.state"
            onChange={mainFormik.handleChange}
            label="State"
            fullWidth
            size="small"
            SelectProps={{
              MenuProps,
            }}
            error={
              mainFormik.touched?.currentAddress?.state &&
              mainFormik.errors?.currentAddress?.state
            }
          >
            {States.map((state, index) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            select
            id="currentAddress.dist"
            required
            value={mainFormik.values.currentAddress.dist}
            name="currentAddress.dist"
            onChange={mainFormik.handleChange}
            label="District"
            fullWidth
            size="small"
            SelectProps={{
              MenuProps,
            }}
            error={
              mainFormik.touched?.currentAddress?.dist &&
              mainFormik.errors?.currentAddress?.dist
            }
          >
            {Distrct[
              States.indexOf(mainFormik.values.currentAddress.state) + 1
            ].map((district) => (
              <MenuItem key={district} value={district}>
                {district}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>
      </Stack>

      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={(e) => CheckBoxhandleChange(e, mainFormik.values)}
          />
        }
        label="Check the Box, If Current Address is Same as Permanent Address"
      />

      <Typography variant="h5">Permanent Address</Typography>
      <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            label="Address"
            id="permanentAddress.address"
            value={mainFormik.values.permanentAddress.address}
            name="permanentAddress.address"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
            error={mainFormik.errors?.permanentAddress?.address}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            label="Pincode"
            id="permanentAddress.pinCode"
            value={mainFormik.values.permanentAddress.pinCode}
            name="permanentAddress.pinCode"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
            error={
              mainFormik.touched?.permanentAddress?.pinCode &&
              mainFormik.errors?.permanentAddress?.pinCode
            }
            inputProps={{ maxLength: 6 }}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            select
            id="permanentAddress.state"
            required
            value={mainFormik.values.permanentAddress.state}
            name="permanentAddress.state"
            onChange={mainFormik.handleChange}
            label="State"
            fullWidth
            size="small"
            SelectProps={{
              MenuProps,
            }}
            error={
              mainFormik.touched?.permanentAddress?.state &&
              mainFormik.errors?.permanentAddress?.state
            }
          >
            {States.map((state, index) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            select
            id="permanentAddress.dist"
            required
            value={mainFormik.values.permanentAddress.dist}
            name="permanentAddress.dist"
            onChange={mainFormik.handleChange}
            label="District"
            fullWidth
            size="small"
            SelectProps={{
              MenuProps,
            }}
            error={
              mainFormik.touched?.permanentAddress?.dist &&
              mainFormik.errors?.permanentAddress?.dist
            }
          >
            {Distrct[
              States.indexOf(mainFormik.values.permanentAddress.state) + 1
            ].map((district) => (
              <MenuItem key={district} value={district}>
                {district}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>
      </Stack>
    </form>
  );
};

export const AdmissionDetail = ({
  mainFormik,
  classesResponse,
  setClass_Stream,
}) => {
  const [selectedStream, setSelectedStream] = useState();
  const classes = classesResponse?.data?.list;
  setClass_Stream(selectedStream);

  return (
    <form>
      <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
        <Box sx={{ flex: 1 }}>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              inputFormat="DD-MM-YYYY"
              onChange={(e) =>
                mainFormik.setValues((prev) => ({ ...prev, admissionDate: e }))
              }
              value={mainFormik.values.admissionDate}
              renderInput={(params) => (
                <CustomTextField
                  {...params}
                  size="small"
                  label="Admission Date"
                  fullWidth
                />
              )}
            />
          </LocalizationProvider>
          {/* <CustomTextField
            label="Admission Date"
            id="admissionDate"
            value={mainFormik.values.admissionDate}
            name="admissionDate"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
            onFocus={(e) => (e.target.type = "date")}
            onBlur={(e) => (e.target.type = "text")}
            error={
              mainFormik.touched?.admissionDate &&
              mainFormik.errors?.admissionDate
            }
          /> */}
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            select
            id="admissionType"
            value={mainFormik.values.admissionType}
            name="admissionType"
            onChange={mainFormik.handleChange}
            label="Admission Type"
            fullWidth
            size="small"
            error={
              mainFormik.touched?.admissionType &&
              mainFormik.errors?.admissionType
            }
            SelectProps={{
              MenuProps,
            }}
          >
            <MenuItem value={"Regular"}>Regular</MenuItem>
            <MenuItem value={"Private"}>Private</MenuItem>
          </CustomTextField>
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            label="Registration Number"
            id="registrationNumber"
            value={mainFormik.values.registrationNumber}
            name="registrationNumber"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            label="Admission Number"
            id="admissionNumber"
            value={mainFormik.values.admissionNumber}
            name="admissionNumber"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
          />
        </Box>
      </Stack>
      <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            select
            id="classDocId"
            value={mainFormik.values.classDocId}
            name="classDocId"
            onChange={mainFormik.handleChange}
            label="Class Stream"
            fullWidth
            size="small"
            SelectProps={{
              MenuProps,
            }}
            error={
              mainFormik.touched?.classDocId && mainFormik.errors?.classDocId
            }
          >
            {classes?.map((stream) => (
              <MenuItem
                key={stream.classDocId}
                onClick={() => {
                  mainFormik.values.classDocId = stream.classDocId;
                  mainFormik.values.section = "";
                  mainFormik.values.admissionFeeCode = "";
                  mainFormik.values.monthlyFeeCode = "";
                  setSelectedStream(stream);
                }}
                value={stream.classDocId}
              >
                {stream.className_stream}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            select
            id="section"
            value={mainFormik.values.section}
            name="section"
            onChange={mainFormik.handleChange}
            label="Section"
            fullWidth
            size="small"
            SelectProps={{
              MenuProps,
            }}
            error={mainFormik.touched?.section && mainFormik.errors.section}
          >
            {selectedStream?.sections?.map((section, index) => (
              <MenuItem key={index} value={section}>
                {section}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            label="Roll Number"
            id="rollNumber"
            value={mainFormik.values.rollNumber}
            name="rollNumber"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            label="Sr Number"
            id="srNumber"
            value={mainFormik.values.srNumber}
            name="srNumber"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
          />
        </Box>
      </Stack>
    </form>
  );
};

export const PrevSchoolInfo = () => {
  const [count, setCount] = React.useState(1);

  return (
    <>
      <PrevSchoolInfoComponent />
    </>
  );
};

export const Transportation = ({ setSelectedStop, mainFormik }) => {
  const { refetch: fetchRouteDetails, data: routeResponse } = useQuery({
    queryKey: ["getAllRouteList"],
    queryFn: () => getAllRouteList(),
    enabled: false,
  });

  const [selectedRoute, setSelectedRoute] = React.useState(null);
  // const mainFormik = usemainFormik({
  //   initialValues: {
  //     usingTransport: " ",
  //     routeNo: " ",
  //     routeDetails: "",
  //     transportVehicle: " ",
  //     to: " ",
  //     remarks: "",
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: (values) => {
  //     alert(JSON.stringify(values, null, 2));
  //   },
  // });

  // Change Route and vehicale detail based on usingTransport variable
  useEffect(() => {
    if (mainFormik.values.transportInfo.using === "No") {
      mainFormik.values.transportInfo.feeAmount = 0;
      mainFormik.values.transportInfo.to = null;
      mainFormik.values.transportInfo.vehicleNumber = null;
      mainFormik.values.transportInfo.remarks = null;
      mainFormik.values.transportInfo.routeNumber = null;
      mainFormik.values.transportationFeeCode = null;

      setSelectedRoute(null);
    }
  }, [mainFormik.values.transportInfo.using]);
  if (mainFormik.values.transportInfo.vehicleNumber === "") {
    mainFormik.values.transportInfo.vehicleNumber = " ";
  }
  useEffect(() => {
    if (mainFormik.values.transportInfo.using === "Yes") {
      fetchRouteDetails();
      mainFormik.values.transportInfo.routeNumber = " ";
      mainFormik.values.transportInfo.to = " ";
    }
  }, [mainFormik.values.transportInfo.using]);

  return (
    <Wrapper>
      <form>
        <CustomTextField
          fullWidth
          select
          id="transportInfo.using"
          value={mainFormik.values.transportInfo.using}
          name="transportInfo.using"
          onChange={mainFormik.handleChange}
          label="Transport Using"
          size="small"
          sx={{
            width: { xs: "90%", md: "200px" },
            color: "black",
            margin: "10px",
          }}
          SelectProps={{
            MenuProps,
          }}
          error={
            mainFormik.touched?.transportInfo?.using &&
            mainFormik.errors?.transportInfo?.using
          }
        >
          <MenuItem value={"Yes"}>Yes</MenuItem>
          <MenuItem value={"No"}>No</MenuItem>
        </CustomTextField>

        {mainFormik.values.transportInfo.using === "Yes" && routeResponse && (
          <>
            <CustomTextField
              fullWidth
              select
              size="small"
              id="transportInfo.routeNumber"
              value={mainFormik.values.transportInfo.routeNumber}
              name="transportInfo.routeNumber"
              onChange={mainFormik.handleChange}
              label="Route No. (Route Name)"
              sx={{
                width: { xs: "90%", md: "200px" },
                color: "black",
                margin: "10px",
              }}
              SelectProps={{
                MenuProps,
              }}
            >
              {routeResponse.data.list?.map((route) => (
                <MenuItem
                  key={route._id}
                  value={route.routeNumber}
                  onClick={() => setSelectedRoute(route)}
                >
                  {route.routeNumber} ({route?.routeName})
                </MenuItem>
              ))}
            </CustomTextField>

            <CustomTextField
              fullWidth
              select
              size="small"
              id="transportInfo.to"
              value={mainFormik.values.transportInfo.to}
              name="transportInfo.to"
              onChange={mainFormik.handleChange}
              label="To"
              sx={{
                margin: "10px",
                width: { xs: "90%", md: "200px" },
                color: "black",
              }}
              SelectProps={{
                MenuProps,
              }}
            >
              {selectedRoute ? null : (
                <MenuItem value={"No result"}>No results</MenuItem>
              )}
              {selectedRoute?.stopList?.map((stop) => (
                <MenuItem
                  value={stop.stopName}
                  key={stop._id}
                  onClick={() => setSelectedStop(stop.stopName)}
                >
                  {stop.stopName}
                </MenuItem>
              ))}
            </CustomTextField>

            <CustomTextField
              fullWidth
              select
              size="small"
              id="transportInfo.vehicleNumber"
              value={mainFormik.values.transportInfo.vehicleNumber}
              name="transportInfo.vehicleNumber"
              onChange={mainFormik.handleChange}
              label="Transport Vehicle"
              sx={{
                width: { xs: "90%", md: "200px" },
                color: "black",
                margin: "10px",
              }}
              SelectProps={{
                MenuProps,
              }}
            >
              {selectedRoute ? null : (
                <MenuItem value={"No result"}>No results</MenuItem>
              )}
              {selectedRoute?.assignedVehicle?.map((vehicle) => (
                <MenuItem
                  key={vehicle.vehicleNumber}
                  value={vehicle.vehicleNumber}
                >
                  {vehicle.vehicleNumber}
                </MenuItem>
              ))}
            </CustomTextField>

            <div className="route-details">
              <span> Route Details :- </span>
              {selectedRoute?.stopList?.map((stop) => (
                <>
                  <span key={stop._id}>
                    {stop.stopName} ({stop.distance} -{" "}
                    {Math.floor(stop.duration / 60) != 0
                      ? Math.floor(stop.duration / 60) + "Hrs"
                      : ""}{" "}
                    {stop.duration % 60 != 0
                      ? (stop.duration % 60) + "mins"
                      : ""}
                    )
                  </span>
                  <span key={stop._id}>
                    <ArrowRight color="royalblue" size={20} />
                  </span>
                </>
              ))}
            </div>

            <CustomTextField
              fullWidth
              size="small"
              label="Remarks (If Any)"
              id="transportInfo.remarks"
              value={mainFormik.values.transportInfo.remarks}
              name="transportInfo.remarks"
              sx={{ width: { xs: "90%", md: "400px" }, margin: "10px" }}
              onChange={mainFormik.handleChange}
            />
          </>
        )}
      </form>
    </Wrapper>
  );
};

export const Hostel = ({ mainFormik }) => {
  return (
    <form>
      <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            label="Hostel Name"
            id="hostelInfo.hostelName"
            value={mainFormik.values.hostelInfo.hostelName}
            name="hostelInfo.hostelName"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            label="Room Type"
            id="hostelInfo.roomType"
            value={mainFormik.values.hostelInfo.roomType}
            name="hostelInfo.roomType"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
          />
        </Box>
        <Box sx={{ flex: 1 }}>
          <CustomTextField
            label="Room Number"
            id="hostelInfo.roomNumber"
            value={mainFormik.values.hostelInfo.roomNumber}
            name="hostelInfo.roomNumber"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
          />
        </Box>
      </Stack>
    </form>
  );
};

export const House = ({ mainFormik }) => {
  // const formik = useFormik({
  //   initialValues: {
  //     houseColor: "",
  //     houseName: "",
  //     membershipRole: " ",
  //   },
  //   validationSchema: validationSchema,
  //   onSubmit: (values) => {
  //     alert(JSON.stringify(values, null, 2));
  //   },
  // });
  if (mainFormik.values.membershipRole === "") {
    mainFormik.values.membershipRole = " ";
  }
  return (
    <form onSubmit={mainFormik.handleSubmit}>
      <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
        <Box flex={1}>
          <CustomTextField
            select
            label="House Color"
            id="house.houseColor"
            value={mainFormik.values.house.houseColor}
            name="house.houseColor"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
            SelectProps={{
              MenuProps,
            }}
          >
            {HouseColor.map((item, ind) => (
              <MenuItem value={item} key={ind}>
                {item}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>
        <Box flex={1}>
          <CustomTextField
            label="House Name"
            id="house.houseName"
            value={mainFormik.values.house.houseName}
            name="house.houseName"
            fullWidth
            size="small"
            onChange={mainFormik.handleChange}
          />
        </Box>
        <Box flex={1}>
          <CustomTextField
            select
            id="house.membershipRole"
            value={mainFormik.values.house.membershipRole}
            name="house.membershipRole"
            onChange={mainFormik.handleChange}
            label="Membership Role"
            fullWidth
            size="small"
            SelectProps={{
              MenuProps,
            }}
          >
            {HOUSE_MEMBERSHIP?.map((member) => (
              <MenuItem value={member} key={member}>
                {member}
              </MenuItem>
            ))}
          </CustomTextField>
        </Box>
      </Stack>
    </form>
  );
};

export const FeeStruct = ({
  transportFee,
  mainFormik,
  selectedSession,
  selectedMedium,
  setMiscFeeVals,
  setRelaxation,
}) => {
  const [selectedFee, setSelectedFee] = useState({
    selectedMonthlyFee: {
      feeCode: "",
    },
    selectedHostelFee: { feeCode: "" },
    selectedAdmissionFee: { feeCode: "" },
    selectedTransportFee: { feeCode: "" },
  });
  const [state, setState] = useState({
    monthlyFee: [],
    hostelFee: [],
    admissionFee: [],
  });
  setMiscFeeVals(selectedFee);
  mainFormik.values.monthlyFeeCode = selectedFee?.selectedMonthlyFee?.feeCode;
  mainFormik.values.transportationFeeCode =
    mainFormik.values.transportInfo.using === "Yes"
      ? selectedFee?.selectedTransportFee?.feeCode
      : null;
  mainFormik.values.hostelFeeCode = selectedFee?.selectedHostelFee?.feeCode;
  mainFormik.values.transportInfo.feeAmount =
    mainFormik.values.transportInfo.using === "Yes"
      ? selectedFee?.selectedTransportFee?.feeAmount
      : 0;
  mainFormik.values.admissionFeeCode =
    selectedFee?.selectedAdmissionFee?.feeCode;

  const { isLoading: loadingFeeStruct, refetch: fetchFeeStructureData } =
    useQuery({
      queryKey: ["getAllFeeStructure"],
      queryFn: () =>
        getAllFeeStructure(
          selectedMedium,
          selectedSession,
          mainFormik.values.classDocId,
          mainFormik.values.section
        ),
      onSuccess: ({ data }) => {
        const monthlyFee = [];
        const hostelFee = [];
        const admissionFee = [];
        data?.list?.forEach((fee) => {
          if (fee.feeType === "Monthly Fee") {
            monthlyFee.push(fee);
          }
          if (fee.feeType === "Hostel Fee") {
            hostelFee.push(fee);
          }
          if (fee.feeType === "Admission Fee") {
            admissionFee.push(fee);
          }
          if (fee.feeType === "Transport Fee") {
            transportFee.push(fee);
          }
        });
        setState((prev) => ({ ...prev, monthlyFee, hostelFee, admissionFee }));
      },
      enabled: false,
    });

  useEffect(() => {
    if (mainFormik.values.section) {
      fetchFeeStructureData();
    }
  }, [selectedMedium, selectedSession, mainFormik.values.section]);

  return (
    <>
      <CustomLoader show={loadingFeeStruct} />
      <form>
        <Stack direction={{ xs: "column", md: "row" }} gap={2} sx={{ pt: 2 }}>
          <Box flex={1}>
            <CustomTextField
              select
              id="admissionFeeCode"
              value={mainFormik.values.admissionFeeCode}
              name="admissionFeeCode"
              onChange={mainFormik.handleChange}
              label="Admission FeeCode"
              fullWidth
              size="small"
              SelectProps={{
                MenuProps,
              }}
              error={
                mainFormik.touched?.admissionFeeCode &&
                mainFormik.errors?.admissionFeeCode
              }
            >
              {state.admissionFee?.map((fee) => (
                <MenuItem
                  key={fee._id}
                  onClick={() => {
                    selectedFee.selectedAdmissionFee = fee;
                    setRelaxation((prev) => ({
                      ...prev,
                      admissionFeeRelaxationCategory:
                        fee?.feeRelaxationCategory,
                    }));
                  }}
                  value={fee.feeCode}
                >
                  {fee.feeCode} - Rs.{fee.feeAmount}{" "}
                  {fee?.feeRelaxationCategory &&
                    `- ${fee?.feeRelaxationCategory}`}
                </MenuItem>
              ))}
            </CustomTextField>
          </Box>
          <Box flex={1}>
            <CustomTextField
              select
              id="monthlyFeeCode"
              value={mainFormik.values.monthlyFeeCode}
              name="monthlyFeeCode"
              onChange={mainFormik.handleChange}
              label="Monthly FeeCode"
              fullWidth
              size="small"
              SelectProps={{
                MenuProps,
              }}
              error={
                mainFormik.touched?.monthlyFeeCode &&
                mainFormik.errors?.monthlyFeeCode
              }
            >
              {state.monthlyFee?.map((fee) => (
                <MenuItem
                  key={fee._id}
                  onClick={() => {
                    selectedFee.selectedMonthlyFee = fee;
                    setRelaxation((prev) => ({
                      ...prev,
                      monthlyFeeRelaxationCategory: fee?.feeRelaxationCategory,
                    }));
                  }}
                  value={fee.feeCode}
                >
                  {fee.feeCode} - Rs.{fee.feeAmount}{" "}
                  {fee?.feeRelaxationCategory &&
                    `- ${fee?.feeRelaxationCategory}`}
                </MenuItem>
              ))}
            </CustomTextField>
          </Box>
          {mainFormik.values.transportInfo.using === "Yes" ? (
            <Box flex={1}>
              <CustomTextField
                select
                id="transportationFeeCode"
                value={mainFormik.values.transportationFeeCode}
                name="transportationFeeCode"
                onChange={mainFormik.handleChange}
                label="Transport FeeCode"
                fullWidth
                error={
                  mainFormik.touched?.transportationFeeCode &&
                  mainFormik.errors?.transportationFeeCode
                }
                size="small"
                SelectProps={{
                  MenuProps,
                }}
              >
                {transportFee?.data?.list?.map((fee) => (
                  <MenuItem
                    key={fee._id}
                    onClick={() => {
                      selectedFee.selectedTransportFee = fee;
                      setRelaxation((prev) => ({
                        ...prev,
                        transportationFeeRelaxationCategory:
                          fee?.feeRelaxationCategory,
                      }));
                    }}
                    value={fee.feeCode}
                  >
                    {fee.feeCode} - Rs.{fee.feeAmount}{" "}
                    {fee?.feeRelaxationCategory &&
                      `- ${fee?.feeRelaxationCategory}`}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Box>
          ) : null}
          {mainFormik.values.hostelInfo.hostelName && (
            <Box flex={1}>
              <CustomTextField
                select
                id="hostelFeeCode"
                value={mainFormik.values.hostelFeeCode}
                name="hostelFeeCode"
                onChange={mainFormik.handleChange}
                label="Hostel FeeCode"
                fullWidth
                size="small"
                SelectProps={{
                  MenuProps,
                }}
              >
                {state.hostelFee?.map((fee) => (
                  <MenuItem
                    key={fee._id}
                    onClick={() => {
                      selectedFee.selectedHostelFee = fee;
                      setRelaxation((prev) => ({
                        ...prev,
                        hostelFeeRelaxationCategory: fee?.feeRelaxationCategory,
                      }));
                    }}
                    value={fee.feeCode}
                  >
                    {fee.feeCode} - Rs.{fee.feeAmount}{" "}
                    {fee?.feeRelaxationCategory &&
                      `- ${fee?.feeRelaxationCategory}`}
                  </MenuItem>
                ))}
              </CustomTextField>
            </Box>
          )}
        </Stack>

        {/*  Show transport dropdown */}
      </form>
    </>
  );
};

export const UploadProfilePicBtn = styled.button`
  background: #ffffff;
  border: 1px solid #3d3efd;
  border-radius: 20px;
  width: 7rem;
  display: flex;
  height: 3rem;
  justify-content: center;
  align-items: center;
  p {
    padding-top: 12px;
    font-weight: 500;
    font-size: 11px;
    color: #3d3efd;
  }
`;
export const UploadProfilePicDiv = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 98px;
  background: #d0d0e2;
  border-radius: 20px;
  margin: 10px;
`;

export const ProfilePic = styled.img`
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98px;
  background: #d0d0e2;
  border-radius: 20px;
  margin: 10px;
`;

export const Wrapper = styled.div`
  position: relative;
  border-radius: round;
  overflow: hidden !important;
  margin: 2px;
  flex-wrap: wrap;
  .separate {
    margin: 20px 0 10px 0;
  }
  .route-details {
    margin: 7px 7px 5px 12px;
    font-size: 15px;
  }
  .route-details span {
    margin: 3px;
  }
`;
export const UploadBtn = styled.button`
  height: 50px;
  width: 10rem;
  background: #2789fd;
  margin: 5px;
  border-radius: 5px;
  color: white;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
  &:hover {
    background-color: white;
    border: 2px solid #2789fd;
    color: #2789fd;
    transition: 100ms all ease-in-out;
  }
`;
export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: right;
  margin: 10px;
`;
