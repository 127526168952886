import { ArrowForwardIos } from "@mui/icons-material";
import {
  Divider,
  Collapse,
  Stack,
  Checkbox,
  Typography,
  Box,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
const RouteStopDropDown = ({ item, formikState, index, formikSetState }) => {
  const [expand, setExpand] = useState(false);
  const [data, setData] = useState(item);
  const [update, setUpdate] = useState(false);
  const isMounted = useRef(false);
  const handleCheckRoute = () => {
    if (data?.checked) {
      data["checked"] = false;
      data?.stopList?.forEach((stop) => {
        stop["checked"] = false;
      });
      setUpdate(!update);
    } else {
      data?.stopList?.forEach((stop) => {
        stop["checked"] = true;
      });
      data["checked"] = true;
    }
    setUpdate(!update);
  };
  const handleCheckStop = () => {
    let allStopCheck = true;
    data?.stopList?.forEach((sec) => {
      if (!sec.checked) {
        allStopCheck = false;
      }
      data["checked"] = allStopCheck;
      setUpdate(!update);
    });
  };

  useEffect(() => {
    if (isMounted.current) {
      const filteredByRouteNumber = formikState?.postRouteList?.filter(
        (res) => res?.routeNumber !== data?.routeNumber
      );
      const newArray = [];
      data?.stopList?.forEach((res) => {
        if (res?.checked) {
          newArray.push({
            routeNumber: data?.routeNumber,
            routeName: data?.routeName,
            stopName: res?.stopName,
            checked: true,
            display: `${data?.routeNumber}(${data?.routeName})-${res?.stopName}`,
          });
        }
      });

      formikSetState((prev) => ({
        ...prev,
        postRouteList: [...filteredByRouteNumber, ...newArray],
      }));
    } else {
      isMounted.current = true;
    }
  }, [update]);
  return (
    <>
      <Box key={data?.routeNumber}>
        <Stack
          direction="row"
          alignItems="center"
          sx={{ px: 1, py: 0.5, width: "100%" }}
        >
          <Stack direction="row" alignItems="center" gap={1}>
            <Checkbox
              color="secondary"
              checked={data?.checked}
              onChange={handleCheckRoute}
            />
            <Typography>
              {data?.routeNumber}({data?.routeName})
            </Typography>
            <Typography
              sx={{
                color: "blue",
                fontWeight: 600,
                fontSize: "14px",
                mt: "-1px",
              }}
            >
              {data?.stopList
                ?.filter((item) => item.checked)
                .map((item) => item?.stopName)
                .join(", ")}
            </Typography>
          </Stack>
          <Stack
            direction="row"
            justifyContent="flex-end"
            onClick={() => setExpand(!expand)}
            sx={{ cursor: "pointer", flexGrow: 1 }}
          >
            <ArrowForwardIos
              sx={{
                fontSize: "14px",
                transform: expand
                  ? "rotate(270deg) !important"
                  : "rotate(0deg) !important",
                cursor: "pointer",
              }}
            />
          </Stack>
        </Stack>
        <Divider sx={{ background: "#B6C8D6" }} />
        <Collapse in={expand}>
          <Stack direction="row" gap={1} sx={{ pl: 5 }} flexWrap={"wrap"}>
            {data?.stopList?.map((stop) => (
              <Stack direction="row" alignItems="center" key={stop?.stopName}>
                <Checkbox
                  color="secondary"
                  checked={stop?.checked}
                  onChange={(e) => {
                    stop["checked"] = !stop?.checked;
                    handleCheckStop();
                  }}
                />
                <Typography>{stop?.stopName}</Typography>
              </Stack>
            ))}
          </Stack>
          <Divider sx={{ background: "#B6C8D6" }} />
        </Collapse>
      </Box>
    </>
  );
};

export default RouteStopDropDown;
